import {
  CheckCircleFilled,
} from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Col, message, Row, Space, Typography, Upload } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import LoadingUploadView from './views/LoadingUploadView';
import DefaultUploadView from './views/DefaultUploadView';
import SuccessUploadView from './views/SuccessUploadView';
import ErrorUploadView from './views/ErrorUploadView';
import ModelPreview from './views/ModeelPreview';
import './style.less';
import { modelUploaderToAWSTemporary } from '../../../../../api/model';
import { getTemporaryUploadedModelFileLocationUrl } from '../../../../../utils/util';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import { getAWSUrl } from '../../../utils/aws';
import { toJS } from 'mobx';
import { validateBytes } from 'gltf-validator'
import { filterUnsupportedFeatures } from '../../../../../utils/model';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';
import { MAX_FILE_SIZE } from '../../../../../constants/project/project';
import { BEST_PRACTICE_DOC_LINK } from '../../../../../constants/links';
import WarningUploadView from './views/WarniingUploadView';
import { authProvider } from '../../../../../authPrvider/authProvider';
import {
  checkWarningLimitModalUploadPopupVisibility,
  isSubscriptionModelUploadLimitsAchieved,
} from '../../../../../utils/subscription';
import { checkIfAndroidInterfaceAvailable } from '../../../../../utils/checkMobile';

const { Dragger } = Upload;

interface ModelUploaderStepViewProps {
  onModelUploaded: (modelFile: any) => Promise<void>;
  setModelFileForPrew: any
}

interface ArrayIssues {
    code: string;
    message: string;
    pointer?: string;
    severity: number;
}

const ModelUploaderStepView: React.FC<ModelUploaderStepViewProps> = observer(({ 
  onModelUploaded,
  setModelFileForPrew }) => {
  const [uploadState, setUploadState] = useState<any>('');
  const [modelFile, setModelFile] = useState<any>(null);
  const [warningFileToUpload, setWarningFileToUpload] = useState<any>(null);

  const { projectCreationStore } = useContext(Context);
  const { 
    projectId,
    buildingsList,
    setBuildingsList,
    handleSetModelsList,
    handleSetModelsListEmptyItem,
    setProjectSize,
  } =  projectCreationStore
  const {setUploadModel, isUploadModel, showErrorMessage} = projectCreationStore
  const {isModelReplaced, setReplaceModel,
    setIsShowLimitModelUploadPopup,
    setIsShowWarningLimitModelUploadPopup,
  } = projectCreationStore
  const [modelValidationErrors, setModelValidationErrors] = useState<any>(null);

  useEffect(() => {
    const bList = buildingsList // projectCreationStore.getBuildingsList()
    if(bList) {
      const firstModel = toJS(bList[0]?.modelsList?.[0]);
      const glbUrl = firstModel?.glb?.url ?? firstModel?.glb?.file?.name
      if (firstModel && glbUrl) {
        const modelUrl = firstModel?.glb?.url ?
          `${getAWSUrl()}/${glbUrl}` : 
          getTemporaryUploadedModelFileLocationUrl(glbUrl)
        setModelFile(modelUrl);
        setModelFileForPrew(modelUrl)
      }
    } else {
      setModelFile('');
    }
  }, [buildingsList]);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.glb, .usdz, .zip, .obj, .fbx, .dae',
    action: '',
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    className: 'model-uploader-step-viewer',
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      setUploadState(status);
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: async (file: File) => {
      if (file.name.endsWith('.glb')) {
        file?.arrayBuffer()?.then((data: ArrayBuffer) => {
          validateBytes(new Uint8Array(data))
            .then(async (result) => {
              setModelValidationErrors(null);
              const unsupportedFeatures = filterUnsupportedFeatures(result?.info?.extensionsRequired ?? []);

              if (result.issues.numErrors === 0 && result.issues.numWarnings > 0) {
                const arrayIsue = result.issues.messages
                //@ts-ignore
                const unique = [...new Map(arrayIsue.map((item: ArrayIssues) =>[item['message'], item])).values()];
                setModelValidationErrors(unique.map((errorObj: any) => (errorObj.message)));

                const fileToUpload = { file: file }
                setWarningFileToUpload(fileToUpload);
                setUploadState('warning')

              } else if (result.issues.numErrors > 0){
                const arrayIsue = result.issues.messages
                //@ts-ignore
                const unique = [...new Map(arrayIsue.map((item: ArrayIssues) =>[item['message'], item])).values()];
                setModelValidationErrors(unique.map((errorObj: any) => (errorObj.message)));
                setUploadState('error')
              } else if (unsupportedFeatures.length > 0) {
                setModelValidationErrors(['Model is not supported.'
                + ' Please remove the following features from the model: '
                + unsupportedFeatures.join(', '),
                ]);
                setUploadState('error')
              } else {
                const fileToUpload = { file: file }
                setUploadState('uploading')
                await uploadToAWS(fileToUpload)
              }
            })
            .catch((error: any) => {
              setModelValidationErrors([error]);
              setUploadState('error')
            });
        });

        return false;
      } else {
        // const fileToUpload = { file: file }
        // await uploadToAWS(fileToUpload)
      }
    },
  };

  const continueWithCurrentModel = async () => {
    if (warningFileToUpload !== null) {
      setUploadState('uploading')
      await uploadToAWS(warningFileToUpload)
    }
  }
  const screen = useMemo(() => {
    switch (uploadState) {
      case 'uploading':
        return <LoadingUploadView />;
      case 'done':
        return <SuccessUploadView />;
      case 'warning':
        console.log('Warning modelValidationErrors:', modelValidationErrors)
        return <WarningUploadView continueWithCurrentModel={continueWithCurrentModel} errorMessage={modelValidationErrors ?
          modelValidationErrors.join(', ') : 
          'Unknown issue'} />;
      case 'error':
        console.log('Error modelValidationErrors:', modelValidationErrors)
        return <ErrorUploadView errorMessage={modelValidationErrors ?
          modelValidationErrors.join(', ') : 
          'Unknown issue'}/>;
      default:
        return <DefaultUploadView />;
    }
  }, [uploadState]);

  const handleUploadToAWS = async (file: any) => {
    try {
      return await modelUploaderToAWSTemporary(file);
    } catch (error) {
      console.error('Error uploading to AWS:', error);
      return error;
    }
  };

  const uploadToAWS = async (file: any) => {
    if (file.file.size > MAX_FILE_SIZE){
      setModelValidationErrors([' Upload a file under 25mb.',
      ]);
      return setUploadState('error')
    }

    setProjectSize((file.file.size/1000000).toFixed(2))

    mixPanelUtils.track(MixpanelEvent.MODEL_UPLOADED, { projectId: projectId })

    if (isModelReplaced) {
      mixPanelUtils.track(MixpanelEvent.MODEL_REPLACED, { projectId: projectId })
      setReplaceModel(false)
    }

    setUploadModel(false)
    try {
      const response = await handleUploadToAWS(file);
      console.log('AWS upload response:', response);
      setUploadState('done')

      const modelUrl = getTemporaryUploadedModelFileLocationUrl(response)
      console.log('AWS uploaded modelUrl:', modelUrl);
      // setModelFile(modelUrl)

      await onModelUploaded(modelUrl);
      setUploadModel(true)
    } catch (e) {
      setUploadState('error')
      setUploadModel(false)
    }
  };

  const handleReplaceModel = () => {
    const incituUser = authProvider.getInCituUser();
    const { isLimitAchieved, modelUploads, isBasicPlan } = isSubscriptionModelUploadLimitsAchieved(incituUser)
    console.log('isLimitAchieved, modelUploads, isBasicPlan:', isLimitAchieved, modelUploads, isBasicPlan)
    if(isLimitAchieved) {
      setIsShowLimitModelUploadPopup(true);
    }
    else if(isBasicPlan && checkWarningLimitModalUploadPopupVisibility()) {
      setIsShowWarningLimitModelUploadPopup(true);
    } else {
      setUploadModel(false)
      setReplaceModel(true)
      setModelFile(null)
      handleSetModelsListEmptyItem(0, 0)
      setUploadState('')
    }
  };

  return (
    <>
      {uploadState == 'done' ? (
        <Row gutter={[12,12]} align='middle'>
          <Col>
            <CheckCircleFilled style={{ fontSize:'2rem',color: '#CBDB2A' }} />
          </Col>
          <Col>
            <Typography.Paragraph style={{fontSize: 12}}>
              <Typography.Text strong={true}>
                Model upload successful
              </Typography.Text>
              <br />
              Review your model and press next
            </Typography.Paragraph>
          </Col>
        </Row>
      ) : (
        <div style={{fontSize: 12, fontWeight: 400, paddingBottom: 20}}>
          <div>
            Supported file types include .glb, .usdz, .zip, .obj, .fbx, .dae
          </div>
          <div>Size limit is up to 25 mb</div>
          <div>
            <a href={`${BEST_PRACTICE_DOC_LINK}#heading=h.w95neqpho1dm`}
              target="_blank" 
              style={{color: 'black', textDecoration: 'underline'}}>Learn more</a> about 3D model best practices
          </div>
          {/*{showErrorMessage*/}
          {/*  ? <Typography.Text style={{color: 'red'}}>*/}
          {/*      Please, upload 3D model*/}
          {/*  </Typography.Text> : null*/}
          {/*}*/}
        </div>
      )}
      {modelFile
        ? <ModelPreview modelFile={modelFile} onReplaceModel={handleReplaceModel}/>
        : <Dragger {...props} customRequest={async (e) => {
          setUploadState('uploading')
          await uploadToAWS(e)
        }}>{screen}</Dragger>

      }
    </>
  );
});

export default ModelUploaderStepView;
