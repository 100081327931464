/* eslint-disable max-len */

import {Typography, Tabs, Radio, Button} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import type { RadioChangeEvent , TabsProps } from 'antd';
import './style.less';
import GeneralView from './GeneralView';
import AppClipView from './AppClipView';
import ARView from './ARView';
import BrowserPreview from './BrowserPreview';
import IOSPreview from './iOSPreview';
import arrowLeft from '../../../../../assets/arrowLeft.png'
import arrowRight from '../../../../../assets/arrowRight.png'
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import { setWith } from 'lodash';

const ModelProjectDetailStepView =  observer(({modelFileForPrew}: any) => {

  const { projectCreationStore } = useContext(Context);
  const {projectId, createStep } = projectCreationStore;

  const [valueRadioButton, setValueRadioButton] = useState(1);
  const [tabsKeyNumber, setTabsKeyNumber] = useState('1');
  const [initialTrackingDone, setInitialTrackingDone] = useState(false);

  useEffect(() => {
    if (createStep === 2 && !initialTrackingDone) {
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_GENERAL_VISIT, { projectId: projectId });
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_IOS_PREVIEW, { projectId: projectId });
      setInitialTrackingDone(true);
    }
  }, [initialTrackingDone, projectId, createStep]);

  const onChange = (key: string) => {

    switch (key) {
      case '1':
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_GENERAL_VISIT, { projectId: projectId });
        break;
      case '2':
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_APP_CLIP_VISIT, { projectId: projectId });
        break;
      case '3':
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_AR_VISIT, { projectId: projectId });
        break;
    }

    setTabsKeyNumber(key)
    console.log(key);
  };

  const onChangeArrow = (action: string) =>{
    const number = Number(tabsKeyNumber)
    if (action === 'right' && number < 3){
      onChange(String(number + 1))
    }else if (action === 'left' && number > 1){
      onChange(String(number - 1))
    }
  }
  const onChangeRadioButton = (e: RadioChangeEvent) => {
    setValueRadioButton(e.target.value);

    switch(e.target.value) {
      case 1:
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_IOS_PREVIEW, { projectId: projectId });
        break;
      case 2:
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_WEB_PREVIEW, { projectId: projectId });
        break;
      case 3:
        mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_ANDROID_PREVIEW, { projectId: projectId });
        break;

    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'General',
      children: <GeneralView/>,

    },
    {
      key: '2',
      label: 'App Clip',
      children: <AppClipView/>,
    },
    {
      key: '3',
      label: 'AR',
      children: <ARView/>,
    },
  ];

  return (
    <>
      <div className="main-div">
        {/*<span className="span-left-detail" style={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*  <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => onChangeArrow('left')}>*/}
        {/*    <img src={arrowLeft} style={tabsKeyNumber === '1' ? { opacity: 0.5 } : {}} />*/}
        {/*  </span>*/}
        {/*  <Tabs style={{ fontSize: 14, fontWeight: 600 }} defaultActiveKey="1" activeKey={tabsKeyNumber} items={items} onChange={onChange} />*/}
        {/*  <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => onChangeArrow('right')}>*/}
        {/*    <img src={arrowRight} style={tabsKeyNumber === '3' ? { opacity: 0.5 } : {}} />*/}
        {/*  </span>*/}
        {/*</span>*/}
        <span className="span-left-detail">
          <span style={{position: 'absolute', top: 17, marginLeft: '-7%' ,cursor: 'pointer'}} onClick={()=>onChangeArrow('left')}>
            <img src={arrowLeft} style={ tabsKeyNumber === '1' ? {opacity: 0.5} : {}} /></span>
          <span style={{position: 'absolute', top: 17, marginLeft: '80%',cursor: 'pointer'}} onClick={()=>onChangeArrow('right')}>
            <img src={arrowRight} style={ tabsKeyNumber === '3' ? {opacity: 0.5} : {}} />
          </span>
          <Tabs style={{fontSize: 14, fontWeight: 600}} defaultActiveKey="1" activeKey={tabsKeyNumber} items={items} onChange={onChange}/>
        </span>
        <span className="span-right-details" style={{marginTop: 0, transform: 'translateY(-32px)'}}>
          <div style={{textAlign: 'center', fontSize: 20, fontWeight: 400}}>
                    Preview
          </div>
          <Radio.Group onChange={onChangeRadioButton}
            value={valueRadioButton}
            style={{alignItems: 'center',
              display:'flex',
              margin: 'auto',
              justifyContent: 'center',
              marginTop: 8,
              marginBottom:15,
            }}>
            <Radio value={1} style={{marginRight: 0}}>
              <Typography.Paragraph style={{fontSize: 14}}>
                    iOS
              </Typography.Paragraph>
            </Radio>
            <Radio value={2} style={{marginRight: 0}}>
              <Typography.Paragraph style={{fontSize: 14}}>
                    Browser
              </Typography.Paragraph>
            </Radio>
            <Radio value={3} disabled={true} style={{opacity: 0.5, marginRight: 0}}>
              <Typography.Paragraph style={{fontSize: 14}}>
                    Android
              </Typography.Paragraph>
            </Radio>
          </Radio.Group>
          {valueRadioButton === 1 ? <IOSPreview tabsKeyNumber={tabsKeyNumber}/> :
            <BrowserPreview modelFileForPrew={modelFileForPrew}/>
          }
        </span>
      </div>
    </>
  );
})
export default ModelProjectDetailStepView;
