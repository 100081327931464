import React from 'react'
import {Button, Layout, Typography} from 'antd'
import {useNavigation} from '@pankod/refine-core'
import performLogout from '../../hooks/auth0/useLogout';
import { useAuth0 } from '@auth0/auth0-react';
import { CONTACT_US } from '../../constants/links';

interface ErrorNewProps {
    mainTitle: string,
    backwardsRedirectionPath: string
}

const ErrorNew: React.FC<ErrorNewProps> = ({
  mainTitle,
  backwardsRedirectionPath,
}) => {
  const {
    logout,
  } = useAuth0();

  const { push } = useNavigation()

  const contactSupport = async () => {
    window.location.replace(CONTACT_US)
  }
  
  const sendBackwards = async () => {
    await performLogout(logout)
    // push(backwardsRedirectionPath)
  }
  
  return (
    <Layout className="layout-error-new">
      <div style={{ width: '100%', textAlign: 'center', maxWidth: '350px' }}>
        <div className="main-title-error-new">{mainTitle}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '24px' }}>
        <Button className="button-error-new-try-again" onClick={sendBackwards}>
          <div className="div-text-button-error-new" style={{color: '#727272'}}>Try again</div>
        </Button>
        <Button type='primary' className="button-error-new-continue" onClick={contactSupport} >
          <div className="div-text-button-error-new" style={{color: '#FFFFFF'}}>Continue</div>
        </Button>
      </div>
    </Layout>
  )
}

export default ErrorNew
