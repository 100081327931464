import dataProvider from '../dataProvider/dataProvider';

export const createBuilding = async (id: any) => {
  const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)
  
  const { data } = await dProvider.create({
    resource: 'buildings',
    variables: {
      project: {
        connect: {
          id,
        },
      },
    },
    metaData: {
      fields: ['id'],
    },
  })

  return data;
}
