import React from 'react';
import { Card, Col, Button, Typography, Divider, Row } from '@pankod/refine-antd';
import { DocumentsView } from '../../../components/document/index';
import ShareComponent from '../../../components/share-component';
import {ProjectDetailDocumentsListKeys, ProjectDetailHideFromListKeys} from '../../../constants';
import './styles.less';
import { dataUtils } from '../../../../../utils/util';

const { Title, Paragraph,Text } = Typography;

interface ProjectDescriptionProps {
    projectId: string;
    data: any;
    openFeedbackForm?: () => void
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({projectId, data, openFeedbackForm}) => {
  const formatValue = (element: any) => {
    try {
      if (typeof element?.value == 'object') {
        const res = element?.value?.map((item: any) => {
          return item.url
            ? <a target='_blank' href={item.url}>{item.name}</a>
            : item;
        })

        return element?.value?.length > 0
          ? res?.reduce((prev: any, curr: any) => [prev, ', ', curr])
          : ''
      } else {
        return element?.value?.length > 0 ? element.value : '';
      }
    } catch (err) {
      console.error(err);
    }
  };

  const RowComponent = (item: any) => {

    if(item.key.toLowerCase() == 'address' ) {
      return <span
        style={item.key.toLowerCase().includes('status')
          ?{backgroundColor:'#707070',color:'white',padding:'3px'}
          :{}
        }>
        <a href={'https://www.google.com/maps/place/'+dataUtils.findValueByKey(data,'address')} target='_blank'>
          {`${dataUtils.findValueByKey(data,'address')}`}</a>
      </span>
    }
    return <span
      style={item.key.toLowerCase().includes('status')
        ?{backgroundColor:'#707070',color:'white',padding:'3px'}
        :{}
      }>
      {` ${formatValue(item)}`}</span>
  }

  return (
    <div className='description-details-container'>
      <Row align='middle' justify='space-between'>
        <Col span={20}> <Title className='project-title'>{data[0]?.value}</Title></Col>
        <ShareComponent projectId={projectId} />
      </Row>

      {data.filter((item:any)=>!ProjectDetailHideFromListKeys.includes(item?.key?.toLowerCase())).map((item: any) => (
        <Paragraph className={'project-info-p class_'+item.key} key={JSON.stringify(item)}>
          {item.key.toLowerCase() !== 'description' && item.value.length !== 0 &&
            <span className='p-info-title'>{` ${item.key}`} </span>
          }
          { item.key.toLowerCase() == 'bbl'
            ? item.value.map((it: any, index: number) => (
              <span className='value'>{it}{ index < item.value?.length - 1
                ? ', '
                : ''}</span>
            ))
            : RowComponent(item)
          }
        </Paragraph>
      ))}
      <Divider />
      <DocumentsView list={data.filter((item:any)=>ProjectDetailDocumentsListKeys.includes(item.key.toLowerCase()))}  />
      <Button className='feedback-button'
        type='primary' onClick={openFeedbackForm} shape='round'>Submit Feedback</Button>
    </div>
  );
}
export default ProjectDescription;
