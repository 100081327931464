import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';

export default function LimitModalUploadPopup({showCloseWarning,onLeave,onContinue}:any){
  return <Modal className='modal-upload-limit-popup-modal-wrapper' closeIcon={false} closable={false}
    open={showCloseWarning} width={'auto'}
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: 'auto',
      transform: 'translateY(-50%)',
    }}
    footer={[]}>
    <div>
      <Row justify="center">
        <Typography.Text style={{ fontSize: '20px', fontWeight: 600 }}>Continuing with Basic</Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text style={{ fontSize: '20px', fontWeight: 600 }}>Subscription</Typography.Text>
      </Row>
      {/*<Row justify="center" style={{ marginTop: '20px' }}>*/}
      {/*  <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 600 }}><b>Upgrade your subscription</b></Typography.Paragraph>*/}
      {/*</Row>*/}
      <Row justify="center" style={{ marginTop: '16px'}}>
        <Typography.Text style={{ fontSize: '14px' }}>You will be able to keep using your Basic project's</Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text style={{ fontSize: '14px' }}>QR code but will not be able to edit the project</Typography.Text>
      </Row>
      <Row justify="center">
        <Typography.Text style={{ fontSize: '14px' }}>anymore.</Typography.Text>
      </Row>

      <Row style={{ marginTop: '16px',  width: '100%', backgroundColor: 'yellow' }} justify="center">
        <Button
          type="link"
          onClick={onContinue}
          className='keep-btn'
          style={{
            color: 'hsl(0, 0%, 45%)',
            fontSize: '12px',
            fontWeight: 600,
            width: '100%',
          }}>Upgrade to inCitu Pro</Button>
      </Row>
      <Row style={{ marginTop: '16px' }} justify="center">
        <Col><Button type="link" onClick={onLeave} className="upgrade-btn">
          <span style={{ textDecoration: 'underline', fontSize: '14px' }}>
            Got it
          </span>
        </Button>
        </Col>
      </Row>
    </div>
  </Modal>
}
