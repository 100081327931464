import React, {useEffect} from 'react';

import {Refine} from '@pankod/refine-core';
import {ErrorComponent, ReadyPage} from '@pankod/refine-antd';

import '@pankod/refine-antd/dist/styles.min.css';
import routerProvider from '@pankod/refine-react-router-v6';
import ProjectCreationStepComposer from './pages/project-creation-step-composer';
import Projects from './pages/project';
import dataProvider from './dataProvider/dataProvider';
import AuthPage from './pages/login';
import {useAuth0} from '@auth0/auth0-react';
import PublicViewMobile from './pages/public-view/project-mobile';
import PublicView from './pages/public-view/project';
import {deviceUtils} from './utils/util';
import {interceptFetch} from './interceptors/fetchInterceptor';
import PublicMapView from './pages/public-view/map';
import {loadStripeScript} from './utils/strip';
import {ProfileDetails} from './pages/registration/ProfileDetails';
import {WelcomeBack} from './pages/registration/WelcomeBack';
import { EmailVerification } from './pages/registration/EmailVerification';
import { EmailVerificationConfirmation } from './pages/registration/EmailVerificationConfirmation';
import {PricingPage} from './pages/registration/PricingPage';
import CustomLayout from './components/Layouts/CustomLayout';
import {AllSet} from './pages/registration/AllSet';
import ProjectDetailsNew from './pages/project-create-new';
import {observer} from 'mobx-react-lite';
import ErrorPage from './pages/error';
import AccountDetails from './components/AccountDetails';
import LandingPage from './pages/registration/LandingPage';
import MobileComingSoon from './pages/registration/MobileComingSoon';
import ErrorNew from './pages/registration/ErrorNew';
import { Navigate } from 'react-router-dom';
import { Redirect } from './pages/registration/Redirect';
import AccountDetailsMobile from 'components/AccountDetailsMobile';
import { AccountLoading } from './pages/registration/AccountLoading';
import '../src/utils/token';
import AutoLogoutPage from './pages/logout';
import OAuthCallback from './components/ArcGIS/OAuthCallback';

const App: React.FC = () => {
  useEffect(() => {
    loadStripeScript();
  }, []);

  const {
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    interceptFetch(logout)

  }, []);

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [
          // {
          //   path: '/login',
          //   element: (
          //     <AuthPage/>
          //   ),
          // },
          // {
          //   element: <ProjectCreateV2/>,
          //   path: '/project-create',
          // },
          {
            path: '/landing',
            element: <LandingPage/>,
            layout: true,
          },
          // {
          //   path: '/login',
          //   element: (
          //     <AuthPage/>
          //   ),
          // },
          // {
          //   element: <ProjectDetails/>,
          //   path: '/project-create',
          // },
          // {
          //   element: <ProjectDetails/>,
          //   path: '/project-edit/:id',
          // },
          // {
          //   element: <ProjectDetailsNew/>,
          //   path: '/project-create',
          // },
          {
            element: <AutoLogoutPage/>,
            path: '/auto-logout',
          },
          {
            element: <ProjectDetailsNew/>,
            path: '/project-edit/:id',
          },
          {
            element: <ProjectCreationStepComposer/>,
            path: '/project-step-composer',
          },
          {
            element: <ProjectCreationStepComposer/>,
            path: '/project-step-composer/:id',
          },
          {
            element: deviceUtils.isPhone() ? (
              <PublicViewMobile />
            ) : (
              <PublicView />
            ),
            path: '/project',
          },
          {
            element: deviceUtils.isPhone() ? (
              <PublicViewMobile />
            ) : (
              <PublicView />
            ),
            path: '/project/:id',
          },
          {
            element: <PublicMapView/>,
            path: '/map',
            // layout: true,
          },
          {
            element: <PublicMapView/>,
            path: '/map/:tag',
          },
          {
            element: <PublicMapView/>,
            path: '/map/org/:tag',
          },
          {
            element: <PublicMapView/>,
            path: '/map/project/:tag',
          },
          // {
          //   element:<Projects />,
          //   path: '/projects',
          // },
          {
            element: deviceUtils.isPhone() ? <Navigate to="/coming-soon" replace={true} /> : <Projects />,
            path: '/projects',
          },
          {
            element: <ErrorPage/>,
            path: '/error',
          },
          {
            element: <PricingPage/>,
            path: '/pricing',
            layout: true,
          },
          {
            element: <ProfileDetails/>,
            path: '/profile-details',
            layout: true,
          },
          {
            element: <WelcomeBack/>,
            path: '/account-details',
            layout: true,
          },
          {
            element: <AccountLoading/>,
            path: '/account-loading',
            layout: true,
          },
          {
            element: <AllSet/>,
            path: '/all-set',
            layout: true,
          },

          // {
          //   element: <AccountDetails/>,
          //   path: '/account',
          // },
          {
            element: deviceUtils.isPhone() ? <AccountDetailsMobile /> : <AccountDetails />,
            path: '/account',
          },
          {
            element: <OAuthCallback />,
            path: '/callback',
          },
          {
            element: <MobileComingSoon/>,
            path: '/coming-soon',
          },
          {
            element: <EmailVerification/>,
            path: '/email-verification',
            layout: true,
          },
          {
            element: <EmailVerificationConfirmation/>,
            path: '/email-verification-confirmation',
            layout: true,
          },
          {
            element: <Redirect />,
            path: '/redirect',
            layout: true,
          },
          // {
          //   path: '*',
          //   element: <Navigate to="/" replace />,
          // },
        ],
      }}
      dataProvider={dataProvider(`${process.env.REACT_APP_API_URL}/graphql`, getAccessTokenSilently, logout)}
      resources={[
      //   {
      //   name: 'projects',
      //   list: Projects,
      // },
      //   {
      //     name: 'login',
      //     list: AuthPage,
      //     options: { label: 'login' },
      //   },
        {
          name: 'landing',
          list: LandingPage,
          options: { label: 'landing' },
        },
        // {
        //   name: 'redirect',
        //   list: Redirect,
        //   options: { label: 'redirect' },
        // },
      ]}
      Layout={CustomLayout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
    />
  );
};

export default observer(App);

interface Project {
  id: number;
  name: string;
  createdAt: string;
  organization: { id: number };
}

interface Organization {
  id: number;
  name: string;
}
