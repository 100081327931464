import { ProjectVisibility } from '../../../enums';

export function buildPreferenceQuery(publishStatus: string, isUpdate: boolean): any {
  const visibility = publishStatus ?? ProjectVisibility.RESTRICTED
  const preferenceQuery = isUpdate ? {
    update: {
      visibility: {
        set: visibility,
      },
    },
  } : {
    create: {
      visibility: visibility,
    },
  };

  return preferenceQuery
}
