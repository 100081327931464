import { Button, Modal, Spin } from 'antd';

import './style.less';
import React, { useMemo } from 'react';
import { CONTACT_US } from '../../constants/links';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from '@pankod/refine-antd';
import successLogo from '../../assets/completed-icon.svg';
import failureLogo from '../../assets/failure-icon.svg';

const ArcGISImportingModal = ({ status, open, onCancel, onNavigateToProfile, onNavigateToProjects }: {
  status: string,
  open: boolean,
  onCancel: () => void
  onNavigateToProfile: () => void
  onNavigateToProjects: () => void
}) => {

  return (
    <Modal 
      open={open}
      onCancel={onCancel}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      footer={null} 
      width={436}>
      {status === 'loading' && (
        <div className="arcgis-modal">
          <h1 className="title">Importing your ArcGIS project</h1>
          <div
            className="acc-loading-logo-container"
            style={{ width: '100%', height: '80px', position: 'relative', marginTop: 50 }}
          >
            <Spin
              style={{
                position: 'absolute',
                color: '#F98168',
                // color: 'grey',
                // fill: 'grey',
                opacity: 0.7,
                fontSize: 10,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              indicator={
                <LoadingOutlined style={{ fontSize: 95 }} spin={true} />
              }
            />
            <div style={{ marginTop: 30, color: '#938d8d' }}>Loading</div>
          </div>
          <div
            style={{
              marginTop: 70,
            }}
          >
            <Button type="link" className="link-button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {status === 'success' && (
        <div className="arcgis-modal">
          <h1 className="title" >Your project was successfully imported!</h1>
          <div className="description">Go to My Projects tab</div>
          <div className="description">to see all projects</div>
          <div
            className="acc-loading-logo-container"
            style={{ width: '100%', height: '80px', position: 'relative' }}
          >
            <img
              src={successLogo}
              style={{ width: 80, height: 70 }}
            />
          </div>
         
          <div>
            <Button
              style={{width: 120}}
              className="orange-button"
              type="primary"
              onClick={onNavigateToProjects}
            >
                  Close
            </Button>
          </div>
        </div>
      )}
      {status === 'failed' && (
        <div className="arcgis-modal">
          <div className="title">Project import was unsuccessful</div>
          <div className="description">We were not able to import</div>
          <div className="description"> your projects</div>
          <div
            className="acc-loading-logo-container"
            style={{ width: '100%', height: '80px', position: 'relative' }}
          >
            <img
              src={failureLogo}
              style={{ width: 80, height: 70 }}
            />
          </div>
          <div>
            <div>
              <Button
                style={{width: 120}}
                className="orange-button"
                type="primary"
                onClick={onCancel}
              >
                  Try Again
              </Button>
            </div>
            <div>
              <Button
                className="grey-button"
                onClick={() => window.open(CONTACT_US, '_blank')}
              >
                  Contact Support
              </Button>
            </div>
          </div>
          <div
            style={{
              marginTop: '30px',
            }}
          >
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ArcGISImportingModal;
