import React from 'react'
import { Card, Col } from '@pankod/refine-antd';

export default function ProjectCard(props:any) {
  const {children, containerclassName, ...restProps} = props;
  return (
    <Col xl={6} xxl={8} md={8} xs={24} sm={12} lg={6} className={containerclassName}>
      <Card style={{minHeight:'350px',minWidth:'inherit'}} {...restProps}>
        {children}
      </Card>
    </Col>
  )
}
