import { FormImageUploader } from '../../FormImageUploader';
import { AcceptedImageTypesAttr, JPG_CONTENT_TYPE } from '../../../constants/files';
import { Col, Form, Input, Row, Typography } from 'antd';

export const Images = ({ loading, projectId }: { loading: boolean, projectId: string }) => {
  return (
    <Col span={24}>
      <Typography.Title style={{ paddingBottom: '0' }} level={4}>Upload Image</Typography.Title>
      <Row justify='space-between' gutter={20} className='image-field-container'>
        {/* <Col span={12}>
          <FormImageUploader
            name="images"
            accept={AcceptedImageTypesAttr}
            maxCount={4}
            multiple={true}
          />
        </Col> */}
        <Col span={12}>
          <Form.Item
            label="App Clip cover image (1800x1200px)"
            name="cover"
            labelAlign="left"
            labelCol={{
              xs: 24,
            }}
            wrapperCol={{
              xs: 24,
            }}
          >
            <FormImageUploader
              projectId={projectId}
              name="cover"
              accept={[JPG_CONTENT_TYPE]}
              maxCount={1}
              multiple={false}
              isCover={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Description"
            name="description"
            labelAlign="left"
            labelCol={{
              xs: 24,
            }}
            wrapperCol={{
              xs: 20,
            }}
          >
            <Input.TextArea
              disabled={loading}
              placeholder="Project Description (max characters: x)"
              data-testid="project_description"
              autoSize={{ minRows: 8 }}
              className='image-description'
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
}
