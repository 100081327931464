
export async function documentUploader(files: Array<any>, projectId: string): Promise<any> {
  try {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file.originFileObj);
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/aws/multipart-files-upload/${projectId}`, {
      method: 'POST',
      body: formData,
    });

    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function deleteDocumentsFromAWS(filenames: Array<any>, projectId: string) {
  try {
    const filenamesParam = filenames.join(',')
    if(filenamesParam.length == 0) {
      return
    }
    // eslint-disable-next-line max-len
    const res = await fetch(`${process.env.REACT_APP_API_URL}/aws/multipart-files-delete/${projectId}/${filenamesParam}`, {
      method: 'GET',
      // mode: 'no-cors',
    });
    return res;
  } catch (error) {
    console.error(error);
  }
}
