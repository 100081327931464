
import React, { useEffect, useRef, useState } from 'react';

import SceneView from '@arcgis/core/views/SceneView';
import esriConfig from '@arcgis/core/config';
import WebScene from '@arcgis/core/WebScene';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import Home from '@arcgis/core/widgets/Home.js';

import '@arcgis/core/assets/esri/themes/light/main.css';
import './styles.less';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import { ObjectSymbol3DLayer, PointSymbol3D } from '@arcgis/core/symbols';
import { Button } from 'antd';
// @ts-ignore
import searchMarker from '../../../../assets/searchMarker.glb'
import Layer from '@arcgis/core/layers/Layer';
import { delay } from '../../../../utils/util';

const EsriMapUploader = ({
  onModelUpload,
  onLatitudeChange,
  onLongitudeChange,
  onAltitudeChange,
  onRotationChange,
  modelUrl,
  latitude,
  longitude,
  rotation,
  searchedLocation,
  altitude,
  isEdit,
  newModelUrl,
  onModelReset,
  setIsModelReset,
  onTakeScreenshot,
  onScreenshotTaken,
}: {
  onModelUpload: (file: File) => void,
  onLatitudeChange: (latitude: number) => void,
  onLongitudeChange: (longitude: number) => void,
  onAltitudeChange: (altitude: number) => void,
  onRotationChange: (rotation: number) => void,
  searchedLocation: any,
  modelUrl: string,
  latitude?: number,
  longitude?: number,
  altitude?: number,
  rotation?: number,
  isEdit: boolean,
  newModelUrl?: string,
  onModelReset: () => void,
  setIsModelReset: (isReset: boolean) => void,
  onTakeScreenshot: boolean,
  onScreenshotTaken: (dataUrl: string) => void;
}) => {
  const mapContainer = useRef(null);
  const sketchVMRef = useRef<null | SketchViewModel>(null);
  const [graphicsLayer, setGraphicsLayer] = useState<any>(null);
  const [modelObjectUrl, setModelObjectUrl] = useState<any>(null);
  const [view, setView] = useState<any>(null);
  const [isModelInitialized, setIsModelInitialized] = useState<boolean>(false);
  const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);

  const homeWidgetRef = useRef(null);

  let storedRot = Number(rotation);
  let toolRotation = 0;
  console.log('Initial storedRot:', storedRot)
  console.log('Initial rotation:', rotation)
  const createPointGraphic = (longitude: number, latitude: number, altitude: number, objectURL: string) => {
    const projectPoint = new Point({
      longitude: longitude,
      latitude: latitude,
      // z: altitude,
      spatialReference: { wkid: 4326 },

    });

    const pointSymbol = new PointSymbol3D({
      symbolLayers: [
        new ObjectSymbol3DLayer({
          resource: {
            href: objectURL,
          },
          heading: -storedRot || 0,
        }),
      ],
    });

    const pointGraphic = new Graphic({
      geometry: projectPoint,
      symbol: pointSymbol,

    });

    return pointGraphic;
  }

  useEffect(() => {
    if (
      !isModelInitialized
      && view
      && isEdit
      && modelUrl
      && longitude
      && latitude
      && !newModelUrl
    ) {
      fetch(modelUrl)
        .then(res => res.blob())
        .then((blob: any) => {
          setIsModelInitialized(true);

          const objectURL = URL.createObjectURL(blob);
          setModelObjectUrl(objectURL);

          view.when(() => {
            if (sketchVMRef && sketchVMRef.current) {
              const pointGraphic = createPointGraphic(longitude, latitude, altitude || 0, objectURL);

              graphicsLayer.add(pointGraphic);
              view.goTo(pointGraphic);
            }
          });
        });
    }
  }, [modelUrl, isModelInitialized, isEdit, view, newModelUrl]);

  useEffect(() => {
    if (
      !isModelInitialized
      && view
      && !isEdit
      && modelUrl
      && longitude
      && latitude
      && !newModelUrl
    ) {
      setIsModelInitialized(true);
      setModelObjectUrl(modelUrl);

      view.when(() => {
        if (sketchVMRef && sketchVMRef.current) {
          const pointGraphic = createPointGraphic(longitude, latitude, altitude || 0, modelUrl);

          graphicsLayer.add(pointGraphic);
          view.goTo(pointGraphic);
        }
      });
    }
  }, [modelUrl, isModelInitialized, isEdit, view, newModelUrl]);

  useEffect(() => {
    if (view && searchedLocation?.longitude && searchedLocation?.latitude) {
      view.goTo({
        center: [searchedLocation?.longitude, searchedLocation?.latitude],
        zoom: 17.9,
      });

      graphicsLayer.removeAll();

      const pointGraphic = createPointGraphic(
        searchedLocation?.longitude,
        searchedLocation?.latitude,
        altitude || 0,
        modelObjectUrl,
      );

      const searchPointGraphic = createPointGraphic(
        searchedLocation?.longitude,
        searchedLocation?.latitude,
        altitude || 0,
        searchMarker,
      );

      searchPointGraphic.set('title','search_graphic')
      graphicsLayer.add(pointGraphic);
      graphicsLayer.add(searchPointGraphic);

      view.goTo(pointGraphic);
    }
  }, [view, searchedLocation?.longitude, searchedLocation?.latitude]);

  useEffect(() => {
    if (!isMapInitialized) {
      esriConfig.apiKey = 'AAPKf090a4dc9a734410b7694a2edd72f75dLZYBMrS5DdrwbbiDi_bLpmDg4MAZ2XN6HeOXZ40T4FVjfTOBh1zRSEJqKFzcYOgQ'
      // load web scene from ArcGIS Online
      const webScene = new WebScene({
        portalItem: {
          // autocasts as new PortalItem()
          id: '8cb10cf54b5d42999e12b41721da722b',
        },
      });

      const view = new SceneView({
        // @ts-ignore
        container: mapContainer.current,
        map: webScene,
        zoom: 17.9,
        center: [
          searchedLocation?.longitude || longitude || -73.99634029737001,
          searchedLocation?.latitude || latitude || 40.71401792650208,
        ],
      });

      setView(view);

      const graphicsLayer = new GraphicsLayer({
        elevationInfo: { mode: 'relative-to-ground' },
      });
      view.map.add(graphicsLayer);
      setGraphicsLayer(graphicsLayer);

      const tentBtn = document.getElementById('tent');

      view.when(() => {

        const homeWidget = new Home({
          view: view,

        });
        // @ts-ignore
        homeWidgetRef.current = homeWidget;
        view.ui.add(homeWidget, 'top-right');
        homeWidget.goToOverride = function(view, goToParams) {
          goToParams.options.duration = 1;
          goToParams.target = {
            center: [longitude, latitude],
            zoom: 17,
            heading: 0,
            tilt: 60,
          }
          return view.goTo(goToParams.target, goToParams.options);
        };

        const allLayers: Array<Layer> = []
        view.map.allLayers.forEach(layer =>{ allLayers.push(layer)})
        //the 5th layers is the basemap 3d building
        if(allLayers[5])
        {
          allLayers[5].opacity = 0.5;
        }

        sketchVMRef.current = new SketchViewModel({
          layer: graphicsLayer,
          view,
          defaultUpdateOptions: {
            enableScaling: false,
          },
        });

        setTimeout(() => {
          setIsMapInitialized(true)
        }, 1000);
        // sketchVMRef.current = sketchVM

        // tentBtn?.addEventListener('click', () => {
        //   console.log('_____tentBtn click: ')
        //   // load model on map
        //   sketchVMRef?.current?.pointSymbol = {
        //     type: 'point-3d',
        //     // @ts-ignore
        //     symbolLayers: [
        //       {
        //         type: 'object',
        //         resource: {
        //           href: fileUrl
        //           // href: 'https://incitu-3d-model-production.s3.us-east-2.amazonaws.com/projects/cclcuwwib800023n6kryinzng9/clf8ior0s001ex102dqrnrbgc/clf8ior0s001ex102dqrnrbgc_0.glb'
        //         }
        //       }
        //     ]
        //   };
        //   sketchVMRef.current?.create('point');
        //   deactivateButtons();
        //   tentBtn.classList.add('esri-button--secondary');
        // });
        sketchVMRef.current.on('create', (event) => {
          if (event.state === 'complete') {
            setIsModelReset(true);
            sketchVMRef.current?.update(event.graphic);

            deactivateButtons();
          }
        });

        sketchVMRef.current.on('update', async (event) => {
          if(event.graphics[0].get('title') === 'search_graphic')
          {
            sketchVMRef.current?.cancel()
            return null

          }
          const updatedGraphic = event.graphics[0]
          const lat: number = updatedGraphic.geometry.get('latitude')
          const lon: number = updatedGraphic.geometry.get('longitude')

          onLatitudeChange(lat);
          onLongitudeChange(lon);

          let alt = 0;
          if (updatedGraphic.geometry.hasZ) {
            alt = updatedGraphic.geometry.get('z');

          }
          onAltitudeChange(alt);

          // homeWidget.goToOverride = function(view, goToParams) {
          //   goToParams.options.duration = 1;
          //   goToParams.target = {
          //     center: [lon, lat],
          //     zoom: 17,
          //     heading: 0,
          //     tilt: 60,
          //   }
          //   return view.goTo(goToParams.target, goToParams.options);
          // };
          // TODO: get altitude automatic approach
          // await getElevation(lat, lon)

          if (event.tool === 'transform') {

            if (event.toolEventInfo) {
              const info = event.toolEventInfo,
                type = info.type;

              // rotate events only
              if (type.match('rotate-stop')) {
                if ('angle' in info) {
                  console.log('Rotate Stop')
                  console.log('Before rotation:', rotation)
                  console.log('Before storedRot:', storedRot)
                  console.log('Info.angle:', info.angle)

                  toolRotation = info.angle
                  if(toolRotation < 0){
                    toolRotation += 360
                  }

                  storedRot += toolRotation
                  if(storedRot > 360)
                  {
                    storedRot -= 360
                  }
                  console.log('Updated storedRot:', storedRot)
                  //const newRotation = storedRot;
                  //const normalizedRotation = newRotation  > 360 || newRotation < -360 ? newRotation % 360 : newRotation;
                  onRotationChange(storedRot);
                }
              }
              // if (type.includes('rotate')) {
              //   if ('angle' in info) {
              //     const newRotation = (rotation ? +rotation : 0) - info.angle;
              //     const normalizedRotation = newRotation > 180 || newRotation < -180 ? newRotation % 180 : newRotation;
              //
              //     onRotationChange(normalizedRotation);
              //   }
              // }
            }

          }
        })

        // @ts-ignore
        document.getElementById('submit').onsubmit = function handleSubmit(e) {
          // Prevent the browser from reloading the page
          e.preventDefault();

          // Read the form data
          const form = e.target;
          if (form) {
            // @ts-ignore
            const formData = new FormData(form);
            const lat = formData.get('latitude')
            const lon = formData.get('longitude')

            if (sketchVMRef && sketchVMRef.current) {
              sketchVMRef.current.updateGraphics.at(0).geometry.set('latitude', lat)
              sketchVMRef.current.updateGraphics.at(0).geometry.set('longitude', lon)
              sketchVMRef.current.update(graphicsLayer.graphics.at(0), { tool: 'transform' })
              // Or you can work with it as a plain object:
              const formJson = Object.fromEntries(formData.entries());
              console.log(formJson);
            }
          }
        }

      })
        .catch(console.error);

      const pane = document.getElementById('paneDiv');
      // @ts-ignore
      view.ui.add(pane, 'top-right');
    }

    function deactivateButtons() {
      const elements = Array.prototype.slice.call(document.getElementsByClassName('esri-button'));
      elements.forEach((element) => {
        element.classList.remove('esri-button--secondary');
      });
    }
  }, [rotation, newModelUrl, isMapInitialized, longitude, latitude]);

  useEffect(() => {
    if (isMapInitialized && newModelUrl) {
      placePoint(newModelUrl);
    }
  }, [newModelUrl, isMapInitialized]);

  // Convert glb -> usdz
  // const usdzModel = (models || {})['usdz'];
  const glbModel = ['glb']; // (models || {})['glb'];

  const placePoint = (url: string) => {
    if (!sketchVMRef.current || !url) {
      return;
    }

    sketchVMRef.current.pointSymbol = {
      type: 'point-3d',
      // @ts-ignore
      symbolLayers: [
        {
          type: 'object',
          resource: {
            href: url,
          },
        },
      ],
    };
    setModelObjectUrl(url);

    sketchVMRef.current.create('point');
  };

  const handleResetModelPostion = () => {
    onModelReset();
  }
  
  function triggerGoToOverride() {
    if (homeWidgetRef.current) {
      const homeWidget: any = homeWidgetRef.current
      const goToParams = {
        options: {
          duration: 1,
        },
        target: {
          center: [longitude, latitude],
          zoom: 17,
          heading: 0,
          tilt: 60,
        },
      };

      homeWidget.goToOverride(view, goToParams);
    }
  }

  useEffect(() => {
    const takeAndHandleScreenshot = async () => {
      try {
        if (onTakeScreenshot) {
          // @ts-ignore
          if (homeWidgetRef.current) {
            triggerGoToOverride();
          }

          await delay(3000)

          const result = await view.takeScreenshot({ format: 'png' });
          onScreenshotTaken(result.dataUrl);
        }
      } catch (error) {
        console.error('Error taking screenshot:', error);
      }
    };

    takeAndHandleScreenshot();

  }, [onTakeScreenshot]);

  return (
    <div>
      <div ref={mapContainer} style={{ height: '336px' }}>
        <div id={'paneDiv'} className={'esri-widget'}>
        </div>
      </div>
      {longitude && latitude && (
        <Button
          className='reset-model-btn'
          onClick={handleResetModelPostion}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

export default EsriMapUploader;
