/* eslint-disable max-len */

import { Typography} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import './style.less';

import {CloseOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Input} from '@pankod/refine-antd';

import {UploadImages} from './UploadImage';
import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import { isExampleProjectByOrigin } from '../../../../../utils/util';

const AppClipView = observer(() => {

  const {projectCreationStore} = useContext(Context)

  const { projectSubTitle, changeProjectSubTitle , projectOrigin, showInfo , setShowInfo, projectId} = projectCreationStore

  // const hasEffectRun = useRef(false);
  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_APP_CLIP_VISIT, { projectId: projectId });
  //     hasEffectRun.current = true;
  //   }
  // }, []);

  // const [showInfo, setShowInfo] = useState(false)

  return (
    <>
      <div >
        <p/>
        <Typography.Text style={{fontSize: 14, fontWeight: 500}}>
                        Subtitle
        </Typography.Text>

        <Typography.Paragraph style={{fontSize: 14, marginTop: 4, marginBottom: 5, fontWeight: 500}}>
                    Character limit 56
        </Typography.Paragraph>
        <Input placeholder="Project subtitle (optional)"
          disabled={isExampleProjectByOrigin(projectOrigin)}
          maxLength={56}
          style={{maxWidth: 313}}
          onChange={(event)=>changeProjectSubTitle(event.target.value)}
          showCount={true} value={projectSubTitle}/>
        <Typography.Paragraph style={{paddingTop: 18}}>
          <Typography.Text style={{fontSize: 14, fontWeight: 500 }}>
                        AppClip Image <ExclamationCircleOutlined onClick={()=> setShowInfo()}/>
            {showInfo ? <div style={{
              minWidth: 200,
              minHeight: 100,
              backdropFilter: 'blur(8px)',
              backgroundColor: 'gray',
              padding: 10,
              borderRadius: 10,
              position: 'absolute',
              opacity: 0.9,
              zIndex: 777,
              left: '20%',
            }}>
              <div style={{display: 'flex'}}>
                <CloseOutlined style={{marginTop: -10, color: 'white'}} onClick={()=> setShowInfo()} />
                <Typography.Paragraph style={{marginTop: 20, marginLeft: 10}}>
                  <Typography.Text style={{color: 'white', fontSize: 14}} strong={true}>
                                        AppClip Image
                  </Typography.Text>
                </Typography.Paragraph>
              </div>
              <Typography.Paragraph
                style={{color: 'white', marginTop: -10, paddingLeft: 25, paddingRight: 10, fontSize: 14}}>
                                This image will be display when users view your project in iOS
              </Typography.Paragraph>
            </div> : null
            }
          </Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph style={{fontSize: 14, marginTop: -10, fontWeight: 500}}>
                    Upload 1800px wide by 1200 px high image
        </Typography.Paragraph>
        <UploadImages loading={true} projectId={projectId}/>
      </div>
    </>
  );
});

export default AppClipView;
