
import React, { useEffect, useMemo, useRef, useState } from 'react';

import SceneView from '@arcgis/core/views/SceneView';
import esriConfig from '@arcgis/core/config';
import WebScene from '@arcgis/core/WebScene';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import Locate from '@arcgis/core/widgets/Locate';
import Zoom from '@arcgis/core/widgets/Zoom';
import NavigationToggle from '@arcgis/core/widgets/NavigationToggle';
import Compas from '@arcgis/core/widgets/Compass';
import Layer from '@arcgis/core/layers/Layer';
import '@arcgis/core/assets/esri/themes/light/main.css';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import { ObjectSymbol3DLayer, PointSymbol3D } from '@arcgis/core/symbols';
import { Button, Card } from 'antd';

// @ts-ignore
import searchMarker from '../../../../../assets/searchMarker.glb';
import locateIcon from '../../../../../assets/map-icons/locate.svg';
import compasIcon from '../../../../../assets/map-icons/compas.svg';
import zoomInIcon from '../../../../../assets/map-icons/zoomIn.svg';
import zoomOutIcon from '../../../../../assets/map-icons/zoomOut.svg';
import focusIcon from '../../../../../assets/map-icons/focus.svg';
import mapMoveIcon from '../../../../../assets/map-icons/mapMove.svg';
import mapRotateIcon from '../../../../../assets/map-icons/mapRotate.svg';

import './styles.less';
import { delay, getStepName, isExampleProjectByOrigin } from '../../../../../utils/util';
import { getFileLocationUrl } from '../../../../../utils/model';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';
import { Header, Tooltip } from '@pankod/refine-antd';
import Paragraph from 'antd/es/skeleton/Paragraph';
import { CloseOutlined } from '@ant-design/icons';
import { authProvider } from 'authPrvider/authProvider';
import { isSubscriptionModelUploadLimitsAchieved } from 'utils/subscription';
import HintPopover from 'pages/registration/HintPopover/HintPopover';

const EsriMap = ({
  isEdit,
  newModelUrl,
  rotation,
  longitude,
  latitude,
  currentLongitude,
  currentLatitude,
  modelUrl,
  altitude,
  searchedLocation,
  isAddressSet,
  requestedCoordinates,
  onModelReset,
  onModelMove,
  onAltitudeChange,
  onRotationChange,
  models,
  setIsModelReset,
  takeScreenshot,
  onScreenshotTaken,
  onModelReplace,
  projectId,
  // setCloseWarningPopup,
}: {
  isEdit: boolean,
  newModelUrl?: string,
  rotation?: number,
  longitude?: number,
  latitude?: number,
  currentLongitude?: number,
  currentLatitude?: number,
  modelUrl?: string,
  altitude?: number,
  searchedLocation?: {
    longitude?: number,
    latitude?: number,
    altitude?: number,
  },
  isAddressSet: boolean,
  requestedCoordinates?: {
    longitude: number,
    latitude: number,
    altitude: number,
  }
  onModelReset?: () => void,
  onModelMove: (longitude: number, latitude: number) => void,
  onAltitudeChange: (altitude: number) => void,
  onRotationChange: (rotation: number) => void,
  models: any,
  setIsModelReset: (value: boolean) => void,
  takeScreenshot: boolean,
  onScreenshotTaken: (dataUrl: string) => void;
  onModelReplace: () => void,
  projectId?: string;
  // setCloseWarningPopup: () => void,
}) => {
  const mapContainer = useRef(null);
  const sketchVMRef = useRef<null | SketchViewModel>(null);
  const [graphicsLayer, setGraphicsLayer] = useState<any>(null);
  const [modelObjectUrl, setModelObjectUrl] = useState<any>(null);
  const [view, setView] = useState<any>(null);
  const [isModelInitialized, setIsModelInitialized] = useState<boolean>(false);
  const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);
  const [isHorizontalNavigation, setIsHorizontalNavigation] = useState<boolean>(true);
  const locateWidgetRef = useRef<any>();
  const zoomWidgetRef = useRef<any>();
  const navigationToggleWidgetRef = useRef<any>();
  const compasWidgetRef = useRef<any>();
  const focusWidgetRef = useRef<any>();
  const [showToolTip, setShowToolTip] = useState(false)
  const [disableReplaceButton, setDisableReplaceButton] = useState(false)

  const homeWidgetRef = useRef(null);

  const createPointGraphic = (longitude: number, latitude: number, altitude: number, objectURL: string) => {
    graphicsLayer?.removeAll();
    const projectPoint = new Point({
      longitude: longitude,
      latitude: latitude,
      z: altitude,
      hasZ: true,
      spatialReference: { wkid: 4326 },
    });

    const pointSymbol = new PointSymbol3D({
      symbolLayers: [
        new ObjectSymbol3DLayer({
          resource: {
            href: objectURL,
          },
          heading: rotation || 0,
        }),
      ],
    });

    const pointGraphic = new Graphic({
      geometry: projectPoint,
      symbol: pointSymbol,

    });

    return pointGraphic;
  }

  const initWidgets = (view: any) => {
    const locateWidget = new Locate({
      view,
    });

    view.ui.add(locateWidget, 'top-right');
    locateWidgetRef.current = locateWidget;
    focusWidgetRef.current = view;

    const zoomWidget = new Zoom({
      view,
    });

    view.ui.add(zoomWidget, 'top-right');
    zoomWidgetRef.current = zoomWidget;

    const navigationToggleWidget = new NavigationToggle({
      view,
    });

    view.ui.add(navigationToggleWidget, 'top-right');
    navigationToggleWidgetRef.current = navigationToggleWidget;

    const compasWidget = new Compas({
      view,
    });

    view.ui.add(compasWidget, 'top-right');
    compasWidgetRef.current = compasWidget;
  }

  useEffect(() => {
    if (
      !isModelInitialized
      && view
      && models?.glb
      && longitude
      && latitude
      && !newModelUrl
      && isEdit
      && isAddressSet
    ) {
      // eslint-disable-next-line no-undef
      const s3Link = getFileLocationUrl(models?.glb);
      fetch(s3Link)
        .then(res => res.blob())
        .then((blob: any) => {
          setIsModelInitialized(true);
          const objectURL = URL.createObjectURL(blob);

          setModelObjectUrl(objectURL);

          view.when(() => {
            if (sketchVMRef && sketchVMRef.current) {
              const pointGraphic = createPointGraphic(longitude, latitude, altitude || 0, objectURL);

              graphicsLayer.add(pointGraphic);
              view.goTo(pointGraphic);
              initWidgets(view);
              sketchVMRef.current?.update(pointGraphic, { tool: 'transform' });
            }
          });
        });
    }
  }, [modelUrl, isModelInitialized, view, isEdit, models?.glb, longitude, latitude, newModelUrl, isAddressSet]);

  useEffect(() => {
    if (
      !isModelInitialized
      && view
      && !isEdit
      && modelUrl
      && longitude
      && latitude
      && !newModelUrl
      && isAddressSet
    ) {
      setIsModelInitialized(true);
      setModelObjectUrl(modelUrl);

      view.when(() => {
        if (sketchVMRef && sketchVMRef.current) {
          const pointGraphic = createPointGraphic(longitude, latitude, altitude || 0, modelUrl);

          graphicsLayer.add(pointGraphic);
          view.goTo(pointGraphic);

          initWidgets(view);
          sketchVMRef.current?.update(pointGraphic, { tool: 'transform' });
        }
      });
    }
  }, [modelUrl, isModelInitialized, isEdit, view, newModelUrl, isAddressSet]);

  useEffect(() => {
    if (view && searchedLocation?.longitude && searchedLocation?.latitude) {

      try {
        graphicsLayer.removeAll();

        const pointGraphic = createPointGraphic(
          searchedLocation?.longitude,
          searchedLocation?.latitude,
          altitude || 0,
          modelObjectUrl,
        );

        graphicsLayer.add(pointGraphic);
        let zoom =2
        if(isAddressSet) zoom = 17.9
        view.goTo({
          center: [searchedLocation?.longitude, searchedLocation?.latitude],
          zoom: zoom,
        });

        initWidgets(view);
        sketchVMRef.current?.update(pointGraphic, { tool: 'transform' });
      } catch (e) {
        console.error('Error:', e)
      }
    }
  }, [view, searchedLocation?.longitude, searchedLocation?.latitude]);

  useEffect(() => {
    if (view && requestedCoordinates?.longitude && requestedCoordinates?.latitude && requestedCoordinates?.altitude) {
      try {
        graphicsLayer.removeAll();

        view.when(() => {

          const pointGraphic = createPointGraphic(
            requestedCoordinates?.longitude,
            requestedCoordinates?.latitude,
            requestedCoordinates?.altitude,
            modelObjectUrl,
          );

          graphicsLayer.add(pointGraphic);

          initWidgets(view);
          sketchVMRef.current?.update(pointGraphic, { tool: 'transform' });
        });
      } catch (e) {
        console.error('Error:', e)
      }
    }
  }, [view, requestedCoordinates?.altitude, requestedCoordinates?.longitude, requestedCoordinates?.latitude]);

  useEffect(() => {
    if (!isMapInitialized) {
      esriConfig.apiKey = 'AAPKf090a4dc9a734410b7694a2edd72f75dLZYBMrS5DdrwbbiDi_bLpmDg4MAZ2XN6HeOXZ40T4FVjfTOBh1zRSEJqKFzcYOgQ'
      // load web scene from ArcGIS Online
      const webScene = new WebScene({
        portalItem: {
          // autocasts as new PortalItem()
          id: '8cb10cf54b5d42999e12b41721da722b',
        },
      });

      const sceneViewObj1 = {
        // @ts-ignore
        container: mapContainer.current,
        map: webScene,
        zoom: 2,
        center: [
          searchedLocation?.longitude || longitude || -73.99634029737001,
          searchedLocation?.latitude || latitude || 40.71401792650208,
        ],
      }
      const sceneViewObj2 = {
        // @ts-ignore
        container: mapContainer.current,
        map: webScene,
        zoom: 17.9,
        center: [
          searchedLocation?.longitude || longitude || -73.99634029737001,
          searchedLocation?.latitude || latitude || 40.71401792650208,
        ],
      }

      let zoom = 2
      if(isAddressSet) zoom = 17.9

      // const view = new SceneView(sceneViewBase);
      const view = new SceneView({
        // @ts-ignore
        container: mapContainer.current,
        map: webScene,
        zoom: zoom,
        center: [
          searchedLocation?.longitude || longitude || -73.99634029737001,
          searchedLocation?.latitude || latitude || 40.71401792650208,
        ],
      });

      setView(view);

      // TODO: example project
      // view.on('pointer-move', function (event: any) {
      //   view.hitTest(event).then(function (response: { results: any }) {
      //     if (response.results.length) {
      //       setShowToolTip(true)
      //     } else {
      //       setShowToolTip(false)
      //     }
      //   });
      // });

      const graphicsLayer = new GraphicsLayer({
        elevationInfo: { mode: 'absolute-height' },
      });
      view.map.add(graphicsLayer);
      setGraphicsLayer(graphicsLayer);

      const tentBtn = document.getElementById('tent');

      view.when(() => {
        // const homeWidget = new Home({
        //   view: view,

        // });
        // // @ts-ignore
        // homeWidgetRef.current = homeWidget;
        // view.ui.add(homeWidget, 'top-right');
        // homeWidget.goToOverride = function (view, goToParams) {
        //   if (longitude && latitude) {
        //     console.log(longitude, latitude)
        //     goToParams.options.duration = 1;
        //     goToParams.target = {
        //       center: [+longitude, +latitude],
        //       zoom: 17,
        //       heading: 0,
        //       tilt: 60,
        //     }
        //     return view.goTo(goToParams.target, goToParams.options);
        //   }
        // };
        const osmLayerUrl = 'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_Buildings_v1/SceneServer'
        view.when(() => {
          view.map.allLayers.forEach(layer => {
            if (layer.get('url') == osmLayerUrl) {
              layer.opacity = 0.3

            }
          })
        })
        sketchVMRef.current = new SketchViewModel({
          layer: graphicsLayer,
          view,
          defaultUpdateOptions: {
            enableScaling: false,
            enableZ: true,
            tool: 'transform',
          },
        });

        initWidgets(view);

        setTimeout(() => {
          setIsMapInitialized(true)
        }, 1000);
        // sketchVMRef.current = sketchVM

        // tentBtn?.addEventListener('click', () => {
        //   console.log('_____tentBtn click: ')
        //   // load model on map
        //   sketchVMRef?.current?.pointSymbol = {
        //     type: 'point-3d',
        //     // @ts-ignore
        //     symbolLayers: [
        //       {
        //         type: 'object',
        //         resource: {
        //           href: fileUrl
        //           // href: 'https://incitu-3d-model-production.s3.us-east-2.amazonaws.com/projects/cclcuwwib800023n6kryinzng9/clf8ior0s001ex102dqrnrbgc/clf8ior0s001ex102dqrnrbgc_0.glb'
        //         }
        //       }
        //     ]
        //   };
        //   sketchVMRef.current?.create('point');
        //   deactivateButtons();
        //   tentBtn.classList.add('esri-button--secondary');
        // })
        sketchVMRef.current?.on('create', (event) => {
          if (event.state === 'complete') {
            setIsModelReset(true);
            sketchVMRef.current?.update(event.graphic, { tool: 'transform' });

            deactivateButtons();
          }
        });

        let originalGeometry: any;

        sketchVMRef.current?.on('update', async (event) => {
          const updatedGraphic = event.graphics[0]; // Assuming only one graphic is being updated
          const updatedGeometry = updatedGraphic.geometry;

          // Check if this is the first update event (indicating the start of editing)
          if (!originalGeometry) {
            originalGeometry = updatedGeometry.clone(); // Store a clone of the original geometry
            return; // Exit the function to avoid further processing
          }

          // Assuming the geometry is a point
          if (updatedGeometry.type === 'point') {
            const originalLongitude = originalGeometry.longitude;
            const originalLatitude = originalGeometry.latitude;
            const originalElevation = originalGeometry.z;

            const updatedLongitude: number = updatedGeometry.get('longitude');
            const updatedLatitude: number = updatedGeometry.get('latitude');
            const updatedElevation: number = updatedGeometry.get('z');

            const isMovement = originalLongitude !== updatedLongitude || originalLatitude !== updatedLatitude;
            const isElevationChange = originalElevation !== updatedElevation;

            if (isMovement) {
              onModelMove(updatedLongitude, updatedLatitude);
            } else if (isElevationChange && event.toolEventInfo && event?.toolEventInfo?.type === 'move') {
              onAltitudeChange(updatedElevation);
            }
          }
          // homeWidget.goToOverride = function(view, goToParams) {
          //   goToParams.options.duration = 1;
          //   goToParams.target = {
          //     center: [lon, lat],
          //     zoom: 17,
          //     heading: 0,
          //     tilt: 60,
          //   }
          //   return view.goTo(goToParams.target, goToParams.options);
          // };

          if (event.tool === 'transform') {

            if (event.toolEventInfo) {
              const info = event.toolEventInfo,
                type = info.type;

              if (type.match('move-stop')) {
                originalGeometry = null;
              }

              if (type.includes('rotate-stop')) {
                if ('angle' in info) {
                  onRotationChange(info.angle);
                }
              }
            }

          }
        })

        // @ts-ignore
        // document.getElementById('submit').onsubmit = function handleSubmit(e) {
        //   // Prevent the browser from reloading the page
        //   e.preventDefault();

        //   // Read the form data
        //   const form = e.target;
        //   if (form) {
        //     // @ts-ignore
        //     const formData = new FormData(form);
        //     const lat = formData.get('latitude')
        //     const lon = formData.get('longitude')

        //     if (sketchVMRef && sketchVMRef.current) {
        //       sketchVMRef.current.updateGraphics.at(0).geometry.set('latitude', lat)
        //       sketchVMRef.current.updateGraphics.at(0).geometry.set('longitude', lon)
        //       sketchVMRef.current.update(graphicsLayer.graphics.at(0), { tool: 'transform' })
        //       // Or you can work with it as a plain object:
        //       const formJson = Object.fromEntries(formData.entries());
        //       console.log(formJson);
        //     }
        //   }
        // }

      })
        .catch(console.error);

      // const pane = document.getElementById('paneDiv');
      // // @ts-ignore
      // view.ui.add(pane, 'top-right');

    }
    function deactivateButtons() {
      const elements = Array.prototype.slice.call(document.getElementsByClassName('esri-button'));
      elements.forEach((element) => {
        element.classList.remove('esri-button--secondary');
      });
    }
  }, [rotation, newModelUrl, isMapInitialized, longitude, latitude, isAddressSet]);

  useEffect(() => {
    if (newModelUrl && isMapInitialized && isAddressSet && longitude && latitude) {
      graphicsLayer.removeAll();

      fetch(newModelUrl)
        .then(res => res.blob())
        .then((blob: any) => {
          setIsModelInitialized(true);

          const objectURL = URL.createObjectURL(blob);
          setModelObjectUrl(objectURL);

          view.when(() => {
            if (sketchVMRef && sketchVMRef.current) {
              const pointGraphic = createPointGraphic(+longitude, +latitude, altitude || 0, objectURL);

              let zoom =2
              if(isAddressSet) zoom = 17.9
              graphicsLayer.add(pointGraphic);
              view.goTo({
                center: [+longitude, +latitude],
                zoom: zoom,
              });

              initWidgets(view);

              sketchVMRef.current?.update(pointGraphic, { tool: 'transform' });
            }
          });
        });
    }
  }, [newModelUrl, isMapInitialized, isAddressSet, longitude, latitude]);

  useEffect(() => {
    const takeAndHandleScreenshot = async () => {
      try {
        if (takeScreenshot) {
          // @ts-ignore
          // if (homeWidgetRef.current) {
          //   triggerGoToOverride();
          // }
          if (focusWidgetRef.current) {
            triggerGoToOverride();
          }
          await delay(3000)

          const result = await view.takeScreenshot({ format: 'png' });
          onScreenshotTaken(result.dataUrl);
        }
      } catch (error) {
        console.error('Error taking screenshot:', error);
      }
    };

    takeAndHandleScreenshot();

  }, [takeScreenshot]);

  const placePoint = (url: string) => {
    if (!sketchVMRef.current || !url) {
      return;
    }

    sketchVMRef.current.pointSymbol = {
      type: 'point-3d',
      // @ts-ignore
      symbolLayers: [
        {
          type: 'object',
          resource: {
            href: url,
          },
        },
      ],
    };
    setModelObjectUrl(url);

    sketchVMRef.current.create('point');
  };

  const handleResetModelPostion = () => {
    onModelReset?.();
  }

  function triggerGoToOverride() {
    console.log('_____focus 111>>>>>>')
    const zoom = 17.9
    // if(isAddressSet) zoom = 17.9
    if (focusWidgetRef.current) {
      const focusWidget: any = focusWidgetRef.current
      focusWidget.goTo({
        center: [longitude, latitude],
        zoom: zoom,
        heading: 0,
        tilt: 60,
      });
    }
  }

  // function triggerGoToOverride() {
  //   if (homeWidgetRef.current) {
  //     const homeWidget: any = homeWidgetRef.current
  //     const goToParams = {
  //       options: {
  //         duration: 1,
  //       },
  //       target: {
  //         center: [longitude, latitude],
  //         zoom: 17,
  //         heading: 0,
  //         tilt: 60,
  //       },
  //     };

  //     homeWidget.goToOverride(view, goToParams);
  //   }
  // }

  // useEffect(() => {
  //   const takeAndHandleScreenshot = async () => {
  //     try {
  //       if (onTakeScreenshot) {
  //         // @ts-ignore
  //         if (homeWidgetRef.current) {
  //           triggerGoToOverride();
  //         }

  //         await delay(3000)

  //         const result = await view.takeScreenshot({ format: 'png' });
  //         onScreenshotTaken(result.dataUrl);
  //       }
  //     } catch (error) {
  //       console.error('Error taking screenshot:', error);
  //     }
  //   };

  //   takeAndHandleScreenshot();

  // }, [onTakeScreenshot]);

  const handleZoomIn = () => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_ZOOM_IN_ACTION, { projectId: projectId });
    zoomWidgetRef.current?.zoomIn();
  }

  const handleZoomOut = () => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_ZOOM_OUT_ACTION, { projectId: projectId });
    zoomWidgetRef.current?.zoomOut();
  }

  const handleFocus = () => {
    if (currentLatitude && currentLongitude) {
      view?.goTo({
        center: [+currentLongitude, +currentLatitude],
        zoom: 17.9,
        heading: 0,
        tilt: 60,
      });
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_FOCUS_MAP_ACTION, { projectId: projectId });
    }
  }

  const handleLocate = () => {
    if (longitude && latitude && locateWidgetRef.current) {
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_USER_LOCATION_ACTION, { projectId: projectId });
      locateWidgetRef.current.locate();
    }
  }

  const handleNavigationToggle = () => {
    navigationToggleWidgetRef.current.toggle();
    const isHorizontalNavigationUpdated = !isHorizontalNavigation
    if(isHorizontalNavigationUpdated) {
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_MAP_MOVEMENT_ACTION, { projectId: projectId });
    } else {
      mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_MAP_ROTATION_ACTION, { projectId: projectId });
    }
    setIsHorizontalNavigation(isHorizontalNavigationUpdated);
  }

  const handleCompasReset = () => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_COMPASS_MAP_ACTION, { projectId: projectId });
    compasWidgetRef.current.reset();
  }

  const TooltipContent = (
    <Card bordered={false} style={{ width: 250, backgroundColor: 'transparent', color: 'white' }}>
      <h1 className="example-popup-popover-title">Adjust Placement</h1>
      <div className="example-popup-popover-description">Use orange arrows to adjust model position</div>
    </Card>
  );

  useEffect(() => {
    
    const incituUser = authProvider.getInCituUser();
    const { isLimitAchieved, modelUploads, isBasicPlan } = isSubscriptionModelUploadLimitsAchieved(incituUser)
    if(isLimitAchieved) {
      setDisableReplaceButton(true);
    }
   
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={mapContainer} className="map-div-size">
      </div>
      <div className="controls-right">
        <>
          {longitude && latitude && modelObjectUrl && (
            <Button
              style={disableReplaceButton ? {opacity: 0.5} : {}}
              className='replace-model-btn'
              onClick={onModelReplace}
            >
          Replace model
            </Button>
          )}
        </>

        {longitude && latitude && (
          <Button
            className='reset-model-btn'
            onClick={handleResetModelPostion}
          >
          Reset
          </Button>
        )}

        <div className="controls-block">

          {showToolTip ?
            <Tooltip placement="bottomLeft" color="#9C9C9C" zIndex={99999999999}
              title={TooltipContent} open={true}>
              <Button style={{marginLeft: '-22vw', marginTop: '17vw' , position: 'absolute', opacity: 0}}></Button>
            </Tooltip> : null
          }
          <Button
            className='widget-btn left'
            id='zoom-in'
            style={{marginTop: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5}}
            onClick={handleZoomIn}
            icon={<img src={zoomInIcon} alt='zoomIn' />}
          />
          <Button
            className='widget-btn right'
            id='zoom-out'
            style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5,  marginBottom: -8}}
            onClick={handleZoomOut}
            icon={<img src={zoomOutIcon} alt='zoomOut' />}
          />
          <br/>
          <Button
            className='widget-btn top'
            id='reset'
            style={{borderTopLeftRadius: 5, borderTopRightRadius: 5}}
            onClick={handleNavigationToggle}
            disabled={isHorizontalNavigation}
            icon={<img src={mapMoveIcon} alt='locate' />}
          />
          <Button
            className='widget-btn bottom'
            id='rotate'
            style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: -8}}
            onClick={handleNavigationToggle}
            disabled={!isHorizontalNavigation}
            icon={<img src={mapRotateIcon} alt='compas' />}
          />
          <br/>
          <Button
            className='widget-btn left'
            id='locate'
            style={{borderTopLeftRadius: 5, borderTopRightRadius: 5}}
            onClick={handleLocate}
            icon={<img src={locateIcon} alt='locate' />}
          />
          <Button
            className='widget-btn right'
            id='compas'
            style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5,  marginBottom: -8}}
            onClick={handleCompasReset}
            icon={<img src={compasIcon} alt='compas' />}
          />
          <br/>
          <Button
            className='widget-btn left'
            id='focus'
            style={{borderRadius: 5}}
            onClick={handleFocus}
            icon={<img src={focusIcon} alt='locate' />}
          />
        </div>
      </div>
    
    </div>
  );
};

export default EsriMap;
