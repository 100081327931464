import React, { useMemo, useState } from 'react';
import ShareIcon from '../../../assets/Share_Icon.svg'
import QRIcon from '../../../assets/Qr_Code.svg'
import DeleteIcon from '../../../assets/Bin_2.svg'
import EditIcon from '../../../assets/Content_Pen_5.svg'
import { useDelete, useNavigation } from '@pankod/refine-core';
import { Button, Col, Popconfirm, Row, Tooltip } from '@pankod/refine-antd';
import { Image, message, Popover, Space, Spin } from 'antd';
import QRCodeModalComponent from '../../ProjectDetailsFormNew/components/QRCode/QRCodeComponent';
import { showProjectDeleteConfirmModal } from '../../Modal/ProjectDeleteConfirmModal';
import {
  copyToClipboard,
  goToStripeCustomerPanel,
  isExampleProject,
  isExampleProjectByOrigin,
} from '../../../utils/util';
import ShareModel from '../../../pages/public-view/project-demo/components/project-description/ShareModal';
import { MoreOutlined } from '@ant-design/icons';
import { showErrorModal } from '../../Modal/ShowErrorModal';
import { isBasicSubscriptionPro } from '../../../utils/subscription';
import { authProvider } from '../../../authPrvider/authProvider';
import DeletionErrorPopup from '../../../pages/project-creation-step-composer/components/DeletionErrorPopup';
import '../style.less';
import HintPopover from '../../../pages/registration/HintPopover/HintPopover';

function ProjectCardActions(props: { project: any; deleteProject: any, handleOpenProjectForm: any; }){
  const {
    project,
    deleteProject,
    handleOpenProjectForm,
  }:any = props;
  const { push } = useNavigation();
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [isShowCloseWarning, setShowCloseWarning] = useState<boolean>(false);

  const actions=[
    {
      icon:ShareIcon,
      handleClick:()=>{
        setIsQRModalOpen(true)
        // setIsShareModalOpen(true)
      },
      title: 'Share',
    },
    // {
    //   icon:QRIcon,
    //   handleClick:()=>{
    //     setIsQRModalOpen(true)
    //   },
    //   title: 'QR',
    //
    // },
    {
      icon:EditIcon,
      handleClick:()=>{
        // push(`/project-edit/${project.id}`)
        handleOpenProjectForm(project.id)
      },
      title: 'Edit',
    },
    {
      icon:DeleteIcon,
      type:'delete',
      handleClick:()=>{
        if (isExampleProject(project)){
          console.log('You cannot delete the example project')
          showErrorModal('Error', 'You cannot delete the example project')
        } else {
          const incituUser = authProvider.getInCituUser();
          if (isBasicSubscriptionPro(incituUser)) {
            setShowCloseWarning(true);
          } else {
            showProjectDeleteConfirmModal(project.id, deleteProject)
          }
        }
      },
      title: 'Delete',

    },
  ]

  const handleOpenChange=()=>{
    setOpenPop(!openPop);
  }

  const popoverContent = useMemo(() => {
    return (
      <h1 className='project-action-popup-popover-title'>
          Edit this project to see it in AR near you
      </h1>
    )
  }, [])
  return (
    <>
      {
        <Row justify="space-around" className="project-grid-card-actions">
          {actions.map((item: any) => (
            <Col key={JSON.stringify(item)}>
              <div>
                {item.title === 'Edit' && isExampleProject(project) ? (
                  <HintPopover
                    content={popoverContent}
                    arrowBottom={true}
                    popoverInner={true}
                    style={{
                      width: '100px !important',
                    }}
                  >
                    <div className="grid-action-btn" onClick={item.handleClick}>
                      <img src={item.icon} alt={item.title} />
                    </div>
                  </HintPopover>
                ) : (
                  <div className="grid-action-btn" onClick={item.handleClick}>
                    <img src={item.icon} alt={item.title} />
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      }
      {/*<Popover*/}
      {/*  content={*/}
      {/*    <Space direction="vertical"  className='project-grid-card-actions'>*/}
      {/*      {*/}
      {/*        actions.map((item:any)=><Col key={JSON.stringify(item)}>*/}
      {/*          {*/}
      {/*            <Row align="middle" onClick={item.handleClick} style={{cursor:'pointer'}}> */}
      {/*              <div><img src={item.icon} /></div> */}
      {/*              <span style={{marginLeft:'10px'}}>{item.title}</span> </Row>*/}
      {/*          }*/}
      {/*        </Col>)*/}
      {/*      }*/}
      {/*    */}
      {/*    </Space> */}
      {/*  }*/}
      {/*  title=""*/}
      {/*  className='projects_table_actions'*/}
      {/*  trigger="click"*/}
      {/*  open={openPop}*/}
      {/*  onOpenChange={handleOpenChange}*/}
      {/*  placement="bottom"*/}
      {/*>*/}
      {/*  <MoreOutlined onClick={handleOpenChange} />*/}
      {/*</Popover>*/}
      <DeletionErrorPopup
        showCloseWarning={isShowCloseWarning}
        onLeave={() => {
          setShowCloseWarning(false);
        }}
        onContinue={() => {
          setShowCloseWarning(false);
          goToStripeCustomerPanel();
        }}
      />
      <ShareModel
        visible={isShareModalOpen}
        projectId={project?.id}
        handleOpenShareModal={() => setIsShareModalOpen(false)}
      />
      <QRCodeModalComponent
        projectId={project?.id}
        isQRModalOpen={isQRModalOpen}
        setIsQRModalOpen={setIsQRModalOpen}
        projectName={project?.name}
      />
    </>
  );
}

export default ProjectCardActions;
