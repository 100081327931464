import '@google/model-viewer';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Image } from 'antd';
import { MixpanelEvent, mixPanelUtils } from '../../../mixpanel/mixpanel';
import { DeleteOutlined } from '@ant-design/icons';

import ARButton from '../../../../../assets/ar_button.png';
import './styles.less';
import { getFileLocationUrl } from '../../../../../utils/model';

/* eslint-disable */
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': {
        src: string;
        'ios-src'?: string;
        'camera-controls'?: boolean;
        'disable-zoom'?: boolean;
        'auto-rotate'?: boolean;
        ar?: boolean;
        autoplay?: boolean;
        exposure?: number | string;
        'shadow-intensity'?: number | string;
        'ar-scale'?: number | string;
        'loading'?: string;
        'scale'?: string;
        'poster'?:string;
        toBlob?: () => Promise<Blob>;
      } & React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const Model = ({ isDebugButtonVisible, isModelAvailable, projectId, usdzModel, glbModel, isPreview=false, deleteModel }: any) => {
  const [arDebug, setARDebug] = useState(false)

  const arStarted = useRef(false);
  const arMode = useRef(false);
  const [modelGLB, setModelGLB] = useState(undefined)
  const [modelUSDZ, setModelUSDZ] = useState(undefined)

  const getAWSUrl = () => {
    return `https://${process.env.REACT_APP_AWS_BUCKET_NAME_MODELS}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;
  }

  useEffect(() => {
      const modelViewer: any = document.querySelector('model-viewer')
      // if(modelViewer) {
      //   modelViewer?.addEventListener('load', () => {
      //     console.log(modelViewer.model.materials)
      //     for (const material of modelViewer?.model.materials) {
      //       if(material.getAlphaMode() == "OPAQUE")
      //       {
      //         material.pbrMetallicRoughness.setBaseColorFactor([0, 0,0]);
      //       }
      //     }
      //   });
      // }
  }, [modelGLB, modelUSDZ])


  useEffect(() => {
    /* eslint-disable */
    const glbHologramStorageID = getFileLocationUrl(glbModel);
    const usdzHologramStorageID = getFileLocationUrl(usdzModel);

    if (glbHologramStorageID || usdzHologramStorageID) {
      console.log("DEBUG glbHologramStorageID: ", glbHologramStorageID);
      console.log("DEBUG usdzHologramStorageID: ", usdzHologramStorageID);

      // Assuming these functions need to be invoked even if one of the IDs is null
      setModelUSDZ(usdzHologramStorageID);
      setModelGLB(glbHologramStorageID);
    }  else{
        setModelUSDZ(undefined)
        setModelGLB(undefined)
    }
  }, [usdzModel, glbModel])


  useEffect(() => {
    document.addEventListener('visibilitychange', function (event) {
      if (!document.hidden && arStarted.current == true) {
        // if(arMode.current) {
        //   alert('AR FINISHED')
        // }
        if(projectId) {
          mixPanelUtils.track(MixpanelEvent.AR_SESSION_END, { projectId  })
          arStarted.current = false
        }
      }
    });
  },[]);

  const onModelClick = () => {
    // if(arMode.current) {
    //   alert('AR STARTERD')
    // }

    if(projectId) {
      mixPanelUtils.track(MixpanelEvent.AR_SESSION_START, { projectId })
      arStarted.current = true
    }
  }

  const onDebugClick = () => {
    const ar = arMode.current
    arMode.current = !ar
    setARDebug(!ar)
  }

  const modelViewerRef: any = useRef();
  const triggerAR = () => {
    if (modelViewerRef.current) {
      modelViewerRef.current.activateAR();
    }
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let arParam = urlParams.get('ar');
    if (arParam) {
      console.log('____AR HERE___', urlParams)
      triggerAR();
    }
  }, []);

  return (
      <div id='card'>
        {isDebugButtonVisible === 'true' &&
          <button
            onClick={onDebugClick}
          >{
            arDebug == false ? 'Enable debug' : 'Disable debug'}
          </button>
        }

        {modelGLB || modelUSDZ ? // && isModelAvailable ?
          <model-viewer
            ref={modelViewerRef}
            src={`${modelGLB}`}
            ios-src={`${modelUSDZ}`}
            shadow-intensity='1'
            camera-controls
            auto-rotate={true}
            ar={true}
            exposure='0.02'
            ar-placement='floor'
            ar-scale='auto'
            loading='eager'
            className={modelUSDZ && 'preview-usdz-model'}
            poster={modelUSDZ ? 'https://external-preview.redd.it/Z-N5pyinowcWfbZhqc56po-Q3OVjraltf3aTkxH07y0.jpg?auto=webp&s=a5110d87196aed6428a997b79f56cb60504c62b5' : ''}
          >
            { glbModel?.url &&
              <Button className='modelPreviewDeleteButton' shape='circle' icon={<DeleteOutlined />} type="primary" danger onClick={deleteModel} />
            }
            <button className='arButton arButtonCustom' onClick={onModelClick} slot='ar-button'>
              <img src={ARButton} alt='*'/>
            </button>
          </model-viewer>
          : <Image
            width={'100%'}
            height={'100%'}
            src={require('../../../../../assets/3d.png')}
          />
        }
      </div>
  )
}

export default Model;

