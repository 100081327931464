import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { authProvider } from '../../../authPrvider/authProvider';
import { MAX_MODEL_UPLOADS } from '../../../constants/subscription';
import { CloseOutlined } from '@ant-design/icons';

export default function WarningLimitModalUploadPopup({
  showCloseWarning,
  onModelReplace,
  onContinue,
  isShowWarningLimitModelUploadPopup,
  setIsShowWarningLimitModelUploadPopup,
}:any){

  const userIncitu = authProvider.getInCituUser();
  const uploadNumbersLeft = MAX_MODEL_UPLOADS - userIncitu?.subscriptionPlan?.modelUploads;

  return (
    <Modal
      className="modal-upload-limit-popup-modal-wrapper"
      closeIcon={false}
      closable={false}
      open={showCloseWarning}
      width={'auto'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: 'auto',
        transform: 'translateY(-50%)',
      }}
      footer={[]}
    >
      <div>
        <CloseOutlined style={{float: 'right', marginTop: -10, marginRight: -10}}
          onClick={()=>  setIsShowWarningLimitModelUploadPopup(!isShowWarningLimitModelUploadPopup)}/>
        <Row justify="center">
          <Typography.Text style={{ fontSize: '20px', fontWeight: 600 }}>
            Model replacement limit
          </Typography.Text>
        </Row>
        <Row justify="center" style={{ marginTop: '16px', fontWeight: 600 }}>
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 600 }}>
            <b>You can replace you model {uploadNumbersLeft} more time</b>
          </Typography.Paragraph>
        </Row>
        <Row justify="center" style={{ marginTop: '16px' }}>
          <Typography.Text style={{ fontSize: '14px' }}>
            With the Basic subscription, you can
          </Typography.Text>
        </Row>
        <Row justify="center">
          <Typography.Text style={{ fontSize: '14px' }}>
            only replace your model 3 times.
          </Typography.Text>
        </Row>

        <Row style={{ marginTop: '16px' }} justify="center">
          <Col>
            <Button
              type="link"
              onClick={onModelReplace}
              className="keep-btn"
              style={{ fontSize: '12px', fontWeight: 600 }}
            >
              Replace Model
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }} justify="center">
          <Col>
            <Button
              type="link"
              onClick={onContinue}
              className="upgrade-btn"
              style={{
                color: 'hsl(0, 0%, 45%)',
              }}
            >
              <span style={{ textDecoration: 'underline',  fontSize: '14px' }}>
                Upgrade Subscription
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
