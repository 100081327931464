import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { getRemainingNumberOfUploads } from '../../../utils/subscription';
import { authProvider } from '../../../authPrvider/authProvider';
import { MAX_MODEL_UPLOADS } from '../../../constants/subscription';

export default function DeletionErrorPopup({showCloseWarning,onLeave,onContinue}:any){

  const uploadNumbersLeft = getRemainingNumberOfUploads(authProvider.getInCituUser())
  return (
    <Modal
      className="modal-upload-limit-popup-modal-wrapper"
      closeIcon={false}
      closable={false}
      open={showCloseWarning}
      width={'auto'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: 'auto',
        transform: 'translateY(-50%)',
      }}
      footer={[]}
    >
      <div>
        <Row justify="center">
          <Typography.Text style={{ fontSize: '20px', fontWeight: 600 }}>
            Your one Basic project
          </Typography.Text>
        </Row>
        {/*<Row justify="center">*/}
        {/*  <Typography.Text style={{ fontSize: "20px", fontWeight: 600 }}>*/}
        {/*    subscriptions are not able to*/}
        {/*  </Typography.Text>*/}
        {/*</Row>*/}
        <Row justify="center">
          <Typography.Text style={{ fontSize: '20px', fontWeight: 600 }}>
            cannot be deleted
          </Typography.Text>
        </Row>
        {uploadNumbersLeft > 0 && (
          <>
            <Row justify="center">
              <Typography.Text style={{ fontSize: '14px', fontWeight: 600 }}>
                In your project, you can replace the model
              </Typography.Text>
            </Row>
            <Row justify="center">
              <Typography.Text style={{ fontSize: '14px', fontWeight: 600 }}>
                up to {MAX_MODEL_UPLOADS} times
              </Typography.Text>
            </Row>
          </>
        )}

        <Row
          style={{
            marginTop: '16px',
            width: '100%',
            backgroundColor: 'yellow',
          }}
          justify="center"
        >
          <Button
            type="link"
            onClick={onContinue}
            className="keep-btn"
            style={{
              color: 'hsl(0, 0%, 45%)',
              fontSize: '12px',
              fontWeight: 600,
              width: '100%',
            }}
          >
            Upgrade to inCitu Pro
          </Button>
        </Row>
        <Row style={{ marginTop: '16px' }} justify="center">
          <Col>
            <Button type="link" onClick={onLeave} className="upgrade-btn">
              <span style={{ textDecoration: 'underline', fontSize: '14px' }}>
                Keep Basic Subscription
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
