import { Col, Row } from '@pankod/refine-antd';
import React from 'react';
import LogoSVG from '../../../../assets/Logo_Black.png';
import HeaderAccount from '../../../../components/HeaderAccount';

import './styles.less';
import NavigationTabBar from '../../../../components/TabBar';

import { isLinkUsPage } from '../../../../utils/util';

const ProjectHeader: React.FC = () => (
  <Row align='middle' justify='center'  style={{ visibility: isLinkUsPage() ? 'hidden' : 'visible' }}>
    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} className='map-header-container'>
      <a href='https://www.incitu.us' target='_blank' rel='noopener noreferrer'><img src={LogoSVG} alt='*' /></a>
    </Col>
    <Col xxl={17} xl={17} lg={17} md={17} sm={17} xs={17}>
      <NavigationTabBar dashboard={false}/>
    </Col>
    <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
      <HeaderAccount />
    </Col>
  </Row>
);

export default ProjectHeader;
