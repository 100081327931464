import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Typography } from '@pankod/refine-antd';

import ProjectDescription from '../project-description';
import ProjectModelPreview from '../model-preview';
import QRCodeModalComponent from '../../../../../components/ProjectDetailsForm/components/QRCode/QRCodeComponent';
import FeedbackForm from '../feedback/feedback';
import useProjectCommentsFetch from '../../../../../hooks/useProjectCommentsFetch';
import SearchComponent from 'pages/public-view/map/SearchComponent';
import { checkIfAndroidInterfaceAvailable, openNativeAndroidSharePopup } from '../../../../../utils/checkMobile';

const ProjectView = (props: any) => {
  const {
    projectId,
    descriptionData,
    feedbackVisible,
    setFeedbackVisible,
    isModelAvailable,
    usdzModel,
    glbModel,
    isModelExpanded,
    setIsModelExpanded,
    isMobile,
    isMaxHeight,
    autoGenerateModels,
    searchLocation,
    goTo,
    searchItems,
    locate,
  }: any = props;
  const [fetchComments] = useProjectCommentsFetch(projectId);
  const [fetchedAllComments, setFetchedAllComments] = useState<any>(false);
  const [comments, setComments] = useState<any>({
    list: [],
    count: 0,
  });
  const commentsFetchCount = useMemo(() =>
    fetchedAllComments ? comments.count + 1 : 10
  , [comments.count, fetchedAllComments]);

  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const isMobileMinimized = isMobile && !isMaxHeight;

  const fetchData = async () => {
    const data = await fetchComments(commentsFetchCount);

    setComments(data);
  }

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const handleExpandModel = () => {
    setIsModelExpanded(true);
  }

  const handleCollapseModel = () => {
    setIsModelExpanded(false);
  }

  const handleOpenQRModal = () => {
    setIsQRModalOpen(true);
  }

  const buildUrl = (projId: string) => {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/project/${projId}`
  }

  const handleOpenShareModal = (toggledState?:boolean)=>{
    if(checkIfAndroidInterfaceAvailable()) {
      openNativeAndroidSharePopup(`${encodeURIComponent(buildUrl(projectId))}`)
    } else {
      setOpenShareModal(toggledState == null ? true : false);
    }
  }

  const handleCloseFeedbackForm = () => {
    setFeedbackVisible(false);
    fetchData().catch(console.error);
  }

  const handleGetAllComments = async () => {
    const data = await fetchComments(comments.count);

    setComments(data);
    setFetchedAllComments(true);
  }

  return (
    <Row gutter={[24, 48]} justify='center' className='h-100'>
      <Col
        span={isModelExpanded ? 10 : 24}
        className={`
          project-details-column 
          ${isMobile ? 'mobile' : ''} 
          ${isMobileMinimized ? 'minimized' : ''} 
          ${isModelExpanded ? 'expanded' : ''}
        `}>
        <Row gutter={24} className='grid-rows'>
          {!isMobile && <SearchComponent
            searchLocation={searchLocation}
            goTo={goTo}
            searchItems={searchItems}
            locate={locate}
          />}
          {!isModelExpanded && (
            <ProjectModelPreview
              scale={0.05}
              modelPosition={[0, -3, 0]}
              isModelAvailable={isModelAvailable}
              projectId={projectId}
              usdzModel={usdzModel}
              glbModel={glbModel}
              isMap={true}
              expandModel={handleExpandModel}
              isMobile={isMobile}
              isMaxHeight={isMaxHeight}
              autoGenerateModels={autoGenerateModels}
            />
          )}
          <Col span={24}>
          </Col>
          <Col span={24}>
            <ProjectDescription
              projectId={projectId}
              openFeedbackForm={() => setFeedbackVisible(true)}
              data={descriptionData}
              openQRModal={handleOpenQRModal}
              isMobile={isMobile}
              isMobileMinimized={isMobileMinimized}
              comments={comments}
              getAllComments={handleGetAllComments}
              openShareModal={openShareModal}
              handleOpenShareModal={handleOpenShareModal}
              isModelExpanded={isModelExpanded}
            />
          </Col>
        </Row>
        {!isMobile && <QRCodeModalComponent
          projectId={projectId}
          isQRModalOpen={isQRModalOpen}
          setIsQRModalOpen={setIsQRModalOpen}
        />}
        <FeedbackForm
          projectId={projectId}
          visible={feedbackVisible}
          onClose={handleCloseFeedbackForm}
        />
      </Col>
      {isModelExpanded && !isMobile && (<Col md={14} xs={24} sm={24}>
        <ProjectModelPreview
          scale={0.05}
          modelPosition={[0, -3, 0]}
          isModelAvailable={isModelAvailable}
          projectId={projectId}
          usdzModel={usdzModel}
          glbModel={glbModel}
          isMap={true}
          isModelExpanded={true}
          collapseModel={handleCollapseModel}
          autoGenerateModels={autoGenerateModels}
        />
      </Col>)}
    </Row>
  );
}

export default ProjectView;
