import { message } from '@pankod/refine-antd';
import { Project } from '../dataProvider/gql/types';
import dataProvider from '../dataProvider/dataProvider';
import { useNavigation, useOne } from '@pankod/refine-core';

export default function useProjectDetailsFetch({ id }: any) {
  const { push } = useNavigation();

  if (id) {
    const {
      data: projectInfo,
      status: pageLoadStatus,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    }: any = useOne<Project>({
      resource: 'project',
      id,
      metaData: {
        fields: [
          'id',
          'name',
          'subtitle',
          'origin',
          'description',
          'status',
          'createdAt',
          'address',
          'longitude',
          'latitude',
          'altitude',
          'organizationId',
          // 'statusDetail',
          'borough',
          'ceqr',
          'bbl',
          // 'attributes',
          'appClipSync',
          'autoGenerateModels',
          // eslint-disable-next-line max-len
          'buildings{id buildingHeight model3ds{id type fileLocation fileSizeMb externalId order anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
          'organization{id organizationUsers{user{id email firstName lastName}}}',
          'projectUsers { relation user { firstName lastName email }}',
          'documents{id url name type category}',
          'timelineEvents{id name timelineEventType{id name}}',
          'projectViewPreference{id visibility isAdditionalInfoVisible isSnapToGround}',
        ],
      },
    });
    if (projectInfo?.data === null) {
      message.error('Invalid projectd Id!', 1);
      push('/projects');
    }
    return projectInfo ? [projectInfo?.data, pageLoadStatus] : [];
  } else {
    return [];
  }
}

