export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AltitudeResult = {
  __typename?: 'AltitudeResult';
  elevation: Scalars['Decimal']['output'];
  geoidHeight: Scalars['Decimal']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
};

export type Anchoring = {
  __typename?: 'Anchoring';
  _count: AnchoringCount;
  anchorCoordinate?: Maybe<Coordinate>;
  anchorCoordinateId?: Maybe<Scalars['String']['output']>;
  anchorIdx?: Maybe<Scalars['Int']['output']>;
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  curbCoordinate?: Maybe<Coordinate>;
  curbCoordinateId?: Maybe<Scalars['String']['output']>;
  footprintCoordinates?: Maybe<Array<Coordinate>>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  model3D: Model3D;
  model3DId?: Maybe<Scalars['String']['output']>;
  rotation?: Maybe<Scalars['Decimal']['output']>;
  secondaryAnchorCoordinate?: Maybe<Coordinate>;
  secondaryAnchorCoordinateId?: Maybe<Scalars['String']['output']>;
  secondaryAnchorIdx?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AnchoringAvgAggregate = {
  __typename?: 'AnchoringAvgAggregate';
  anchorIdx?: Maybe<Scalars['Float']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  rotation?: Maybe<Scalars['Decimal']['output']>;
  secondaryAnchorIdx?: Maybe<Scalars['Float']['output']>;
};

export type AnchoringCount = {
  __typename?: 'AnchoringCount';
  footprintCoordinates: Scalars['Int']['output'];
};

export type AnchoringCountAggregate = {
  __typename?: 'AnchoringCountAggregate';
  _all: Scalars['Int']['output'];
  anchorCoordinateId: Scalars['Int']['output'];
  anchorIdx: Scalars['Int']['output'];
  buildingId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  curbCoordinateId: Scalars['Int']['output'];
  geoidHeight: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  model3DId: Scalars['Int']['output'];
  rotation: Scalars['Int']['output'];
  secondaryAnchorCoordinateId: Scalars['Int']['output'];
  secondaryAnchorIdx: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AnchoringCreateInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateNestedManyWithoutFootprintCoordinatesInput = {
  connect?: InputMaybe<Array<AnchoringWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnchoringCreateOrConnectWithoutFootprintCoordinatesInput>>;
  create?: InputMaybe<Array<AnchoringCreateWithoutFootprintCoordinatesInput>>;
};

export type AnchoringCreateNestedOneWithoutAnchorCoordinateInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutAnchorCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutAnchorCoordinateInput>;
};

export type AnchoringCreateNestedOneWithoutBuildingInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutBuildingInput>;
  create?: InputMaybe<AnchoringCreateWithoutBuildingInput>;
};

export type AnchoringCreateNestedOneWithoutCurbCoordinateInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutCurbCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutCurbCoordinateInput>;
};

export type AnchoringCreateNestedOneWithoutModel3DInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutModel3DInput>;
  create?: InputMaybe<AnchoringCreateWithoutModel3DInput>;
};

export type AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutSecondaryAnchorCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutSecondaryAnchorCoordinateInput>;
};

export type AnchoringCreateOrConnectWithoutAnchorCoordinateInput = {
  create: AnchoringCreateWithoutAnchorCoordinateInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateOrConnectWithoutBuildingInput = {
  create: AnchoringCreateWithoutBuildingInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateOrConnectWithoutCurbCoordinateInput = {
  create: AnchoringCreateWithoutCurbCoordinateInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateOrConnectWithoutFootprintCoordinatesInput = {
  create: AnchoringCreateWithoutFootprintCoordinatesInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateOrConnectWithoutModel3DInput = {
  create: AnchoringCreateWithoutModel3DInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateOrConnectWithoutSecondaryAnchorCoordinateInput = {
  create: AnchoringCreateWithoutSecondaryAnchorCoordinateInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringCreateWithoutAnchorCoordinateInput = {
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateWithoutBuildingInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateWithoutCurbCoordinateInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateWithoutFootprintCoordinatesInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateWithoutModel3DInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringCreateWithoutSecondaryAnchorCoordinateInput = {
  anchorCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutAnchorCoordinateInput>;
  anchorIdx?: InputMaybe<Scalars['Int']['input']>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutDefaultAnchoringInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<CoordinateCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<CoordinateCreateNestedManyWithoutFootprintCoordinatesInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3D?: InputMaybe<Model3DCreateNestedOneWithoutAnchoringInput>;
  rotation?: InputMaybe<Scalars['Decimal']['input']>;
  secondaryAnchorIdx?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AnchoringListRelationFilter = {
  every?: InputMaybe<AnchoringWhereInput>;
  none?: InputMaybe<AnchoringWhereInput>;
  some?: InputMaybe<AnchoringWhereInput>;
};

export type AnchoringMaxAggregate = {
  __typename?: 'AnchoringMaxAggregate';
  anchorCoordinateId?: Maybe<Scalars['String']['output']>;
  anchorIdx?: Maybe<Scalars['Int']['output']>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curbCoordinateId?: Maybe<Scalars['String']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  model3DId?: Maybe<Scalars['String']['output']>;
  rotation?: Maybe<Scalars['Decimal']['output']>;
  secondaryAnchorCoordinateId?: Maybe<Scalars['String']['output']>;
  secondaryAnchorIdx?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnchoringMinAggregate = {
  __typename?: 'AnchoringMinAggregate';
  anchorCoordinateId?: Maybe<Scalars['String']['output']>;
  anchorIdx?: Maybe<Scalars['Int']['output']>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curbCoordinateId?: Maybe<Scalars['String']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  model3DId?: Maybe<Scalars['String']['output']>;
  rotation?: Maybe<Scalars['Decimal']['output']>;
  secondaryAnchorCoordinateId?: Maybe<Scalars['String']['output']>;
  secondaryAnchorIdx?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnchoringOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AnchoringOrderByWithRelationInput = {
  anchorCoordinate?: InputMaybe<CoordinateOrderByWithRelationInput>;
  anchorCoordinateId?: InputMaybe<SortOrder>;
  anchorIdx?: InputMaybe<SortOrder>;
  building?: InputMaybe<BuildingOrderByWithRelationInput>;
  buildingId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  curbCoordinate?: InputMaybe<CoordinateOrderByWithRelationInput>;
  curbCoordinateId?: InputMaybe<SortOrder>;
  footprintCoordinates?: InputMaybe<CoordinateOrderByRelationAggregateInput>;
  geoidHeight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model3D?: InputMaybe<Model3DOrderByWithRelationInput>;
  model3DId?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateOrderByWithRelationInput>;
  secondaryAnchorCoordinateId?: InputMaybe<SortOrder>;
  secondaryAnchorIdx?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AnchoringRelationFilter = {
  is?: InputMaybe<AnchoringWhereInput>;
  isNot?: InputMaybe<AnchoringWhereInput>;
};

export enum AnchoringScalarFieldEnum {
  AnchorCoordinateId = 'anchorCoordinateId',
  AnchorIdx = 'anchorIdx',
  BuildingId = 'buildingId',
  CreatedAt = 'createdAt',
  CurbCoordinateId = 'curbCoordinateId',
  GeoidHeight = 'geoidHeight',
  Id = 'id',
  Model3DId = 'model3DId',
  Rotation = 'rotation',
  SecondaryAnchorCoordinateId = 'secondaryAnchorCoordinateId',
  SecondaryAnchorIdx = 'secondaryAnchorIdx',
  UpdatedAt = 'updatedAt'
}

export type AnchoringScalarWhereInput = {
  AND?: InputMaybe<Array<AnchoringScalarWhereInput>>;
  NOT?: InputMaybe<Array<AnchoringScalarWhereInput>>;
  OR?: InputMaybe<Array<AnchoringScalarWhereInput>>;
  anchorCoordinateId?: InputMaybe<StringNullableFilter>;
  anchorIdx?: InputMaybe<IntNullableFilter>;
  buildingId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  curbCoordinateId?: InputMaybe<StringNullableFilter>;
  geoidHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  model3DId?: InputMaybe<StringNullableFilter>;
  rotation?: InputMaybe<DecimalNullableFilter>;
  secondaryAnchorCoordinateId?: InputMaybe<StringNullableFilter>;
  secondaryAnchorIdx?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnchoringSumAggregate = {
  __typename?: 'AnchoringSumAggregate';
  anchorIdx?: Maybe<Scalars['Int']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  rotation?: Maybe<Scalars['Decimal']['output']>;
  secondaryAnchorIdx?: Maybe<Scalars['Int']['output']>;
};

export type AnchoringUpdateInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateManyMutationInput = {
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateManyWithWhereWithoutFootprintCoordinatesInput = {
  data: AnchoringUpdateManyMutationInput;
  where: AnchoringScalarWhereInput;
};

export type AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput = {
  connect?: InputMaybe<Array<AnchoringWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AnchoringCreateOrConnectWithoutFootprintCoordinatesInput>>;
  create?: InputMaybe<Array<AnchoringCreateWithoutFootprintCoordinatesInput>>;
  delete?: InputMaybe<Array<AnchoringWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AnchoringScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AnchoringWhereUniqueInput>>;
  set?: InputMaybe<Array<AnchoringWhereUniqueInput>>;
  update?: InputMaybe<Array<AnchoringUpdateWithWhereUniqueWithoutFootprintCoordinatesInput>>;
  updateMany?: InputMaybe<Array<AnchoringUpdateManyWithWhereWithoutFootprintCoordinatesInput>>;
  upsert?: InputMaybe<Array<AnchoringUpsertWithWhereUniqueWithoutFootprintCoordinatesInput>>;
};

export type AnchoringUpdateOneWithoutAnchorCoordinateNestedInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutAnchorCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutAnchorCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AnchoringUpdateWithoutAnchorCoordinateInput>;
  upsert?: InputMaybe<AnchoringUpsertWithoutAnchorCoordinateInput>;
};

export type AnchoringUpdateOneWithoutBuildingNestedInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutBuildingInput>;
  create?: InputMaybe<AnchoringCreateWithoutBuildingInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AnchoringUpdateWithoutBuildingInput>;
  upsert?: InputMaybe<AnchoringUpsertWithoutBuildingInput>;
};

export type AnchoringUpdateOneWithoutCurbCoordinateNestedInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutCurbCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutCurbCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AnchoringUpdateWithoutCurbCoordinateInput>;
  upsert?: InputMaybe<AnchoringUpsertWithoutCurbCoordinateInput>;
};

export type AnchoringUpdateOneWithoutModel3DNestedInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutModel3DInput>;
  create?: InputMaybe<AnchoringCreateWithoutModel3DInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AnchoringUpdateWithoutModel3DInput>;
  upsert?: InputMaybe<AnchoringUpsertWithoutModel3DInput>;
};

export type AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput = {
  connect?: InputMaybe<AnchoringWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnchoringCreateOrConnectWithoutSecondaryAnchorCoordinateInput>;
  create?: InputMaybe<AnchoringCreateWithoutSecondaryAnchorCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AnchoringUpdateWithoutSecondaryAnchorCoordinateInput>;
  upsert?: InputMaybe<AnchoringUpsertWithoutSecondaryAnchorCoordinateInput>;
};

export type AnchoringUpdateWithWhereUniqueWithoutFootprintCoordinatesInput = {
  data: AnchoringUpdateWithoutFootprintCoordinatesInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringUpdateWithoutAnchorCoordinateInput = {
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateWithoutBuildingInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateWithoutCurbCoordinateInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateWithoutFootprintCoordinatesInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateWithoutModel3DInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpdateWithoutSecondaryAnchorCoordinateInput = {
  anchorCoordinate?: InputMaybe<CoordinateUpdateOneWithoutAnchorCoordinateNestedInput>;
  anchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutDefaultAnchoringNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<CoordinateUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3D?: InputMaybe<Model3DUpdateOneWithoutAnchoringNestedInput>;
  rotation?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  secondaryAnchorIdx?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AnchoringUpsertWithWhereUniqueWithoutFootprintCoordinatesInput = {
  create: AnchoringCreateWithoutFootprintCoordinatesInput;
  update: AnchoringUpdateWithoutFootprintCoordinatesInput;
  where: AnchoringWhereUniqueInput;
};

export type AnchoringUpsertWithoutAnchorCoordinateInput = {
  create: AnchoringCreateWithoutAnchorCoordinateInput;
  update: AnchoringUpdateWithoutAnchorCoordinateInput;
};

export type AnchoringUpsertWithoutBuildingInput = {
  create: AnchoringCreateWithoutBuildingInput;
  update: AnchoringUpdateWithoutBuildingInput;
};

export type AnchoringUpsertWithoutCurbCoordinateInput = {
  create: AnchoringCreateWithoutCurbCoordinateInput;
  update: AnchoringUpdateWithoutCurbCoordinateInput;
};

export type AnchoringUpsertWithoutModel3DInput = {
  create: AnchoringCreateWithoutModel3DInput;
  update: AnchoringUpdateWithoutModel3DInput;
};

export type AnchoringUpsertWithoutSecondaryAnchorCoordinateInput = {
  create: AnchoringCreateWithoutSecondaryAnchorCoordinateInput;
  update: AnchoringUpdateWithoutSecondaryAnchorCoordinateInput;
};

export type AnchoringWhereInput = {
  AND?: InputMaybe<Array<AnchoringWhereInput>>;
  NOT?: InputMaybe<Array<AnchoringWhereInput>>;
  OR?: InputMaybe<Array<AnchoringWhereInput>>;
  anchorCoordinate?: InputMaybe<CoordinateRelationFilter>;
  anchorCoordinateId?: InputMaybe<StringNullableFilter>;
  anchorIdx?: InputMaybe<IntNullableFilter>;
  building?: InputMaybe<BuildingRelationFilter>;
  buildingId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  curbCoordinate?: InputMaybe<CoordinateRelationFilter>;
  curbCoordinateId?: InputMaybe<StringNullableFilter>;
  footprintCoordinates?: InputMaybe<CoordinateListRelationFilter>;
  geoidHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  model3D?: InputMaybe<Model3DRelationFilter>;
  model3DId?: InputMaybe<StringNullableFilter>;
  rotation?: InputMaybe<DecimalNullableFilter>;
  secondaryAnchorCoordinate?: InputMaybe<CoordinateRelationFilter>;
  secondaryAnchorCoordinateId?: InputMaybe<StringNullableFilter>;
  secondaryAnchorIdx?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnchoringWhereUniqueInput = {
  anchorCoordinateId?: InputMaybe<Scalars['String']['input']>;
  buildingId?: InputMaybe<Scalars['String']['input']>;
  curbCoordinateId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3DId?: InputMaybe<Scalars['String']['input']>;
  secondaryAnchorCoordinateId?: InputMaybe<Scalars['String']['input']>;
};

export enum AssetCategory {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export enum AssetFileType {
  Jpeg = 'JPEG',
  Mpeg = 'MPEG',
  Pdf = 'PDF',
  Png = 'PNG',
  Svg = 'SVG'
}

export enum BalconyType {
  Balcony = 'BALCONY',
  None = 'NONE',
  Terrace = 'TERRACE'
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Building = {
  __typename?: 'Building';
  _count: BuildingCount;
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingMaterial: BuildingMaterial;
  buildingShape: BuildingShape;
  buildingType: BuildingType;
  createdAt: Scalars['DateTime']['output'];
  defaultAnchoring?: Maybe<Anchoring>;
  dwellingUnits?: Maybe<Scalars['Int']['output']>;
  existingBuilding?: Maybe<ExistingBuilding>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  floors?: Maybe<Array<Floor>>;
  footprintId?: Maybe<Scalars['String']['output']>;
  groundFloorHeight?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  model3ds?: Maybe<Array<Model3D>>;
  project: Project;
  projectId: Scalars['String']['output'];
  squareFootage?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BuildingAvgAggregate = {
  __typename?: 'BuildingAvgAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  dwellingUnits?: Maybe<Scalars['Float']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  groundFloorHeight?: Maybe<Scalars['Decimal']['output']>;
  squareFootage?: Maybe<Scalars['Decimal']['output']>;
};

export type BuildingCount = {
  __typename?: 'BuildingCount';
  floors: Scalars['Int']['output'];
  model3ds: Scalars['Int']['output'];
};

export type BuildingCountAggregate = {
  __typename?: 'BuildingCountAggregate';
  _all: Scalars['Int']['output'];
  buildingHeight: Scalars['Int']['output'];
  buildingMaterial: Scalars['Int']['output'];
  buildingShape: Scalars['Int']['output'];
  buildingType: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  dwellingUnits: Scalars['Int']['output'];
  floorHeight: Scalars['Int']['output'];
  footprintId: Scalars['Int']['output'];
  groundFloorHeight: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  squareFootage: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BuildingCreateInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultAnchoring?: InputMaybe<AnchoringCreateNestedOneWithoutBuildingInput>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  existingBuilding?: InputMaybe<ExistingBuildingCreateNestedOneWithoutBuildingInput>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  floors?: InputMaybe<FloorCreateNestedManyWithoutBuildingInput>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3ds?: InputMaybe<Model3DCreateNestedManyWithoutBuildingInput>;
  project: ProjectCreateNestedOneWithoutBuildingsInput;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateManyProjectInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateManyProjectInputEnvelope = {
  data: Array<BuildingCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BuildingCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BuildingCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<BuildingCreateWithoutProjectInput>>;
  createMany?: InputMaybe<BuildingCreateManyProjectInputEnvelope>;
};

export type BuildingCreateNestedOneWithoutDefaultAnchoringInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutDefaultAnchoringInput>;
  create?: InputMaybe<BuildingCreateWithoutDefaultAnchoringInput>;
};

export type BuildingCreateNestedOneWithoutExistingBuildingInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutExistingBuildingInput>;
  create?: InputMaybe<BuildingCreateWithoutExistingBuildingInput>;
};

export type BuildingCreateNestedOneWithoutFloorsInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutFloorsInput>;
  create?: InputMaybe<BuildingCreateWithoutFloorsInput>;
};

export type BuildingCreateNestedOneWithoutModel3dsInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutModel3dsInput>;
  create?: InputMaybe<BuildingCreateWithoutModel3dsInput>;
};

export type BuildingCreateOrConnectWithoutDefaultAnchoringInput = {
  create: BuildingCreateWithoutDefaultAnchoringInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingCreateOrConnectWithoutExistingBuildingInput = {
  create: BuildingCreateWithoutExistingBuildingInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingCreateOrConnectWithoutFloorsInput = {
  create: BuildingCreateWithoutFloorsInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingCreateOrConnectWithoutModel3dsInput = {
  create: BuildingCreateWithoutModel3dsInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingCreateOrConnectWithoutProjectInput = {
  create: BuildingCreateWithoutProjectInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingCreateWithoutDefaultAnchoringInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  existingBuilding?: InputMaybe<ExistingBuildingCreateNestedOneWithoutBuildingInput>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  floors?: InputMaybe<FloorCreateNestedManyWithoutBuildingInput>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3ds?: InputMaybe<Model3DCreateNestedManyWithoutBuildingInput>;
  project: ProjectCreateNestedOneWithoutBuildingsInput;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateWithoutExistingBuildingInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultAnchoring?: InputMaybe<AnchoringCreateNestedOneWithoutBuildingInput>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  floors?: InputMaybe<FloorCreateNestedManyWithoutBuildingInput>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3ds?: InputMaybe<Model3DCreateNestedManyWithoutBuildingInput>;
  project: ProjectCreateNestedOneWithoutBuildingsInput;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateWithoutFloorsInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultAnchoring?: InputMaybe<AnchoringCreateNestedOneWithoutBuildingInput>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  existingBuilding?: InputMaybe<ExistingBuildingCreateNestedOneWithoutBuildingInput>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3ds?: InputMaybe<Model3DCreateNestedManyWithoutBuildingInput>;
  project: ProjectCreateNestedOneWithoutBuildingsInput;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateWithoutModel3dsInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultAnchoring?: InputMaybe<AnchoringCreateNestedOneWithoutBuildingInput>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  existingBuilding?: InputMaybe<ExistingBuildingCreateNestedOneWithoutBuildingInput>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  floors?: InputMaybe<FloorCreateNestedManyWithoutBuildingInput>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutBuildingsInput;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingCreateWithoutProjectInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  defaultAnchoring?: InputMaybe<AnchoringCreateNestedOneWithoutBuildingInput>;
  dwellingUnits?: InputMaybe<Scalars['Int']['input']>;
  existingBuilding?: InputMaybe<ExistingBuildingCreateNestedOneWithoutBuildingInput>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  floors?: InputMaybe<FloorCreateNestedManyWithoutBuildingInput>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  groundFloorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model3ds?: InputMaybe<Model3DCreateNestedManyWithoutBuildingInput>;
  squareFootage?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BuildingListRelationFilter = {
  every?: InputMaybe<BuildingWhereInput>;
  none?: InputMaybe<BuildingWhereInput>;
  some?: InputMaybe<BuildingWhereInput>;
};

export enum BuildingMaterial {
  Brick = 'BRICK',
  Concrete = 'CONCRETE',
  Glass = 'GLASS',
  Metal = 'METAL',
  Siding = 'SIDING',
  Stone = 'STONE',
  Wood = 'WOOD'
}

export type BuildingMaxAggregate = {
  __typename?: 'BuildingMaxAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingMaterial?: Maybe<BuildingMaterial>;
  buildingShape?: Maybe<BuildingShape>;
  buildingType?: Maybe<BuildingType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dwellingUnits?: Maybe<Scalars['Int']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  footprintId?: Maybe<Scalars['String']['output']>;
  groundFloorHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  squareFootage?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BuildingMinAggregate = {
  __typename?: 'BuildingMinAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingMaterial?: Maybe<BuildingMaterial>;
  buildingShape?: Maybe<BuildingShape>;
  buildingType?: Maybe<BuildingType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dwellingUnits?: Maybe<Scalars['Int']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  footprintId?: Maybe<Scalars['String']['output']>;
  groundFloorHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  squareFootage?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BuildingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BuildingOrderByWithRelationInput = {
  buildingHeight?: InputMaybe<SortOrder>;
  buildingMaterial?: InputMaybe<SortOrder>;
  buildingShape?: InputMaybe<SortOrder>;
  buildingType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  defaultAnchoring?: InputMaybe<AnchoringOrderByWithRelationInput>;
  dwellingUnits?: InputMaybe<SortOrder>;
  existingBuilding?: InputMaybe<ExistingBuildingOrderByWithRelationInput>;
  floorHeight?: InputMaybe<SortOrder>;
  floors?: InputMaybe<FloorOrderByRelationAggregateInput>;
  footprintId?: InputMaybe<SortOrder>;
  groundFloorHeight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model3ds?: InputMaybe<Model3DOrderByRelationAggregateInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  squareFootage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BuildingRelationFilter = {
  is?: InputMaybe<BuildingWhereInput>;
  isNot?: InputMaybe<BuildingWhereInput>;
};

export enum BuildingScalarFieldEnum {
  BuildingHeight = 'buildingHeight',
  BuildingMaterial = 'buildingMaterial',
  BuildingShape = 'buildingShape',
  BuildingType = 'buildingType',
  CreatedAt = 'createdAt',
  DwellingUnits = 'dwellingUnits',
  FloorHeight = 'floorHeight',
  FootprintId = 'footprintId',
  GroundFloorHeight = 'groundFloorHeight',
  Id = 'id',
  ProjectId = 'projectId',
  SquareFootage = 'squareFootage',
  UpdatedAt = 'updatedAt'
}

export type BuildingScalarWhereInput = {
  AND?: InputMaybe<Array<BuildingScalarWhereInput>>;
  NOT?: InputMaybe<Array<BuildingScalarWhereInput>>;
  OR?: InputMaybe<Array<BuildingScalarWhereInput>>;
  buildingHeight?: InputMaybe<DecimalNullableFilter>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFilter>;
  buildingShape?: InputMaybe<EnumBuildingShapeFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dwellingUnits?: InputMaybe<IntNullableFilter>;
  floorHeight?: InputMaybe<DecimalNullableFilter>;
  footprintId?: InputMaybe<StringNullableFilter>;
  groundFloorHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<StringFilter>;
  squareFootage?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum BuildingShape {
  Courtyard = 'COURTYARD',
  CShape = 'C_SHAPE',
  Highrise = 'HIGHRISE',
  HShape = 'H_SHAPE',
  LShape = 'L_SHAPE',
  Rectangular = 'RECTANGULAR'
}

export type BuildingSumAggregate = {
  __typename?: 'BuildingSumAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  dwellingUnits?: Maybe<Scalars['Int']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  groundFloorHeight?: Maybe<Scalars['Decimal']['output']>;
  squareFootage?: Maybe<Scalars['Decimal']['output']>;
};

export enum BuildingType {
  Apartment = 'APARTMENT',
  ApartmentComm = 'APARTMENT_COMM',
  Church = 'CHURCH',
  Commercial = 'COMMERCIAL',
  Condo = 'CONDO',
  Factory = 'FACTORY',
  Garage = 'GARAGE',
  Hotel = 'HOTEL',
  House = 'HOUSE',
  Medical = 'MEDICAL',
  Office = 'OFFICE',
  OfficeComm = 'OFFICE_COMM',
  PublicBuilding = 'PUBLIC_BUILDING',
  RowHouse = 'ROW_HOUSE',
  School = 'SCHOOL',
  Trailer = 'TRAILER',
  Warehouse = 'WAREHOUSE'
}

export type BuildingUpdateInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  defaultAnchoring?: InputMaybe<AnchoringUpdateOneWithoutBuildingNestedInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  existingBuilding?: InputMaybe<ExistingBuildingUpdateOneWithoutBuildingNestedInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  floors?: InputMaybe<FloorUpdateManyWithoutBuildingNestedInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3ds?: InputMaybe<Model3DUpdateManyWithoutBuildingNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBuildingsNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateManyMutationInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateManyWithWhereWithoutProjectInput = {
  data: BuildingUpdateManyMutationInput;
  where: BuildingScalarWhereInput;
};

export type BuildingUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BuildingCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<BuildingCreateWithoutProjectInput>>;
  createMany?: InputMaybe<BuildingCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BuildingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  set?: InputMaybe<Array<BuildingWhereUniqueInput>>;
  update?: InputMaybe<Array<BuildingUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<BuildingUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<BuildingUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type BuildingUpdateOneRequiredWithoutExistingBuildingNestedInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutExistingBuildingInput>;
  create?: InputMaybe<BuildingCreateWithoutExistingBuildingInput>;
  update?: InputMaybe<BuildingUpdateWithoutExistingBuildingInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutExistingBuildingInput>;
};

export type BuildingUpdateOneRequiredWithoutFloorsNestedInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutFloorsInput>;
  create?: InputMaybe<BuildingCreateWithoutFloorsInput>;
  update?: InputMaybe<BuildingUpdateWithoutFloorsInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutFloorsInput>;
};

export type BuildingUpdateOneWithoutDefaultAnchoringNestedInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutDefaultAnchoringInput>;
  create?: InputMaybe<BuildingCreateWithoutDefaultAnchoringInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<BuildingUpdateWithoutDefaultAnchoringInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutDefaultAnchoringInput>;
};

export type BuildingUpdateOneWithoutModel3dsNestedInput = {
  connect?: InputMaybe<BuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BuildingCreateOrConnectWithoutModel3dsInput>;
  create?: InputMaybe<BuildingCreateWithoutModel3dsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<BuildingUpdateWithoutModel3dsInput>;
  upsert?: InputMaybe<BuildingUpsertWithoutModel3dsInput>;
};

export type BuildingUpdateWithWhereUniqueWithoutProjectInput = {
  data: BuildingUpdateWithoutProjectInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingUpdateWithoutDefaultAnchoringInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  existingBuilding?: InputMaybe<ExistingBuildingUpdateOneWithoutBuildingNestedInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  floors?: InputMaybe<FloorUpdateManyWithoutBuildingNestedInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3ds?: InputMaybe<Model3DUpdateManyWithoutBuildingNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBuildingsNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateWithoutExistingBuildingInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  defaultAnchoring?: InputMaybe<AnchoringUpdateOneWithoutBuildingNestedInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  floors?: InputMaybe<FloorUpdateManyWithoutBuildingNestedInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3ds?: InputMaybe<Model3DUpdateManyWithoutBuildingNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBuildingsNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateWithoutFloorsInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  defaultAnchoring?: InputMaybe<AnchoringUpdateOneWithoutBuildingNestedInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  existingBuilding?: InputMaybe<ExistingBuildingUpdateOneWithoutBuildingNestedInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3ds?: InputMaybe<Model3DUpdateManyWithoutBuildingNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBuildingsNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateWithoutModel3dsInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  defaultAnchoring?: InputMaybe<AnchoringUpdateOneWithoutBuildingNestedInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  existingBuilding?: InputMaybe<ExistingBuildingUpdateOneWithoutBuildingNestedInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  floors?: InputMaybe<FloorUpdateManyWithoutBuildingNestedInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBuildingsNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpdateWithoutProjectInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  defaultAnchoring?: InputMaybe<AnchoringUpdateOneWithoutBuildingNestedInput>;
  dwellingUnits?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  existingBuilding?: InputMaybe<ExistingBuildingUpdateOneWithoutBuildingNestedInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  floors?: InputMaybe<FloorUpdateManyWithoutBuildingNestedInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groundFloorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model3ds?: InputMaybe<Model3DUpdateManyWithoutBuildingNestedInput>;
  squareFootage?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BuildingUpsertWithWhereUniqueWithoutProjectInput = {
  create: BuildingCreateWithoutProjectInput;
  update: BuildingUpdateWithoutProjectInput;
  where: BuildingWhereUniqueInput;
};

export type BuildingUpsertWithoutDefaultAnchoringInput = {
  create: BuildingCreateWithoutDefaultAnchoringInput;
  update: BuildingUpdateWithoutDefaultAnchoringInput;
};

export type BuildingUpsertWithoutExistingBuildingInput = {
  create: BuildingCreateWithoutExistingBuildingInput;
  update: BuildingUpdateWithoutExistingBuildingInput;
};

export type BuildingUpsertWithoutFloorsInput = {
  create: BuildingCreateWithoutFloorsInput;
  update: BuildingUpdateWithoutFloorsInput;
};

export type BuildingUpsertWithoutModel3dsInput = {
  create: BuildingCreateWithoutModel3dsInput;
  update: BuildingUpdateWithoutModel3dsInput;
};

export type BuildingWhereInput = {
  AND?: InputMaybe<Array<BuildingWhereInput>>;
  NOT?: InputMaybe<Array<BuildingWhereInput>>;
  OR?: InputMaybe<Array<BuildingWhereInput>>;
  buildingHeight?: InputMaybe<DecimalNullableFilter>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFilter>;
  buildingShape?: InputMaybe<EnumBuildingShapeFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultAnchoring?: InputMaybe<AnchoringRelationFilter>;
  dwellingUnits?: InputMaybe<IntNullableFilter>;
  existingBuilding?: InputMaybe<ExistingBuildingRelationFilter>;
  floorHeight?: InputMaybe<DecimalNullableFilter>;
  floors?: InputMaybe<FloorListRelationFilter>;
  footprintId?: InputMaybe<StringNullableFilter>;
  groundFloorHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  model3ds?: InputMaybe<Model3DListRelationFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  squareFootage?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BuildingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type City = {
  __typename?: 'City';
  _count: CityCount;
  centroid: Coordinate;
  coordinateId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  curbLayerId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  footprintLayerId?: Maybe<Scalars['String']['output']>;
  geometryFileLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projects?: Maybe<Array<Project>>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CityCount = {
  __typename?: 'CityCount';
  projects: Scalars['Int']['output'];
};

export type CityCountAggregate = {
  __typename?: 'CityCountAggregate';
  _all: Scalars['Int']['output'];
  coordinateId: Scalars['Int']['output'];
  country: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  curbLayerId: Scalars['Int']['output'];
  displayName: Scalars['Int']['output'];
  footprintLayerId: Scalars['Int']['output'];
  geometryFileLocation: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CityCreateInput = {
  centroid: CoordinateCreateNestedOneWithoutCityCoordinateInput;
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbLayerId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  footprintLayerId?: InputMaybe<Scalars['String']['input']>;
  geometryFileLocation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projects?: InputMaybe<ProjectCreateNestedManyWithoutCityInput>;
  state: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CityCreateNestedOneWithoutCentroidInput = {
  connect?: InputMaybe<CityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CityCreateOrConnectWithoutCentroidInput>;
  create?: InputMaybe<CityCreateWithoutCentroidInput>;
};

export type CityCreateNestedOneWithoutProjectsInput = {
  connect?: InputMaybe<CityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CityCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<CityCreateWithoutProjectsInput>;
};

export type CityCreateOrConnectWithoutCentroidInput = {
  create: CityCreateWithoutCentroidInput;
  where: CityWhereUniqueInput;
};

export type CityCreateOrConnectWithoutProjectsInput = {
  create: CityCreateWithoutProjectsInput;
  where: CityWhereUniqueInput;
};

export type CityCreateWithoutCentroidInput = {
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbLayerId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  footprintLayerId?: InputMaybe<Scalars['String']['input']>;
  geometryFileLocation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projects?: InputMaybe<ProjectCreateNestedManyWithoutCityInput>;
  state: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CityCreateWithoutProjectsInput = {
  centroid: CoordinateCreateNestedOneWithoutCityCoordinateInput;
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbLayerId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  footprintLayerId?: InputMaybe<Scalars['String']['input']>;
  geometryFileLocation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CityMaxAggregate = {
  __typename?: 'CityMaxAggregate';
  coordinateId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curbLayerId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  footprintLayerId?: Maybe<Scalars['String']['output']>;
  geometryFileLocation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CityMinAggregate = {
  __typename?: 'CityMinAggregate';
  coordinateId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curbLayerId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  footprintLayerId?: Maybe<Scalars['String']['output']>;
  geometryFileLocation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CityOrderByWithRelationInput = {
  centroid?: InputMaybe<CoordinateOrderByWithRelationInput>;
  coordinateId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  curbLayerId?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  footprintLayerId?: InputMaybe<SortOrder>;
  geometryFileLocation?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projects?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CityRelationFilter = {
  is?: InputMaybe<CityWhereInput>;
  isNot?: InputMaybe<CityWhereInput>;
};

export enum CityScalarFieldEnum {
  CoordinateId = 'coordinateId',
  Country = 'country',
  CreatedAt = 'createdAt',
  CurbLayerId = 'curbLayerId',
  DisplayName = 'displayName',
  FootprintLayerId = 'footprintLayerId',
  GeometryFileLocation = 'geometryFileLocation',
  Id = 'id',
  Name = 'name',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

export type CityUpdateInput = {
  centroid?: InputMaybe<CoordinateUpdateOneRequiredWithoutCityCoordinateNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footprintLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  geometryFileLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutCityNestedInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CityUpdateOneWithoutCentroidNestedInput = {
  connect?: InputMaybe<CityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CityCreateOrConnectWithoutCentroidInput>;
  create?: InputMaybe<CityCreateWithoutCentroidInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CityUpdateWithoutCentroidInput>;
  upsert?: InputMaybe<CityUpsertWithoutCentroidInput>;
};

export type CityUpdateOneWithoutProjectsNestedInput = {
  connect?: InputMaybe<CityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CityCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<CityCreateWithoutProjectsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CityUpdateWithoutProjectsInput>;
  upsert?: InputMaybe<CityUpsertWithoutProjectsInput>;
};

export type CityUpdateWithoutCentroidInput = {
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footprintLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  geometryFileLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutCityNestedInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CityUpdateWithoutProjectsInput = {
  centroid?: InputMaybe<CoordinateUpdateOneRequiredWithoutCityCoordinateNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footprintLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  geometryFileLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CityUpsertWithoutCentroidInput = {
  create: CityCreateWithoutCentroidInput;
  update: CityUpdateWithoutCentroidInput;
};

export type CityUpsertWithoutProjectsInput = {
  create: CityCreateWithoutProjectsInput;
  update: CityUpdateWithoutProjectsInput;
};

export type CityWhereInput = {
  AND?: InputMaybe<Array<CityWhereInput>>;
  NOT?: InputMaybe<Array<CityWhereInput>>;
  OR?: InputMaybe<Array<CityWhereInput>>;
  centroid?: InputMaybe<CoordinateRelationFilter>;
  coordinateId?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  curbLayerId?: InputMaybe<StringNullableFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  footprintLayerId?: InputMaybe<StringNullableFilter>;
  geometryFileLocation?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  projects?: InputMaybe<ProjectListRelationFilter>;
  state?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CityWhereUniqueInput = {
  coordinateId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  _count: CommentCount;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parentComment?: Maybe<Comment>;
  parentCommentId?: Maybe<Scalars['String']['output']>;
  project: Project;
  projectId: Scalars['String']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  replies?: Maybe<Array<Comment>>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CommentAvgAggregate = {
  __typename?: 'CommentAvgAggregate';
  rating?: Maybe<Scalars['Float']['output']>;
};

export type CommentCount = {
  __typename?: 'CommentCount';
  replies: Scalars['Int']['output'];
};

export type CommentCountAggregate = {
  __typename?: 'CommentCountAggregate';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  hidden: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  parentCommentId: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type CommentCreateInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutRepliesInput>;
  project: ProjectCreateNestedOneWithoutCommentsInput;
  rating?: InputMaybe<Scalars['Int']['input']>;
  replies?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateManyParentCommentInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentCreateManyParentCommentInputEnvelope = {
  data: Array<CommentCreateManyParentCommentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyProjectInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentCommentId?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type CommentCreateManyProjectInputEnvelope = {
  data: Array<CommentCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyUserInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentCommentId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyUserInputEnvelope = {
  data: Array<CommentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateNestedManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentCommentInputEnvelope>;
};

export type CommentCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutProjectInput>>;
  createMany?: InputMaybe<CommentCreateManyProjectInputEnvelope>;
};

export type CommentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
};

export type CommentCreateNestedOneWithoutRepliesInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<CommentCreateWithoutRepliesInput>;
};

export type CommentCreateOrConnectWithoutParentCommentInput = {
  create: CommentCreateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutProjectInput = {
  create: CommentCreateWithoutProjectInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutRepliesInput = {
  create: CommentCreateWithoutRepliesInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutParentCommentInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutCommentsInput;
  rating?: InputMaybe<Scalars['Int']['input']>;
  replies?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutProjectInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutRepliesInput>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  replies?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutRepliesInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutRepliesInput>;
  project: ProjectCreateNestedOneWithoutCommentsInput;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCommentsInput;
};

export type CommentCreateWithoutUserInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutRepliesInput>;
  project: ProjectCreateNestedOneWithoutCommentsInput;
  rating?: InputMaybe<Scalars['Int']['input']>;
  replies?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: 'CommentMaxAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  parentCommentId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentMinAggregate = {
  __typename?: 'CommentMinAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  parentCommentId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  hidden?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parentComment?: InputMaybe<CommentOrderByWithRelationInput>;
  parentCommentId?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  replies?: InputMaybe<CommentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CommentRelationFilter = {
  is?: InputMaybe<CommentWhereInput>;
  isNot?: InputMaybe<CommentWhereInput>;
};

export enum CommentScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Hidden = 'hidden',
  Id = 'id',
  ParentCommentId = 'parentCommentId',
  ProjectId = 'projectId',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hidden?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentSumAggregate = {
  __typename?: 'CommentSumAggregate';
  rating?: Maybe<Scalars['Int']['output']>;
};

export type CommentUpdateInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutRepliesNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCommentsNestedInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replies?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithWhereWithoutParentCommentInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutProjectInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutUserInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutParentCommentNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentCommentInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutParentCommentInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutParentCommentInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutParentCommentInput>>;
};

export type CommentUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutProjectInput>>;
  createMany?: InputMaybe<CommentCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type CommentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommentUpdateOneWithoutRepliesNestedInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<CommentCreateWithoutRepliesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CommentUpdateWithoutRepliesInput>;
  upsert?: InputMaybe<CommentUpsertWithoutRepliesInput>;
};

export type CommentUpdateWithWhereUniqueWithoutParentCommentInput = {
  data: CommentUpdateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutProjectInput = {
  data: CommentUpdateWithoutProjectInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutUserInput = {
  data: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutParentCommentInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCommentsNestedInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replies?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutProjectInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutRepliesNestedInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replies?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutRepliesInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutRepliesNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCommentsNestedInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
};

export type CommentUpdateWithoutUserInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hidden?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutRepliesNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCommentsNestedInput>;
  rating?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replies?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpsertWithWhereUniqueWithoutParentCommentInput = {
  create: CommentCreateWithoutParentCommentInput;
  update: CommentUpdateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutProjectInput = {
  create: CommentCreateWithoutProjectInput;
  update: CommentUpdateWithoutProjectInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutUserInput = {
  create: CommentCreateWithoutUserInput;
  update: CommentUpdateWithoutUserInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithoutRepliesInput = {
  create: CommentCreateWithoutRepliesInput;
  update: CommentUpdateWithoutRepliesInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hidden?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  parentComment?: InputMaybe<CommentRelationFilter>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  replies?: InputMaybe<CommentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  _count: CoordinateCount;
  altitude: Scalars['Decimal']['output'];
  anchorCoordinate?: Maybe<Anchoring>;
  cityCoordinate?: Maybe<City>;
  createdAt: Scalars['DateTime']['output'];
  curbCoordinate?: Maybe<Anchoring>;
  footprintCoordinates?: Maybe<Array<Anchoring>>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  secondaryAnchorCoordinate?: Maybe<Anchoring>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CoordinateAvgAggregate = {
  __typename?: 'CoordinateAvgAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type CoordinateCount = {
  __typename?: 'CoordinateCount';
  footprintCoordinates: Scalars['Int']['output'];
};

export type CoordinateCountAggregate = {
  __typename?: 'CoordinateCountAggregate';
  _all: Scalars['Int']['output'];
  altitude: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CoordinateCreateInput = {
  altitude: Scalars['Decimal']['input'];
  anchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutAnchorCoordinateInput>;
  cityCoordinate?: InputMaybe<CityCreateNestedOneWithoutCentroidInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<AnchoringCreateNestedManyWithoutFootprintCoordinatesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  secondaryAnchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateCreateNestedManyWithoutFootprintCoordinatesInput = {
  connect?: InputMaybe<Array<CoordinateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoordinateCreateOrConnectWithoutFootprintCoordinatesInput>>;
  create?: InputMaybe<Array<CoordinateCreateWithoutFootprintCoordinatesInput>>;
};

export type CoordinateCreateNestedOneWithoutAnchorCoordinateInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutAnchorCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutAnchorCoordinateInput>;
};

export type CoordinateCreateNestedOneWithoutCityCoordinateInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutCityCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutCityCoordinateInput>;
};

export type CoordinateCreateNestedOneWithoutCurbCoordinateInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutCurbCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutCurbCoordinateInput>;
};

export type CoordinateCreateNestedOneWithoutSecondaryAnchorCoordinateInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutSecondaryAnchorCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutSecondaryAnchorCoordinateInput>;
};

export type CoordinateCreateOrConnectWithoutAnchorCoordinateInput = {
  create: CoordinateCreateWithoutAnchorCoordinateInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateCreateOrConnectWithoutCityCoordinateInput = {
  create: CoordinateCreateWithoutCityCoordinateInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateCreateOrConnectWithoutCurbCoordinateInput = {
  create: CoordinateCreateWithoutCurbCoordinateInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateCreateOrConnectWithoutFootprintCoordinatesInput = {
  create: CoordinateCreateWithoutFootprintCoordinatesInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateCreateOrConnectWithoutSecondaryAnchorCoordinateInput = {
  create: CoordinateCreateWithoutSecondaryAnchorCoordinateInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateCreateWithoutAnchorCoordinateInput = {
  altitude: Scalars['Decimal']['input'];
  cityCoordinate?: InputMaybe<CityCreateNestedOneWithoutCentroidInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<AnchoringCreateNestedManyWithoutFootprintCoordinatesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  secondaryAnchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateCreateWithoutCityCoordinateInput = {
  altitude: Scalars['Decimal']['input'];
  anchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutAnchorCoordinateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<AnchoringCreateNestedManyWithoutFootprintCoordinatesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  secondaryAnchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateCreateWithoutCurbCoordinateInput = {
  altitude: Scalars['Decimal']['input'];
  anchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutAnchorCoordinateInput>;
  cityCoordinate?: InputMaybe<CityCreateNestedOneWithoutCentroidInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  footprintCoordinates?: InputMaybe<AnchoringCreateNestedManyWithoutFootprintCoordinatesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  secondaryAnchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateCreateWithoutFootprintCoordinatesInput = {
  altitude: Scalars['Decimal']['input'];
  anchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutAnchorCoordinateInput>;
  cityCoordinate?: InputMaybe<CityCreateNestedOneWithoutCentroidInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutCurbCoordinateInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  secondaryAnchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutSecondaryAnchorCoordinateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateCreateWithoutSecondaryAnchorCoordinateInput = {
  altitude: Scalars['Decimal']['input'];
  anchorCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutAnchorCoordinateInput>;
  cityCoordinate?: InputMaybe<CityCreateNestedOneWithoutCentroidInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  curbCoordinate?: InputMaybe<AnchoringCreateNestedOneWithoutCurbCoordinateInput>;
  footprintCoordinates?: InputMaybe<AnchoringCreateNestedManyWithoutFootprintCoordinatesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CoordinateListRelationFilter = {
  every?: InputMaybe<CoordinateWhereInput>;
  none?: InputMaybe<CoordinateWhereInput>;
  some?: InputMaybe<CoordinateWhereInput>;
};

export type CoordinateMaxAggregate = {
  __typename?: 'CoordinateMaxAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoordinateMinAggregate = {
  __typename?: 'CoordinateMinAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoordinateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CoordinateOrderByWithRelationInput = {
  altitude?: InputMaybe<SortOrder>;
  anchorCoordinate?: InputMaybe<AnchoringOrderByWithRelationInput>;
  cityCoordinate?: InputMaybe<CityOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  curbCoordinate?: InputMaybe<AnchoringOrderByWithRelationInput>;
  footprintCoordinates?: InputMaybe<AnchoringOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CoordinateRelationFilter = {
  is?: InputMaybe<CoordinateWhereInput>;
  isNot?: InputMaybe<CoordinateWhereInput>;
};

export enum CoordinateScalarFieldEnum {
  Altitude = 'altitude',
  CreatedAt = 'createdAt',
  Id = 'id',
  Latitude = 'latitude',
  Longitude = 'longitude',
  UpdatedAt = 'updatedAt'
}

export type CoordinateScalarWhereInput = {
  AND?: InputMaybe<Array<CoordinateScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoordinateScalarWhereInput>>;
  OR?: InputMaybe<Array<CoordinateScalarWhereInput>>;
  altitude?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CoordinateSumAggregate = {
  __typename?: 'CoordinateSumAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type CoordinateUpdateInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  anchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutAnchorCoordinateNestedInput>;
  cityCoordinate?: InputMaybe<CityUpdateOneWithoutCentroidNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<AnchoringUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateManyMutationInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateManyWithWhereWithoutFootprintCoordinatesInput = {
  data: CoordinateUpdateManyMutationInput;
  where: CoordinateScalarWhereInput;
};

export type CoordinateUpdateManyWithoutFootprintCoordinatesNestedInput = {
  connect?: InputMaybe<Array<CoordinateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoordinateCreateOrConnectWithoutFootprintCoordinatesInput>>;
  create?: InputMaybe<Array<CoordinateCreateWithoutFootprintCoordinatesInput>>;
  delete?: InputMaybe<Array<CoordinateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoordinateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoordinateWhereUniqueInput>>;
  set?: InputMaybe<Array<CoordinateWhereUniqueInput>>;
  update?: InputMaybe<Array<CoordinateUpdateWithWhereUniqueWithoutFootprintCoordinatesInput>>;
  updateMany?: InputMaybe<Array<CoordinateUpdateManyWithWhereWithoutFootprintCoordinatesInput>>;
  upsert?: InputMaybe<Array<CoordinateUpsertWithWhereUniqueWithoutFootprintCoordinatesInput>>;
};

export type CoordinateUpdateOneRequiredWithoutCityCoordinateNestedInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutCityCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutCityCoordinateInput>;
  update?: InputMaybe<CoordinateUpdateWithoutCityCoordinateInput>;
  upsert?: InputMaybe<CoordinateUpsertWithoutCityCoordinateInput>;
};

export type CoordinateUpdateOneWithoutAnchorCoordinateNestedInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutAnchorCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutAnchorCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoordinateUpdateWithoutAnchorCoordinateInput>;
  upsert?: InputMaybe<CoordinateUpsertWithoutAnchorCoordinateInput>;
};

export type CoordinateUpdateOneWithoutCurbCoordinateNestedInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutCurbCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutCurbCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoordinateUpdateWithoutCurbCoordinateInput>;
  upsert?: InputMaybe<CoordinateUpsertWithoutCurbCoordinateInput>;
};

export type CoordinateUpdateOneWithoutSecondaryAnchorCoordinateNestedInput = {
  connect?: InputMaybe<CoordinateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoordinateCreateOrConnectWithoutSecondaryAnchorCoordinateInput>;
  create?: InputMaybe<CoordinateCreateWithoutSecondaryAnchorCoordinateInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoordinateUpdateWithoutSecondaryAnchorCoordinateInput>;
  upsert?: InputMaybe<CoordinateUpsertWithoutSecondaryAnchorCoordinateInput>;
};

export type CoordinateUpdateWithWhereUniqueWithoutFootprintCoordinatesInput = {
  data: CoordinateUpdateWithoutFootprintCoordinatesInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateUpdateWithoutAnchorCoordinateInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  cityCoordinate?: InputMaybe<CityUpdateOneWithoutCentroidNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<AnchoringUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateWithoutCityCoordinateInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  anchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutAnchorCoordinateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<AnchoringUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateWithoutCurbCoordinateInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  anchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutAnchorCoordinateNestedInput>;
  cityCoordinate?: InputMaybe<CityUpdateOneWithoutCentroidNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  footprintCoordinates?: InputMaybe<AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateWithoutFootprintCoordinatesInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  anchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutAnchorCoordinateNestedInput>;
  cityCoordinate?: InputMaybe<CityUpdateOneWithoutCentroidNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<AnchoringUpdateOneWithoutCurbCoordinateNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutSecondaryAnchorCoordinateNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpdateWithoutSecondaryAnchorCoordinateInput = {
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  anchorCoordinate?: InputMaybe<AnchoringUpdateOneWithoutAnchorCoordinateNestedInput>;
  cityCoordinate?: InputMaybe<CityUpdateOneWithoutCentroidNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curbCoordinate?: InputMaybe<AnchoringUpdateOneWithoutCurbCoordinateNestedInput>;
  footprintCoordinates?: InputMaybe<AnchoringUpdateManyWithoutFootprintCoordinatesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CoordinateUpsertWithWhereUniqueWithoutFootprintCoordinatesInput = {
  create: CoordinateCreateWithoutFootprintCoordinatesInput;
  update: CoordinateUpdateWithoutFootprintCoordinatesInput;
  where: CoordinateWhereUniqueInput;
};

export type CoordinateUpsertWithoutAnchorCoordinateInput = {
  create: CoordinateCreateWithoutAnchorCoordinateInput;
  update: CoordinateUpdateWithoutAnchorCoordinateInput;
};

export type CoordinateUpsertWithoutCityCoordinateInput = {
  create: CoordinateCreateWithoutCityCoordinateInput;
  update: CoordinateUpdateWithoutCityCoordinateInput;
};

export type CoordinateUpsertWithoutCurbCoordinateInput = {
  create: CoordinateCreateWithoutCurbCoordinateInput;
  update: CoordinateUpdateWithoutCurbCoordinateInput;
};

export type CoordinateUpsertWithoutSecondaryAnchorCoordinateInput = {
  create: CoordinateCreateWithoutSecondaryAnchorCoordinateInput;
  update: CoordinateUpdateWithoutSecondaryAnchorCoordinateInput;
};

export type CoordinateWhereInput = {
  AND?: InputMaybe<Array<CoordinateWhereInput>>;
  NOT?: InputMaybe<Array<CoordinateWhereInput>>;
  OR?: InputMaybe<Array<CoordinateWhereInput>>;
  altitude?: InputMaybe<DecimalFilter>;
  anchorCoordinate?: InputMaybe<AnchoringRelationFilter>;
  cityCoordinate?: InputMaybe<CityRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  curbCoordinate?: InputMaybe<AnchoringRelationFilter>;
  footprintCoordinates?: InputMaybe<AnchoringListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  secondaryAnchorCoordinate?: InputMaybe<AnchoringRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CoordinateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']['input']>;
  divide?: InputMaybe<Scalars['Decimal']['input']>;
  increment?: InputMaybe<Scalars['Decimal']['input']>;
  multiply?: InputMaybe<Scalars['Decimal']['input']>;
  set?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export enum DevicePlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Document = {
  __typename?: 'Document';
  category: AssetCategory;
  createdAt: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  type: AssetFileType;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type DocumentAvgAggregate = {
  __typename?: 'DocumentAvgAggregate';
  position?: Maybe<Scalars['Float']['output']>;
};

export type DocumentCountAggregate = {
  __typename?: 'DocumentCountAggregate';
  _all: Scalars['Int']['output'];
  category: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  default: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type DocumentCreateInput = {
  category?: InputMaybe<AssetCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  project: ProjectCreateNestedOneWithoutDocumentsInput;
  type?: InputMaybe<AssetFileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type DocumentCreateManyProjectInput = {
  category?: InputMaybe<AssetCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<AssetFileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type DocumentCreateManyProjectInputEnvelope = {
  data: Array<DocumentCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DocumentCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<DocumentCreateWithoutProjectInput>>;
  createMany?: InputMaybe<DocumentCreateManyProjectInputEnvelope>;
};

export type DocumentCreateOrConnectWithoutProjectInput = {
  create: DocumentCreateWithoutProjectInput;
  where: DocumentWhereUniqueInput;
};

export type DocumentCreateWithoutProjectInput = {
  category?: InputMaybe<AssetCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<AssetFileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type DocumentListRelationFilter = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type DocumentMaxAggregate = {
  __typename?: 'DocumentMaxAggregate';
  category?: Maybe<AssetCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssetFileType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentMinAggregate = {
  __typename?: 'DocumentMinAggregate';
  category?: Maybe<AssetCategory>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssetFileType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DocumentOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum DocumentScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  Default = 'default',
  Id = 'id',
  Name = 'name',
  Position = 'position',
  ProjectId = 'projectId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type DocumentScalarWhereInput = {
  AND?: InputMaybe<Array<DocumentScalarWhereInput>>;
  NOT?: InputMaybe<Array<DocumentScalarWhereInput>>;
  OR?: InputMaybe<Array<DocumentScalarWhereInput>>;
  category?: InputMaybe<EnumAssetCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  default?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<IntFilter>;
  projectId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAssetFileTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type DocumentSumAggregate = {
  __typename?: 'DocumentSumAggregate';
  position?: Maybe<Scalars['Int']['output']>;
};

export type DocumentUpdateInput = {
  category?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutDocumentsNestedInput>;
  type?: InputMaybe<EnumAssetFileTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DocumentUpdateManyMutationInput = {
  category?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAssetFileTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DocumentUpdateManyWithWhereWithoutProjectInput = {
  data: DocumentUpdateManyMutationInput;
  where: DocumentScalarWhereInput;
};

export type DocumentUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DocumentCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<DocumentCreateWithoutProjectInput>>;
  createMany?: InputMaybe<DocumentCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DocumentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  set?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  update?: InputMaybe<Array<DocumentUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<DocumentUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<DocumentUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type DocumentUpdateWithWhereUniqueWithoutProjectInput = {
  data: DocumentUpdateWithoutProjectInput;
  where: DocumentWhereUniqueInput;
};

export type DocumentUpdateWithoutProjectInput = {
  category?: InputMaybe<EnumAssetCategoryFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAssetFileTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DocumentUpsertWithWhereUniqueWithoutProjectInput = {
  create: DocumentCreateWithoutProjectInput;
  update: DocumentUpdateWithoutProjectInput;
  where: DocumentWhereUniqueInput;
};

export type DocumentWhereInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  category?: InputMaybe<EnumAssetCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  default?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<IntFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAssetFileTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type DocumentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type EnumAssetCategoryFieldUpdateOperationsInput = {
  set?: InputMaybe<AssetCategory>;
};

export type EnumAssetCategoryFilter = {
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type EnumAssetFileTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AssetFileType>;
};

export type EnumAssetFileTypeFilter = {
  equals?: InputMaybe<AssetFileType>;
  in?: InputMaybe<Array<AssetFileType>>;
  not?: InputMaybe<NestedEnumAssetFileTypeFilter>;
  notIn?: InputMaybe<Array<AssetFileType>>;
};

export type EnumBalconyTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BalconyType>;
};

export type EnumBalconyTypeFilter = {
  equals?: InputMaybe<BalconyType>;
  in?: InputMaybe<Array<BalconyType>>;
  not?: InputMaybe<NestedEnumBalconyTypeFilter>;
  notIn?: InputMaybe<Array<BalconyType>>;
};

export type EnumBuildingMaterialFieldUpdateOperationsInput = {
  set?: InputMaybe<BuildingMaterial>;
};

export type EnumBuildingMaterialFilter = {
  equals?: InputMaybe<BuildingMaterial>;
  in?: InputMaybe<Array<BuildingMaterial>>;
  not?: InputMaybe<NestedEnumBuildingMaterialFilter>;
  notIn?: InputMaybe<Array<BuildingMaterial>>;
};

export type EnumBuildingShapeFieldUpdateOperationsInput = {
  set?: InputMaybe<BuildingShape>;
};

export type EnumBuildingShapeFilter = {
  equals?: InputMaybe<BuildingShape>;
  in?: InputMaybe<Array<BuildingShape>>;
  not?: InputMaybe<NestedEnumBuildingShapeFilter>;
  notIn?: InputMaybe<Array<BuildingShape>>;
};

export type EnumBuildingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BuildingType>;
};

export type EnumBuildingTypeFilter = {
  equals?: InputMaybe<BuildingType>;
  in?: InputMaybe<Array<BuildingType>>;
  not?: InputMaybe<NestedEnumBuildingTypeFilter>;
  notIn?: InputMaybe<Array<BuildingType>>;
};

export type EnumDevicePlatformFieldUpdateOperationsInput = {
  set?: InputMaybe<DevicePlatform>;
};

export type EnumDevicePlatformFilter = {
  equals?: InputMaybe<DevicePlatform>;
  in?: InputMaybe<Array<DevicePlatform>>;
  not?: InputMaybe<NestedEnumDevicePlatformFilter>;
  notIn?: InputMaybe<Array<DevicePlatform>>;
};

export type EnumLocationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<LocationType>;
};

export type EnumLocationTypeFilter = {
  equals?: InputMaybe<LocationType>;
  in?: InputMaybe<Array<LocationType>>;
  not?: InputMaybe<NestedEnumLocationTypeFilter>;
  notIn?: InputMaybe<Array<LocationType>>;
};

export type EnumModelExportChannelNullableListFilter = {
  equals?: InputMaybe<Array<ModelExportChannel>>;
  has?: InputMaybe<ModelExportChannel>;
  hasEvery?: InputMaybe<Array<ModelExportChannel>>;
  hasSome?: InputMaybe<Array<ModelExportChannel>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumModelSourceFieldUpdateOperationsInput = {
  set?: InputMaybe<ModelSource>;
};

export type EnumModelSourceFilter = {
  equals?: InputMaybe<ModelSource>;
  in?: InputMaybe<Array<ModelSource>>;
  not?: InputMaybe<NestedEnumModelSourceFilter>;
  notIn?: InputMaybe<Array<ModelSource>>;
};

export type EnumProjectPublishStatusNullableFilter = {
  equals?: InputMaybe<ProjectPublishStatus>;
  in?: InputMaybe<Array<ProjectPublishStatus>>;
  not?: InputMaybe<NestedEnumProjectPublishStatusNullableFilter>;
  notIn?: InputMaybe<Array<ProjectPublishStatus>>;
};

export type EnumProjectStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ProjectStatus>;
};

export type EnumProjectStatusFilter = {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<ProjectStatus>>;
  not?: InputMaybe<NestedEnumProjectStatusFilter>;
  notIn?: InputMaybe<Array<ProjectStatus>>;
};

export type EnumProjectTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProjectType>;
};

export type EnumProjectTypeFilter = {
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<ProjectType>>;
  not?: InputMaybe<NestedEnumProjectTypeFilter>;
  notIn?: InputMaybe<Array<ProjectType>>;
};

export type EnumProjectUserRelationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProjectUserRelationType>;
};

export type EnumProjectUserRelationTypeFilter = {
  equals?: InputMaybe<ProjectUserRelationType>;
  in?: InputMaybe<Array<ProjectUserRelationType>>;
  not?: InputMaybe<NestedEnumProjectUserRelationTypeFilter>;
  notIn?: InputMaybe<Array<ProjectUserRelationType>>;
};

export type EnumProjectVisibilityTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProjectVisibilityType>;
};

export type EnumProjectVisibilityTypeFilter = {
  equals?: InputMaybe<ProjectVisibilityType>;
  in?: InputMaybe<Array<ProjectVisibilityType>>;
  not?: InputMaybe<NestedEnumProjectVisibilityTypeFilter>;
  notIn?: InputMaybe<Array<ProjectVisibilityType>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumSubscriptionTypeNullableFilter = {
  equals?: InputMaybe<SubscriptionType>;
  in?: InputMaybe<Array<SubscriptionType>>;
  not?: InputMaybe<NestedEnumSubscriptionTypeNullableFilter>;
  notIn?: InputMaybe<Array<SubscriptionType>>;
};

export type EnumUserStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<UserStatus>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type ExistingBuilding = {
  __typename?: 'ExistingBuilding';
  building: Building;
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingId: Scalars['String']['output'];
  buildingMaterial: BuildingMaterial;
  buildingShape: BuildingShape;
  buildingType: BuildingType;
  createdAt: Scalars['DateTime']['output'];
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  footprintId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ExistingBuildingAvgAggregate = {
  __typename?: 'ExistingBuildingAvgAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
};

export type ExistingBuildingCountAggregate = {
  __typename?: 'ExistingBuildingCountAggregate';
  _all: Scalars['Int']['output'];
  buildingHeight: Scalars['Int']['output'];
  buildingId: Scalars['Int']['output'];
  buildingMaterial: Scalars['Int']['output'];
  buildingShape: Scalars['Int']['output'];
  buildingType: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  floorHeight: Scalars['Int']['output'];
  footprintId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ExistingBuildingCreateInput = {
  building: BuildingCreateNestedOneWithoutExistingBuildingInput;
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExistingBuildingCreateNestedOneWithoutBuildingInput = {
  connect?: InputMaybe<ExistingBuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ExistingBuildingCreateOrConnectWithoutBuildingInput>;
  create?: InputMaybe<ExistingBuildingCreateWithoutBuildingInput>;
};

export type ExistingBuildingCreateOrConnectWithoutBuildingInput = {
  create: ExistingBuildingCreateWithoutBuildingInput;
  where: ExistingBuildingWhereUniqueInput;
};

export type ExistingBuildingCreateWithoutBuildingInput = {
  buildingHeight?: InputMaybe<Scalars['Decimal']['input']>;
  buildingMaterial?: InputMaybe<BuildingMaterial>;
  buildingShape?: InputMaybe<BuildingShape>;
  buildingType?: InputMaybe<BuildingType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExistingBuildingMaxAggregate = {
  __typename?: 'ExistingBuildingMaxAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingId?: Maybe<Scalars['String']['output']>;
  buildingMaterial?: Maybe<BuildingMaterial>;
  buildingShape?: Maybe<BuildingShape>;
  buildingType?: Maybe<BuildingType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  footprintId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExistingBuildingMinAggregate = {
  __typename?: 'ExistingBuildingMinAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  buildingId?: Maybe<Scalars['String']['output']>;
  buildingMaterial?: Maybe<BuildingMaterial>;
  buildingShape?: Maybe<BuildingShape>;
  buildingType?: Maybe<BuildingType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  footprintId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExistingBuildingOrderByWithRelationInput = {
  building?: InputMaybe<BuildingOrderByWithRelationInput>;
  buildingHeight?: InputMaybe<SortOrder>;
  buildingId?: InputMaybe<SortOrder>;
  buildingMaterial?: InputMaybe<SortOrder>;
  buildingShape?: InputMaybe<SortOrder>;
  buildingType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  floorHeight?: InputMaybe<SortOrder>;
  footprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ExistingBuildingRelationFilter = {
  is?: InputMaybe<ExistingBuildingWhereInput>;
  isNot?: InputMaybe<ExistingBuildingWhereInput>;
};

export enum ExistingBuildingScalarFieldEnum {
  BuildingHeight = 'buildingHeight',
  BuildingId = 'buildingId',
  BuildingMaterial = 'buildingMaterial',
  BuildingShape = 'buildingShape',
  BuildingType = 'buildingType',
  CreatedAt = 'createdAt',
  FloorHeight = 'floorHeight',
  FootprintId = 'footprintId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type ExistingBuildingSumAggregate = {
  __typename?: 'ExistingBuildingSumAggregate';
  buildingHeight?: Maybe<Scalars['Decimal']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
};

export type ExistingBuildingUpdateInput = {
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutExistingBuildingNestedInput>;
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExistingBuildingUpdateOneWithoutBuildingNestedInput = {
  connect?: InputMaybe<ExistingBuildingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ExistingBuildingCreateOrConnectWithoutBuildingInput>;
  create?: InputMaybe<ExistingBuildingCreateWithoutBuildingInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ExistingBuildingUpdateWithoutBuildingInput>;
  upsert?: InputMaybe<ExistingBuildingUpsertWithoutBuildingInput>;
};

export type ExistingBuildingUpdateWithoutBuildingInput = {
  buildingHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFieldUpdateOperationsInput>;
  buildingShape?: InputMaybe<EnumBuildingShapeFieldUpdateOperationsInput>;
  buildingType?: InputMaybe<EnumBuildingTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  footprintId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExistingBuildingUpsertWithoutBuildingInput = {
  create: ExistingBuildingCreateWithoutBuildingInput;
  update: ExistingBuildingUpdateWithoutBuildingInput;
};

export type ExistingBuildingWhereInput = {
  AND?: InputMaybe<Array<ExistingBuildingWhereInput>>;
  NOT?: InputMaybe<Array<ExistingBuildingWhereInput>>;
  OR?: InputMaybe<Array<ExistingBuildingWhereInput>>;
  building?: InputMaybe<BuildingRelationFilter>;
  buildingHeight?: InputMaybe<DecimalNullableFilter>;
  buildingId?: InputMaybe<StringFilter>;
  buildingMaterial?: InputMaybe<EnumBuildingMaterialFilter>;
  buildingShape?: InputMaybe<EnumBuildingShapeFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  floorHeight?: InputMaybe<DecimalNullableFilter>;
  footprintId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ExistingBuildingWhereUniqueInput = {
  buildingId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Floor = {
  __typename?: 'Floor';
  balconyType: BalconyType;
  building: Building;
  buildingId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  groundFloor: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  setback?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FloorAvgAggregate = {
  __typename?: 'FloorAvgAggregate';
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  level?: Maybe<Scalars['Float']['output']>;
  setback?: Maybe<Scalars['Decimal']['output']>;
};

export type FloorCountAggregate = {
  __typename?: 'FloorCountAggregate';
  _all: Scalars['Int']['output'];
  balconyType: Scalars['Int']['output'];
  buildingId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  floorHeight: Scalars['Int']['output'];
  groundFloor: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  setback: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FloorCreateInput = {
  balconyType?: InputMaybe<BalconyType>;
  building: BuildingCreateNestedOneWithoutFloorsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  groundFloor?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  level: Scalars['Int']['input'];
  setback?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FloorCreateManyBuildingInput = {
  balconyType?: InputMaybe<BalconyType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  groundFloor?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  level: Scalars['Int']['input'];
  setback?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FloorCreateManyBuildingInputEnvelope = {
  data: Array<FloorCreateManyBuildingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FloorCreateNestedManyWithoutBuildingInput = {
  connect?: InputMaybe<Array<FloorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FloorCreateOrConnectWithoutBuildingInput>>;
  create?: InputMaybe<Array<FloorCreateWithoutBuildingInput>>;
  createMany?: InputMaybe<FloorCreateManyBuildingInputEnvelope>;
};

export type FloorCreateOrConnectWithoutBuildingInput = {
  create: FloorCreateWithoutBuildingInput;
  where: FloorWhereUniqueInput;
};

export type FloorCreateWithoutBuildingInput = {
  balconyType?: InputMaybe<BalconyType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorHeight?: InputMaybe<Scalars['Decimal']['input']>;
  groundFloor?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  level: Scalars['Int']['input'];
  setback?: InputMaybe<Scalars['Decimal']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FloorListRelationFilter = {
  every?: InputMaybe<FloorWhereInput>;
  none?: InputMaybe<FloorWhereInput>;
  some?: InputMaybe<FloorWhereInput>;
};

export type FloorMaxAggregate = {
  __typename?: 'FloorMaxAggregate';
  balconyType?: Maybe<BalconyType>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  groundFloor?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  setback?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FloorMinAggregate = {
  __typename?: 'FloorMinAggregate';
  balconyType?: Maybe<BalconyType>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  groundFloor?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  setback?: Maybe<Scalars['Decimal']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FloorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FloorOrderByWithRelationInput = {
  balconyType?: InputMaybe<SortOrder>;
  building?: InputMaybe<BuildingOrderByWithRelationInput>;
  buildingId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  floorHeight?: InputMaybe<SortOrder>;
  groundFloor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  setback?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FloorScalarFieldEnum {
  BalconyType = 'balconyType',
  BuildingId = 'buildingId',
  CreatedAt = 'createdAt',
  FloorHeight = 'floorHeight',
  GroundFloor = 'groundFloor',
  Id = 'id',
  Level = 'level',
  Setback = 'setback',
  UpdatedAt = 'updatedAt'
}

export type FloorScalarWhereInput = {
  AND?: InputMaybe<Array<FloorScalarWhereInput>>;
  NOT?: InputMaybe<Array<FloorScalarWhereInput>>;
  OR?: InputMaybe<Array<FloorScalarWhereInput>>;
  balconyType?: InputMaybe<EnumBalconyTypeFilter>;
  buildingId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  floorHeight?: InputMaybe<DecimalNullableFilter>;
  groundFloor?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  setback?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FloorSumAggregate = {
  __typename?: 'FloorSumAggregate';
  floorHeight?: Maybe<Scalars['Decimal']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  setback?: Maybe<Scalars['Decimal']['output']>;
};

export type FloorUpdateInput = {
  balconyType?: InputMaybe<EnumBalconyTypeFieldUpdateOperationsInput>;
  building?: InputMaybe<BuildingUpdateOneRequiredWithoutFloorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  groundFloor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  setback?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FloorUpdateManyMutationInput = {
  balconyType?: InputMaybe<EnumBalconyTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  groundFloor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  setback?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FloorUpdateManyWithWhereWithoutBuildingInput = {
  data: FloorUpdateManyMutationInput;
  where: FloorScalarWhereInput;
};

export type FloorUpdateManyWithoutBuildingNestedInput = {
  connect?: InputMaybe<Array<FloorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FloorCreateOrConnectWithoutBuildingInput>>;
  create?: InputMaybe<Array<FloorCreateWithoutBuildingInput>>;
  createMany?: InputMaybe<FloorCreateManyBuildingInputEnvelope>;
  delete?: InputMaybe<Array<FloorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FloorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FloorWhereUniqueInput>>;
  set?: InputMaybe<Array<FloorWhereUniqueInput>>;
  update?: InputMaybe<Array<FloorUpdateWithWhereUniqueWithoutBuildingInput>>;
  updateMany?: InputMaybe<Array<FloorUpdateManyWithWhereWithoutBuildingInput>>;
  upsert?: InputMaybe<Array<FloorUpsertWithWhereUniqueWithoutBuildingInput>>;
};

export type FloorUpdateWithWhereUniqueWithoutBuildingInput = {
  data: FloorUpdateWithoutBuildingInput;
  where: FloorWhereUniqueInput;
};

export type FloorUpdateWithoutBuildingInput = {
  balconyType?: InputMaybe<EnumBalconyTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  groundFloor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  setback?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FloorUpsertWithWhereUniqueWithoutBuildingInput = {
  create: FloorCreateWithoutBuildingInput;
  update: FloorUpdateWithoutBuildingInput;
  where: FloorWhereUniqueInput;
};

export type FloorWhereInput = {
  AND?: InputMaybe<Array<FloorWhereInput>>;
  NOT?: InputMaybe<Array<FloorWhereInput>>;
  OR?: InputMaybe<Array<FloorWhereInput>>;
  balconyType?: InputMaybe<EnumBalconyTypeFilter>;
  building?: InputMaybe<BuildingRelationFilter>;
  buildingId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  floorHeight?: InputMaybe<DecimalNullableFilter>;
  groundFloor?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  setback?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FloorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Decimal']['output'];
  locationType: LocationType;
  longitude: Scalars['Decimal']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  locationType: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type LocationCreateInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  locationType: LocationType;
  longitude: Scalars['Decimal']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutLocationsInput;
};

export type LocationCreateManyUserInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  locationType: LocationType;
  longitude: Scalars['Decimal']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LocationCreateManyUserInputEnvelope = {
  data: Array<LocationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutUserInput>>;
  createMany?: InputMaybe<LocationCreateManyUserInputEnvelope>;
};

export type LocationCreateOrConnectWithoutUserInput = {
  create: LocationCreateWithoutUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutUserInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  locationType: LocationType;
  longitude: Scalars['Decimal']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  locationType?: Maybe<LocationType>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  locationType?: Maybe<LocationType>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type LocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LocationOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  locationType?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum LocationScalarFieldEnum {
  Address = 'address',
  CreatedAt = 'createdAt',
  Id = 'id',
  Latitude = 'latitude',
  LocationType = 'locationType',
  Longitude = 'longitude',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type LocationScalarWhereInput = {
  AND?: InputMaybe<Array<LocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  locationType?: InputMaybe<EnumLocationTypeFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export enum LocationType {
  Home = 'HOME',
  Other = 'OTHER',
  Work = 'WORK'
}

export type LocationUpdateInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  locationType?: InputMaybe<EnumLocationTypeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLocationsNestedInput>;
};

export type LocationUpdateManyMutationInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  locationType?: InputMaybe<EnumLocationTypeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithWhereWithoutUserInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutUserInput>>;
  createMany?: InputMaybe<LocationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LocationUpdateWithWhereUniqueWithoutUserInput = {
  data: LocationUpdateWithoutUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithoutUserInput = {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  locationType?: InputMaybe<EnumLocationTypeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationUpsertWithWhereUniqueWithoutUserInput = {
  create: LocationCreateWithoutUserInput;
  update: LocationUpdateWithoutUserInput;
  where: LocationWhereUniqueInput;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  locationType?: InputMaybe<EnumLocationTypeFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type LocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Model3D = {
  __typename?: 'Model3D';
  anchoring?: Maybe<Anchoring>;
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  exportChannels?: Maybe<Array<ModelExportChannel>>;
  externalId?: Maybe<Scalars['String']['output']>;
  fileLocation: Scalars['String']['output'];
  fileSizeMb?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  source: ModelSource;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Model3DAvgAggregate = {
  __typename?: 'Model3DAvgAggregate';
  fileSizeMb?: Maybe<Scalars['Decimal']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

export type Model3DCountAggregate = {
  __typename?: 'Model3DCountAggregate';
  _all: Scalars['Int']['output'];
  buildingId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  exportChannels: Scalars['Int']['output'];
  externalId: Scalars['Int']['output'];
  fileLocation: Scalars['Int']['output'];
  fileSizeMb: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  source: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type Model3DCreateInput = {
  anchoring?: InputMaybe<AnchoringCreateNestedOneWithoutModel3DInput>;
  building?: InputMaybe<BuildingCreateNestedOneWithoutModel3dsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exportChannels?: InputMaybe<Model3DCreateexportChannelsInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fileLocation: Scalars['String']['input'];
  fileSizeMb?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<ModelSource>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Model3DCreateManyBuildingInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exportChannels?: InputMaybe<Model3DCreateexportChannelsInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fileLocation: Scalars['String']['input'];
  fileSizeMb?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<ModelSource>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Model3DCreateManyBuildingInputEnvelope = {
  data: Array<Model3DCreateManyBuildingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Model3DCreateNestedManyWithoutBuildingInput = {
  connect?: InputMaybe<Array<Model3DWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Model3DCreateOrConnectWithoutBuildingInput>>;
  create?: InputMaybe<Array<Model3DCreateWithoutBuildingInput>>;
  createMany?: InputMaybe<Model3DCreateManyBuildingInputEnvelope>;
};

export type Model3DCreateNestedOneWithoutAnchoringInput = {
  connect?: InputMaybe<Model3DWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Model3DCreateOrConnectWithoutAnchoringInput>;
  create?: InputMaybe<Model3DCreateWithoutAnchoringInput>;
};

export type Model3DCreateOrConnectWithoutAnchoringInput = {
  create: Model3DCreateWithoutAnchoringInput;
  where: Model3DWhereUniqueInput;
};

export type Model3DCreateOrConnectWithoutBuildingInput = {
  create: Model3DCreateWithoutBuildingInput;
  where: Model3DWhereUniqueInput;
};

export type Model3DCreateWithoutAnchoringInput = {
  building?: InputMaybe<BuildingCreateNestedOneWithoutModel3dsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exportChannels?: InputMaybe<Model3DCreateexportChannelsInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fileLocation: Scalars['String']['input'];
  fileSizeMb?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<ModelSource>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Model3DCreateWithoutBuildingInput = {
  anchoring?: InputMaybe<AnchoringCreateNestedOneWithoutModel3DInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exportChannels?: InputMaybe<Model3DCreateexportChannelsInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fileLocation: Scalars['String']['input'];
  fileSizeMb?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<ModelSource>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Model3DCreateexportChannelsInput = {
  set: Array<ModelExportChannel>;
};

export type Model3DListRelationFilter = {
  every?: InputMaybe<Model3DWhereInput>;
  none?: InputMaybe<Model3DWhereInput>;
  some?: InputMaybe<Model3DWhereInput>;
};

export type Model3DMaxAggregate = {
  __typename?: 'Model3DMaxAggregate';
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fileLocation?: Maybe<Scalars['String']['output']>;
  fileSizeMb?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<ModelSource>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Model3DMinAggregate = {
  __typename?: 'Model3DMinAggregate';
  buildingId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fileLocation?: Maybe<Scalars['String']['output']>;
  fileSizeMb?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<ModelSource>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Model3DOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Model3DOrderByWithRelationInput = {
  anchoring?: InputMaybe<AnchoringOrderByWithRelationInput>;
  building?: InputMaybe<BuildingOrderByWithRelationInput>;
  buildingId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  exportChannels?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  fileLocation?: InputMaybe<SortOrder>;
  fileSizeMb?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Model3DRelationFilter = {
  is?: InputMaybe<Model3DWhereInput>;
  isNot?: InputMaybe<Model3DWhereInput>;
};

export enum Model3DScalarFieldEnum {
  BuildingId = 'buildingId',
  CreatedAt = 'createdAt',
  ExportChannels = 'exportChannels',
  ExternalId = 'externalId',
  FileLocation = 'fileLocation',
  FileSizeMb = 'fileSizeMb',
  Id = 'id',
  Order = 'order',
  Source = 'source',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type Model3DScalarWhereInput = {
  AND?: InputMaybe<Array<Model3DScalarWhereInput>>;
  NOT?: InputMaybe<Array<Model3DScalarWhereInput>>;
  OR?: InputMaybe<Array<Model3DScalarWhereInput>>;
  buildingId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exportChannels?: InputMaybe<EnumModelExportChannelNullableListFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  fileLocation?: InputMaybe<StringFilter>;
  fileSizeMb?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  source?: InputMaybe<EnumModelSourceFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Model3DSumAggregate = {
  __typename?: 'Model3DSumAggregate';
  fileSizeMb?: Maybe<Scalars['Decimal']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type Model3DUpdateInput = {
  anchoring?: InputMaybe<AnchoringUpdateOneWithoutModel3DNestedInput>;
  building?: InputMaybe<BuildingUpdateOneWithoutModel3dsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exportChannels?: InputMaybe<Model3DUpdateexportChannelsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileLocation?: InputMaybe<StringFieldUpdateOperationsInput>;
  fileSizeMb?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumModelSourceFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type Model3DUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exportChannels?: InputMaybe<Model3DUpdateexportChannelsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileLocation?: InputMaybe<StringFieldUpdateOperationsInput>;
  fileSizeMb?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumModelSourceFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type Model3DUpdateManyWithWhereWithoutBuildingInput = {
  data: Model3DUpdateManyMutationInput;
  where: Model3DScalarWhereInput;
};

export type Model3DUpdateManyWithoutBuildingNestedInput = {
  connect?: InputMaybe<Array<Model3DWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Model3DCreateOrConnectWithoutBuildingInput>>;
  create?: InputMaybe<Array<Model3DCreateWithoutBuildingInput>>;
  createMany?: InputMaybe<Model3DCreateManyBuildingInputEnvelope>;
  delete?: InputMaybe<Array<Model3DWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Model3DScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Model3DWhereUniqueInput>>;
  set?: InputMaybe<Array<Model3DWhereUniqueInput>>;
  update?: InputMaybe<Array<Model3DUpdateWithWhereUniqueWithoutBuildingInput>>;
  updateMany?: InputMaybe<Array<Model3DUpdateManyWithWhereWithoutBuildingInput>>;
  upsert?: InputMaybe<Array<Model3DUpsertWithWhereUniqueWithoutBuildingInput>>;
};

export type Model3DUpdateOneWithoutAnchoringNestedInput = {
  connect?: InputMaybe<Model3DWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Model3DCreateOrConnectWithoutAnchoringInput>;
  create?: InputMaybe<Model3DCreateWithoutAnchoringInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<Model3DUpdateWithoutAnchoringInput>;
  upsert?: InputMaybe<Model3DUpsertWithoutAnchoringInput>;
};

export type Model3DUpdateWithWhereUniqueWithoutBuildingInput = {
  data: Model3DUpdateWithoutBuildingInput;
  where: Model3DWhereUniqueInput;
};

export type Model3DUpdateWithoutAnchoringInput = {
  building?: InputMaybe<BuildingUpdateOneWithoutModel3dsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exportChannels?: InputMaybe<Model3DUpdateexportChannelsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileLocation?: InputMaybe<StringFieldUpdateOperationsInput>;
  fileSizeMb?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumModelSourceFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type Model3DUpdateWithoutBuildingInput = {
  anchoring?: InputMaybe<AnchoringUpdateOneWithoutModel3DNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exportChannels?: InputMaybe<Model3DUpdateexportChannelsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileLocation?: InputMaybe<StringFieldUpdateOperationsInput>;
  fileSizeMb?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumModelSourceFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type Model3DUpdateexportChannelsInput = {
  push?: InputMaybe<Array<ModelExportChannel>>;
  set?: InputMaybe<Array<ModelExportChannel>>;
};

export type Model3DUpsertWithWhereUniqueWithoutBuildingInput = {
  create: Model3DCreateWithoutBuildingInput;
  update: Model3DUpdateWithoutBuildingInput;
  where: Model3DWhereUniqueInput;
};

export type Model3DUpsertWithoutAnchoringInput = {
  create: Model3DCreateWithoutAnchoringInput;
  update: Model3DUpdateWithoutAnchoringInput;
};

export type Model3DWhereInput = {
  AND?: InputMaybe<Array<Model3DWhereInput>>;
  NOT?: InputMaybe<Array<Model3DWhereInput>>;
  OR?: InputMaybe<Array<Model3DWhereInput>>;
  anchoring?: InputMaybe<AnchoringRelationFilter>;
  building?: InputMaybe<BuildingRelationFilter>;
  buildingId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exportChannels?: InputMaybe<EnumModelExportChannelNullableListFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  fileLocation?: InputMaybe<StringFilter>;
  fileSizeMb?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  source?: InputMaybe<EnumModelSourceFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Model3DWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ModelConvertResult = {
  __typename?: 'ModelConvertResult';
  outputModel: Scalars['String']['output'];
};

export enum ModelExportChannel {
  Echo3D = 'ECHO3D',
  Esri = 'ESRI'
}

export enum ModelSource {
  Generated = 'GENERATED',
  Manual = 'MANUAL'
}

export type Mutation = {
  __typename?: 'Mutation';
  convertModel: ModelConvertResult;
  createAnchoring: Anchoring;
  createBuilding: Building;
  createCity: City;
  createComment: Comment;
  createCoordinate: Coordinate;
  createDocument: Document;
  createExistingBuilding: ExistingBuilding;
  createFloor: Floor;
  createLocation: Location;
  createModel3D: Model3D;
  createOrganization: Organization;
  createOrganizationsUsers: OrganizationsUsers;
  createProject: Project;
  createProjectViewPreference: ProjectViewPreference;
  createProjectsUsers: ProjectsUsers;
  createSubscriptionPlan: SubscriptionPlan;
  createTimelineEvent: TimelineEvent;
  createTimelineEventType: TimelineEventType;
  createUser: User;
  createUserDevice: UserDevice;
  exportProjects: ProjectBatchOperationResult;
  generateQRCodes: ProjectBatchOperationResult;
  removeAnchoring: Anchoring;
  removeBuilding: Building;
  removeCity: City;
  removeComment: Comment;
  removeCoordinate: Coordinate;
  removeDocument: Document;
  removeExistingBuilding: ExistingBuilding;
  removeFloor: Floor;
  removeLocation: Location;
  removeManyProjectModels: ProjectBatchOperationResult;
  removeManyProjects: ProjectBatchOperationResult;
  removeModel3D: Model3D;
  removeOrganization: Organization;
  removeOrganizationsUsers: OrganizationsUsers;
  removeProject: Project;
  removeProjectViewPreference: ProjectViewPreference;
  removeProjectsUsers: ProjectsUsers;
  removeSubscriptionPlan: SubscriptionPlan;
  removeTimelineEvent: TimelineEvent;
  removeTimelineEventType: TimelineEventType;
  removeUser: User;
  removeUserDevice: UserDevice;
  updateAnchoring: Anchoring;
  updateBuilding: Building;
  updateCity: City;
  updateComment: Comment;
  updateCoordinate: Coordinate;
  updateDocument: Document;
  updateExistingBuilding: ExistingBuilding;
  updateFloor: Floor;
  updateLocation: Location;
  updateModel3D: Model3D;
  updateOrganization: Organization;
  updateOrganizationsUsers: OrganizationsUsers;
  updateProject: Project;
  updateProjectViewPreference: ProjectViewPreference;
  updateProjectsUsers: ProjectsUsers;
  updateSubscriptionPlan: SubscriptionPlan;
  updateTimelineEvent: TimelineEvent;
  updateTimelineEventType: TimelineEventType;
  updateUser: User;
  updateUserDevice: UserDevice;
};


export type MutationConvertModelArgs = {
  modelLocation: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateAnchoringArgs = {
  createAnchoringInput: AnchoringCreateInput;
};


export type MutationCreateBuildingArgs = {
  createBuildingInput: BuildingCreateInput;
};


export type MutationCreateCityArgs = {
  createCityInput: CityCreateInput;
};


export type MutationCreateCommentArgs = {
  createCommentInput: CommentCreateInput;
};


export type MutationCreateCoordinateArgs = {
  createCoordinateInput: CoordinateCreateInput;
};


export type MutationCreateDocumentArgs = {
  createDocumentInput: DocumentCreateInput;
};


export type MutationCreateExistingBuildingArgs = {
  createExistingBuildingInput: ExistingBuildingCreateInput;
};


export type MutationCreateFloorArgs = {
  createFloorInput: FloorCreateInput;
};


export type MutationCreateLocationArgs = {
  createLocationInput: LocationCreateInput;
};


export type MutationCreateModel3DArgs = {
  createModel3DInput: Model3DCreateInput;
};


export type MutationCreateOrganizationArgs = {
  createOrganizationInput: OrganizationCreateInput;
};


export type MutationCreateOrganizationsUsersArgs = {
  createOrganizationsUsersInput: OrganizationsUsersCreateInput;
};


export type MutationCreateProjectArgs = {
  createProjectInput: ProjectCreateInput;
};


export type MutationCreateProjectViewPreferenceArgs = {
  createProjectViewPreferenceInput: ProjectViewPreferenceCreateInput;
};


export type MutationCreateProjectsUsersArgs = {
  createProjectsUsersInput: ProjectsUsersCreateInput;
};


export type MutationCreateSubscriptionPlanArgs = {
  createSubscriptionPlanInput: SubscriptionPlanCreateInput;
};


export type MutationCreateTimelineEventArgs = {
  createTimelineEventInput: TimelineEventCreateInput;
};


export type MutationCreateTimelineEventTypeArgs = {
  createTimelineEventTypeInput: TimelineEventTypeCreateInput;
};


export type MutationCreateUserArgs = {
  createUserInput: UserCreateInput;
};


export type MutationCreateUserDeviceArgs = {
  createUserDeviceInput: UserDeviceCreateInput;
};


export type MutationExportProjectsArgs = {
  arcgis?: InputMaybe<Scalars['Boolean']['input']>;
  batch?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationGenerateQrCodesArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  smart?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationRemoveAnchoringArgs = {
  where: AnchoringWhereUniqueInput;
};


export type MutationRemoveBuildingArgs = {
  where: BuildingWhereUniqueInput;
};


export type MutationRemoveCityArgs = {
  where: CityWhereUniqueInput;
};


export type MutationRemoveCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationRemoveCoordinateArgs = {
  where: CoordinateWhereUniqueInput;
};


export type MutationRemoveDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationRemoveExistingBuildingArgs = {
  where: ExistingBuildingWhereUniqueInput;
};


export type MutationRemoveFloorArgs = {
  where: FloorWhereUniqueInput;
};


export type MutationRemoveLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type MutationRemoveManyProjectModelsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationRemoveManyProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationRemoveModel3DArgs = {
  where: Model3DWhereUniqueInput;
};


export type MutationRemoveOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationRemoveOrganizationsUsersArgs = {
  where: OrganizationsUsersWhereUniqueInput;
};


export type MutationRemoveProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationRemoveProjectViewPreferenceArgs = {
  where: ProjectViewPreferenceWhereUniqueInput;
};


export type MutationRemoveProjectsUsersArgs = {
  where: ProjectsUsersWhereUniqueInput;
};


export type MutationRemoveSubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationRemoveTimelineEventArgs = {
  where: TimelineEventWhereUniqueInput;
};


export type MutationRemoveTimelineEventTypeArgs = {
  where: TimelineEventTypeWhereUniqueInput;
};


export type MutationRemoveUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationRemoveUserDeviceArgs = {
  where: UserDeviceWhereUniqueInput;
};


export type MutationUpdateAnchoringArgs = {
  updateAnchoringInput: AnchoringUpdateInput;
  where: AnchoringWhereUniqueInput;
};


export type MutationUpdateBuildingArgs = {
  updateBuildingInput: BuildingUpdateInput;
  where: BuildingWhereUniqueInput;
};


export type MutationUpdateCityArgs = {
  updateCityInput: CityUpdateInput;
  where: CityWhereUniqueInput;
};


export type MutationUpdateCommentArgs = {
  updateCommentInput: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateCoordinateArgs = {
  updateCoordinateInput: CoordinateUpdateInput;
  where: CoordinateWhereUniqueInput;
};


export type MutationUpdateDocumentArgs = {
  updateDocumentInput: DocumentUpdateInput;
  where: DocumentWhereUniqueInput;
};


export type MutationUpdateExistingBuildingArgs = {
  updateExistingBuildingInput: ExistingBuildingUpdateInput;
  where: ExistingBuildingWhereUniqueInput;
};


export type MutationUpdateFloorArgs = {
  updateFloorInput: FloorUpdateInput;
  where: FloorWhereUniqueInput;
};


export type MutationUpdateLocationArgs = {
  updateLocationInput: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};


export type MutationUpdateModel3DArgs = {
  updateModel3DInput: Model3DUpdateInput;
  where: Model3DWhereUniqueInput;
};


export type MutationUpdateOrganizationArgs = {
  updateOrganizationInput: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateOrganizationsUsersArgs = {
  updateOrganizationsUsersInput: OrganizationsUsersUpdateInput;
  where: OrganizationsUsersWhereUniqueInput;
};


export type MutationUpdateProjectArgs = {
  updateProjectInput: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateProjectViewPreferenceArgs = {
  updateProjectViewPreferenceInput: ProjectViewPreferenceUpdateInput;
  where: ProjectViewPreferenceWhereUniqueInput;
};


export type MutationUpdateProjectsUsersArgs = {
  updateProjectsUsersInput: ProjectsUsersUpdateInput;
  where: ProjectsUsersWhereUniqueInput;
};


export type MutationUpdateSubscriptionPlanArgs = {
  updateSubscriptionPlanInput: SubscriptionPlanUpdateInput;
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationUpdateTimelineEventArgs = {
  updateTimelineEventInput: TimelineEventUpdateInput;
  where: TimelineEventWhereUniqueInput;
};


export type MutationUpdateTimelineEventTypeArgs = {
  updateTimelineEventTypeInput: TimelineEventTypeUpdateInput;
  where: TimelineEventTypeWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserDeviceArgs = {
  updateUserDeviceInput: UserDeviceUpdateInput;
  where: UserDeviceWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumAssetCategoryFilter = {
  equals?: InputMaybe<AssetCategory>;
  in?: InputMaybe<Array<AssetCategory>>;
  not?: InputMaybe<NestedEnumAssetCategoryFilter>;
  notIn?: InputMaybe<Array<AssetCategory>>;
};

export type NestedEnumAssetFileTypeFilter = {
  equals?: InputMaybe<AssetFileType>;
  in?: InputMaybe<Array<AssetFileType>>;
  not?: InputMaybe<NestedEnumAssetFileTypeFilter>;
  notIn?: InputMaybe<Array<AssetFileType>>;
};

export type NestedEnumBalconyTypeFilter = {
  equals?: InputMaybe<BalconyType>;
  in?: InputMaybe<Array<BalconyType>>;
  not?: InputMaybe<NestedEnumBalconyTypeFilter>;
  notIn?: InputMaybe<Array<BalconyType>>;
};

export type NestedEnumBuildingMaterialFilter = {
  equals?: InputMaybe<BuildingMaterial>;
  in?: InputMaybe<Array<BuildingMaterial>>;
  not?: InputMaybe<NestedEnumBuildingMaterialFilter>;
  notIn?: InputMaybe<Array<BuildingMaterial>>;
};

export type NestedEnumBuildingShapeFilter = {
  equals?: InputMaybe<BuildingShape>;
  in?: InputMaybe<Array<BuildingShape>>;
  not?: InputMaybe<NestedEnumBuildingShapeFilter>;
  notIn?: InputMaybe<Array<BuildingShape>>;
};

export type NestedEnumBuildingTypeFilter = {
  equals?: InputMaybe<BuildingType>;
  in?: InputMaybe<Array<BuildingType>>;
  not?: InputMaybe<NestedEnumBuildingTypeFilter>;
  notIn?: InputMaybe<Array<BuildingType>>;
};

export type NestedEnumDevicePlatformFilter = {
  equals?: InputMaybe<DevicePlatform>;
  in?: InputMaybe<Array<DevicePlatform>>;
  not?: InputMaybe<NestedEnumDevicePlatformFilter>;
  notIn?: InputMaybe<Array<DevicePlatform>>;
};

export type NestedEnumLocationTypeFilter = {
  equals?: InputMaybe<LocationType>;
  in?: InputMaybe<Array<LocationType>>;
  not?: InputMaybe<NestedEnumLocationTypeFilter>;
  notIn?: InputMaybe<Array<LocationType>>;
};

export type NestedEnumModelSourceFilter = {
  equals?: InputMaybe<ModelSource>;
  in?: InputMaybe<Array<ModelSource>>;
  not?: InputMaybe<NestedEnumModelSourceFilter>;
  notIn?: InputMaybe<Array<ModelSource>>;
};

export type NestedEnumProjectPublishStatusNullableFilter = {
  equals?: InputMaybe<ProjectPublishStatus>;
  in?: InputMaybe<Array<ProjectPublishStatus>>;
  not?: InputMaybe<NestedEnumProjectPublishStatusNullableFilter>;
  notIn?: InputMaybe<Array<ProjectPublishStatus>>;
};

export type NestedEnumProjectStatusFilter = {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<ProjectStatus>>;
  not?: InputMaybe<NestedEnumProjectStatusFilter>;
  notIn?: InputMaybe<Array<ProjectStatus>>;
};

export type NestedEnumProjectTypeFilter = {
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<ProjectType>>;
  not?: InputMaybe<NestedEnumProjectTypeFilter>;
  notIn?: InputMaybe<Array<ProjectType>>;
};

export type NestedEnumProjectUserRelationTypeFilter = {
  equals?: InputMaybe<ProjectUserRelationType>;
  in?: InputMaybe<Array<ProjectUserRelationType>>;
  not?: InputMaybe<NestedEnumProjectUserRelationTypeFilter>;
  notIn?: InputMaybe<Array<ProjectUserRelationType>>;
};

export type NestedEnumProjectVisibilityTypeFilter = {
  equals?: InputMaybe<ProjectVisibilityType>;
  in?: InputMaybe<Array<ProjectVisibilityType>>;
  not?: InputMaybe<NestedEnumProjectVisibilityTypeFilter>;
  notIn?: InputMaybe<Array<ProjectVisibilityType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumSubscriptionTypeNullableFilter = {
  equals?: InputMaybe<SubscriptionType>;
  in?: InputMaybe<Array<SubscriptionType>>;
  not?: InputMaybe<NestedEnumSubscriptionTypeNullableFilter>;
  notIn?: InputMaybe<Array<SubscriptionType>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']['input']>;
  divide?: InputMaybe<Scalars['Decimal']['input']>;
  increment?: InputMaybe<Scalars['Decimal']['input']>;
  multiply?: InputMaybe<Scalars['Decimal']['input']>;
  set?: InputMaybe<Scalars['Decimal']['input']>;
};

export type NullableEnumProjectPublishStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ProjectPublishStatus>;
};

export type NullableEnumSubscriptionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SubscriptionType>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  _count: OrganizationCount;
  address?: Maybe<Scalars['String']['output']>;
  client: Scalars['Boolean']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organizationUsers?: Maybe<Array<OrganizationsUsers>>;
  projects?: Maybe<Array<Project>>;
  publicEmail?: Maybe<Scalars['String']['output']>;
  publicPhone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationCount = {
  __typename?: 'OrganizationCount';
  organizationUsers: Scalars['Int']['output'];
  projects: Scalars['Int']['output'];
};

export type OrganizationCountAggregate = {
  __typename?: 'OrganizationCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  client: Scalars['Int']['output'];
  contactEmail: Scalars['Int']['output'];
  contactPhone: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  notes: Scalars['Int']['output'];
  publicEmail: Scalars['Int']['output'];
  publicPhone: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  website: Scalars['Int']['output'];
};

export type OrganizationCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutOrganizationInput>;
  projects?: InputMaybe<ProjectCreateNestedManyWithoutOrganizationInput>;
  publicEmail?: InputMaybe<Scalars['String']['input']>;
  publicPhone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationCreateNestedOneWithoutOrganizationUsersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrganizationUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrganizationUsersInput>;
};

export type OrganizationCreateNestedOneWithoutProjectsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProjectsInput>;
};

export type OrganizationCreateOrConnectWithoutOrganizationUsersInput = {
  create: OrganizationCreateWithoutOrganizationUsersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutProjectsInput = {
  create: OrganizationCreateWithoutProjectsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateWithoutOrganizationUsersInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<ProjectCreateNestedManyWithoutOrganizationInput>;
  publicEmail?: InputMaybe<Scalars['String']['input']>;
  publicPhone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationCreateWithoutProjectsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutOrganizationInput>;
  publicEmail?: InputMaybe<Scalars['String']['input']>;
  publicPhone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationMaxAggregate = {
  __typename?: 'OrganizationMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['Boolean']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  publicEmail?: Maybe<Scalars['String']['output']>;
  publicPhone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationMinAggregate = {
  __typename?: 'OrganizationMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['Boolean']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  publicEmail?: Maybe<Scalars['String']['output']>;
  publicPhone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  client?: InputMaybe<SortOrder>;
  contactEmail?: InputMaybe<SortOrder>;
  contactPhone?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  organizationUsers?: InputMaybe<OrganizationsUsersOrderByRelationAggregateInput>;
  projects?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  publicEmail?: InputMaybe<SortOrder>;
  publicPhone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  website?: InputMaybe<SortOrder>;
};

export type OrganizationRelationFilter = {
  is?: InputMaybe<OrganizationWhereInput>;
  isNot?: InputMaybe<OrganizationWhereInput>;
};

export enum OrganizationScalarFieldEnum {
  Address = 'address',
  Client = 'client',
  ContactEmail = 'contactEmail',
  ContactPhone = 'contactPhone',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Notes = 'notes',
  PublicEmail = 'publicEmail',
  PublicPhone = 'publicPhone',
  UpdatedAt = 'updatedAt',
  Website = 'website'
}

export type OrganizationUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<BoolFieldUpdateOperationsInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutOrganizationNestedInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutOrganizationNestedInput>;
  publicEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationUsersNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrganizationUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrganizationUsersInput>;
  update?: InputMaybe<OrganizationUpdateWithoutOrganizationUsersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutOrganizationUsersInput>;
};

export type OrganizationUpdateOneWithoutProjectsNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProjectsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProjectsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutProjectsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutProjectsInput>;
};

export type OrganizationUpdateWithoutOrganizationUsersInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<BoolFieldUpdateOperationsInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutOrganizationNestedInput>;
  publicEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutProjectsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<BoolFieldUpdateOperationsInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutOrganizationNestedInput>;
  publicEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publicPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrganizationUpsertWithoutOrganizationUsersInput = {
  create: OrganizationCreateWithoutOrganizationUsersInput;
  update: OrganizationUpdateWithoutOrganizationUsersInput;
};

export type OrganizationUpsertWithoutProjectsInput = {
  create: OrganizationCreateWithoutProjectsInput;
  update: OrganizationUpdateWithoutProjectsInput;
};

export type OrganizationWhereInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<BoolFilter>;
  contactEmail?: InputMaybe<StringNullableFilter>;
  contactPhone?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringNullableFilter>;
  organizationUsers?: InputMaybe<OrganizationsUsersListRelationFilter>;
  projects?: InputMaybe<ProjectListRelationFilter>;
  publicEmail?: InputMaybe<StringNullableFilter>;
  publicPhone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  website?: InputMaybe<StringNullableFilter>;
};

export type OrganizationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationsUsers = {
  __typename?: 'OrganizationsUsers';
  assignedAt: Scalars['DateTime']['output'];
  contactPerson: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type OrganizationsUsersCountAggregate = {
  __typename?: 'OrganizationsUsersCountAggregate';
  _all: Scalars['Int']['output'];
  assignedAt: Scalars['Int']['output'];
  contactPerson: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type OrganizationsUsersCreateInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationUsersInput;
  user: UserCreateNestedOneWithoutOrganizationUsersInput;
};

export type OrganizationsUsersCreateManyOrganizationInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type OrganizationsUsersCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationsUsersCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationsUsersCreateManyUserInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type OrganizationsUsersCreateManyUserInputEnvelope = {
  data: Array<OrganizationsUsersCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationsUsersCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationsUsersCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationsUsersCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationsUsersCreateManyOrganizationInputEnvelope>;
};

export type OrganizationsUsersCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationsUsersCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrganizationsUsersCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrganizationsUsersCreateManyUserInputEnvelope>;
};

export type OrganizationsUsersCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationsUsersCreateWithoutOrganizationInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersCreateOrConnectWithoutUserInput = {
  create: OrganizationsUsersCreateWithoutUserInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersCreateWithoutOrganizationInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutOrganizationUsersInput;
};

export type OrganizationsUsersCreateWithoutUserInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationUsersInput;
};

export type OrganizationsUsersListRelationFilter = {
  every?: InputMaybe<OrganizationsUsersWhereInput>;
  none?: InputMaybe<OrganizationsUsersWhereInput>;
  some?: InputMaybe<OrganizationsUsersWhereInput>;
};

export type OrganizationsUsersMaxAggregate = {
  __typename?: 'OrganizationsUsersMaxAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  contactPerson?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationsUsersMinAggregate = {
  __typename?: 'OrganizationsUsersMinAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  contactPerson?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationsUsersOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrganizationsUsersOrderByWithRelationInput = {
  assignedAt?: InputMaybe<SortOrder>;
  contactPerson?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type OrganizationsUsersOrganizationIdUserIdCompoundUniqueInput = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum OrganizationsUsersScalarFieldEnum {
  AssignedAt = 'assignedAt',
  ContactPerson = 'contactPerson',
  Id = 'id',
  OrganizationId = 'organizationId',
  UserId = 'userId'
}

export type OrganizationsUsersScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationsUsersScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationsUsersScalarWhereInput>>;
  OR?: InputMaybe<Array<OrganizationsUsersScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  contactPerson?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type OrganizationsUsersUpdateInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  contactPerson?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutOrganizationUsersNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrganizationUsersNestedInput>;
};

export type OrganizationsUsersUpdateManyMutationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  contactPerson?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrganizationsUsersUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrganizationsUsersUpdateManyMutationInput;
  where: OrganizationsUsersScalarWhereInput;
};

export type OrganizationsUsersUpdateManyWithWhereWithoutUserInput = {
  data: OrganizationsUsersUpdateManyMutationInput;
  where: OrganizationsUsersScalarWhereInput;
};

export type OrganizationsUsersUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationsUsersCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationsUsersCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationsUsersCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationsUsersScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationsUsersUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrganizationsUsersUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrganizationsUsersUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationsUsersUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationsUsersCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrganizationsUsersCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrganizationsUsersCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationsUsersScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationsUsersWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationsUsersUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<OrganizationsUsersUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<OrganizationsUsersUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OrganizationsUsersUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrganizationsUsersUpdateWithoutOrganizationInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersUpdateWithWhereUniqueWithoutUserInput = {
  data: OrganizationsUsersUpdateWithoutUserInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersUpdateWithoutOrganizationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  contactPerson?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrganizationUsersNestedInput>;
};

export type OrganizationsUsersUpdateWithoutUserInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  contactPerson?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutOrganizationUsersNestedInput>;
};

export type OrganizationsUsersUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrganizationsUsersCreateWithoutOrganizationInput;
  update: OrganizationsUsersUpdateWithoutOrganizationInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersUpsertWithWhereUniqueWithoutUserInput = {
  create: OrganizationsUsersCreateWithoutUserInput;
  update: OrganizationsUsersUpdateWithoutUserInput;
  where: OrganizationsUsersWhereUniqueInput;
};

export type OrganizationsUsersWhereInput = {
  AND?: InputMaybe<Array<OrganizationsUsersWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationsUsersWhereInput>>;
  OR?: InputMaybe<Array<OrganizationsUsersWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  contactPerson?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type OrganizationsUsersWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId_userId?: InputMaybe<OrganizationsUsersOrganizationIdUserIdCompoundUniqueInput>;
};

export type Project = {
  __typename?: 'Project';
  _count: ProjectCount;
  address?: Maybe<Scalars['String']['output']>;
  altitude: Scalars['Decimal']['output'];
  appClipSync: Scalars['Boolean']['output'];
  arcgisFeatureLayerId?: Maybe<Scalars['String']['output']>;
  arcgisSceneLayerId?: Maybe<Scalars['String']['output']>;
  autoGenerateModels: Scalars['Boolean']['output'];
  averageRating: CommentAvgAggregate;
  bbl?: Maybe<Scalars['String']['output']>;
  borough?: Maybe<Scalars['String']['output']>;
  buildings?: Maybe<Array<Building>>;
  ceqr?: Maybe<Scalars['String']['output']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  projectType: ProjectType;
  projectUsers?: Maybe<Array<ProjectsUsers>>;
  projectViewPreference?: Maybe<ProjectViewPreference>;
  publishStatus?: Maybe<ProjectPublishStatus>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sourceRecordId?: Maybe<Scalars['String']['output']>;
  status: ProjectStatus;
  subtitle?: Maybe<Scalars['String']['output']>;
  timelineEvents?: Maybe<Array<TimelineEvent>>;
  trialExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProjectAvgAggregate = {
  __typename?: 'ProjectAvgAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type ProjectBatchCount = {
  __typename?: 'ProjectBatchCount';
  count: Scalars['Int']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
};

export type ProjectBatchOperationResult = {
  __typename?: 'ProjectBatchOperationResult';
  count: Scalars['Int']['output'];
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  buildings: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  documents: Scalars['Int']['output'];
  projectUsers: Scalars['Int']['output'];
  timelineEvents: Scalars['Int']['output'];
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  altitude: Scalars['Int']['output'];
  appClipSync: Scalars['Int']['output'];
  arcgisFeatureLayerId: Scalars['Int']['output'];
  arcgisSceneLayerId: Scalars['Int']['output'];
  autoGenerateModels: Scalars['Int']['output'];
  bbl: Scalars['Int']['output'];
  borough: Scalars['Int']['output'];
  ceqr: Scalars['Int']['output'];
  cityId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  geoidHeight: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  origin: Scalars['Int']['output'];
  projectType: Scalars['Int']['output'];
  publishStatus: Scalars['Int']['output'];
  publishedAt: Scalars['Int']['output'];
  sourceRecordId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  subtitle: Scalars['Int']['output'];
  trialExpiresAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ProjectCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateManyCityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateManyCityInputEnvelope = {
  data: Array<ProjectCreateManyCityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateManyOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateManyOrganizationInputEnvelope = {
  data: Array<ProjectCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateNestedManyWithoutCityInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutCityInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutCityInput>>;
  createMany?: InputMaybe<ProjectCreateManyCityInputEnvelope>;
};

export type ProjectCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProjectCreateManyOrganizationInputEnvelope>;
};

export type ProjectCreateNestedOneWithoutBuildingsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBuildingsInput>;
  create?: InputMaybe<ProjectCreateWithoutBuildingsInput>;
};

export type ProjectCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ProjectCreateWithoutCommentsInput>;
};

export type ProjectCreateNestedOneWithoutDocumentsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutDocumentsInput>;
  create?: InputMaybe<ProjectCreateWithoutDocumentsInput>;
};

export type ProjectCreateNestedOneWithoutProjectUsersInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectUsersInput>;
  create?: InputMaybe<ProjectCreateWithoutProjectUsersInput>;
};

export type ProjectCreateNestedOneWithoutProjectViewPreferenceInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectViewPreferenceInput>;
  create?: InputMaybe<ProjectCreateWithoutProjectViewPreferenceInput>;
};

export type ProjectCreateNestedOneWithoutTimelineEventsInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTimelineEventsInput>;
  create?: InputMaybe<ProjectCreateWithoutTimelineEventsInput>;
};

export type ProjectCreateOrConnectWithoutBuildingsInput = {
  create: ProjectCreateWithoutBuildingsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutCityInput = {
  create: ProjectCreateWithoutCityInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutCommentsInput = {
  create: ProjectCreateWithoutCommentsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutDocumentsInput = {
  create: ProjectCreateWithoutDocumentsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutOrganizationInput = {
  create: ProjectCreateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutProjectUsersInput = {
  create: ProjectCreateWithoutProjectUsersInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutProjectViewPreferenceInput = {
  create: ProjectCreateWithoutProjectViewPreferenceInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutTimelineEventsInput = {
  create: ProjectCreateWithoutTimelineEventsInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateWithoutBuildingsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutCityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutCommentsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutDocumentsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutProjectUsersInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutProjectViewPreferenceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutProjectInput>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectCreateWithoutTimelineEventsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  altitude: Scalars['Decimal']['input'];
  appClipSync?: InputMaybe<Scalars['Boolean']['input']>;
  arcgisFeatureLayerId?: InputMaybe<Scalars['String']['input']>;
  arcgisSceneLayerId?: InputMaybe<Scalars['String']['input']>;
  autoGenerateModels?: InputMaybe<Scalars['Boolean']['input']>;
  bbl?: InputMaybe<Scalars['String']['input']>;
  borough?: InputMaybe<Scalars['String']['input']>;
  buildings?: InputMaybe<BuildingCreateNestedManyWithoutProjectInput>;
  ceqr?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<CityCreateNestedOneWithoutProjectsInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<DocumentCreateNestedManyWithoutProjectInput>;
  geoidHeight?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutProjectsInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  projectType?: InputMaybe<ProjectType>;
  projectUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutProjectInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceCreateNestedOneWithoutProjectInput>;
  publishStatus?: InputMaybe<ProjectPublishStatus>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceRecordId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  altitude?: Maybe<Scalars['Decimal']['output']>;
  appClipSync?: Maybe<Scalars['Boolean']['output']>;
  arcgisFeatureLayerId?: Maybe<Scalars['String']['output']>;
  arcgisSceneLayerId?: Maybe<Scalars['String']['output']>;
  autoGenerateModels?: Maybe<Scalars['Boolean']['output']>;
  bbl?: Maybe<Scalars['String']['output']>;
  borough?: Maybe<Scalars['String']['output']>;
  ceqr?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  projectType?: Maybe<ProjectType>;
  publishStatus?: Maybe<ProjectPublishStatus>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sourceRecordId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProjectStatus>;
  subtitle?: Maybe<Scalars['String']['output']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  altitude?: Maybe<Scalars['Decimal']['output']>;
  appClipSync?: Maybe<Scalars['Boolean']['output']>;
  arcgisFeatureLayerId?: Maybe<Scalars['String']['output']>;
  arcgisSceneLayerId?: Maybe<Scalars['String']['output']>;
  autoGenerateModels?: Maybe<Scalars['Boolean']['output']>;
  bbl?: Maybe<Scalars['String']['output']>;
  borough?: Maybe<Scalars['String']['output']>;
  ceqr?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  projectType?: Maybe<ProjectType>;
  publishStatus?: Maybe<ProjectPublishStatus>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sourceRecordId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProjectStatus>;
  subtitle?: Maybe<Scalars['String']['output']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  altitude?: InputMaybe<SortOrder>;
  appClipSync?: InputMaybe<SortOrder>;
  arcgisFeatureLayerId?: InputMaybe<SortOrder>;
  arcgisSceneLayerId?: InputMaybe<SortOrder>;
  autoGenerateModels?: InputMaybe<SortOrder>;
  bbl?: InputMaybe<SortOrder>;
  borough?: InputMaybe<SortOrder>;
  buildings?: InputMaybe<BuildingOrderByRelationAggregateInput>;
  ceqr?: InputMaybe<SortOrder>;
  city?: InputMaybe<CityOrderByWithRelationInput>;
  cityId?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documents?: InputMaybe<DocumentOrderByRelationAggregateInput>;
  geoidHeight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
  projectType?: InputMaybe<SortOrder>;
  projectUsers?: InputMaybe<ProjectsUsersOrderByRelationAggregateInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceOrderByWithRelationInput>;
  publishStatus?: InputMaybe<SortOrder>;
  publishedAt?: InputMaybe<SortOrder>;
  sourceRecordId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subtitle?: InputMaybe<SortOrder>;
  timelineEvents?: InputMaybe<TimelineEventOrderByRelationAggregateInput>;
  trialExpiresAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProjectPublishStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Published = 'PUBLISHED'
}

export type ProjectRelationFilter = {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
};

export enum ProjectScalarFieldEnum {
  Address = 'address',
  Altitude = 'altitude',
  AppClipSync = 'appClipSync',
  ArcgisFeatureLayerId = 'arcgisFeatureLayerId',
  ArcgisSceneLayerId = 'arcgisSceneLayerId',
  AutoGenerateModels = 'autoGenerateModels',
  Bbl = 'bbl',
  Borough = 'borough',
  Ceqr = 'ceqr',
  CityId = 'cityId',
  CreatedAt = 'createdAt',
  Description = 'description',
  GeoidHeight = 'geoidHeight',
  Id = 'id',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  OrganizationId = 'organizationId',
  Origin = 'origin',
  ProjectType = 'projectType',
  PublishStatus = 'publishStatus',
  PublishedAt = 'publishedAt',
  SourceRecordId = 'sourceRecordId',
  Status = 'status',
  Subtitle = 'subtitle',
  TrialExpiresAt = 'trialExpiresAt',
  UpdatedAt = 'updatedAt'
}

export type ProjectScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  altitude?: InputMaybe<DecimalFilter>;
  appClipSync?: InputMaybe<BoolFilter>;
  arcgisFeatureLayerId?: InputMaybe<StringNullableFilter>;
  arcgisSceneLayerId?: InputMaybe<StringNullableFilter>;
  autoGenerateModels?: InputMaybe<BoolFilter>;
  bbl?: InputMaybe<StringNullableFilter>;
  borough?: InputMaybe<StringNullableFilter>;
  ceqr?: InputMaybe<StringNullableFilter>;
  cityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  geoidHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<StringNullableFilter>;
  projectType?: InputMaybe<EnumProjectTypeFilter>;
  publishStatus?: InputMaybe<EnumProjectPublishStatusNullableFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  sourceRecordId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumProjectStatusFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  trialExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ProjectStatus {
  Approved = 'APPROVED',
  Done = 'DONE',
  Filed = 'FILED',
  Rezoning = 'REZONING',
  UnderConstruction = 'UNDER_CONSTRUCTION',
  Withdrawn = 'WITHDRAWN'
}

export type ProjectSumAggregate = {
  __typename?: 'ProjectSumAggregate';
  altitude?: Maybe<Scalars['Decimal']['output']>;
  geoidHeight?: Maybe<Scalars['Decimal']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export enum ProjectType {
  Alteration = 'ALTERATION',
  Demolition = 'DEMOLITION',
  NewBuilding = 'NEW_BUILDING'
}

export type ProjectUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyWithWhereWithoutCityInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithWhereWithoutOrganizationInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithoutCityNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutCityInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutCityInput>>;
  createMany?: InputMaybe<ProjectCreateManyCityInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutCityInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutCityInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutCityInput>>;
};

export type ProjectUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProjectCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ProjectUpdateOneRequiredWithoutBuildingsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBuildingsInput>;
  create?: InputMaybe<ProjectCreateWithoutBuildingsInput>;
  update?: InputMaybe<ProjectUpdateWithoutBuildingsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutBuildingsInput>;
};

export type ProjectUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ProjectCreateWithoutCommentsInput>;
  update?: InputMaybe<ProjectUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutCommentsInput>;
};

export type ProjectUpdateOneRequiredWithoutDocumentsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutDocumentsInput>;
  create?: InputMaybe<ProjectCreateWithoutDocumentsInput>;
  update?: InputMaybe<ProjectUpdateWithoutDocumentsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutDocumentsInput>;
};

export type ProjectUpdateOneRequiredWithoutProjectUsersNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectUsersInput>;
  create?: InputMaybe<ProjectCreateWithoutProjectUsersInput>;
  update?: InputMaybe<ProjectUpdateWithoutProjectUsersInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectUsersInput>;
};

export type ProjectUpdateOneRequiredWithoutProjectViewPreferenceNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectViewPreferenceInput>;
  create?: InputMaybe<ProjectCreateWithoutProjectViewPreferenceInput>;
  update?: InputMaybe<ProjectUpdateWithoutProjectViewPreferenceInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectViewPreferenceInput>;
};

export type ProjectUpdateOneRequiredWithoutTimelineEventsNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTimelineEventsInput>;
  create?: InputMaybe<ProjectCreateWithoutTimelineEventsInput>;
  update?: InputMaybe<ProjectUpdateWithoutTimelineEventsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutTimelineEventsInput>;
};

export type ProjectUpdateWithWhereUniqueWithoutCityInput = {
  data: ProjectUpdateWithoutCityInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ProjectUpdateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithoutBuildingsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutCityInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutCommentsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutDocumentsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutOrganizationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutProjectUsersInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutProjectViewPreferenceInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutProjectNestedInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutTimelineEventsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  altitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  appClipSync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arcgisFeatureLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  arcgisSceneLayerId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  autoGenerateModels?: InputMaybe<BoolFieldUpdateOperationsInput>;
  bbl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  borough?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buildings?: InputMaybe<BuildingUpdateManyWithoutProjectNestedInput>;
  ceqr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<CityUpdateOneWithoutProjectsNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documents?: InputMaybe<DocumentUpdateManyWithoutProjectNestedInput>;
  geoidHeight?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  longitude?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutProjectsNestedInput>;
  origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectType?: InputMaybe<EnumProjectTypeFieldUpdateOperationsInput>;
  projectUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutProjectNestedInput>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceUpdateOneWithoutProjectNestedInput>;
  publishStatus?: InputMaybe<NullableEnumProjectPublishStatusFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sourceRecordId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trialExpiresAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithWhereUniqueWithoutCityInput = {
  create: ProjectCreateWithoutCityInput;
  update: ProjectUpdateWithoutCityInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ProjectCreateWithoutOrganizationInput;
  update: ProjectUpdateWithoutOrganizationInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithoutBuildingsInput = {
  create: ProjectCreateWithoutBuildingsInput;
  update: ProjectUpdateWithoutBuildingsInput;
};

export type ProjectUpsertWithoutCommentsInput = {
  create: ProjectCreateWithoutCommentsInput;
  update: ProjectUpdateWithoutCommentsInput;
};

export type ProjectUpsertWithoutDocumentsInput = {
  create: ProjectCreateWithoutDocumentsInput;
  update: ProjectUpdateWithoutDocumentsInput;
};

export type ProjectUpsertWithoutProjectUsersInput = {
  create: ProjectCreateWithoutProjectUsersInput;
  update: ProjectUpdateWithoutProjectUsersInput;
};

export type ProjectUpsertWithoutProjectViewPreferenceInput = {
  create: ProjectCreateWithoutProjectViewPreferenceInput;
  update: ProjectUpdateWithoutProjectViewPreferenceInput;
};

export type ProjectUpsertWithoutTimelineEventsInput = {
  create: ProjectCreateWithoutTimelineEventsInput;
  update: ProjectUpdateWithoutTimelineEventsInput;
};

export enum ProjectUserRelationType {
  Access = 'ACCESS',
  Favourite = 'FAVOURITE'
}

export type ProjectViewPreference = {
  __typename?: 'ProjectViewPreference';
  allowPushNotification: Scalars['Boolean']['output'];
  appClipProjectId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAdditionalInfoVisible: Scalars['Boolean']['output'];
  isAnimatedSlider: Scalars['Boolean']['output'];
  isCoverModelGenerated: Scalars['Boolean']['output'];
  isForceARCore: Scalars['Boolean']['output'];
  isOffSiteAccess: Scalars['Boolean']['output'];
  isProjectDetailsOn: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  isSnapToGround: Scalars['Boolean']['output'];
  languageCTA?: Maybe<Scalars['String']['output']>;
  lensGroupUrl?: Maybe<Scalars['String']['output']>;
  linkCTA?: Maybe<Scalars['String']['output']>;
  preferLens: Scalars['Boolean']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useCoverImage: Scalars['Boolean']['output'];
  visibility: ProjectVisibilityType;
};

export type ProjectViewPreferenceCountAggregate = {
  __typename?: 'ProjectViewPreferenceCountAggregate';
  _all: Scalars['Int']['output'];
  allowPushNotification: Scalars['Int']['output'];
  appClipProjectId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isAdditionalInfoVisible: Scalars['Int']['output'];
  isAnimatedSlider: Scalars['Int']['output'];
  isCoverModelGenerated: Scalars['Int']['output'];
  isForceARCore: Scalars['Int']['output'];
  isOffSiteAccess: Scalars['Int']['output'];
  isProjectDetailsOn: Scalars['Int']['output'];
  isPublished: Scalars['Int']['output'];
  isSnapToGround: Scalars['Int']['output'];
  languageCTA: Scalars['Int']['output'];
  lensGroupUrl: Scalars['Int']['output'];
  linkCTA: Scalars['Int']['output'];
  preferLens: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  useCoverImage: Scalars['Int']['output'];
  visibility: Scalars['Int']['output'];
};

export type ProjectViewPreferenceCreateInput = {
  allowPushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  appClipProjectId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdditionalInfoVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isAnimatedSlider?: InputMaybe<Scalars['Boolean']['input']>;
  isCoverModelGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isForceARCore?: InputMaybe<Scalars['Boolean']['input']>;
  isOffSiteAccess?: InputMaybe<Scalars['Boolean']['input']>;
  isProjectDetailsOn?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isSnapToGround?: InputMaybe<Scalars['Boolean']['input']>;
  languageCTA?: InputMaybe<Scalars['String']['input']>;
  lensGroupUrl?: InputMaybe<Scalars['String']['input']>;
  linkCTA?: InputMaybe<Scalars['String']['input']>;
  preferLens?: InputMaybe<Scalars['Boolean']['input']>;
  project: ProjectCreateNestedOneWithoutProjectViewPreferenceInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  useCoverImage?: InputMaybe<Scalars['Boolean']['input']>;
  visibility?: InputMaybe<ProjectVisibilityType>;
};

export type ProjectViewPreferenceCreateNestedOneWithoutProjectInput = {
  connect?: InputMaybe<ProjectViewPreferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectViewPreferenceCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectViewPreferenceCreateWithoutProjectInput>;
};

export type ProjectViewPreferenceCreateOrConnectWithoutProjectInput = {
  create: ProjectViewPreferenceCreateWithoutProjectInput;
  where: ProjectViewPreferenceWhereUniqueInput;
};

export type ProjectViewPreferenceCreateWithoutProjectInput = {
  allowPushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  appClipProjectId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdditionalInfoVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isAnimatedSlider?: InputMaybe<Scalars['Boolean']['input']>;
  isCoverModelGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  isForceARCore?: InputMaybe<Scalars['Boolean']['input']>;
  isOffSiteAccess?: InputMaybe<Scalars['Boolean']['input']>;
  isProjectDetailsOn?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isSnapToGround?: InputMaybe<Scalars['Boolean']['input']>;
  languageCTA?: InputMaybe<Scalars['String']['input']>;
  lensGroupUrl?: InputMaybe<Scalars['String']['input']>;
  linkCTA?: InputMaybe<Scalars['String']['input']>;
  preferLens?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  useCoverImage?: InputMaybe<Scalars['Boolean']['input']>;
  visibility?: InputMaybe<ProjectVisibilityType>;
};

export type ProjectViewPreferenceMaxAggregate = {
  __typename?: 'ProjectViewPreferenceMaxAggregate';
  allowPushNotification?: Maybe<Scalars['Boolean']['output']>;
  appClipProjectId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isAdditionalInfoVisible?: Maybe<Scalars['Boolean']['output']>;
  isAnimatedSlider?: Maybe<Scalars['Boolean']['output']>;
  isCoverModelGenerated?: Maybe<Scalars['Boolean']['output']>;
  isForceARCore?: Maybe<Scalars['Boolean']['output']>;
  isOffSiteAccess?: Maybe<Scalars['Boolean']['output']>;
  isProjectDetailsOn?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  isSnapToGround?: Maybe<Scalars['Boolean']['output']>;
  languageCTA?: Maybe<Scalars['String']['output']>;
  lensGroupUrl?: Maybe<Scalars['String']['output']>;
  linkCTA?: Maybe<Scalars['String']['output']>;
  preferLens?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  useCoverImage?: Maybe<Scalars['Boolean']['output']>;
  visibility?: Maybe<ProjectVisibilityType>;
};

export type ProjectViewPreferenceMinAggregate = {
  __typename?: 'ProjectViewPreferenceMinAggregate';
  allowPushNotification?: Maybe<Scalars['Boolean']['output']>;
  appClipProjectId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isAdditionalInfoVisible?: Maybe<Scalars['Boolean']['output']>;
  isAnimatedSlider?: Maybe<Scalars['Boolean']['output']>;
  isCoverModelGenerated?: Maybe<Scalars['Boolean']['output']>;
  isForceARCore?: Maybe<Scalars['Boolean']['output']>;
  isOffSiteAccess?: Maybe<Scalars['Boolean']['output']>;
  isProjectDetailsOn?: Maybe<Scalars['Boolean']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  isSnapToGround?: Maybe<Scalars['Boolean']['output']>;
  languageCTA?: Maybe<Scalars['String']['output']>;
  lensGroupUrl?: Maybe<Scalars['String']['output']>;
  linkCTA?: Maybe<Scalars['String']['output']>;
  preferLens?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  useCoverImage?: Maybe<Scalars['Boolean']['output']>;
  visibility?: Maybe<ProjectVisibilityType>;
};

export type ProjectViewPreferenceOrderByWithRelationInput = {
  allowPushNotification?: InputMaybe<SortOrder>;
  appClipProjectId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdditionalInfoVisible?: InputMaybe<SortOrder>;
  isAnimatedSlider?: InputMaybe<SortOrder>;
  isCoverModelGenerated?: InputMaybe<SortOrder>;
  isForceARCore?: InputMaybe<SortOrder>;
  isOffSiteAccess?: InputMaybe<SortOrder>;
  isProjectDetailsOn?: InputMaybe<SortOrder>;
  isPublished?: InputMaybe<SortOrder>;
  isSnapToGround?: InputMaybe<SortOrder>;
  languageCTA?: InputMaybe<SortOrder>;
  lensGroupUrl?: InputMaybe<SortOrder>;
  linkCTA?: InputMaybe<SortOrder>;
  preferLens?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  useCoverImage?: InputMaybe<SortOrder>;
  visibility?: InputMaybe<SortOrder>;
};

export type ProjectViewPreferenceRelationFilter = {
  is?: InputMaybe<ProjectViewPreferenceWhereInput>;
  isNot?: InputMaybe<ProjectViewPreferenceWhereInput>;
};

export enum ProjectViewPreferenceScalarFieldEnum {
  AllowPushNotification = 'allowPushNotification',
  AppClipProjectId = 'appClipProjectId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsAdditionalInfoVisible = 'isAdditionalInfoVisible',
  IsAnimatedSlider = 'isAnimatedSlider',
  IsCoverModelGenerated = 'isCoverModelGenerated',
  IsForceArCore = 'isForceARCore',
  IsOffSiteAccess = 'isOffSiteAccess',
  IsProjectDetailsOn = 'isProjectDetailsOn',
  IsPublished = 'isPublished',
  IsSnapToGround = 'isSnapToGround',
  LanguageCta = 'languageCTA',
  LensGroupUrl = 'lensGroupUrl',
  LinkCta = 'linkCTA',
  PreferLens = 'preferLens',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt',
  UseCoverImage = 'useCoverImage',
  Visibility = 'visibility'
}

export type ProjectViewPreferenceUpdateInput = {
  allowPushNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appClipProjectId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdditionalInfoVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isAnimatedSlider?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCoverModelGenerated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isForceARCore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isOffSiteAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isProjectDetailsOn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSnapToGround?: InputMaybe<BoolFieldUpdateOperationsInput>;
  languageCTA?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lensGroupUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkCTA?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferLens?: InputMaybe<BoolFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectViewPreferenceNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useCoverImage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visibility?: InputMaybe<EnumProjectVisibilityTypeFieldUpdateOperationsInput>;
};

export type ProjectViewPreferenceUpdateOneWithoutProjectNestedInput = {
  connect?: InputMaybe<ProjectViewPreferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectViewPreferenceCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectViewPreferenceCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectViewPreferenceUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectViewPreferenceUpsertWithoutProjectInput>;
};

export type ProjectViewPreferenceUpdateWithoutProjectInput = {
  allowPushNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  appClipProjectId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdditionalInfoVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isAnimatedSlider?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCoverModelGenerated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isForceARCore?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isOffSiteAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isProjectDetailsOn?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublished?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isSnapToGround?: InputMaybe<BoolFieldUpdateOperationsInput>;
  languageCTA?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lensGroupUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkCTA?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preferLens?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useCoverImage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visibility?: InputMaybe<EnumProjectVisibilityTypeFieldUpdateOperationsInput>;
};

export type ProjectViewPreferenceUpsertWithoutProjectInput = {
  create: ProjectViewPreferenceCreateWithoutProjectInput;
  update: ProjectViewPreferenceUpdateWithoutProjectInput;
};

export type ProjectViewPreferenceWhereInput = {
  AND?: InputMaybe<Array<ProjectViewPreferenceWhereInput>>;
  NOT?: InputMaybe<Array<ProjectViewPreferenceWhereInput>>;
  OR?: InputMaybe<Array<ProjectViewPreferenceWhereInput>>;
  allowPushNotification?: InputMaybe<BoolFilter>;
  appClipProjectId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdditionalInfoVisible?: InputMaybe<BoolFilter>;
  isAnimatedSlider?: InputMaybe<BoolFilter>;
  isCoverModelGenerated?: InputMaybe<BoolFilter>;
  isForceARCore?: InputMaybe<BoolFilter>;
  isOffSiteAccess?: InputMaybe<BoolFilter>;
  isProjectDetailsOn?: InputMaybe<BoolFilter>;
  isPublished?: InputMaybe<BoolFilter>;
  isSnapToGround?: InputMaybe<BoolFilter>;
  languageCTA?: InputMaybe<StringNullableFilter>;
  lensGroupUrl?: InputMaybe<StringNullableFilter>;
  linkCTA?: InputMaybe<StringNullableFilter>;
  preferLens?: InputMaybe<BoolFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useCoverImage?: InputMaybe<BoolFilter>;
  visibility?: InputMaybe<EnumProjectVisibilityTypeFilter>;
};

export type ProjectViewPreferenceWhereUniqueInput = {
  appClipProjectId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};

export enum ProjectVisibilityType {
  Draft = 'DRAFT',
  LinkOnly = 'LINK_ONLY',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED'
}

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  altitude?: InputMaybe<DecimalFilter>;
  appClipSync?: InputMaybe<BoolFilter>;
  arcgisFeatureLayerId?: InputMaybe<StringNullableFilter>;
  arcgisSceneLayerId?: InputMaybe<StringNullableFilter>;
  autoGenerateModels?: InputMaybe<BoolFilter>;
  bbl?: InputMaybe<StringNullableFilter>;
  borough?: InputMaybe<StringNullableFilter>;
  buildings?: InputMaybe<BuildingListRelationFilter>;
  ceqr?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<CityRelationFilter>;
  cityId?: InputMaybe<StringNullableFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documents?: InputMaybe<DocumentListRelationFilter>;
  geoidHeight?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<StringNullableFilter>;
  projectType?: InputMaybe<EnumProjectTypeFilter>;
  projectUsers?: InputMaybe<ProjectsUsersListRelationFilter>;
  projectViewPreference?: InputMaybe<ProjectViewPreferenceRelationFilter>;
  publishStatus?: InputMaybe<EnumProjectPublishStatusNullableFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  sourceRecordId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumProjectStatusFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  timelineEvents?: InputMaybe<TimelineEventListRelationFilter>;
  trialExpiresAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectsUsers = {
  __typename?: 'ProjectsUsers';
  assignedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  relation: ProjectUserRelationType;
  user: User;
  userId: Scalars['String']['output'];
};

export type ProjectsUsersCountAggregate = {
  __typename?: 'ProjectsUsersCountAggregate';
  _all: Scalars['Int']['output'];
  assignedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  relation: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type ProjectsUsersCreateInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutProjectUsersInput;
  relation?: InputMaybe<ProjectUserRelationType>;
  user: UserCreateNestedOneWithoutProjectsUsersInput;
};

export type ProjectsUsersCreateManyProjectInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<ProjectUserRelationType>;
  userId: Scalars['String']['input'];
};

export type ProjectsUsersCreateManyProjectInputEnvelope = {
  data: Array<ProjectsUsersCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectsUsersCreateManyUserInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  relation?: InputMaybe<ProjectUserRelationType>;
};

export type ProjectsUsersCreateManyUserInputEnvelope = {
  data: Array<ProjectsUsersCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectsUsersCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectsUsersCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<ProjectsUsersCreateWithoutProjectInput>>;
  createMany?: InputMaybe<ProjectsUsersCreateManyProjectInputEnvelope>;
};

export type ProjectsUsersCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectsUsersCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProjectsUsersCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProjectsUsersCreateManyUserInputEnvelope>;
};

export type ProjectsUsersCreateOrConnectWithoutProjectInput = {
  create: ProjectsUsersCreateWithoutProjectInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersCreateOrConnectWithoutUserInput = {
  create: ProjectsUsersCreateWithoutUserInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersCreateWithoutProjectInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<ProjectUserRelationType>;
  user: UserCreateNestedOneWithoutProjectsUsersInput;
};

export type ProjectsUsersCreateWithoutUserInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutProjectUsersInput;
  relation?: InputMaybe<ProjectUserRelationType>;
};

export type ProjectsUsersListRelationFilter = {
  every?: InputMaybe<ProjectsUsersWhereInput>;
  none?: InputMaybe<ProjectsUsersWhereInput>;
  some?: InputMaybe<ProjectsUsersWhereInput>;
};

export type ProjectsUsersMaxAggregate = {
  __typename?: 'ProjectsUsersMaxAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<ProjectUserRelationType>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProjectsUsersMinAggregate = {
  __typename?: 'ProjectsUsersMinAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<ProjectUserRelationType>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProjectsUsersOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectsUsersOrderByWithRelationInput = {
  assignedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  relation?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ProjectsUsersProjectIdUserIdRelationCompoundUniqueInput = {
  projectId: Scalars['String']['input'];
  relation: ProjectUserRelationType;
  userId: Scalars['String']['input'];
};

export enum ProjectsUsersScalarFieldEnum {
  AssignedAt = 'assignedAt',
  Id = 'id',
  ProjectId = 'projectId',
  Relation = 'relation',
  UserId = 'userId'
}

export type ProjectsUsersScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectsUsersScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectsUsersScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectsUsersScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<StringFilter>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ProjectsUsersUpdateInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectUsersNestedInput>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProjectsUsersNestedInput>;
};

export type ProjectsUsersUpdateManyMutationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFieldUpdateOperationsInput>;
};

export type ProjectsUsersUpdateManyWithWhereWithoutProjectInput = {
  data: ProjectsUsersUpdateManyMutationInput;
  where: ProjectsUsersScalarWhereInput;
};

export type ProjectsUsersUpdateManyWithWhereWithoutUserInput = {
  data: ProjectsUsersUpdateManyMutationInput;
  where: ProjectsUsersScalarWhereInput;
};

export type ProjectsUsersUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectsUsersCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<ProjectsUsersCreateWithoutProjectInput>>;
  createMany?: InputMaybe<ProjectsUsersCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectsUsersScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectsUsersUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<ProjectsUsersUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<ProjectsUsersUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type ProjectsUsersUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectsUsersCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ProjectsUsersCreateWithoutUserInput>>;
  createMany?: InputMaybe<ProjectsUsersCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectsUsersScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectsUsersWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectsUsersUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ProjectsUsersUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ProjectsUsersUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ProjectsUsersUpdateWithWhereUniqueWithoutProjectInput = {
  data: ProjectsUsersUpdateWithoutProjectInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersUpdateWithWhereUniqueWithoutUserInput = {
  data: ProjectsUsersUpdateWithoutUserInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersUpdateWithoutProjectInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutProjectsUsersNestedInput>;
};

export type ProjectsUsersUpdateWithoutUserInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectUsersNestedInput>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFieldUpdateOperationsInput>;
};

export type ProjectsUsersUpsertWithWhereUniqueWithoutProjectInput = {
  create: ProjectsUsersCreateWithoutProjectInput;
  update: ProjectsUsersUpdateWithoutProjectInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersUpsertWithWhereUniqueWithoutUserInput = {
  create: ProjectsUsersCreateWithoutUserInput;
  update: ProjectsUsersUpdateWithoutUserInput;
  where: ProjectsUsersWhereUniqueInput;
};

export type ProjectsUsersWhereInput = {
  AND?: InputMaybe<Array<ProjectsUsersWhereInput>>;
  NOT?: InputMaybe<Array<ProjectsUsersWhereInput>>;
  OR?: InputMaybe<Array<ProjectsUsersWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  relation?: InputMaybe<EnumProjectUserRelationTypeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ProjectsUsersWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectId_userId_relation?: InputMaybe<ProjectsUsersProjectIdUserIdRelationCompoundUniqueInput>;
};

export type Query = {
  __typename?: 'Query';
  altitude: AltitudeResult;
  anchoring?: Maybe<Anchoring>;
  anchorings: Array<Anchoring>;
  building?: Maybe<Building>;
  buildings: Array<Building>;
  cities: Array<City>;
  city?: Maybe<City>;
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  coordinate?: Maybe<Coordinate>;
  coordinates: Array<Coordinate>;
  document?: Maybe<Document>;
  documents: Array<Document>;
  existingBuilding?: Maybe<ExistingBuilding>;
  existingBuildings: Array<ExistingBuilding>;
  floor?: Maybe<Floor>;
  floors: Array<Floor>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  model3D?: Maybe<Model3D>;
  model3Ds: Array<Model3D>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  organizationsUsers?: Maybe<OrganizationsUsers>;
  organizationsUserss: Array<OrganizationsUsers>;
  project?: Maybe<Project>;
  projectViewPreference?: Maybe<ProjectViewPreference>;
  projectViewPreferences: Array<ProjectViewPreference>;
  projects: Array<Project>;
  projectsBatchCount: ProjectBatchCount;
  projectsUsers?: Maybe<ProjectsUsers>;
  projectsUserss: Array<ProjectsUsers>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlans: Array<SubscriptionPlan>;
  timelineEvent?: Maybe<TimelineEvent>;
  timelineEventType?: Maybe<TimelineEventType>;
  timelineEventTypes: Array<TimelineEventType>;
  timelineEvents: Array<TimelineEvent>;
  user?: Maybe<User>;
  userDevice?: Maybe<UserDevice>;
  userDevices: Array<UserDevice>;
  users: Array<User>;
};


export type QueryAltitudeArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type QueryAnchoringArgs = {
  where: AnchoringWhereUniqueInput;
};


export type QueryAnchoringsArgs = {
  cursor?: InputMaybe<AnchoringWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnchoringScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnchoringOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AnchoringWhereInput>;
};


export type QueryBuildingArgs = {
  where: BuildingWhereUniqueInput;
};


export type QueryBuildingsArgs = {
  cursor?: InputMaybe<BuildingWhereUniqueInput>;
  distinct?: InputMaybe<Array<BuildingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BuildingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BuildingWhereInput>;
};


export type QueryCitiesArgs = {
  cursor?: InputMaybe<CityWhereUniqueInput>;
  distinct?: InputMaybe<Array<CityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CityWhereInput>;
};


export type QueryCityArgs = {
  where: CityWhereUniqueInput;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryCoordinateArgs = {
  where: CoordinateWhereUniqueInput;
};


export type QueryCoordinatesArgs = {
  cursor?: InputMaybe<CoordinateWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoordinateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoordinateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoordinateWhereInput>;
};


export type QueryDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type QueryDocumentsArgs = {
  cursor?: InputMaybe<DocumentWhereUniqueInput>;
  distinct?: InputMaybe<Array<DocumentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DocumentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type QueryExistingBuildingArgs = {
  where: ExistingBuildingWhereUniqueInput;
};


export type QueryExistingBuildingsArgs = {
  cursor?: InputMaybe<ExistingBuildingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExistingBuildingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExistingBuildingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExistingBuildingWhereInput>;
};


export type QueryFloorArgs = {
  where: FloorWhereUniqueInput;
};


export type QueryFloorsArgs = {
  cursor?: InputMaybe<FloorWhereUniqueInput>;
  distinct?: InputMaybe<Array<FloorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FloorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FloorWhereInput>;
};


export type QueryLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type QueryLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  distinct?: InputMaybe<Array<LocationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LocationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryModel3DArgs = {
  where: Model3DWhereUniqueInput;
};


export type QueryModel3DsArgs = {
  cursor?: InputMaybe<Model3DWhereUniqueInput>;
  distinct?: InputMaybe<Array<Model3DScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Model3DOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Model3DWhereInput>;
};


export type QueryOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryOrganizationsUsersArgs = {
  where: OrganizationsUsersWhereUniqueInput;
};


export type QueryOrganizationsUserssArgs = {
  cursor?: InputMaybe<OrganizationsUsersWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationsUsersScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationsUsersOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationsUsersWhereInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectViewPreferenceArgs = {
  where: ProjectViewPreferenceWhereUniqueInput;
};


export type QueryProjectViewPreferencesArgs = {
  cursor?: InputMaybe<ProjectViewPreferenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectViewPreferenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectViewPreferenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectViewPreferenceWhereInput>;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryProjectsBatchCountArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryProjectsUsersArgs = {
  where: ProjectsUsersWhereUniqueInput;
};


export type QueryProjectsUserssArgs = {
  cursor?: InputMaybe<ProjectsUsersWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectsUsersScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectsUsersOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectsUsersWhereInput>;
};


export type QuerySubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type QuerySubscriptionPlansArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryTimelineEventArgs = {
  where: TimelineEventWhereUniqueInput;
};


export type QueryTimelineEventTypeArgs = {
  where: TimelineEventTypeWhereUniqueInput;
};


export type QueryTimelineEventTypesArgs = {
  cursor?: InputMaybe<TimelineEventTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<TimelineEventTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TimelineEventTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TimelineEventTypeWhereInput>;
};


export type QueryTimelineEventsArgs = {
  cursor?: InputMaybe<TimelineEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TimelineEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TimelineEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TimelineEventWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserDeviceArgs = {
  where: UserDeviceWhereUniqueInput;
};


export type QueryUserDevicesArgs = {
  cursor?: InputMaybe<UserDeviceWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserDeviceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserDeviceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserDeviceWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Role {
  Admin = 'ADMIN',
  CityOfficial = 'CITY_OFFICIAL',
  Client = 'CLIENT',
  RealEstateDeveloper = 'REAL_ESTATE_DEVELOPER',
  User = 'USER'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  trial: Scalars['Boolean']['output'];
  type?: Maybe<SubscriptionType>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
  users: User;
};

export type SubscriptionPlanCountAggregate = {
  __typename?: 'SubscriptionPlanCountAggregate';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  stripeCustomerId: Scalars['Int']['output'];
  stripeSubscriptionId: Scalars['Int']['output'];
  trial: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type SubscriptionPlanCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId: Scalars['String']['input'];
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<SubscriptionType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutSubscriptionPlanInput;
};

export type SubscriptionPlanCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutUserInput>;
};

export type SubscriptionPlanCreateOrConnectWithoutUserInput = {
  create: SubscriptionPlanCreateWithoutUserInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateWithoutUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId: Scalars['String']['input'];
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<SubscriptionType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionPlanMaxAggregate = {
  __typename?: 'SubscriptionPlanMaxAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  trial?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<SubscriptionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanMinAggregate = {
  __typename?: 'SubscriptionPlanMinAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  trial?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<SubscriptionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
  stripeSubscriptionId?: InputMaybe<SortOrder>;
  trial?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanRelationFilter = {
  is?: InputMaybe<SubscriptionPlanWhereInput>;
  isNot?: InputMaybe<SubscriptionPlanWhereInput>;
};

export enum SubscriptionPlanScalarFieldEnum {
  Active = 'active',
  CreatedAt = 'createdAt',
  Id = 'id',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  Trial = 'trial',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type SubscriptionPlanUpdateInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripeSubscriptionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSubscriptionPlanNestedInput>;
};

export type SubscriptionPlanUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SubscriptionPlanUpdateWithoutUserInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutUserInput>;
};

export type SubscriptionPlanUpdateWithoutUserInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripeCustomerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripeSubscriptionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpsertWithoutUserInput = {
  create: SubscriptionPlanCreateWithoutUserInput;
  update: SubscriptionPlanUpdateWithoutUserInput;
};

export type SubscriptionPlanWhereInput = {
  AND?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  stripeCustomerId?: InputMaybe<StringFilter>;
  stripeSubscriptionId?: InputMaybe<StringNullableFilter>;
  trial?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumSubscriptionTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SubscriptionPlanWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum SubscriptionType {
  Basic = 'BASIC',
  Enterprise = 'ENTERPRISE',
  Pro = 'PRO'
}

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  timelineEventType: TimelineEventType;
  timelineEventTypeId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TimelineEventCountAggregate = {
  __typename?: 'TimelineEventCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  timelineEventTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TimelineEventCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  project: ProjectCreateNestedOneWithoutTimelineEventsInput;
  timelineEventType: TimelineEventTypeCreateNestedOneWithoutTimelineEventsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventCreateManyProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  timelineEventTypeId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventCreateManyProjectInputEnvelope = {
  data: Array<TimelineEventCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimelineEventCreateManyTimelineEventTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventCreateManyTimelineEventTypeInputEnvelope = {
  data: Array<TimelineEventCreateManyTimelineEventTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimelineEventCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimelineEventCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<TimelineEventCreateWithoutProjectInput>>;
  createMany?: InputMaybe<TimelineEventCreateManyProjectInputEnvelope>;
};

export type TimelineEventCreateNestedManyWithoutTimelineEventTypeInput = {
  connect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimelineEventCreateOrConnectWithoutTimelineEventTypeInput>>;
  create?: InputMaybe<Array<TimelineEventCreateWithoutTimelineEventTypeInput>>;
  createMany?: InputMaybe<TimelineEventCreateManyTimelineEventTypeInputEnvelope>;
};

export type TimelineEventCreateOrConnectWithoutProjectInput = {
  create: TimelineEventCreateWithoutProjectInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventCreateOrConnectWithoutTimelineEventTypeInput = {
  create: TimelineEventCreateWithoutTimelineEventTypeInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventCreateWithoutProjectInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  timelineEventType: TimelineEventTypeCreateNestedOneWithoutTimelineEventsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventCreateWithoutTimelineEventTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  project: ProjectCreateNestedOneWithoutTimelineEventsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventListRelationFilter = {
  every?: InputMaybe<TimelineEventWhereInput>;
  none?: InputMaybe<TimelineEventWhereInput>;
  some?: InputMaybe<TimelineEventWhereInput>;
};

export type TimelineEventMaxAggregate = {
  __typename?: 'TimelineEventMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  timelineEventTypeId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimelineEventMinAggregate = {
  __typename?: 'TimelineEventMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  timelineEventTypeId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimelineEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TimelineEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  timelineEventType?: InputMaybe<TimelineEventTypeOrderByWithRelationInput>;
  timelineEventTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TimelineEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ProjectId = 'projectId',
  TimelineEventTypeId = 'timelineEventTypeId',
  UpdatedAt = 'updatedAt'
}

export type TimelineEventScalarWhereInput = {
  AND?: InputMaybe<Array<TimelineEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<TimelineEventScalarWhereInput>>;
  OR?: InputMaybe<Array<TimelineEventScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<StringFilter>;
  timelineEventTypeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimelineEventType = {
  __typename?: 'TimelineEventType';
  _count: TimelineEventTypeCount;
  createdAt: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  timelineEvents?: Maybe<Array<TimelineEvent>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TimelineEventTypeCount = {
  __typename?: 'TimelineEventTypeCount';
  timelineEvents: Scalars['Int']['output'];
};

export type TimelineEventTypeCountAggregate = {
  __typename?: 'TimelineEventTypeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  icon: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TimelineEventTypeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  timelineEvents?: InputMaybe<TimelineEventCreateNestedManyWithoutTimelineEventTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventTypeCreateNestedOneWithoutTimelineEventsInput = {
  connect?: InputMaybe<TimelineEventTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimelineEventTypeCreateOrConnectWithoutTimelineEventsInput>;
  create?: InputMaybe<TimelineEventTypeCreateWithoutTimelineEventsInput>;
};

export type TimelineEventTypeCreateOrConnectWithoutTimelineEventsInput = {
  create: TimelineEventTypeCreateWithoutTimelineEventsInput;
  where: TimelineEventTypeWhereUniqueInput;
};

export type TimelineEventTypeCreateWithoutTimelineEventsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimelineEventTypeMaxAggregate = {
  __typename?: 'TimelineEventTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimelineEventTypeMinAggregate = {
  __typename?: 'TimelineEventTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimelineEventTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  timelineEvents?: InputMaybe<TimelineEventOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TimelineEventTypeRelationFilter = {
  is?: InputMaybe<TimelineEventTypeWhereInput>;
  isNot?: InputMaybe<TimelineEventTypeWhereInput>;
};

export enum TimelineEventTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Icon = 'icon',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type TimelineEventTypeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  icon?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  timelineEvents?: InputMaybe<TimelineEventUpdateManyWithoutTimelineEventTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventTypeUpdateOneRequiredWithoutTimelineEventsNestedInput = {
  connect?: InputMaybe<TimelineEventTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimelineEventTypeCreateOrConnectWithoutTimelineEventsInput>;
  create?: InputMaybe<TimelineEventTypeCreateWithoutTimelineEventsInput>;
  update?: InputMaybe<TimelineEventTypeUpdateWithoutTimelineEventsInput>;
  upsert?: InputMaybe<TimelineEventTypeUpsertWithoutTimelineEventsInput>;
};

export type TimelineEventTypeUpdateWithoutTimelineEventsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  icon?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventTypeUpsertWithoutTimelineEventsInput = {
  create: TimelineEventTypeCreateWithoutTimelineEventsInput;
  update: TimelineEventTypeUpdateWithoutTimelineEventsInput;
};

export type TimelineEventTypeWhereInput = {
  AND?: InputMaybe<Array<TimelineEventTypeWhereInput>>;
  NOT?: InputMaybe<Array<TimelineEventTypeWhereInput>>;
  OR?: InputMaybe<Array<TimelineEventTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  icon?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  timelineEvents?: InputMaybe<TimelineEventListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimelineEventTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type TimelineEventUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutTimelineEventsNestedInput>;
  timelineEventType?: InputMaybe<TimelineEventTypeUpdateOneRequiredWithoutTimelineEventsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventUpdateManyWithWhereWithoutProjectInput = {
  data: TimelineEventUpdateManyMutationInput;
  where: TimelineEventScalarWhereInput;
};

export type TimelineEventUpdateManyWithWhereWithoutTimelineEventTypeInput = {
  data: TimelineEventUpdateManyMutationInput;
  where: TimelineEventScalarWhereInput;
};

export type TimelineEventUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimelineEventCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<TimelineEventCreateWithoutProjectInput>>;
  createMany?: InputMaybe<TimelineEventCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimelineEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  set?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  update?: InputMaybe<Array<TimelineEventUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<TimelineEventUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<TimelineEventUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type TimelineEventUpdateManyWithoutTimelineEventTypeNestedInput = {
  connect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimelineEventCreateOrConnectWithoutTimelineEventTypeInput>>;
  create?: InputMaybe<Array<TimelineEventCreateWithoutTimelineEventTypeInput>>;
  createMany?: InputMaybe<TimelineEventCreateManyTimelineEventTypeInputEnvelope>;
  delete?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimelineEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  set?: InputMaybe<Array<TimelineEventWhereUniqueInput>>;
  update?: InputMaybe<Array<TimelineEventUpdateWithWhereUniqueWithoutTimelineEventTypeInput>>;
  updateMany?: InputMaybe<Array<TimelineEventUpdateManyWithWhereWithoutTimelineEventTypeInput>>;
  upsert?: InputMaybe<Array<TimelineEventUpsertWithWhereUniqueWithoutTimelineEventTypeInput>>;
};

export type TimelineEventUpdateWithWhereUniqueWithoutProjectInput = {
  data: TimelineEventUpdateWithoutProjectInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventUpdateWithWhereUniqueWithoutTimelineEventTypeInput = {
  data: TimelineEventUpdateWithoutTimelineEventTypeInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventUpdateWithoutProjectInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  timelineEventType?: InputMaybe<TimelineEventTypeUpdateOneRequiredWithoutTimelineEventsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventUpdateWithoutTimelineEventTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutTimelineEventsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimelineEventUpsertWithWhereUniqueWithoutProjectInput = {
  create: TimelineEventCreateWithoutProjectInput;
  update: TimelineEventUpdateWithoutProjectInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventUpsertWithWhereUniqueWithoutTimelineEventTypeInput = {
  create: TimelineEventCreateWithoutTimelineEventTypeInput;
  update: TimelineEventUpdateWithoutTimelineEventTypeInput;
  where: TimelineEventWhereUniqueInput;
};

export type TimelineEventWhereInput = {
  AND?: InputMaybe<Array<TimelineEventWhereInput>>;
  NOT?: InputMaybe<Array<TimelineEventWhereInput>>;
  OR?: InputMaybe<Array<TimelineEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  projectId?: InputMaybe<StringFilter>;
  timelineEventType?: InputMaybe<TimelineEventTypeRelationFilter>;
  timelineEventTypeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimelineEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _count: UserCount;
  comments?: Maybe<Array<Comment>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  locations?: Maybe<Array<Location>>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  organizationUsers?: Maybe<Array<OrganizationsUsers>>;
  projectsUsers?: Maybe<Array<ProjectsUsers>>;
  role: Role;
  status: UserStatus;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  updatedAt: Scalars['DateTime']['output'];
  userDevices?: Maybe<Array<UserDevice>>;
};

export type UserCount = {
  __typename?: 'UserCount';
  comments: Scalars['Int']['output'];
  locations: Scalars['Int']['output'];
  organizationUsers: Scalars['Int']['output'];
  projectsUsers: Scalars['Int']['output'];
  userDevices: Scalars['Int']['output'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  externalId: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  metadata: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserCreateInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutLocationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<UserCreateWithoutLocationsInput>;
};

export type UserCreateNestedOneWithoutOrganizationUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrganizationUsersInput>;
  create?: InputMaybe<UserCreateWithoutOrganizationUsersInput>;
};

export type UserCreateNestedOneWithoutProjectsUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProjectsUsersInput>;
  create?: InputMaybe<UserCreateWithoutProjectsUsersInput>;
};

export type UserCreateNestedOneWithoutSubscriptionPlanInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubscriptionPlanInput>;
  create?: InputMaybe<UserCreateWithoutSubscriptionPlanInput>;
};

export type UserCreateNestedOneWithoutUserDevicesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserDevicesInput>;
  create?: InputMaybe<UserCreateWithoutUserDevicesInput>;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLocationsInput = {
  create: UserCreateWithoutLocationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrganizationUsersInput = {
  create: UserCreateWithoutOrganizationUsersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProjectsUsersInput = {
  create: UserCreateWithoutProjectsUsersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSubscriptionPlanInput = {
  create: UserCreateWithoutSubscriptionPlanInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserDevicesInput = {
  create: UserCreateWithoutUserDevicesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCommentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutLocationsInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrganizationUsersInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutProjectsUsersInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSubscriptionPlanInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userDevices?: InputMaybe<UserDeviceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserDevicesInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  locations?: InputMaybe<LocationCreateNestedManyWithoutUserInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersCreateNestedManyWithoutUserInput>;
  projectsUsers?: InputMaybe<ProjectsUsersCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  status: UserStatus;
  subscriptionPlan?: InputMaybe<SubscriptionPlanCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserDevice = {
  __typename?: 'UserDevice';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  platform: DevicePlatform;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserDeviceCountAggregate = {
  __typename?: 'UserDeviceCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  platform: Scalars['Int']['output'];
  token: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserDeviceCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform: DevicePlatform;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutUserDevicesInput;
};

export type UserDeviceCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform: DevicePlatform;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserDeviceCreateManyUserInputEnvelope = {
  data: Array<UserDeviceCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserDeviceCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserDeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserDeviceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserDeviceCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserDeviceCreateManyUserInputEnvelope>;
};

export type UserDeviceCreateOrConnectWithoutUserInput = {
  create: UserDeviceCreateWithoutUserInput;
  where: UserDeviceWhereUniqueInput;
};

export type UserDeviceCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  platform: DevicePlatform;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserDeviceListRelationFilter = {
  every?: InputMaybe<UserDeviceWhereInput>;
  none?: InputMaybe<UserDeviceWhereInput>;
  some?: InputMaybe<UserDeviceWhereInput>;
};

export type UserDeviceMaxAggregate = {
  __typename?: 'UserDeviceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<DevicePlatform>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserDeviceMinAggregate = {
  __typename?: 'UserDeviceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<DevicePlatform>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserDeviceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserDeviceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserDeviceScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Platform = 'platform',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserDeviceScalarWhereInput = {
  AND?: InputMaybe<Array<UserDeviceScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserDeviceScalarWhereInput>>;
  OR?: InputMaybe<Array<UserDeviceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  platform?: InputMaybe<EnumDevicePlatformFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserDeviceUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<EnumDevicePlatformFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserDevicesNestedInput>;
};

export type UserDeviceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<EnumDevicePlatformFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserDeviceUpdateManyWithWhereWithoutUserInput = {
  data: UserDeviceUpdateManyMutationInput;
  where: UserDeviceScalarWhereInput;
};

export type UserDeviceUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserDeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserDeviceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserDeviceCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserDeviceCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserDeviceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserDeviceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserDeviceWhereUniqueInput>>;
  set?: InputMaybe<Array<UserDeviceWhereUniqueInput>>;
  update?: InputMaybe<Array<UserDeviceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserDeviceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserDeviceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserDeviceUpdateWithWhereUniqueWithoutUserInput = {
  data: UserDeviceUpdateWithoutUserInput;
  where: UserDeviceWhereUniqueInput;
};

export type UserDeviceUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<EnumDevicePlatformFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserDeviceUpsertWithWhereUniqueWithoutUserInput = {
  create: UserDeviceCreateWithoutUserInput;
  update: UserDeviceUpdateWithoutUserInput;
  where: UserDeviceWhereUniqueInput;
};

export type UserDeviceUserIdPlatformTokenCompoundUniqueInput = {
  platform: DevicePlatform;
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserDeviceWhereInput = {
  AND?: InputMaybe<Array<UserDeviceWhereInput>>;
  NOT?: InputMaybe<Array<UserDeviceWhereInput>>;
  OR?: InputMaybe<Array<UserDeviceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  platform?: InputMaybe<EnumDevicePlatformFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserDeviceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_platform_token?: InputMaybe<UserDeviceUserIdPlatformTokenCompoundUniqueInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserOrderByWithRelationInput = {
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  locations?: InputMaybe<LocationOrderByRelationAggregateInput>;
  metadata?: InputMaybe<SortOrder>;
  organizationUsers?: InputMaybe<OrganizationsUsersOrderByRelationAggregateInput>;
  projectsUsers?: InputMaybe<ProjectsUsersOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userDevices?: InputMaybe<UserDeviceOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Email = 'email',
  ExternalId = 'externalId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Metadata = 'metadata',
  Role = 'role',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  PendingDeletion = 'PENDING_DELETION'
}

export type UserUpdateInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneRequiredWithoutLocationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLocationsInput>;
  create?: InputMaybe<UserCreateWithoutLocationsInput>;
  update?: InputMaybe<UserUpdateWithoutLocationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutLocationsInput>;
};

export type UserUpdateOneRequiredWithoutOrganizationUsersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrganizationUsersInput>;
  create?: InputMaybe<UserCreateWithoutOrganizationUsersInput>;
  update?: InputMaybe<UserUpdateWithoutOrganizationUsersInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrganizationUsersInput>;
};

export type UserUpdateOneRequiredWithoutProjectsUsersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProjectsUsersInput>;
  create?: InputMaybe<UserCreateWithoutProjectsUsersInput>;
  update?: InputMaybe<UserUpdateWithoutProjectsUsersInput>;
  upsert?: InputMaybe<UserUpsertWithoutProjectsUsersInput>;
};

export type UserUpdateOneRequiredWithoutSubscriptionPlanNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSubscriptionPlanInput>;
  create?: InputMaybe<UserCreateWithoutSubscriptionPlanInput>;
  update?: InputMaybe<UserUpdateWithoutSubscriptionPlanInput>;
  upsert?: InputMaybe<UserUpsertWithoutSubscriptionPlanInput>;
};

export type UserUpdateOneRequiredWithoutUserDevicesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserDevicesInput>;
  create?: InputMaybe<UserCreateWithoutUserDevicesInput>;
  update?: InputMaybe<UserUpdateWithoutUserDevicesInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserDevicesInput>;
};

export type UserUpdateWithoutCommentsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutLocationsInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutOrganizationUsersInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutProjectsUsersInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutSubscriptionPlanInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userDevices?: InputMaybe<UserDeviceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserDevicesInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutUserNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  locations?: InputMaybe<LocationUpdateManyWithoutUserNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  organizationUsers?: InputMaybe<OrganizationsUsersUpdateManyWithoutUserNestedInput>;
  projectsUsers?: InputMaybe<ProjectsUsersUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutLocationsInput = {
  create: UserCreateWithoutLocationsInput;
  update: UserUpdateWithoutLocationsInput;
};

export type UserUpsertWithoutOrganizationUsersInput = {
  create: UserCreateWithoutOrganizationUsersInput;
  update: UserUpdateWithoutOrganizationUsersInput;
};

export type UserUpsertWithoutProjectsUsersInput = {
  create: UserCreateWithoutProjectsUsersInput;
  update: UserUpdateWithoutProjectsUsersInput;
};

export type UserUpsertWithoutSubscriptionPlanInput = {
  create: UserCreateWithoutSubscriptionPlanInput;
  update: UserUpdateWithoutSubscriptionPlanInput;
};

export type UserUpsertWithoutUserDevicesInput = {
  create: UserCreateWithoutUserDevicesInput;
  update: UserUpdateWithoutUserDevicesInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  locations?: InputMaybe<LocationListRelationFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  organizationUsers?: InputMaybe<OrganizationsUsersListRelationFilter>;
  projectsUsers?: InputMaybe<ProjectsUsersListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  subscriptionPlan?: InputMaybe<SubscriptionPlanRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userDevices?: InputMaybe<UserDeviceListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};
