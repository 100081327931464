// RegistrationProgressContext.tsx
import React, {createContext, useContext, useState, useEffect} from 'react'
import {CustomLayoutProps} from '../components/Layouts/CustomLayout'

// Define the context shape
interface RegistrationProgressContextType {
  progress: number
  setProgress: (value: number) => void
}

// Create the context
const RegistrationProgressContext = createContext<RegistrationProgressContextType | undefined>(undefined)

// Provider Component
export const RegistrationProgressProvider: React.FC<CustomLayoutProps> = ({ children }) => {
  const [progress, setProgress] = useState<number>(() => {
    const savedProgress = localStorage.getItem('registrationProgress')
    return savedProgress ? parseInt(savedProgress, 10) : 0
  })

  useEffect(() => {
    localStorage.setItem('registrationProgress', progress.toString())
  }, [progress])

  return (
    <RegistrationProgressContext.Provider value={{ progress, setProgress }}>
      {children}
    </RegistrationProgressContext.Provider>
  )
}

export const useRegistrationProgress = () => {
  const context = useContext(RegistrationProgressContext)
  if (!context) {
    throw new Error('useRegistrationProgress must be used within a RegistrationProgressProvider')
  }
  return context
}
