import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@pankod/refine-antd'
import { useRegistrationProgress } from '../../hooks/useRegistrationProgressBar'
import landingPage from '../../assets/landing-page-img.jpg'
import incituProLogo from '../../assets/incitu-pro-logo.png'
import {useNavigation} from '@pankod/refine-core'
import { authProvider } from '../../authPrvider/authProvider'
import useAuth0Authorization from '../../hooks/auth0/useAuth0Authorization';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import { useAuth0 } from '@auth0/auth0-react';
import { delay } from '../../utils/util';
import HintPopover from './HintPopover/HintPopover';

import './style.less';
import { INCITU_PRO, TALK_TO_US } from '../../constants/links';
import { useUpdateUserMetadata} from '../../api/user';

import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import {
  isUserNeverHadSubscription, isUserPro,
} from '../../utils/subscription';
import { callSignInNativeAndroidApp, checkIfAndroidInterfaceAvailable } from '../../utils/checkMobile';
import { checkSubscription, handleEntryPoint, refetchUser, updateEntryPoints } from '../../utils/registration';

const LandingPage = (): JSX.Element => {
  window.addEventListener('message', function (event) {
    if (event.data.type === 'signupForNewsletterSubscription') {
      console.log('signupForNewsletterSubscription___:', event.data);
      const isSubscribed = event.data.subscribed;
      authProvider.saveSignUpForNewsLetter(isSubscribed);
      console.log('User is subscribed to the newsletter:', isSubscribed);

      // todo save entry_point
      console.log('window.location:', window.location);
      console.log('window.location.href:', window.location.href);
      authProvider.saveUserEntryPoint(window.location.href);
      // authProvider.saveSignUpForNewsLetter(isSubscribed);
    }
  });

  const isAndroidInterfaceAvailable = checkIfAndroidInterfaceAvailable()
  const { updateUserMetadata, isLoading, error, data } = useUpdateUserMetadata()
  const {
    user: userAuth0,
    isAuthenticated,
  } = useAuth0();
  const { setProgress } = useRegistrationProgress()
  const { performAuthorization } = useAuth0Authorization()
  const { push } = useNavigation()
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [authType, setAuthType] = useState('login')
  const [fetchUserInfo] = useUserInfoFetch();
  const [isLoadedAuth0UserData, setIsLoadedAuth0UserData] = useState(false)
  const [countHintPopover, setCountHintPopover] = useState(0)
  const [showHintPopoverResult, setShowHintPopoverResult] = useState(true)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const eaCode = queryParams.get('ea');
    if (eaCode) {
      authProvider.saveEACode(eaCode);
    }
  }, []);

  useEffect(() => {
    setProgress(0)
  }, [])

  const handleUserRedirect = async (userAuth0: any) => {
    if (!userAuth0 || !userAuth0.sub) {
      push('/email-verification');
      return;
    }

    const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
    // const fetchedUser = await refetchUser(fetchUserInfo, userAuth0)
    // console.log('>>> fetchedUser:', fetchedUser);
    //
    // authProvider.saveInCituUser(fetchedUser);
    authProvider.saveInCituUser(fetchedUser.data);
    const incituUser = authProvider.getInCituUser();
    const entryPoint = incituUser?.metadata?.entryPoint || authProvider.getUserEntryPoint();

    if (!entryPoint) {
      console.log('get from local db entryPoint:', entryPoint);
    }

    // TODO: onboarding step
    const redirectURL = handleEntryPoint(userAuth0, incituUser, entryPoint);
    console.log('redirectURL:', redirectURL);
    if(redirectURL !== undefined) {
      push(redirectURL);
    }
  };

  useEffect(() => {
    const checkEmailVerification = async () => {
      console.log('Rechecking authentication...userAuth0:', userAuth0);
      console.log('Rechecking authentication...isAuthenticated:', isAuthenticated);
      console.log('Rechecking authentication...isLoadedAuth0UserData:', isLoadedAuth0UserData);
      if (userAuth0 !== undefined && !isLoadedAuth0UserData){
        setIsLoadingUser(true);
        await delay(5000)
        const isSubscriptionExists = await checkSubscription(fetchUserInfo, updateUserMetadata, userAuth0)
        await updateEntryPoints(fetchUserInfo, updateUserMetadata, String(userAuth0.sub))
        // setIsLoadingUser(false);

        if(isSubscriptionExists) {
          push('/projects')
        } else {
          await handleUserRedirect(userAuth0);
        }
        setIsLoadedAuth0UserData(true);
      }
    };

    checkEmailVerification();
  }, [userAuth0]);

  // HERE
  const goLogin = async (type: string) => {
    setAuthType(type)
    window.localStorage.removeItem('showInfoAboutErrorLogin')
    await performAuthorization()
  }

  const popoverContent = useMemo(() => {
    return (
      <div>
        <h1 className='popup-popover-title'>
          Turn off pop-up blocker
        </h1>
        <div className='popup-popover-description'>
          For a simple signup experience, turn off pop-up blocker
        </div>
      </div>
    )
  }, [])
  
  const showHintPopover = async () => {
    if(authProvider.getShowHintPopover() === 'false'){
      return
    }

    await delay(1000)
    const updatedCount = countHintPopover + 1;
    setCountHintPopover(updatedCount)
    if (updatedCount > 1) {

      authProvider.saveShowHintPopover('false')
      setShowHintPopoverResult(false)
    }
  }

  const handleLoginClick = () => {
    if (isAndroidInterfaceAvailable) {
      callSignInNativeAndroidApp();
    } else {
      goLogin('login');
    }
  };

  const LoginButton = () => (
    <Button
      type="primary"
      loading={isLoadingUser && authType === 'login'}
      onMouseEnter={()=>showHintPopover()}
      className="sing-in-single-button"
      onClick={() => {handleLoginClick()}}
    >
      Login
    </Button>
  );
  
  return (
    <div className="main-div-landing-page">
      <div className="sub-main-div-landing-page">
        {/*<div className="title">Sign up for</div>*/}
        <img src={incituProLogo} alt="incitu pro logo" className="img-incitu" />
        <div className="sub-div">
          <div className="sub-title">
            Share your 3D content with the world in only a few steps!
          </div>
          <br />
          <br />
          <div className="main-text-bold">Get your first project for free</div>
          <div className="main-text">
            $200/month for unlimited number of projects
          </div>
        </div>
        <div className="button-group-sing">
          {!showHintPopoverResult ? (
            <LoginButton />
          ) : (
            <HintPopover content={popoverContent}>
              <LoginButton />
            </HintPopover>
          )}
        </div>
        <div>
          <div className="pricing-text">
            <a
              href={INCITU_PRO}
              target="_blank"
              style={{ textDecoration: 'underline', color: 'black' }}
            >
              Pricing information
            </a>
          </div>
          <div className="main-text">
            Not a 3D person?{' '}
            <a
              href={TALK_TO_US}
              target="_blank"
              style={{ textDecoration: 'underline', color: 'black' }}
            >
              {' '}
              Talk to us
            </a>
          </div>
        </div>
      </div>
      <img src={landingPage} className="img-landing-page" alt="Landing" />
    </div>
  );
}

export default LandingPage;
