import { useCreate, useUpdate } from '@pankod/refine-core';
import { Project as ProjectInstance } from '../dataProvider/gql/types';
import useUpdateCreateOrganizationMutation from './useUpdateCreateOrganizationMutation';
import useUploadDocuments from './useUpdateDocuments';
import useUploadCover from './useUpdateCover';
import { ProjectValues } from '../interfaces/project';
import useAppClip from './useAppClip';
import { useSaveBuilding } from './useSaveBuilding';
import { cloneDeep } from 'lodash';
import { AssetCategory, AWSDocumentNames } from '../enums';
import { ModelFormat } from '../constants/model';
import { modelToImage } from '../components/ProjectGridListings/components/modelToImage';
import useUploadPreview from './useUpdatePreview';
import { authProvider } from '../authPrvider/authProvider';

// @ts-ignore
let resultedProjectId = null;

export default function useProjectSave(id: any, projectInfo: any = {}) {
  const { mutate: updateMutate, status: updateStatus, error: updateError } =
    useUpdate<ProjectInstance>();
  const { mutate: createMutate, status: createStatus, error: createError } =
    useCreate<ProjectInstance>();
  const [saveCover] = useUploadCover();
  const [saveBuilding] = useSaveBuilding();
  const [saveAppClip] = useAppClip();
  const [updateDocumentsUrls] = useUploadDocuments();

  const handleSuccess = async (
    data: any,
    values: any,
    buildingsList: any,
    cover: any,
    clients: any,
    rotation?: number,
    isUpdate?: boolean,
    isCoverExist?: boolean,
  ) => {
    resultedProjectId = data.data.id;

    await saveBuilding(data, buildingsList, rotation);
    await updateDocumentsUrls({
      projectInfo: data.data,
      values,
    });

    await saveCover(data.data, cover, isCoverExist, isUpdate);

    console.log('appClipSync: ', data?.data?.appClipSync)
    if (data?.data?.appClipSync) {
      await saveAppClip(data.data);
    }

    return data;
  };

  const updateProject = async (
    values: any,
    buildingsList: any,
    cover: any,
    clients: any,
    rotation?: number,
    isCoverExist?: boolean,
  ) => {
    const projectVariables: ProjectValues = {
      name: values.name,
      subtitle: values.subtitle,
      address: values.address,
      altitude: values.altitude,
      latitude: values.latitude,
      longitude: values.longitude,
      description: values.description,
      // statusDetail: values.statusDetail,
      projectViewPreference: values.projectViewPreference,
    };

    return new Promise((resolve, reject) => {
      updateMutate(
        {
          resource: 'project',
          id,
          values: projectVariables,
          mutationMode: 'optimistic',
          metaData: {
            fields: [
              'id',
              'name',
              'subtitle',
              'origin',
              'longitude', 'latitude', 'altitude',
              'appClipSync',
              'timelineEvents{id name timelineEventType{id name}}',
              // eslint-disable-next-line max-len
              'buildings{id model3ds{id type fileLocation externalId order anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
              'documents{id url name}',
              'projectViewPreference{id visibility isAdditionalInfoVisible isSnapToGround}',
            ],
          },
        },
        {
          onError: (error) => console.error(error.message),
          onSuccess: (data) => {
            handleSuccess(data, values, buildingsList, cover, clients, rotation, true, isCoverExist)
              .then((info) => {
                resolve(info);
              })
              .catch((error) => {
                reject(error);
              });
          },
        },
      );
    });
  };

  const createProject = async (
    values: any,
    buildingsList: any,
    cover: any,
    clients: any,
    rotation?: number,
  ) => {
    const valuesCopy = cloneDeep(values);
    let userOrganizationId: any = undefined
    const incituUser = authProvider.getInCituUser()
    if(incituUser?.organizationUsers?.length > 0) {
      userOrganizationId = incituUser.organizationUsers[0].organizationId
    }

    return new Promise(function (resolve, reject) {
      if (valuesCopy.documents) {
        valuesCopy.documents.createMany.data = valuesCopy.documents.createMany.data.map((e: any) => {
          return {
            url: e.url,
            name: e.name,
          };
        });
      }

      if (userOrganizationId) {
        valuesCopy.organization = {
          connect: {
            id: userOrganizationId,
          },
        };
      }

      // Add projectUsers relation
      valuesCopy.projectUsers = {
        create: {
          relation: 'OWNER',
          user: {
            connect: {
              id: incituUser.id,
            },
          },
        },
      };

      createMutate(
        {
          resource: 'project',
          values: valuesCopy,
          metaData: {
            fields: [
              'id',
              'name',
              'subtitle',
              'origin',
              'longitude', 'latitude', 'altitude',
              'appClipSync',
              'timelineEvents{id name timelineEventType{id name}}',
              'buildings{id model3ds{id type fileLocation externalId order}}',
              'documents{id url name}',
              'organization{organizationUsers{user{id}}}',
              'projectViewPreference{id visibility isAdditionalInfoVisible isSnapToGround}',
            ],
          },
        },
        {
          onSuccess: (data) => {
            handleSuccess(data, values, buildingsList, cover, clients, rotation, false).then((info) => {
              resolve(info);
            }).catch((error) => {
              reject(error);
            });
          },
        },
      );
    });
  };

  const saveData = async (
    values: any,
    buildingsList: any,
    cover: any,
    clients: any,
    isCoverExist?: boolean,
    rotation?: number,
  ) => {
    if (id) {
      return await updateProject(
        values,
        buildingsList,
        cover,
        clients,
        rotation,
        isCoverExist,
      );
    } else {
      return await createProject(values, buildingsList, cover, clients, rotation);
    }
  };

  const saveStatus = id ? updateStatus : createStatus;
  const saveError = id ? updateError : createError;

  // @ts-ignore
  return [saveData, saveStatus, saveError, resultedProjectId];
}
