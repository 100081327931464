
export const BEST_PRACTICE_DOC_LINK = 'https://docs.google.com/document/d/1mh9OKbFUQ-oT91AmyPF5tyu80SEBeGRd4TNxrAv9Vqs/edit' //'https://docs.google.com/document/d/19cyRzS-6VPXhGevZCzVbQsBO4xYowP-XBzmnsbI9-Qo/edit';

export const TALK_TO_US='https://calendly.com/danachermesh/15-minute-call?month=2024-03'
export const INCITU_PRO='https://www.incitu.us/pro'

export  const CONTACT_US='https://airtable.com/appRV7tZM5I9nfmpX/pagqslZmraFvrw24L/form'

export const MAP_PATH = ['map', 'project'];

export const IOS_PATH = 'https://apps.apple.com/no/app/incituar/id1557897783'
export const DOCUMENTATION_PATH = 'https://docs.google.com/document/d/1lrWH9YrytS5FCMTKT6_GS8FuoJpMT9HufOI1fOlr9jQ/edit#heading=h.32pp5ar0ay7x'

export const DOCUMENTATION_PATH_BASE = "https://docs.google.com/document/d/1lrWH9YrytS5FCMTKT6_GS8FuoJpMT9HufOI1fOlr9jQ"
