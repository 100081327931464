import { useCreate, useDelete, useUpdate } from '@pankod/refine-core';
import { Model3D } from '../dataProvider/gql/types';
import { copyModelsToPermanentFolder } from '../api/model';
import { buildModelWith3D } from '../components/ProjectDetailsFormNew/utils/model';
import { ModelFormat } from 'constants/model';

export default function useSaveModel() {
  const { mutate: createModel3DMutate } = useCreate<Model3D>();
  const { mutate: updateModel3DMutate } = useUpdate<Model3D>();
  const { mutate: deleteModel3DMutate } = useDelete<Model3D>();

  const saveModel = async ({
    projectInfo,
    modelsList,
    order,
    buildingId,
  }: any) => {
    const models = modelsList[order];

    for (const modelType of Object.keys(models)) {
      const model = models[modelType];
      const isNewModel = !model.id;

      if (model.file) {
        // New model creation
        try {
          const response = await copyModelsToPermanentFolder(projectInfo?.id, buildingId, model.file.name)
          // const response = await modelUploaderToAWS(model, projectInfo?.id, buildingId); // OLD

          const { result: uploadedFilesNames } = await response.json();
          console.log('_DEBUG_saveModel_copyModelsToPermanentFolder uploadedFilesNames:', uploadedFilesNames)

          if (!isNewModel) {
            await deleteModel3DMutate({
              resource: 'model3ds',
              id: model.id,
              metaData: {
                fields: ['id', 'fileLocation', 'externalId', 'type'],
              },
            });
          }

          // if (isNewModel) {
          //   const buildings = projectInfo?.buildings;
          //
          //   if (buildings) {
          //     const building = buildings.find((building: any) => building.id === buildingId);
          //
          //     if (building) {
          //       const models = building.model3ds;
          //
          //       if (models) {
          //         for (const item of models) {
          //           if (item.type === modelType) {
          //             await deleteModel3DMutate({
          //               resource: 'model3ds',
          //               id: item.id,
          //               metaData: {
          //                 fields: ['id', 'fileLocation', 'externalId', 'type'],
          //               },
          //             });
          //           }
          //         }
          //       }
          //     }
          //   }
          // }

          const buildingsQuery = await buildModelWith3D(
            projectInfo,
            uploadedFilesNames[0],
            model.anchorCoordinate,
            +order,
            buildingId,
            true,
          );

          const data = buildingsQuery?.create?.model3ds?.create;

          if (!data) {
            return;
          }

          await createModel3DMutate({
            resource: 'model3ds',
            values: data,
            metaData: {
              fields: ['id', 'fileLocation', 'externalId', 'type'],
            },
          });
          console.log('Model 3D uploaded to AWS');
        } catch (e) {
          console.log('Error modelUploaderToAWS:', e);
        }
      } else {
        // Anchor coordinate update without model upload
        const anchorCoordinate = model.anchorCoordinate;
        const projectBuilding = projectInfo?.buildings?.find((building: any) => building.id === buildingId);
        const previousAnchorCoordinate =
          projectBuilding?.model3ds
            ?.find(
              (item: any) => +item.order === +order && item.type === ModelFormat.USDZ,
            )?.anchoring?.anchorCoordinate;
        const hasAnchorCoordinate = anchorCoordinate && !previousAnchorCoordinate
          && (anchorCoordinate.latitude || anchorCoordinate.longitude || anchorCoordinate.altitude);
        const hasNewAnchorCoordinate = anchorCoordinate && previousAnchorCoordinate
          && (
            anchorCoordinate.latitude !== previousAnchorCoordinate.latitude
            || anchorCoordinate.longitude !== previousAnchorCoordinate.longitude
            || anchorCoordinate.altitude !== previousAnchorCoordinate.altitude
          );

        if (hasAnchorCoordinate) {
          try {
            await updateModel3DMutate({
              resource: 'model3ds',
              values: {
                anchoring: {
                  create: {
                    anchorCoordinate: {
                      create: {
                        latitude: anchorCoordinate.latitude ?? 0,
                        longitude: anchorCoordinate.longitude ?? 0,
                        altitude: anchorCoordinate.altitude ?? 0,
                      },
                    },
                  },
                },
              },
              id: model.id,
              metaData: {
                fields: ['id', 'fileLocation', 'externalId', 'type'],
              },
            });
          } catch (e) {
            console.log('Error update anchorCoordinate:', e);
          }
        }

        if (hasNewAnchorCoordinate) {
          try {
            await updateModel3DMutate({
              resource: 'model3ds',
              values: {
                anchoring: {
                  update: {
                    anchorCoordinate: {
                      update: {
                        latitude: { set: anchorCoordinate.latitude ?? 0 },
                        longitude: { set: anchorCoordinate.longitude ?? 0 },
                        altitude: { set: anchorCoordinate.altitude ?? 0 },
                      },
                    },
                  },
                },
              },
              id: model.id,
              mutationMode: 'optimistic',
              metaData: {
                fields: ['id', 'fileLocation', 'externalId', 'type'],
              },
            });
          } catch (e) {
            console.log('Error update anchorCoordinate:', e);
          }
        }
      }
    }
  }

  return [saveModel];
}
