import { useState } from 'react';
import { AutoComplete, Col, Form, Input, Row } from 'antd';

import './styles.less';
import CoordinateInput from './components/CoordinateInput';
import ProjectNameInput from './components/ProjectNameInput';
import { findAddressCandidates } from '../../../../api/address';
import { MixpanelEvent, mixPanelUtils } from '../../../../pages/public-view/mixpanel/mixpanel';

const CustomInput = ({ value, label }: { value?: any, label: string }) => {
  return (
    <div className='coordinates-container'>
      <span>{label} </span>
      <span className='value'>{value ? Number(value).toFixed(2) : 0}</span>
    </div>
  );
}

const ProjectMainInfo = ({
  projectId,
  loading,
  setSearchedLocation,
  setInitLatitude,
  setInitLongitude,
  setInitAltitude,
}: {
  projectId: string;
  loading: boolean;
  setSearchedLocation: (location: any) => void;
  setInitLatitude: (latitude: number) => void;
  setInitLongitude: (longitude: number) => void;
  setInitAltitude: (altitude: number) => void;
}) => {
  const form = Form.useFormInstance();
  const [addressOptions, setAddressOptions] = useState<any>([]);

  const setAddress = async (text: any) => {
    const suggestionData: any = JSON.parse(text)
    console.log('suggestionData: ', suggestionData)

    const addressData = await findAddressCandidates(suggestionData.text, suggestionData.magicKey)
    const locationY = addressData?.location?.y ?? 0
    const locationX = addressData?.location?.x ?? 0
    mixPanelUtils.track( MixpanelEvent.PROJECT_ADDRESS_CHANGED, { projectId: projectId, 'latitude' : String(locationY), 'longitude': String(locationX) })
    form.setFieldValue('address', suggestionData?.text);

    // form.setFieldValue('altitude', String(addressData?.altitude?.y ?? 0));
    form.setFieldValue('altitude', String(0));
    setInitAltitude(0);
    form.setFieldValue('latitude', String(locationY));
    setInitLatitude(locationY);
    form.setFieldValue('longitude', String(locationX));
    setInitLongitude(locationX);

    setSearchedLocation({
      latitude: addressData?.location?.y,
      longitude: addressData?.location?.x,
    });
  }

  const getAddressValues = async (address: any) => {
    const endpoint = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest';
    const params = new URLSearchParams({
      text: address,
      f: 'json',
    });

    try {
      const response = await fetch(`${endpoint}?${params.toString()}`);
      if (!response.ok) {
        console.error('Network response was not ok');
        return
      }
      const data = await response.json();
      setAddressOptions(
        data.suggestions.map((suggestion: any) => ({
          value: JSON.stringify(suggestion),
          label: suggestion.text,
        })),
      );
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleAltitudeChange = (value: any) => {
    form.setFieldValue('altitude', value);
    setInitAltitude(+value);
  }

  const handleLatitudeChange = (value: any) => {
    if (form.getFieldValue('latitude') !== value) {
      form.setFieldValue('latitude', value);
      setInitLatitude(+value);

      setSearchedLocation({
        latitude: +value,
        longitude: +form.getFieldValue('longitude'),
      });
    }
  }

  const handleLongitudeChange = (value: any) => {
    if (form.getFieldValue('longitude') !== value) {
      form.setFieldValue('longitude', value);
      setInitLongitude(+value);

      setSearchedLocation({
        latitude: +form.getFieldValue('latitude'),
        longitude: +value,
      });
    }
  }

  return (
    <Col span={24} style={{ paddingLeft: '12px' }}>
      <Form.Item
        name="name"
        label="Project Name"
        required={true}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[{ required: true, message: 'Please enter a valid project name' }]}
      >
        <Input />
      </Form.Item>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            className='address-form-item'
            label="Address"
            name="address"
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            rules={[
              { required: true, message: 'Please enter a valid address' },
            ]}
          >
            <AutoComplete
              placeholder="Address"
              data-testid="project_address"
              disabled={loading}
              options={addressOptions}
              onSelect={setAddress}
              className='address-auto'
              onSearch={(text) => getAddressValues(text)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            name="latitude"
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 24 }}
          >
            <CoordinateInput label="latitude:" setValue={handleLatitudeChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="longitude"
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 24 }}
          >
            <CoordinateInput label="longitude:" setValue={handleLongitudeChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="altitude"
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 24 }}
          >
            <CoordinateInput label="altitude:" setValue={handleAltitudeChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="rotation"
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
          >
            <CustomInput label="rotation:" />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  )
};

export default ProjectMainInfo;
