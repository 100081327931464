import dataProvider from '../dataProvider/dataProvider';
import { authProvider } from '../authPrvider/authProvider';
import {gql, GraphQLClient, Variables} from 'graphql-request'

export const getProjectData = async (id: string) => {
  const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)

  if(id) {
    const { data } = await dProvider.getOne({
      resource: 'projects',
      id,
      metaData: {
        fields: [
          'id',
          'name',
          'subtitle',
          'origin',
          'description',
          'status',
          'createdAt',
          'address',
          'longitude',
          'latitude',
          'altitude',
          'organizationId',
          // 'statusDetail',
          'borough',
          'ceqr',
          'bbl',
          // 'attributes',
          'appClipSync',
          'autoGenerateModels',
          // eslint-disable-next-line max-len
          'buildings{' +
          'id ' +
          'projectId ' +
          'footprintId ' +
          'buildingType ' +
          'buildingShape ' +
          'buildingMaterial ' +
          'buildingHeight ' +
          'floorHeight ' +
          'groundFloorHeight ' +
          'model3ds{id type fileLocation}' +
          '}',
          // 'organization{id organizationUsers{user{id email}}}',
          // 'documents{id url name type category}',
          // 'timelineEvents{id name timelineEventType{id name}}',
          // 'projectViewPreference{id visibility}',
        ],
      },
    })
    return data;
  }
  return undefined;
}

// TODO: handle if we can havve array of organisationIds
const GET_PROJECTS = gql`
  query GetProjects($organizationId: String!) {
    projects (
       where: {
         organizationId:  {
          equals: $organizationId
        } 
      }
    ){
      id
      name
      subtitle
      address
      timelineEvents {
        id
        updatedAt
      }
      organization {
        id
        organizationUsers {
          user {
            id
            email
          }
        }
      }
      projectViewPreference {
        id
        visibility
      }
      buildings {
        model3ds {
          fileSizeMb
        }
      }
    }
  }
`;

export const fetchUsersProjects = async (userData: any) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/graphql`;
  const token = authProvider.getToken();
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  try {
    console.log('___0000____fetchUsersProjects userId: ', userData);
    let data = {projects: []};
    if(userData.organizationUsers.length > 0) {
      const organizationId = userData.organizationUsers[0].organizationId;
      console.log('___0000____organizationId: ', organizationId);
      data = await graphQLClient.request(GET_PROJECTS, { organizationId });
      console.log('___0000___data: ', data);
    }

    console.log('fetchUsersProjects: ', data);
    return { data: data?.projects };
  } catch (error) {
    console.error('Error fetching user projects:', error);
    return undefined;
  }
}

