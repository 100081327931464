import { useEffect, useState } from 'react';
import { useNavigation } from '@pankod/refine-core';
import { message } from '@pankod/refine-antd';
import dataProvider from '../dataProvider/dataProvider';

export default function useProjectDetailsFetchById({ id }: any) {
  const { push } = useNavigation();
  const [projectInfo, setProjectInfo] = useState(null);
  const [pageLoadStatus, setPageLoadStatus] = useState('loading');

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setPageLoadStatus('loaded');
        return;
      }

      const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)

      setPageLoadStatus('loading');

      const { data } = await dProvider.getOne({
        resource: 'projects',
        id,
        metaData: {
          fields: [
            'id',
            'name',
            'subtitle',
            'origin',
            'description',
            'status',
            'createdAt',
            'address',
            'longitude',
            'latitude',
            'altitude',
            'organizationId',
            // 'statusDetail',
            'borough',
            'ceqr',
            'bbl',
            // 'attributes',
            'appClipSync',
            'autoGenerateModels',
            // eslint-disable-next-line max-len
            'buildings{id buildingHeight defaultAnchoring{rotation anchorCoordinate{id latitude longitude altitude}} ' +
            'model3ds{id type fileLocation externalId order fileSizeMb anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
            // 'model3ds{id type fileLocation externalId order anchoring{anchorCoordinate{id latitude longitude altitude}}}}',
            'organization{id organizationUsers{user{id email  firstName lastName}}}',
            'documents{id url name type category}',
            'timelineEvents{id name timelineEventType{id name}}',
            'projectViewPreference{id visibility isSnapToGround}',
          ],
        },
      })

      setPageLoadStatus('loaded');

      if (data) {
        setProjectInfo(data as any);
      } else {
        message.error('Invalid projectd Id!', id);
        push('/projects');
      }
    };

    fetchData().then();
  }, [id]);

  return [projectInfo, pageLoadStatus];
}

