import React, { useEffect,useState } from 'react';

import { Card, Col, Row, Typography } from '@pankod/refine-antd';

import ProjectDescription from './components/project-description';
import ProjectHeader from '../components/project-header';
import {appUtils, dataUtils, jsonUtils} from '../../../utils/util';
import FeedbackForm from './components/feedback/feedback';
import './style.less';
import ProjectModelPreview from './components/model-preview';
import EsriMap from '../components/esri-map';
import {mixPanelUtils, MixpanelEvent} from '../mixpanel/mixpanel';
import useProjectDetailsFetchByPartialId from '../../../hooks/useProjectDetailsFetchByPartialId';
import useProjectDetailsFetch from '../../../hooks/useProjectDetailsFetch';

import {ProjectProps} from '../interfaces/project';
import useAuth0Authorization from '../../../hooks/auth0/useAuth0Authorization';
import { useParams } from 'react-router-dom';
import { ModelStrings } from '../../../constants';
import PublicMapView from '../map';

const PublicViewComponent = (props: ProjectProps) => {
  const {
    projectId,
    descriptionData,
    feedbackVisible,
    setFeedbackVisible,
    isModelAvailable,
    usdzModel,
    glbModel,
  }: ProjectProps = props;

  return (
    <Row align='middle' justify='center'>
      <Card className='project-description-container' title={<ProjectHeader projectId={projectId} />}>
        <Row gutter={[24, 48]} justify='center' className='container-row'>
          <Col xxl={12} xl={16} lg={16} md={20}>
            <Row gutter={24} className='grid-rows'>
              <ProjectModelPreview
                scale={0.05}
                modelPosition={[0, -3, 0]}
                isModelAvailable={isModelAvailable}
                projectId={projectId}
                usdzModel={usdzModel}
                glbModel={glbModel}
                autoGenerateModels={false}
              />
              <EsriMap
                id="viewDiv"
                longitude={parseFloat(dataUtils.findLocationValueByKey(descriptionData, 'longitude'))}
                latitude={parseFloat(dataUtils.findLocationValueByKey(descriptionData, 'latitude'))}
                bbl={dataUtils.findArrayValueByKey(descriptionData, 'bbl')}
                bin={dataUtils.findValueByKey(descriptionData,'bin')}
              />
              <Col span={24}>
                <Typography.Text
                  className='secondary-sub-text'
                  type='secondary'
                >
                    Model generated by inCitu based on publicly available
                    information
                </Typography.Text>
              </Col>
              <Col span={24}>
                <ProjectDescription
                  projectId={projectId}
                  openFeedbackForm={()=>setFeedbackVisible(true)}
                  data={descriptionData}
                />
              </Col>
            </Row>
            <FeedbackForm
              projectId={projectId}
              visible={feedbackVisible}
              onClose={()=>setFeedbackVisible(false)}
            />
          </Col>
        </Row>
      </Card>
    </Row>

  );
}

function PublicViewDB() {
  let { id: projectIdFull }: any = useParams();

  const searchParams = new URLSearchParams(location.search);
  let projectIdShort = searchParams.get('id');

  if (projectIdFull && projectIdFull.length === 8) {
    projectIdShort = projectIdFull;
    projectIdFull = undefined;
  }

  const [descriptionData, setDescriptionData]: any = useState([]);

  const [modelType, setModelType]: any = useState(null);
  const [isModelAvailable, setIsModelAvailable]: any = useState(false);

  const [feedbackVisible,setFeedbackVisible] = useState(false);
  const { performAuthorization } = useAuth0Authorization();

  /* eslint-disable react-hooks/rules-of-hooks */
  const [projectInfo, pageLoadStatus]: any = projectIdFull
    ? useProjectDetailsFetch({ id: projectIdFull })
    : useProjectDetailsFetchByPartialId({ id: projectIdShort });

  const performMixpanelTrack = (projectInfo: any) => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_VISIT, {projectId: projectInfo?.id, projectName: projectInfo?.name})
    appUtils.updatePreviewLinks(projectInfo)
  }

  useEffect(()=>{
    const fetchData = async () => {
      // Load data
      const projectJSON = await jsonUtils.buildJSON(projectInfo)
      const jsonData = dataUtils.jsonToData(projectJSON)
      setDescriptionData(jsonData)

      // Load model data
      const projectJSONModel = await jsonUtils.buildJSONModel(projectInfo)
      setIsModelAvailable(projectJSONModel.is_model_available)
    }

    if (projectInfo) {
      performMixpanelTrack(projectInfo)
      fetchData()
        .catch(console.error);
    }
  },[projectInfo])
  
  const projectModelsList = projectInfo?.buildings?.[0]?.model3ds?.sort((a: any, b: any) => a.order - b.order);
  const usdzModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.USDZ);
  const glbModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.GLB);

  if(projectInfo) {
    return (
      <PublicViewComponent
        projectId={projectInfo?.id}
        descriptionData={descriptionData}
        feedbackVisible={feedbackVisible}
        usdzModel={usdzModel}
        glbModel={glbModel}
        setFeedbackVisible={ async (e: boolean) => {
          if(e) {
            await performAuthorization()
            setFeedbackVisible(e)
          } else {
            setFeedbackVisible(e)
          }
        }}
        type={modelType}
        isModelAvailable={isModelAvailable}
      />
    )
  }

  return (
    <div>There is no project defined</div>
  )
}
export default function PublicView() {
  let { id: projectIdFull }: any = useParams();

  const searchParams = new URLSearchParams(location.search);
  let projectIdShort = searchParams.get('id');

  if (projectIdFull && projectIdFull.length === 8) {
    projectIdShort = projectIdFull;
    projectIdFull = undefined;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const [projectInfo, pageLoadStatus]: any = projectIdFull
    ? useProjectDetailsFetch({ id: projectIdFull })
    : useProjectDetailsFetchByPartialId({ id: projectIdShort });

  // const [projectInfo, setProjectInfo] = useState<any>(undefined);
  // const [fetchProjectInfo] = useProjectDetailsFetch();
  // const [fetchProjectInfoByPartialId] = useProjectDetailsFetchByPartialId();
  //
  //
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const [projectData, pageLoadStatus] = projectIdFull
  //         ? await fetchProjectInfo({ id: projectIdFull })
  //         : await fetchProjectInfoByPartialId({ id: projectIdShort });
  //
  //       if (projectData) {
  //         setProjectInfo(projectData);
  //       } else {
  //         console.error('Invalid project Id!', 1);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching project details:', error);
  //
  //     }
  //   };
  //
  //   fetchData();
  // }, [projectIdFull, projectIdShort, fetchProjectInfo, fetchProjectInfoByPartialId]);

  if(projectInfo) {
    return <PublicMapView projectId={projectInfo.id} projectData={projectInfo}/>
  }
  return (
    <Row align='middle' justify='center' gutter={[24, 48]} className='container-row p-0 m-0'>
      <div>Loading...</div>
    </Row>
  )
  // return <PublicViewDB />
}
