import {Avatar, Button, Popover} from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import { authProvider } from '../../authPrvider/authProvider';
import ProfileIcon from '../../assets/profile.svg';
import SignOutIcon from '../../assets/signOut.svg';
import useAuth0Authorization from '../../hooks/auth0/useAuth0Authorization';

import './styles.less';
import performLogout from '../../hooks/auth0/useLogout';
import {observer} from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {Context} from '../../index';
import { useUpdateUser } from '../../api/user';
import { fetchUserInfo } from '../../hooks/fetchUserInfo';
import { delay } from '../../utils/util';
import { callSignInNativeAndroidApp, checkIfAndroidInterfaceAvailable } from '../../utils/checkMobile';

const HeaderAccount = observer(() => {
  const { projectCreationStore } = useContext(Context);
  const {setUserInfo } =  projectCreationStore
  const { performAuthorization } = useAuth0Authorization();
  const userIncitu = authProvider.getInCituUser();
  const {
    logout,
    user,
  } = useAuth0();
  const {
    updateUser,
    data,
  } = useUpdateUser()

  const isAndroidInterfaceAvailable = checkIfAndroidInterfaceAvailable()
  const handleLogout = async () => {
    await performLogout(logout);
  }

  const handleLogin = () => {
    performAuthorization();
  }

  useEffect(() => {
    setUserInfo(user)
  }, [user]);

  // const ToggleButton = () => {
  //   const userIncitu = authProvider.getInCituUser();
  //   const [isToggled, setIsToggled] = useState(userIncitu.role === 'ADMIN');
  //   const [key, setKey] = useState(Math.random());
  //
  //   const updateUserRoleAndFetch = async (newRole: string) => {
  //     await updateUserRole(newRole);
  //     const fetchedUser = await fetchUserInfo(userIncitu?.externalId);
  //     authProvider.saveInCituUser(fetchedUser?.data);
  //   };
  //
  //   const handleToggle = useCallback(async () => {
  //     if (userIncitu) {
  //       const arMode = !isToggled;
  //       setIsToggled(arMode);
  //
  //       // await delay(1000)
  //       const newRole = arMode ? 'ADMIN' : 'CLIENT';
  //       try {
  //         await updateUserRoleAndFetch(newRole);
  //         const isNewARMode = await updateUserRoleAndFetch(newRole);
  //         // setIsToggled(isNewARMode);
  //         // console.log('+++++ isNewARMode:', isNewARMode);
  //       } catch (error) {
  //         console.error('Error updating user role or fetching user info:', error);
  //       }
  //       setKey(Math.random());
  //     }
  //   }, [isToggled, userIncitu]);
  //
  //   return (
  //     <div>
  //       <button key={key} className='toggle-button' onClick={handleToggle}>
  //         {isToggled === true ? 'ON' : 'OFF'}
  //       </button>
  //       <span> AR Edit mode</span>
  //     </div>
  //   );
  // };
  //

  const updateUserRole = async (role: string) => {
    const payload = {
      role: { set: role },
    }
    if (user !== undefined) {
      await updateUser(user.externalId, payload)
    }
    if (userIncitu !== undefined) {
      await updateUser(userIncitu.externalId, payload)
    }
  }

  // TODO: check user subscription pro
  const popoverContent = () => (
    <div className='profile-popover'>
      <div className='profile-info'>
        <img src={ProfileIcon} className="profile-icon" />
        <div>
          <div className='user-name'>{user?.name || userIncitu?.firstName}</div>
          <div className='user-email'>{user?.email || userIncitu?.email}</div>
        </div>
      </div>
      {/*{ userIncitu ?*/}
      {/*  <div className='sign-out-button'>*/}
      {/*      <ToggleButton />*/}
      {/*  </div>*/}
      {/*  : null*/}
      {/*}*/}
      <div className='sign-out-button' onClick={handleLogout as any}>
        <img src={SignOutIcon} className="sign-out-icon" />
        <div>Sign Out</div>
      </div>
    </div>
  );
  let firstChar = ''

  if (user && user.name?.length){
    firstChar = user.name.substr(0,1).toUpperCase()
  }
  if (userIncitu && userIncitu?.firstName?.length){
    firstChar = userIncitu.firstName.substr(0,1).toUpperCase()
  }

  return (
    <div className='header-profile'>
      {user || userIncitu ? (
        <Popover
          placement="bottomLeft"
          content={popoverContent}
          trigger='click'
        >
          <Avatar style={{ backgroundColor: '#d650f6', verticalAlign: 'middle', marginRight: 10 }} size="large" >
            {firstChar}
          </Avatar>
          {/*<img src={ProfileIcon} className="profile-icon" />*/}
        </Popover>
      ) : (
        <Button
          className='sign-in-btn'
          onClick={() => {
            if(isAndroidInterfaceAvailable) {
              callSignInNativeAndroidApp();
            } else {
              handleLogin();
            }
          }}>
          Sign in
        </Button>
      )}
    </div>
  );
})

export default HeaderAccount;
