import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';

const OAuthCallback = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const handleOAuthResponse = () => {
      if (window.opener) {
        if (window.location.hash) {
          const hash = window.location.hash.substring(1);
          const params = new URLSearchParams(hash);
          const accessToken = params.get('access_token');

          // Send the token back to the opener
          if (window.opener.require && window.opener.require('esri/kernel')) {
            window.opener.require('esri/kernel').id.setOAuthResponseHash(window.location.hash);
          } else {
            window.opener.dispatchEvent(new CustomEvent('arcgis:auth:hash', { detail: window.location.hash }));
          }


          setToken(accessToken);
          window.close();
        } else if (window.location.search) {
          window.opener.dispatchEvent(new CustomEvent('arcgis:auth:location:search', { detail: window.location.search }));
        }
      }
    };

    handleOAuthResponse();
  }, []);


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "auto",
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // padding: "20px",
        // border: "1px solid #ddd",
        // borderRadius: "8px",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Row justify="center">
        <Typography.Text style={{ marginTop: "50px", fontSize: "20px", fontWeight: 600 }}>
          ArcGIS OAuth Callback
        </Typography.Text>
      </Row>
      <Row justify="center" style={{ marginTop: "16px" }}>
        <Typography.Text style={{ fontSize: "14px" }}>
          <b>
            {token
              ? `You were authenticated successfully`
              : "An error occurred during authentication"}
          </b>
        </Typography.Text>
      </Row>
    </div>
  );
};

export default OAuthCallback;
