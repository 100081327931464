import { fetchUserInfo } from '../hooks/fetchUserInfo';
import { authProvider } from '../authPrvider/authProvider';
import { jwtDecode } from 'jwt-decode';
import {  notifySignInDidSucceed, notifySignOutDidSucceed } from './checkMobile';
import performLogout from '../hooks/auth0/useLogout';

// Android
console.log('incituToken before window:', window);
window.incituToken = async function(token) {

  console.log('incituToken: Received token:', token);
  try {
    if (token == null) {
      window.location.href = '/map';

      await authProvider.removeFromLocalStorage();
      notifySignOutDidSucceed(true);
      return;
    }

    await authProvider.saveToken(token);

    const decoded = jwtDecode(token);
    console.log(' decoded:', decoded);
    const externalId = decoded.sub;
    const fetchedUser = await fetchUserInfo(externalId);
    authProvider.saveInCituUser(fetchedUser.data);
    notifySignInDidSucceed(true);
    window.location.reload();
  } catch (e) {
    console.log(e);
    notifySignInDidSucceed(false);
  }
};
console.log('incituToken after window:', window);
