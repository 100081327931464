import { useEffect, useState } from 'react';
import { Input } from 'antd';

import './CoordinateInput.less';

const CoordinateInput = ({ 
  value,
  label,
  setValue,
}: {
  value?: any,
  label: string,
  setValue: (value: any) => void,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(value || 0);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
  
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  }

  const handleInputBlur = () => {
    setIsEdit(false);
    setValue(inputValue);
  };

  const handleInputValueChange = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  if (isEdit) {
    return (
      <div className='coordinates-container'>
        <span>{label} </span>
        <span>
          <Input
            autoFocus={true}
            value={inputValue}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            onChange={handleInputValueChange}
          />
        </span>
      </div>
    );
  }

  return (
    <div className='coordinates-container' onClick={handleEdit}>
      <span>{label}</span>
      <span className='value'>{value ? Number(value).toFixed(2) : 0}</span>
    </div>
  );
};

export default CoordinateInput;