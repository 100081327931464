import React, {useEffect, useRef} from 'react';
import WebMap from '@arcgis/core/Map.js';
import Map from '@arcgis/core/Map.js';
import MapView from '@arcgis/core/views/MapView.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';

import Color from '@arcgis/core/Color';
import Search from '@arcgis/core/widgets/Search';
import Zoom from '@arcgis/core/widgets/Zoom';
import Locate from '@arcgis/core/widgets/Locate';

import SceneView from '@arcgis/core/views/SceneView';
import { isLinkUsPage, mapUtils, ProjectQueryType } from '../../../../utils/util';
import ProjectDrawerMobile from '../../../../pages/public-view/map/ProjectDrawerMobile';
import ProjectDrawer from '../../../../pages/public-view/map/ProjectDrawer';

import './styles.less';
import useProjectsFetch from '../../../../hooks/useProjectsFetch';
import useProjectInfoFetch from '../../../../hooks/useProjectInfoFetch';
import useMapEvaluateTag from '../../../../hooks/useMapEvaluateTag';
import {message} from '@pankod/refine-antd';
import {createMapView2D} from '../../../../utils/map-utils/createMapView2D';
import {createSceneView3D} from '../../../../utils/map-utils/createSceneView3D';
import esriConfig from '@arcgis/core/config';
import {useNavigate} from '@pankod/refine-react-router-v6';
import Basemap from '@arcgis/core/Basemap';
import {highlightSelected} from '../../../../utils/map-utils/highlightSelected';
interface ArcGISMapComponentProps {
  onProjectClick?: (projectId: string) => void; // New callback prop
  isMobile?: boolean;

  projectId: string;

  projectData?: any;
  setSelectedProjectId: any;
}

const ArcGISMapComponent: React.FC<ArcGISMapComponentProps> = ({
  onProjectClick,
  isMobile,
  projectId,
  projectData,
  setSelectedProjectId,
}) => {
  const [search, setSearch] = React.useState<any>(null);
  const [locateWidget, setLocateWidget] = React.useState<any>(null);
  const [locateWidget3D, setLocateWidget3D] = React.useState<any>(null);
  const [searchItems, setSearchItems] = React.useState<any>([]);
  const mapRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef(null);
  const [fetchProjects] = useProjectsFetch();
  const [fetchProjectInfo] = useProjectInfoFetch();
  const {evaluateTag} = useMapEvaluateTag();
  const navigate = useNavigate();
  async function getSingleProjectData(projectId: string) {
    try {
      return await fetchProjectInfo({ id: projectId });
    } catch (e) {
      return undefined;
    }
  }

  const createClickHandle = (map: any, mapView: any, featureLayer: FeatureLayer) => {

    if(featureLayer){
      const clickHandler = (event: any) => {
        console.log('clickHandler:');
        const hitTestOptions = {
          include: [featureLayer],
        };
        mapView.hitTest(event, hitTestOptions)
          .then((response: any) => getFeatures(response,mapView))
          .catch((error: any) => {
            console.error('Error in hitTest:', error);
          });
      };
      mapView.on('click', clickHandler);

    }
  };

  async function buildMap(map: any, mapView: any, tag: string,sceneView: SceneView) {

    message.config({ maxCount: 1 });
    //TODO:Keep the loading message until done
    message.loading('Loading Projects...');
    let currentPage = 0;
    const projects: Array<any>=[]
    let moreProjectsAvailable = true;
    const evaluatedTag = await evaluateTag(tag,mapView);

    if(evaluatedTag){
      const queryType = evaluatedTag.queryType
      const visibility = evaluatedTag.visibility
      const privateVisibility = evaluatedTag.privateVisibility
      const restrictedVisibility = evaluatedTag.restrictedVisibility
      if(queryType== ProjectQueryType.Single && projectData)
      {
        const projectsData = await getManyProjects(currentPage, queryType, evaluatedTag.queryTag, visibility, privateVisibility, restrictedVisibility);
        if(projectsData)
        {
          await createMapView2D(map,mapView, projectData,projectId,tag).then(featureLayer => {
            createClickHandle(map, mapView, featureLayer as FeatureLayer);
            mapView.center=[projectData.longitude,projectData.latitude]
            projects.push(...projectsData)
          }).finally(()=>{

            createSceneView3D(projects,sceneView,onProjectClick,false)
          });

        }

        return;
      }
      while (moreProjectsAvailable) {
        try {

          if(queryType === ProjectQueryType.None)
          {
            message.error('No Projects Found')
            break;
          }
          const projectsData = await getManyProjects(currentPage,queryType,evaluatedTag.queryTag,visibility, privateVisibility, restrictedVisibility);
          if (projectsData) {
            await createMapView2D(map,mapView, projectsData,projectId,tag).then(featureLayer => {
              createClickHandle(map, mapView, featureLayer as FeatureLayer);
              currentPage++;
              projects.push(...projectsData)
            });
          } else {
            if(projects.length > 0)
            {
              //const cityDisplayName = projectsData[0]
              createSceneView3D(projects,sceneView,onProjectClick,true)
              moreProjectsAvailable = false;
            }

          }
        } catch (error) {
          message.error('Error loading projects.');
          return;
        }
      }}
    else
    {
      message.error('Error loading projects.');
      navigate('/error');

    }
    if(!moreProjectsAvailable){
      message.success({ content: 'Loaded all Projects!' });

    }

  }

  async function getManyProjects(currentPage = 1,queryType: ProjectQueryType,queryTag: string,visibility: string, privateVisibility?: string, restrictedVisibility?: string) {
    try {
      if(!queryTag || queryTag.length === 0)
      {

        return null;

      }
      const response = await fetchProjects(currentPage,queryType,queryTag,visibility, privateVisibility, restrictedVisibility);
      if (response.list && response.list.length > 0) {
        return response.list;
      } else {
        return null;
      }
    } catch (e: any) {
      console.error('Error fetching projects:', e.message);
      return null;
    }
  }

  const getFeatures = (response: any,mapView: any) => {
    console.log('getFeatures: ');
    console.dir(response);
    if (response.results.length) {
      // console.log(`hitTest identified ${response.results.length} features:`);
      const feature = response.results[0].graphic;

      const attributes = feature.attributes;
      console.log('layer ' + response.results[0].layer.id + ': ' + JSON.stringify(attributes));
      console.log('projectId: ' + attributes.id);

      if (onProjectClick && attributes.id) {
        highlightSelected(mapView,feature.geometry.latitude,feature.geometry.longitude,window.location.pathname)
        if (window.location.href.includes('localhost')) {
          // const projects = ['clm4045f1000045j27gbiretd', 'clmrpu0d0000045yc37dosmvq']
          // const index = Math.floor(Math.random() * projects.length);
          onProjectClick(attributes.id);
          // onProjectClick('clqftk81p00003kl9r1lup7dr');
        } else if (window.location.href.includes('staging')) {
          // onProjectClick('cljisroi90000yb023a6y9qlz');
          onProjectClick(attributes.id);
        } else {
          onProjectClick(attributes.id);
        }
      }
    }
  };

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    esriConfig.apiKey = `${process.env.REACT_APP_ARCGIS_API_KEY}`

    const map = new WebMap({
      basemap: 'gray-vector',
    });
    const defaultMapCoordinate = [-74.00926445266123,40.70736785129664]

    const mapView = new MapView({
      container: mapRef.current,
      map: map,
      center: defaultMapCoordinate,
      zoom: 10,
      highlightOptions: {
        color: new Color([255, 165, 0]),
      },
    });
    const path = window.location.pathname
    const pathParts = path.split('/');
    console.log('___project data:', projectData?.address);
    const envTag = mapUtils.getEnvTag();
    console.log('___envTag:', envTag);
    // const tag = projectId ? mapUtils.getTag(projectData?.address) : pathParts[pathParts.length - 1];
    const tag = projectId ?? pathParts[pathParts.length - 1];
    const basemap3d = new Basemap({
      portalItem: {
        id: '35cdf329d663403b99df27d6ca5fa38d',
      },
    });
    const mapBase3D = new Map({
      basemap: basemap3d,
      ground: 'world-elevation',
    });
    const sceneView = new SceneView({
      map:mapBase3D,
      viewingMode: 'local',
    })
    // 3D Map
    if(sceneRef.current){
      sceneView.container = sceneRef.current
    }

    buildMap(map, mapView, path,sceneView);
    // build2DMapDynamically(map, mapView, tag, envTag)
    //   .then(r => {
    //   }).catch(error => {
    //     console.warn('An error on 2D map loading occurred:', error);
    //   });

    // Search Component
    const search = new Search({
      view: mapView,
      resultGraphicEnabled: false,
    });
    setSearch(search);

    //handleSearch2D(map, mapView, search, envTag);

    const zoom = new Zoom({
      view: mapView,
    });

    mapView.ui.add(search, {
      position: 'top-left',
    });

    const zoomPosition = isMobile ? 'bottom-right' : 'top-right';
    mapView.ui.add(zoom, {
      position: zoomPosition,
    });

    const locateWidget = new Locate({
      view: mapView,   // Attaches the Locate button to the view
    });

    mapView.ui.add(locateWidget, {
      position: zoomPosition,
    });

    mapView.when(() => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('ul') && queryParams.get('ul') === '1') {
        locateWidget.locate();
      }
    });

    const search3D = new Search({
      view: sceneView,
      resultGraphicEnabled: true,
      popupEnabled: true,
    });
    search3D.goToOverride = function(view, goToParams) {
      goToParams.options.duration = 1;
      const updatedGoToParams = goToParams.target
      updatedGoToParams.scale = 1500
      return view.goTo(updatedGoToParams, goToParams.options);
    };

    sceneView.ui.add(search3D, {
      position: 'top-left',
    });

    // Zoom Component
    const zoom3D = new Zoom({
      view: sceneView,
    });
    const zoomPosition3D = isMobile ? 'bottom-right' : 'top-right';
    sceneView.ui.add(zoom3D, {
      position: zoomPosition3D,
    });

    const locateWidget3D = new Locate({
      view: sceneView,
    });

    sceneView.ui.add(locateWidget3D, {
      position: zoomPosition,
    });

    setLocateWidget3D(locateWidget3D);

    // 3D and 2D switch logic
    const switchButton2d = document.getElementById('switch-btn-2d');
    if (mapRef.current && sceneRef.current) {

      switchButton2d?.addEventListener('click', () => {
        setSearch(search)
        // @ts-ignore
        mapRef.current.style.display = 'flex';
        // @ts-ignore
        sceneRef.current.style.display = 'none';
        console.log('2D');
      });
    }

    const switchButton3d = document.getElementById('switch-btn-3d');
    if (mapRef.current && sceneRef.current && switchButton3d) {
      switchButton3d.addEventListener('click', () => {
        setSearch(search3D)
        mapView.when(() => {
          // mapView is fully loaded

          const screenCoordinates = mapView.toScreen(mapView.center);
          if (screenCoordinates) {
            const mapCenterCoordinates = mapView.toMap(screenCoordinates);
            if (mapCenterCoordinates) {
              // @ts-ignore
              mapRef.current.style.display = 'none';
              // @ts-ignore
              sceneRef.current.style.display = 'flex';
              console.log('3D');
              sceneView.when(()=>{
                sceneView.goTo(
                  {
                    position: {
                      x: mapCenterCoordinates.longitude,
                      y: mapCenterCoordinates.latitude,
                      z: 800,
                      spatialReference: {
                        wkid: 4326,
                      },
                    },
                    heading:30,
                    tilt: 50,
                  },
                  {
                    animate: false,
                  },
                );
              })

            } else {
              console.error('Failed to get map center coordinates.');
            }
          } else {
            console.error('Failed to get screen coordinates.');
          }
        }, (err: any) => {
          console.error('Error waiting for mapView to load:', err);
        });
      });
    } else {
      console.error('Map or scene references are not available, or switch button is missing.');
    }

    const customLocateWidget = document.createElement('button');
    const icon = document.createElement('span');

    icon.classList.add('esri-icon', 'esri-icon-locate');
    customLocateWidget.appendChild(icon);
    customLocateWidget.classList.add(
      'esri-widget--button', 'esri-widget', 'esri-locate-button', 'esri-custom-locate-button',
    );
    mapView.when(function() {
      setTimeout(() => {
        const searchContainer = (search?.container as any)?.querySelector('.esri-search__container');
        const searchButton = (search?.container as any)?.querySelector('.esri-search__submit-button');

        if (searchContainer && !(search?.container as any).querySelector('.esri-custom-locate-button')) {
          (searchContainer as any)?.insertBefore(
            customLocateWidget,
            searchButton,
          );
        }
      }, 10000);

      setLocateWidget(locateWidget);

      customLocateWidget.addEventListener('click', async function() {
        try {
          icon.classList.remove('esri-icon-locate');
          icon.classList.add('esri-rotating', 'esri-icon-loading-indicator');
          await locateWidget.locate();
          await locateWidget3D.locate();

          icon.classList.remove('esri-rotating', 'esri-icon-loading-indicator');
          icon.classList.add('esri-icon-locate');
        } catch (error) {
          console.error(error);
        }
      });
    });

    // Initial setup
    mapRef.current.style.display = 'flex';
    // @ts-ignore
    sceneRef.current.style.display = 'none';

    // Cleanup function to detach events and destroy the view
    return () => {
      switchButton2d?.removeEventListener('click', () => {
      });
      switchButton3d?.removeEventListener('click', () => {
      });
      mapView.destroy();
    };

  }, []);

  const handleSearch = (searchTerm: string) => {
    search?.suggest?.(searchTerm)?.then?.((val: any) => {
      if (val?.results && val.results[0]) {
        setSearchItems(val.results[0].results);
      } else {
        setSearchItems([]);
      }
    });
    return;
  };

  const handleGoTo = (item: any) => {
    search?.search(item);
  };

  const handleLocate = () => {
    locateWidget?.locate();
    locateWidget3D?.locate();
  };

  return (
    <div className={`building-map ${isMobile ? 'mobile' : ''}`}>
      <div
        ref={mapRef}
      />
      <div ref={sceneRef}></div>
      <div id="switchDiv" className={isLinkUsPage() ? 'switchDivLinkUs': ''}>
        <input
          className="esri-component esri-widget--button esri-widget esri-interactive"
          type="button"
          id="switch-btn-3d"
          value="3D"
        />
        <input
          className="esri-component esri-widget--button esri-widget esri-interactive"
          type="button"
          id="switch-btn-2d"
          value="2D"
        />
      </div>
      {isMobile
        ? (
          <ProjectDrawerMobile
            projectId={projectId}
            setSelectedProjectId={setSelectedProjectId}
          />
        ) : (
          <ProjectDrawer
            projectId={projectId}
            setSelectedProjectId={setSelectedProjectId}
            searchLocation={handleSearch}
            goTo={handleGoTo}
            searchItems={searchItems}
            locate={handleLocate}
          />
        )
      }
    </div>
  );
};

export default ArcGISMapComponent;
