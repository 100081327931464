// AppBar.tsx
import React from 'react'
import {Layout} from 'antd'

const AppBar: React.FC = () => {
  return (
    <Layout style={{backgroundColor: '#FFFFFF', height: '28px'}}>
      <div>
        <a href='https://www.incitu.us' target='_blank'>
          <img
            src='https://incitu-public-view-maps.s3.us-east-2.amazonaws.com/default/inCitu_logo_Black-01.svg'
            alt='Logo'
            style={{
              width: '82.743px',
              height: '24px',
              marginLeft: 24,
              position: 'absolute',
              marginTop: 16,
            }}
          />
        </a>
      </div>
    </Layout>
  )
}

export default AppBar
