export const resolutionValidator = (file: File) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;

      if (width === 1800 && height === 1200) {
        resolve(true);
      } if (width >= 1800 && height >= 1200 && (width > 1800 || height > 1200)) {
        resolve('shouldCrop');
      } else {
        reject('Image resolution must be at least 1800x1200px');
      }
    };
  });
};
