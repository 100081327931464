export async function updateOrganizationName(organizationId: string, organisationName: string): Promise<any> {
  try {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          mutation UpdateOrganization($where: OrganizationWhereUniqueInput!, $data: OrganizationUpdateInput!) {
            updateOrganization(where: $where, data: $data) {
              id
              name
            }
          }
        `,
        variables: {
          where: {
            id: organizationId,
          },
          data: {
            name: organisationName,
          },
        },
      }),
    });

    return res;
  } catch (error) {
    return error;
  }
}
