import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';

export default function CloseModalPopup({showCloseWarning,onLeave,onContinue}:any){
  return  <Modal className='close-popup-modal-wrapper'
    closeIcon={false} 
    closable={false}
    open={showCloseWarning} 
    width={'auto'}
    style={{
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      alignItems:'center',
      textAlign:'center',
      width: 'auto', 
      transform: 'translateY(-50%)',
    }}
    footer={[]}>
    <Typography.Title level={5}>Leave Project?</Typography.Title>
    <Typography.Paragraph>Are you sure you want to exit? Your <br/>project will not be saved</Typography.Paragraph>
    <Row>
      <Col><Button type="link" onClick={onContinue} className='continue-btn'>Continue Working</Button></Col>
      <Col><Button onClick={onLeave} className='leave-btn'>Leave Project</Button></Col>
    </Row>
  </Modal>
}