import { isUserNeverHadSubscription, isUserPro } from './subscription';
import { authProvider } from '../authPrvider/authProvider';

import { delay } from './util';
import { MixpanelEvent, mixPanelUtils } from '../pages/public-view/mixpanel/mixpanel';

export const processEntryPoint = (entryPoint: string, incituUser: any, userAuth0: any, isEmailVerified = false) => {
  if (entryPoint.includes('landing')) {
    return userAuth0?.email_verified || isEmailVerified ? '/pricing' : '/email-verification';
  } else {
    return userAuth0?.email_verified || isEmailVerified
      ? isWelcomeRequired(incituUser) ? '/account-details' :  '/map'
      : '/email-verification';
  }
};

export const handleEntryPoint = (
  userAuth0: any,
  incituUser: any,
  entryPoint: any,
) => {

  // TODO: onboarding step
  if (entryPoint && isUserNeverHadSubscription(incituUser)) {
    return processEntryPoint(entryPoint, incituUser, userAuth0);
  }

  else if (isUserPro(incituUser)) {
    return '/projects';
  } else {
    return undefined
  }
};

export const refetchUser = async (fetchUserInfo: any, userAuth0: any) => {
  let retryCount = 0;
  const maxRetries = 3;

  let userData;

  while (retryCount < maxRetries) {
    const incituUser = authProvider.getInCituUser();
    if(incituUser && incituUser?.externalId) {
      const fetchedUser: any = await fetchUserInfo(incituUser.externalId);
      if (fetchedUser) {
        userData = fetchedUser.data;
        return userData;
      }
    } else if (userAuth0 && userAuth0.sub) {
      const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
      if (fetchedUser && fetchedUser?.data) {
        authProvider.saveInCituUser(fetchedUser.data);
        userData = fetchedUser.data;
        return userData;
      }
    } else {
      console.log('userAuth0 or incituUser is undefined, userAuth0', userAuth0);
      console.log('userAuth0 or incituUser is undefined, incituUser', incituUser);
      await delay(1000);
      retryCount++;
      continue;
    }

    retryCount++;
    console.log(`Retrying fetchUserInfoByEmail... Attempt ${retryCount}`);

    if (retryCount < maxRetries) {
      await delay(1000)
    }
  }
  return null;
};

export const updateEntryPoints = async (fetchUserInfo: any, updateUserMetadata: any, externalId: string) => {
  let userData: any = await fetchUserInfo(externalId);
  userData = userData.data;

  if (userData) {
    const isSubscribedForNewsLetter = authProvider.getSignUpForNewsLetter();
    if (isSubscribedForNewsLetter !== undefined) {
      const updatedMetadata = {
        ...userData.metadata,
        emailVerified: false,
        newsletterSubscription: isSubscribedForNewsLetter,
        entryPoint: authProvider.getUserEntryPoint() ?? window.location.href,
      };
      mixPanelUtils.track(MixpanelEvent.USER_SIGNED_UP, { entryPoint: updatedMetadata.entryPoint })
      await updateUserMetadata(userData.externalId, updatedMetadata);
    }
  }
}

export const checkSubscription = async (fetchUserInfo: any, updateUserMetadata: any, userAuth0: any) => {
  const userData = await refetchUser(fetchUserInfo, userAuth0)

  // if (userData) {
  //   const isSubscribedForNewsLetter = authProvider.getSignUpForNewsLetter();
  //   if (isSubscribedForNewsLetter !== undefined) {
  //     const updatedMetadata = {
  //       ...userData.metadata,
  //       newsletterSubscription: isSubscribedForNewsLetter,
  //       entryPoint: authProvider.getUserEntryPoint() ?? window.location.href,
  //     };
  //     mixPanelUtils.track(MixpanelEvent.USER_SIGNED_UP, { entryPoint: updatedMetadata.entryPoint })
  //     await updateUserMetadata(userData.externalId, updatedMetadata);
  //   }
  // }

  if (userData && (userData?.subscriptionPlan || userData?.role === 'ADMIN')) {
    return true;
  }
  return false;
};

export const isWelcomeRequired = (incituUser: any) => {
  const isRequired = !(incituUser && incituUser.firstName && incituUser.firstName.length > 0 && !incituUser.firstName.includes('@'));
  return isRequired
}
