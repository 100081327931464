import {FormImageUploader} from '../../../../../components/FormImageUploader';
import {JPG_CONTENT_TYPE} from '../../../../../constants/files';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';

export const UploadImages = observer(({ loading, projectId }: { loading: boolean, projectId: string }) => {
  const { projectCreationStore } = useContext(Context);
  const { cover, setCover } = projectCreationStore

  // TODO A: remove after QA
  // const [files, setFiles] = useState([]);
  // useEffect(() => {
  //   const coverPlainObject = toJS(cover);
  //   setFiles(cover ? coverPlainObject : []);
  //
  // }, [cover]);

  const handleFilesChange = (newFiles: any) => {
    setCover(newFiles)
  };

  return (
    <FormImageUploader
      value={cover}
      onChange={handleFilesChange}
      projectId={projectId}
      name="cover"
      accept={[JPG_CONTENT_TYPE]}
      maxCount={1}
      multiple={false}
      isCover={true}
      version={'NewNew'}
    />
  );
});
