import { Project } from '../dataProvider/gql/types';
import dataProvider from '../dataProvider/dataProvider';

export default function useOrgInfoFetch() {

  const fetchOrgInfo = async (orgId: any) => {

    const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
    try {
      const {
        data: orgInfo,
      }: any = await dProvider.getOne<any>({
        resource: 'organization',
        id:orgId,
        metaData: {
          fields: [
            'id',

          ],
        },
      });

      return {data: orgInfo}
    } catch (error) {
      console.error('Error fetching org details:', error);
      return undefined;
    }
  }

  return [fetchOrgInfo];
}
