import { MAX_MODEL_UPLOADS } from '../constants/subscription';
import { authProvider } from '../authPrvider/authProvider';

export const isUserPro = (user: any) => {
  return user?.subscriptionPlan || user?.role === 'ADMIN';
};

export const isUserHasActiveSubscription = (user: any) => {
  if (user?.role === 'ADMIN') {
    return true;
  }
  return (user?.subscriptionPlan && user?.subscriptionPlan.active);
};

export const isUserNeverHadSubscription = (user: any) => {
  if (user?.role === 'USER') {
    return true;
  }
  if(user?.role === 'ADMIN') {
    return false
  }
  return !user?.subscriptionPlan;
};

export const isSubscriptionProjectLimitsAchieved = (userIncitu: any, projects: any) => {
  if (userIncitu?.subscriptionPlan?.planDetails?.numberOfProjects > 0 && userIncitu?.subscriptionPlan?.planDetails?.numberOfProjects <= projects?.data?.length  && userIncitu?.role !== 'ADMIN') {
    return true
  }
  return false
}

export const isSubscriptionModelUploadLimitsAchieved = (userIncitu: any) => {
  if (userIncitu?.subscriptionPlan?.type === 'BASIC' && userIncitu?.subscriptionPlan?.modelUploads >= MAX_MODEL_UPLOADS && userIncitu?.role !== 'ADMIN') {
    return { isLimitAchieved: true, modelUploads: userIncitu?.subscriptionPlan?.modelUploads ?? 0, isBasicPlan: userIncitu?.subscriptionPlan?.type === 'BASIC' }
  }
  return { isLimitAchieved: false, modelUploads: userIncitu?.subscriptionPlan?.modelUploads ?? 0,  isBasicPlan: userIncitu?.subscriptionPlan?.type === 'BASIC' }
}

// TODO: example project
export const isRequiresExampleProject = (user: any) => {
  return false
  // return (user?.subscriptionPlan && user?.subscriptionPlan.active && (user?.subscriptionPlan.type === 'BASIC' || user?.subscriptionPlan.type === 'PRO'));
};

export const isBasicSubscriptionPro = (user: any) => {
  if(user?.role === 'ADMIN') {
    return false
  }
  return user?.subscriptionPlan?.type === 'BASIC'
};

export const checkWarningLimitModalUploadPopupVisibility = () => {
  const lastDisplayTime = authProvider.getLastDisplayModalReplaceWarningTime();
  const currentTime = new Date();

  if (lastDisplayTime) {
    const lastTime = new Date(lastDisplayTime).getTime();
    const currentTimeInMillis = currentTime.getTime();
    const minutesDifference = Math.abs(currentTimeInMillis - lastTime) / 60000; // Convert to minutes
    if (minutesDifference > 1440 ) { // 24 hours
      return true;
    }
    return false;
  }

  return true;
};

export const getRemainingNumberOfUploads = (inCituUser: any) => {
  const uploadNumbersLeft =
    MAX_MODEL_UPLOADS -
    inCituUser?.subscriptionPlan?.modelUploads;
  return uploadNumbersLeft;
}
