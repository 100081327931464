import { getAWSUrl } from '../pages/project-creation-step-composer/utils/aws';

export const getFileLocationUrl = (model: any) => {
  const fileLocation = model?.url ?? model?.fileLocation;
  
  return !model?.file && fileLocation ? `${getAWSUrl()}/${fileLocation}` : fileLocation;
}

export const filterUnsupportedFeatures = (features: Array<string>) => features.filter((feature) =>
  feature === 'KHR_mesh_quantization'
  || feature === 'KHR_draco_mesh_compression'
  || feature === 'KHR_texture_basisu',
);
