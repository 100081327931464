import { useDelete } from '@pankod/refine-core';
import useSaveModel from './useSaveModel';
import { Model3D } from '../dataProvider/gql/types';

// export const useUploadModel = () => {
//   const [saveModel] = useSaveModel();
//   const { mutate: deleteModel3DMutate } = useDelete<Model3D>();
//
//   const uploadModel = async (item: any, modelsList: any, buildingId: string) => {
//     if (item.data) {
//       if (Object.keys(modelsList || {}).length > 0) {
//         for (const order of Object.keys(modelsList)) {
//           // Models create/update
//           await saveModel({
//             projectInfo: item.data,
//             modelsList,
//             order,
//             buildingId,
//           });
//         }
//       }
//
//       const models: any = Object.values(modelsList).reduce((acc: any, item: any) => {
//         const modelsArr = [...acc, ...Object.values(item)];
//         return modelsArr;
//       }, []);
//
//       // Delete models
//       const building = item.data?.buildings?.find((building: any) => building.id === buildingId);
//
//       const modelsToDelete = building?.model3ds?.filter((model: any) => {
//         const modelExist = models.find((item: any) => item.id === model.id);
//         return !modelExist;
//       });
//
//       if(modelsToDelete?.length > 0) {
//         for (const model of modelsToDelete) {
//           await deleteModel3DMutate({
//             resource: 'model3ds',
//             id: model.id,
//             metaData: {
//               fields: ['id'],
//             },
//           });
//         }
//       }
//     }
//   }
//
//   return [uploadModel];
// }

export const useUploadModel = () => {
  const [saveModel] = useSaveModel();
  const { mutate: deleteModel3DMutate } = useDelete<Model3D>();

  const uploadModel = async (item: any, modelsList: any, buildingId: string) => {

    if (item.data) {
      if (Object.keys(modelsList || {}).length > 0) {
        for (const order of Object.keys(modelsList)) {
          // Models create/update
          await saveModel({
            projectInfo: item.data,
            modelsList,
            order,
            buildingId,
          });
        }
      }

      // const models: any = Object.values(modelsList).reduce((acc: any, item: any) => {
      //   const modelsArr = [...acc, ...Object.values(item)];
      //   return modelsArr;
      // }, []);
      //
      // // Delete models
      // const building = item.data?.buildings?.find((building: any) => building.id === buildingId);
      //
      // console.log('----> BEFORE building?.model3ds:', building?.model3ds);
      // const modelsToDelete = building?.model3ds?.filter((model: any) => {
      //   const modelExist = models.find((item: any) => item.id === model.id);
      //   return !modelExist;
      // });
      // console.log('----> AFTER modelsToDelete', modelsToDelete);

      // console.log('__DEBUG___modelsToDelete:', modelsToDelete)
      // if(modelsToDelete?.length > 0) {
      //   for (const model of modelsToDelete) {
      //     await deleteModel3DMutate({
      //       resource: 'model3ds',
      //       id: model.id,
      //       metaData: {
      //         fields: ['id'],
      //       },
      //     });
      //   }
      // }
      // console.log('----> BEFORE building?.model3ds:', building?.model3ds);
      // const modelsToDelete = building?.model3ds?.filter((model: any) => {
      //   const modelExist = models.find((item: any) => item.id === model.id);
      //   return !modelExist;
      // });
      // console.log('----> AFTER modelsToDelete', modelsToDelete);
      /*
      if(modelsToDelete?.length > 0) {
        for (const model of modelsToDelete) {
          await deleteModel3DMutate({
            resource: 'model3ds',
            id: model.id,
            metaData: {
              fields: ['id'],
            },
          });
        }
      }
      */
    }
  }

  return [uploadModel];
}
