import { useState } from 'react';
import { Button, Col, Input, Modal, Row, Typography } from '@pankod/refine-antd';
import { sendArcGISConvertRequest } from '../../../api/arcgis';
import { authProvider } from '../../../authPrvider/authProvider';
import { delay } from '../../../utils/util';

export default function PopupARCGIS({
  openPopup,
  setOpenPopup,
  handleSetOpenPopupArcGIS,
  handleDisplayProgressPopup,
}: any) {
  const [itemId, setItemId] = useState('');
  const [error, setError] = useState('');

  const handleSend = async () => {
    const inCituUser = authProvider.getInCituUser()
    if (!itemId) {
      setError('FeatureLayer ID is required.');
      return;
    }

    if (!inCituUser.organizationUsers || inCituUser.organizationUsers.length === 0) {
      setError('User does not have Organisation.');
      return;
    }

    setError('');
    const incituOrgId = inCituUser.organizationUsers[0].organizationId;
    const arcgisToken = authProvider.getArcGISToken();

    if (!arcgisToken) {
      setError('User is not authorised in ArcGIS system.');
      return;
    }

    handleSetOpenPopupArcGIS();
    handleDisplayProgressPopup('loading');
    const response = await sendArcGISConvertRequest(
      itemId,
      incituOrgId,
      arcgisToken,
      inCituUser.id,
    );

    await delay(2000)
    handleDisplayProgressPopup(response?.status?.toString().toLowerCase() ?? 'failed');
    setItemId('')
  };

  const closePopupWindow = () => {
    setOpenPopup(!openPopup)
    setItemId('')
  }

  return (
    <Modal
      className='close-popup-modal-wrapper'
      closeIcon={false}
      closable={false}
      open={openPopup}
      width={'auto'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        transform: 'translateY(-50%)',
      }}
      footer={[]}
    >
      <Typography.Title level={5}>Create AR Project</Typography.Title>
      {/*<Typography.Paragraph>*/}
      {/*  <Input*/}
      {/*    placeholder="Enter ORG ID"*/}
      {/*    value={incituOrgId}*/}
      {/*    onChange={(event) => setIncituOrgId(event.target.value)}*/}
      {/*  />*/}
      {/*</Typography.Paragraph>*/}
      <Typography.Paragraph>
        <Input
          style={{width: 300}}
          placeholder="Enter ArcGIS FeatureLayer item ID"
          value={itemId}
          onChange={(event) => setItemId(event.target.value)}
        />
      </Typography.Paragraph>
      {error && <Typography.Paragraph style={{ color: 'red' }}>{error}</Typography.Paragraph>}
      <Row>
        <Col>
          <Button  
            style={{width: 185, marginRight: 15}} 
            onClick={() => closePopupWindow()} 
            className='continue-btn'>
              Close popup
          </Button>
        </Col>
        <Col>
          <Button style={{width: 100}} onClick={handleSend} className='continue-btn'>Create</Button>
        </Col>
      </Row>
    </Modal>
  );
}
