/* eslint-disable max-len */

import { Spin, Typography} from 'antd';
import React from 'react';

// import './style.less';

import attentionIcon from '../../../../../../assets/attentionIcon.png';

const AttentionBeingPublished = () => {
  return (
    <>
      <div style={{
        paddingTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '313px',
      }}>
        <span style={{paddingRight: 10}}>
          <img src={attentionIcon}/>
        </span>
        <span>
          <Typography.Paragraph style={{fontSize: 12, fontWeight: 'bold', marginBottom: 0}}>
              This will take a couple of minutes
          </Typography.Paragraph>
          <Typography.Paragraph style={{fontSize: 12}}>
            Large files can take up to 10 minutes to load. Check back in a couple of minutes.
          </Typography.Paragraph>
        </span>
      </div>
    </>
  );
};

export default AttentionBeingPublished;
