import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Table} from 'antd';
import ProjectCardActions from '../../components/ProjectGridListings/components/ProjectCardActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link, redirect} from 'react-router-dom';
import {Button, Spin} from '@pankod/refine-antd';
import { UseInfiniteListProps } from '@pankod/refine-core/dist/hooks/data/useInfiniteList';
import { BaseRecord, CrudFilter, HttpError, useInfiniteList } from '@pankod/refine-core';

import './style.less';
import { authProvider } from '../../authPrvider/authProvider';
import {observer} from 'mobx-react-lite';
import {Context} from '../../index';
import {PlusOutlined} from '@ant-design/icons';
import TableWithButtonNewProject from './components/TableWithButtonNewProject';
import RenderCreatedNamePerson from '../Cell/RenderCreatedNamePerson';
import moment from 'moment';

const ProjectTable = observer((props:any) => {
  const { projectCreationStore } = useContext(Context);
  const {userName} =  projectCreationStore
  const { searchFormProps, deleteProject, setProjectId, setIsModalOpen }:any=props

  const [search, setSearch] = useState('');
  const [projects, setProjects]:any = useState([]);

  const inCituUser = authProvider.getInCituUser();
  let userOrganizationId;

  if (inCituUser?.role !== 'ADMIN') {
    if(inCituUser?.organizationUsers.length > 0) {
      userOrganizationId = inCituUser?.organizationUsers[0].organizationId;
    }
  }

  const filters: Array<CrudFilter | undefined> = [
    search ? { field: 'name', operator: 'contains', value: search } : undefined,
    userOrganizationId
      ? { field: 'organizationId', operator: 'eq', value: userOrganizationId }
      : undefined,
  ];
  const filteredFilters = filters.filter((filter): filter is CrudFilter => filter !== undefined);

  const listProps: UseInfiniteListProps<BaseRecord, HttpError> = {
    resource: 'projects',
    config: {
      filters: filteredFilters,
      hasPagination: false,
      sort: [{ field: 'updatedAt', order: 'desc' }],
    },
    metaData: {
      fields: [
        'id',
        'name',
        'subtitle',
        'origin',
        'address',
        'timelineEvents{id updatedAt}',
        'description',
        'status',
        'createdAt',
        'updatedAt',
        'address',
        'longitude',
        'latitude',
        'altitude',
        'organizationId',
        // 'statusDetail',
        'borough',
        'ceqr',
        'bbl',
        // 'attributes',
        'appClipSync',
        'autoGenerateModels',
        // eslint-disable-next-line max-len
        'buildings{id buildingHeight model3ds{id type fileLocation externalId order anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
        'organization{id organizationUsers{user{id email firstName lastName}}}',
        'projectUsers { relation user { firstName lastName email }}',
        'documents{id url name type category}',
        // 'timelineEvents{id name timelineEventType{id name} updatedAt}',
        'projectViewPreference{id visibility}',
      ],
    },
  };
  const { data, isError, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage }: any
    = useInfiniteList(listProps as UseInfiniteListProps<BaseRecord, HttpError>);

  useEffect(()=>{
    if(data){
      const projects = data?.pages.flatMap((page: any) => page.data) || [];
      setProjects(projects)
    }
  },[data])

  const handleOpenModal = (projectId: string) => {
    setProjectId(projectId);
    setIsModalOpen(true);
  }

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a:any, b:any) => a.name - b.name,
      render:(value:string,row:any)=><Link className='row-name' to='' onClick={() => {
        handleOpenModal(row.id);
      }}>{value}</Link>,
    },
    {
      title: 'Created by',
      // dataIndex: 'userName',
      // key: 'userName',
      render:(value:string,row:any)=><Link className='row-name' to='' onClick={() => {
        handleOpenModal(row.id);
      }}>
        <RenderCreatedNamePerson row={row} />
      </Link>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render:(value:string,row:any)=><Link to='' className='row-name' onClick={() => {
        handleOpenModal(row.id);
      }}>{value}</Link>,
    },
    {
      title: 'Date Published',
      render: (value:any,row:any) => (
        moment(value.createdAt).format('MM/DD/YYYY')
      ),
    },
    // {
    //   title: 'Last Edited',
    //   dataIndex: 'timelineEvents',
    //   key: 'timelineEvents',
    //   render: (value:any,row:any) => (
    //     value.updatedAt || '-'
    //   ),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (value:any,row:any) => (
        <div style={{width: 100}}> 
          <ProjectCardActions project={row} deleteProject={deleteProject} handleOpenProjectForm={handleOpenModal} />
        </div>
      ),
    },
  ];

  useEffect(()=>{
    const searchValue = searchFormProps.form.getFieldsValue().name;
    setSearch(searchValue);

  },[searchFormProps])

  // position: absolute;
  // margin-top: 10%;
  // /* background-color: red; */
  // width: 100%;
  // z-index: 1;
  // border: 1px solid red;
  // border-top-right-radius: 0px;
  // border-top-left-radius: 0px;
  // background-color: white;

  return (
    <div className="project-table-container">
      {data && data.pages && data.pages[0].data.length > 0 ?
        <InfiniteScroll
          hasMore={hasNextPage}
          next={()=>fetchNextPage()}
          loader={isFetchingNextPage && <Spin />}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b></b>
            </p>
          }
          pullDownToRefresh={false}
          dataLength={10}
        >
          <Table
            dataSource={projects}
            loading={isLoading || isFetchingNextPage}
            columns={columns}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                // onClick: event => { handleOpenModal(record.id); },
              };
            }}
          />
        </InfiniteScroll>
        :
        <TableWithButtonNewProject openProjectWizard={props.openProjectWizard} columns={columns}/>
      }

    </div>
  );
});

export default ProjectTable;
