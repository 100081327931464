import { makeAutoObservable, reaction, toJS } from 'mobx';
import {ProjectValues} from '../interfaces/project';
import {buildDocuments, loadDocuments} from '../pages/project-creation-step-composer/utils/document';
import {buildCover} from '../pages/project-creation-step-composer/utils/cover';
import {buildEvents, loadEvents} from '../pages/project-creation-step-composer/utils/event';
import {buildPreference} from '../pages/project-creation-step-composer/utils/preference';
import {MixpanelEvent, mixPanelUtils} from '../pages/public-view/mixpanel/mixpanel';
import {showPublishModal} from '../components/Modal/ShowPublishModal';
import {showErrorModal} from '../components/Modal/ShowErrorModal';
import {errorCommon} from '../constants/messages/error/common';
import { AssetCategory, ProjectVisibility } from '../enums';
import {loadProjectDetails} from '../pages/project-creation-step-composer/utils/project';
import { delay, getFileExtension, getQRCodeUrl } from '../utils/util';
import * as util from '../utils/util';
import {message} from 'antd';
import usePublishStatusPooling from '../hooks/usePublishStatusPooling';
import { showSuccessModal } from '../components/Modal/ShowSuccessModal';
import useUploadPreview from '../hooks/useUpdatePreview';
import { Model3D } from '../dataProvider/gql/types';

export enum PublishingStatus {
  None = 'None',
  InProgress = 'In Progress',
  Timeout = 'Timeout',
  Failed = 'Failed',
  Success = 'Success',
}

const dummyLinks = [
  {
    title: 'Project link',
    link: 'https://app.incitu.us/project-edoject',
  },
  {
    title: 'Email',
    link: 'https://app.incitu.us/project-edoject',
  },
  {
    title: 'WhatsApp',
    link: 'https://app.incitu.us/project-edoject',
  }]

export default class ProjectCreationStore {

  isProjectChangesExists = false
  projectId= ''

  visibilityPublishStatus = ProjectVisibility.RESTRICTED
  isSnapToGround = true
  isModelReplaced = false
  projectName = ''
  projectDescription = ''
  projectSubTitle = ''
  projectOrigin = ''
  buildingsList: Array<any> = []

  modelUrl = ''
  address: any = ''
  latitude = '0'
  longitude = '0'
  altitude = '0'
  rotation = '0'
  initLatitude: any;
  initLongitude: any;
  initAltitude: any;
  initRotation: any;

  cover: any  = []

  clients = []
  documents: any = [{}]
  keyDates: any = [{}]

  cta = ''
  ctaLink = ''

  qrCodeUrl = ''
  wpURI = ''
  mailtoURL = ''

  projectURL=''
  arrayCopyLink  =  dummyLinks

  // actions
  pressEnterEvent = false
  takeScreenshot = false
  isQRModalOpen = false

  isFormValid = false
  isProjectSaving = false

  isPublishing = false
  publishingStatus = PublishingStatus.None
  isPoolingProgressStarted = false

  showInfo = false

  isUploadModel = false
  showErrorMessage = false

  saveInfo: any
  savedProjectData: any = {}

  // steps
  createStep = 0
  isEdit = false
  projectSteps: any = [
    { title: 'Upload' },
    { title: 'Place' },
    { title: 'Details' },
    { title: 'Share' },
  ];

  previewImgDetailStep = ''
  isCreateModalVisible = false
  isShowLimitModelUploadPopup = false
  isShowWarningLimitModelUploadPopup = false
  isIgnoreWarningLimitCheck = false
  time = 0

  userName = ''
  userEmail = ''

  projectSize = ''
  objForCheckChanges: any = [{}]

  buildQRCodeURL = () => {
    let qrCodeUrl = ''
    if(this.projectId && (this.publishingStatus === PublishingStatus.Success || !this.isProjectChangesExists)) {
      qrCodeUrl = getQRCodeUrl(this.projectId);
    }
    this.qrCodeUrl = qrCodeUrl
    return qrCodeUrl
  }
  constructor() {
    makeAutoObservable(this)

    // Add reactions to track changes in specific variables
    reaction(() => this.projectName, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.projectDescription, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.projectSubTitle, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.projectOrigin, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.buildingsList, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.modelUrl, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.address, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.latitude, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.longitude, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.altitude, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.rotation, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.cover, () => { this.setIsProjectChangesExists(true); });
    // reaction(() => this.clients, () => { this.setIsProjectChangesExists(true); });
    // reaction(() => this.documents, () => { this.setIsProjectChangesExists(true); });
    // reaction(() => this.keyDates, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.cta, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.ctaLink, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.visibilityPublishStatus, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.isSnapToGround, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.isModelReplaced, () => { this.setIsProjectChangesExists(true); });
    reaction(() => this.isUploadModel, () => { this.setIsProjectChangesExists(true); });
  }

  onFinish = async (values: any, isEdit: boolean, publishProject: any) => {
    this.setIsProjectSaving(true);
    // this.setPublishingState(true, PublishingStatus.InProgress);

    if (this.pressEnterEvent){
      console.log('press enter event')
      return
    }

    const { visibilityPublishStatus, channels } = values;

    const appClipSync = true; // channels?.includes('ios') || false;
    console.log('____appClipSync: ', appClipSync)
    try {
      // setIsLoading(true);
      /* eslint-disable */
            // const statusDetail = {
            //     upcoming: '',
            //     ulurp_status: '',
            //     latest_update: '',
            //     construction_status: 'UNDER_CONSTRUCTION',
            // }

            const formValues: ProjectValues = {
                name: this.projectName,
                subtitle: this.projectSubTitle,
                latitude: this.latitude || '0',
                description: this.projectDescription,
                longitude: this.longitude || '0',
                altitude: this.altitude || '0',
                address: this.address,
                origin: this.projectOrigin,
                // statusDetail: statusDetail,
                appClipSync: appClipSync,
            };

            let cover;
            const isCoverExist = this.cover?.length > 0;

            if (this.cover?.length > 0 && this.cover[0] instanceof File) {
              const coverQuery = await buildCover(this.cover[0]);
              cover = coverQuery;
            }

            // if (values?.documents?.length > 0) {
            //     const documentsQuery = await buildDocuments(values.documents);
            //     formValues.documents = documentsQuery;
            // }

            // //key events
            // if (Object.prototype.hasOwnProperty.call(values, 'timelineEvents')) {
            //     formValues.timelineEvents = buildEvents(values);
            // }

            formValues.projectViewPreference = buildPreference(visibilityPublishStatus, isEdit, this.isSnapToGround);

            //saving to DB
            const buildingsListToSave = this.buildingsList
            const clientsToSave = this.clients
            const saveInfoPromise = this.saveInfo({ ...formValues }, buildingsListToSave, cover, clientsToSave, isCoverExist, this.rotation);
            saveInfoPromise.then(async (data: any) => {
              console.log('________saveInfo:', data);
              this.isEdit = true
              this.setIsProjectSaving(false);
              this.setSavedProjectData(data.data)

              const resultedProjectId = data.data.id;
              this.getShareLink()
              this.setTakeScreenshot(true)

              mixPanelUtils.track(isEdit ? MixpanelEvent.PROJECT_UPDATED : MixpanelEvent.PROJECT_CREATED, { projectId: resultedProjectId })

              // Publishing
              this.setPublishingState(true, PublishingStatus.InProgress);
              await publishProject(resultedProjectId);

              this.setInitLatitude(parseFloat(this.latitude));
              this.setInitLongitude(parseFloat(this.longitude));
              this.setInitAltitude(parseFloat(this.altitude));
              this.setInitRotation(parseFloat(this.rotation));


              // startPublishStatusPooling()

              // TODO: return
              // hideProgressModal();

              // const resultedProjectId = data.data.id;
              //
              // if(!isEdit) {
              //     this.redirectToPage(resultedProjectId)
              // }
              //
              // saveThumbnailsPreview(data.data, isEdit)
              // this.setSavedProjectData(data.data)
              // this.setTakeScreenshot(true)
              //
              // showPublishModal(resultedProjectId, publishProject);
            }).catch((error: any) => {
                console.error('Error for saveInfo occurred:', error);
                this.setPublishingState(false, PublishingStatus.Failed);
                this.setIsProjectSaving(false);

                if (error.message.includes('You have reached the maximum number of model uploads')) {
                  this.setIsShowLimitModelUploadPopup(true);
                }
            });
        } catch (error) {
            this.setIsProjectSaving(false);
            this.setPublishingState(false, PublishingStatus.Failed);
        }
    };

    setTakeScreenshot = (value: boolean) =>{
        console.log('_0000_ call___handleTakeScreenshot_____')
        this.takeScreenshot = value
    }

    handleDownloadQRCode = async () => {
        try {
            const response = await fetch(this.qrCodeUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');

            downloadLink.href = blobUrl;
            downloadLink.download = `QR_${this.projectId}.jpg`;

            document.body.appendChild(downloadLink);

            downloadLink.click();

            document.body.removeChild(downloadLink);

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading QR code:', error);
        }
    }

    checkLinkForCopiedQRCode = async (link: string) => {
        try {
            const response = await fetch(link);
            if (response.ok){
                mixPanelUtils.track(MixpanelEvent.PROJECT_QR_LINK, {projectId: this.projectId})
                util.copyToClipboard(this.qrCodeUrl)
                message.info('Copied');
            }else {
                this.nextActionIfError()
            }
        }catch (error){
            console.log(error);
        }
    }

    nextActionIfError = () =>{
        showErrorModal('Sorry',`Error downloading QR code for ${this.projectName}`)
    }

    setKeyDates = (value: any)=>{
        this.keyDates = value
    }
    setDocuments = (value: any) => {
        this.documents = value
    }

    setSavedProjectData = (projectDetailsInformation: any) => {
        console.log('000008888 setSavedProjectData')
        console.log(projectDetailsInformation)
        if (projectDetailsInformation){
          const usdzModel3ds =  projectDetailsInformation?.buildings[0]?.model3ds.filter((model3d: Model3D) => model3d.type === 'usdz');
          let anchorCoordinate = projectDetailsInformation?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
          let modelAnchoring = projectDetailsInformation?.buildings?.[0]?.defaultAnchoring;
          if(usdzModel3ds?.length > 0) {
            anchorCoordinate = usdzModel3ds[0]?.anchoring?.anchorCoordinate || projectDetailsInformation?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
            modelAnchoring = usdzModel3ds[0]?.anchoring || projectDetailsInformation?.buildings?.[0]?.defaultAnchoring;
          }

          this.savedProjectData = projectDetailsInformation
            this.rotation = this.isEdit ? modelAnchoring?.rotation : projectDetailsInformation?.buildings[0]?.defaultAnchoring?.rotation
            this.address = projectDetailsInformation.address
            this.altitude = anchorCoordinate?.altitude || projectDetailsInformation.altitude
            this.buildingsList = projectDetailsInformation.buildings
            this.projectDescription = projectDetailsInformation.description
            this.documents = projectDetailsInformation.documents
            this.latitude = anchorCoordinate?.latitude || projectDetailsInformation.latitude
            this.longitude = anchorCoordinate?.longitude || projectDetailsInformation.longitude
            this.projectName = projectDetailsInformation.name
            this.projectSubTitle = projectDetailsInformation.subtitle || ''
            this.projectOrigin = projectDetailsInformation.origin || ''
            this.projectId = projectDetailsInformation.id
            this.isSnapToGround = projectDetailsInformation?.projectViewPreference?.isSnapToGround

            const usdzModels = projectDetailsInformation?.buildings[0]?.model3ds?.filter((model3d: Model3D) => model3d.type == 'usdz')
            this.projectSize = usdzModels?.length > 0 ? usdzModels[0]?.fileSizeMb || 0 : 0; // projectDetailsInformation?.buildings[0]?.model3ds[0]?.fileSizeMb
            this.visibilityPublishStatus = projectDetailsInformation?.projectViewPreference?.visibility || ProjectVisibility.RESTRICTED
            // this.showErrorMessage = false

            // this.isProjectChangesExists = false
            // this.setIsProjectChangesExists(false)
        }
    }

    clearProjectData = () => {

      console.log('==========>>>>>> CLEAR PROJECT DATA <<<<<<==========');

      this.visibilityPublishStatus = ProjectVisibility.RESTRICTED
      this.projectId = ''
      this.projectName = ''
      this.projectDescription = ''
      this.projectSubTitle = ''
      this.buildingsList = []

      this.modelUrl = ''
      this.address = ''
      this.latitude = '0'
      this.longitude = '0'
      this.altitude = '0'
      this.rotation = '0'
      this.initLatitude = undefined;
      this.initLongitude = undefined;
      this.initAltitude = undefined;
      this.initRotation = undefined;

      this.cover  = []

      this.clients = []
      this.documents = [{}]
      this.keyDates = [{}]

      this.cta = ''
      this.ctaLink = ''

      this.qrCodeUrl = ''
      this.wpURI = ''
      this.mailtoURL = ''

      this.arrayCopyLink  = dummyLinks

      this.pressEnterEvent = false
      this.takeScreenshot = false
      this.isQRModalOpen = false

      this.isFormValid = false
      this.isProjectSaving = false
      this.isPublishing = false
      this.showInfo = false
      this.isUploadModel = false
      this.showErrorMessage = false
      this.isSnapToGround = true

      this.saveInfo = {}
      this.savedProjectData = {}

      this.createStep = 0

      this.isEdit = false
      // this.isProjectChangesExists = false

      this.previewImgDetailStep = ''
      this.setIsProjectChangesExists(false)
    }

    setIsFormValid = () => {
        if (this.buildingsList &&
            this.buildingsList.length &&
            this.buildingsList[0] &&
            this.buildingsList[0]?.modelsList?.[0]?.usdz){
            this.isFormValid = true
        }else {
            this.isFormValid = false
        }
    }
    setSaveInfo= (value: any)=> {
        this.saveInfo = value
    }
    setIsProjectSaving =(value: boolean) =>{
        this.isProjectSaving = value
        this.time = 0
    }

    setIsProjectChangesExists = (value: boolean) => {
      console.log('setIsProjectChangesExists :', value)
      this.isProjectChangesExists = value
    }

    setVisibilityPublishStatus = (value: any) => {
      this.visibilityPublishStatus = value
    }

    setPoolingProgressStarted=(value: boolean) =>{
      this.isPoolingProgressStarted = value
    }

    setPublishingState =(value: boolean, status: PublishingStatus = PublishingStatus.None) =>{
      this.isPublishing = value
      this.publishingStatus = status
    }

    setBuildingsList = (value: any) => {
        this.buildingsList = toJS(value)
        this.setIsFormValid()
    }

    changeProjectName = (value: string) => {
        if (value.length > 0){
            this.showErrorMessage = false
        }
      console.log(value)
      this.projectName = value
    }
    changeProjectDescription = (value: string) => {
      this.projectDescription = value
    }
    changeProjectSubTitle = (value: string) => {
      this.projectSubTitle = value
    }
    changeProjectOrigin = (value: string) => {
      this.projectOrigin = value
    }
    setShowInfo = () => {
      this.showInfo = !this.showInfo
    }

    setCover = (value: any) => {
        this.cover = value
        this.setPreviewImgDetailStep(value)
    }

    setPreviewImgDetailStep = (value: any) =>{
      this.previewImgDetailStep = ''
      if(value.length > 0 && value[0] instanceof File){
        this.previewImgDetailStep = URL.createObjectURL(value[0])
      }
    }

    changeCta = (value: string) => {
      this.cta = value
    }
    changeCtaLink = (value: string) =>{
      this.ctaLink = value
    }
    setCreateStep = (value: number, isEdit: boolean, publishProject: any) =>{
          this.createStep = value
          this.isEdit = isEdit

          if (value === 3 && this.projectName.length > 0){
            this.setPoolingProgressStarted(false);
            this.setIsProjectSaving(false);
            this.setPublishingState(false, PublishingStatus.None);
          } else if (value === 3 && this.projectName.length === 0) {
              this.createStep = 2
              this.showErrorMessage = true
          }
    }

    projectCreateOrUpdate = (isEdit: boolean, publishProject: any) => {
      console.log("projectCreateOrUpdate isEdit: ", isEdit)
      this.isEdit = isEdit

      const valuesToSave: any = {}
      valuesToSave.visibilityPublishStatus = this.visibilityPublishStatus
      this.onFinish(valuesToSave, isEdit, publishProject)
    }

    setProjectId = (value: string) => {
      this.projectId = value
    }

    setModelUrl = (value: string) => {
        this.modelUrl = value
    }
    setAltitude = (value: string) => {
        this.altitude = value
    }
    setLatitude = (value: string) => {
        this.latitude = value
    }
    setLongitude = (value: string) => {
        this.longitude = value
    }
    setAddress = (value: string) => {
        this.address = value
    }
    setRotation = (value: string) => {
        this.rotation = value
    }
    setInitLatitude = (value: number) => {
        this.initLatitude = value;
    }
    setInitLongitude = (value: number) => {
        this.initLongitude = value;
    }
    setInitAltitude = (value: number) => {
        this.initAltitude = value;
    }
    setInitRotation = (value: number) => {
        this.initRotation = value;
    }


    onScreenshotTaken = async (dataUrl: string, savePreviewFromMap: any) => {
      console.log('handleScreenshotTaken', dataUrl)
      if (this.savedProjectData) {
        try {
          const base64Image: string = dataUrl
          const res = await savePreviewFromMap(this.savedProjectData, base64Image, this.isEdit)
          console.error('handleScreenshotTaken res:', res)
        } catch (e) {
          console.error('handleScreenshotTaken:', e)
        }
      }
      this.setTakeScreenshot(false);
    };

    getProjectDetailInformation = (projectDetailsInformation: any) => {
        console.log('getProjectDetailInformation: 111')
        const fetchData = async () => {
            const documents: Array<any> = await loadDocuments(projectDetailsInformation);
            const filteredDocuments = documents.filter((item: any) => item.category !== AssetCategory.IMAGE);

            if (filteredDocuments.length > 0) {
                this.setDocuments(filteredDocuments);
            }
            console.log('getProjectDetailInformation: 222')

            // await delay(1000);
            // this.setIsProjectChangesExists(false)
            // setDocumentsRefreshKey(Date.now());
        };

        if (projectDetailsInformation) {

          const fetchDataDetails = async () => {
            const projectData = loadProjectDetails(projectDetailsInformation);
            console.log('000008888 getProjectDetailInformation: 333')
            if (projectData?.buildings?.length > 0) {
              this.setUploadModel(true)
              this.setBuildingsList(projectData?.buildings);
            }

            console.log('000008888 projectData:', projectData)
            const usdzModel3ds =  projectData?.buildings[0]?.model3ds.filter((model3d: Model3D) => model3d.type === 'usdz');

            let anchorCoordinate = projectData?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
            if(usdzModel3ds?.length > 0) {
              anchorCoordinate = this.isEdit ? usdzModel3ds[0]?.anchoring?.anchorCoordinate : projectData?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
            }
            this.latitude = anchorCoordinate?.latitude || projectData.latitude
            this.longitude = anchorCoordinate?.longitude || projectData.longitude
            this.altitude = anchorCoordinate?.altitude || projectData.altitude


            // Cover image
            const filteredForCover = projectDetailsInformation.documents?.filter((item: any) => item.name === 'cover')
            if (filteredForCover.length > 0) {
              this.setCover([filteredForCover[0]])
            }
            // if (projectData?.publishStatus) {
            //   setProjectVisibility(projectData?.publishStatus);
            // }

            console.log('projectData?.publishStatus____:', projectData?.publishStatus)

            const loadedKeyDates: Array<any> = loadEvents(projectDetailsInformation);
            if (loadedKeyDates.length) {
              this.setKeyDates(loadedKeyDates);
              // form.setFieldsValue({
              //     timelineEvents: loadedKeyDates,
              // });
            }
          }


          try {
              fetchData()
                  .then(async (r) => {
                    // await delay(3000);
                    this.setIsProjectChangesExists(false)
                  });
              fetchDataDetails()
                  .then(async (r) => {
                    // await delay(3000);
                    this.setIsProjectChangesExists(false)
                  });
          } catch (e) {
                console.error(e);
          }
        } else {
            this.handleSetModelsListEmptyItem(0, 0);
        }
    }

    handleSetModelsListEmptyItem = (buildingIndex: number, modelOrder: number) => {
      const newBuildingsList = this.buildingsList;
      newBuildingsList[buildingIndex] = { ...(newBuildingsList[buildingIndex] || {}) };
      newBuildingsList[buildingIndex].modelsList = newBuildingsList[buildingIndex].modelsList || {};
      newBuildingsList[buildingIndex].modelsList[modelOrder] = { isNew: true };
      this.setBuildingsList(newBuildingsList);
    };

    handleSetModelsList = (newModel: any, buildingIndex: number, modelOrder: number) => {
      const newBuildingsList = this.buildingsList;
      const newModelsList = { ...(newBuildingsList.length > 0 ? newBuildingsList[buildingIndex].modelsList : {}) };
      if (newModel.file) {
        newModelsList[modelOrder] = { ...(newModelsList[modelOrder] || {}) };
        const fileExtension = getFileExtension(newModel.file.name);
        newModelsList[modelOrder][fileExtension] = {
          ...newModelsList[modelOrder][fileExtension],
          ...newModel,
        };
      } else {
        newModelsList[modelOrder] = { glb: undefined, isNew: true };
      }

      newBuildingsList[buildingIndex].modelsList = newModelsList;
      this.setBuildingsList(newBuildingsList);
    };


  updateAnchorCoordinate = async (
    coordinate: { longitude: number, latitude: number, altitude: number },
    buildingIndex: number,
    modelOrder: number
  ) => {
    const newBuildingsList =  this.buildingsList

    if (!newBuildingsList[buildingIndex]) {
      throw new Error(`Building at index ${buildingIndex} does not exist.`);
    }

    if (!newBuildingsList[buildingIndex].modelsList) {
      newBuildingsList[buildingIndex].modelsList = {};
    }
    if (!newBuildingsList[buildingIndex].modelsList[modelOrder]) {
      newBuildingsList[buildingIndex].modelsList[modelOrder] = {};
    }
    if (!newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz']) {
      newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz'] = {};
    }

    newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz'].anchorCoordinate = coordinate;
    this.setBuildingsList(newBuildingsList);
  }


  goToProjects = () => {
        window.location.replace('/projects');
    }

    handleShare = (projectId: any) => {
        if (projectId) {
            this.setIsQRModalOpen(true);
        }
    }
    eventPressKey = (value: any) => {
            this.pressEnterEvent = value.code === "Enter"
    }
    setIsQRModalOpen = (value: boolean)=>{
        this.isQRModalOpen = value
    }

    handleSave = async (project: any) => {
        // const { data } = await axios.post('/api/projects', project);
        // setProject(data);
        // handleClose();
    };

    onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    setUploadModel = (bool: boolean) => {
        this.isUploadModel = bool
        this.showErrorMessage = !bool
    }

    setEditMode = (bool: boolean) => {
      this.isEdit = bool
    }
    setReplaceModel = (bool: boolean) => {
      this.isModelReplaced = bool
    }
    setShowErrorMessage = (bool: boolean) => {
        this.showErrorMessage = bool
    }
    validation = () => {
        if (!this.isUploadModel){
            this.showErrorMessage = true
            console.log("ERROR, please add model")
            return false
        }else {
            this.showErrorMessage = false
            return true
        }
    }

    getShareLink = () => {
      this.arrayCopyLink = []
      this.shareProjectLink()
      this.shareWhatsapp()
      this.shareEmail()
      // this.getQrCodeScan(projectId)

    }

    getArrayCopyLink = () => {
      const arrayCopyLink = toJS(this.arrayCopyLink)
      console.log('___arrayCopyLink____',  arrayCopyLink)
      return arrayCopyLink
    }

    getProjectShareUrlLink = () => {
      return this.projectURL
    }

    shareProjectLink=()=>{
      this.projectURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/project/${this.projectId}`;
      this.arrayCopyLink.push({
        title: 'Project link',
        link: this.projectURL
      })
    }
    shareWhatsapp=()=>{
      this.wpURI = `https://api.whatsapp.com/send?text=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/project/${this.projectId}`;
      this.arrayCopyLink.push({
        title: 'WhatsApp',
        link: this.wpURI
      })
    }
    shareEmail=()=>{
      this.mailtoURL = `mailto:?subject=&body=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/project/${this.projectId}`
      this.arrayCopyLink.push({
        title: 'Email',
        link: this.mailtoURL
      })
    }


    removePreviewImgDetailStep = () =>{
        this.previewImgDetailStep = ''
    }

    setIsCreateModalVisible = (bool: boolean) =>{
        this.isCreateModalVisible = bool
    }

    setIsShowLimitModelUploadPopup = (bool: boolean) =>{
      this.isShowLimitModelUploadPopup = bool
    }

    setIsShowWarningLimitModelUploadPopup = (bool: boolean) =>{
      this.isShowWarningLimitModelUploadPopup = bool
    }

    setUserInfo = (user: any) => {
        if (user && user?.name?.length){
            this.userName = user.name
            this.userEmail = user.email
        }
    }

    setIsSnapToGround = (isSnapToGround: boolean) =>{
        this.isSnapToGround = isSnapToGround
    }

    setProjectSize = (value: string) => {
        this.projectSize = value
    }


    // checkChangesForSaveAndPublishButton = () => {
    //     if (this.isEdit){
    //         if (this.objForCheckChanges.address !== this.address ||
    //             this.objForCheckChanges.name !== this.projectName ||
    //             this.objForCheckChanges.description !== this.projectDescription ||
    //             this.objForCheckChanges.longitude !== this.longitude
    //         ){
    //             return true
    //         }else {
    //             return false
    //         }
    //     }else {
    //         return true
    //     }
    // }
    // setValueForObgForCheckChanges = (obj: any) => {
    //     this.objForCheckChanges = obj
    // }

    isDisableSaveBlocks = () => {
      return (this.publishingStatus === PublishingStatus.Success
        || this.publishingStatus === PublishingStatus.Failed
        || this.publishingStatus === PublishingStatus.Timeout)
        || this.isProjectChangesExists
    }

      isDisableRightBlocks = () => {
        return (!this.isProjectSaving && this.publishingStatus === PublishingStatus.None)
          && this.isProjectChangesExists
      }

      isNotPublishedAndNoChanges = () => {
         return this.publishingStatus !== PublishingStatus.Success && !this.isProjectChangesExists
      }

      // isPublishedOrNoChanges = () => {
      //   return this.publishingStatus === PublishingStatus.Success || !this.isProjectChangesExists
      // }

      // isReadyToSave = () => {
      //   return this.isProjectChangesExists
      // }

      isNotPublishedAndChanges = () => {
        return this.publishingStatus !== PublishingStatus.Success && this.isProjectChangesExists
      }
}
