import React, { useEffect, useMemo, useState } from 'react';
import {authProvider} from '../../authPrvider/authProvider';

export function PricingTable() {
  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])

  const [pricingTableId, setPricingTableId] = useState(process.env.REACT_APP_STRIPE_PRICING_TABLE_ID);

  useEffect(() => {
    const eaCode = authProvider.getEACode();
    if (eaCode) {
      setPricingTableId(eaCode);
      authProvider.removeEACode();
    }
  }, []);

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      client-reference-id={user.id}
    >
    </stripe-pricing-table>
  )
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
        namespace JSX {
            interface IntrinsicElements {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
            }
        }
    }
