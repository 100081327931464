import { useState, useEffect, useRef, useContext } from 'react';
import dataProvider from '../dataProvider/dataProvider';
import { MixpanelEvent, mixPanelUtils } from '../pages/public-view/mixpanel/mixpanel';
// import projectCreationStore from '../store/projectCreationStore';
import { Context } from '../index';
import { PublishingStatus } from '../store/projectCreationStore';

const usePublishStatusPooling = (
  projectId: string,
  hideProgressModal?: () => void,
  showSuccessModal?: (title: string, message: string) => void,
  showErrorModal?: (title: string, message: string) => void,
  redirectToPage?: (projectId: string) => void,
) => {
  const { projectCreationStore } = useContext(Context);

  const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
  const [publishStatus, setPublishStatus] = useState<string>('');
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const startPublishStatusPooling = (projectId: string) => {
    let elapsedTime = 0;
    const pollingInterval = 5000;
    const maxElapsedTime = 600000;

    const fetchData = async () => {
      try {
        const { data } = await dProvider.getOne({
          resource: 'project',
          id: projectId,
          metaData: {
            fields: ['publishStatus'],
          },
        });

        console.log('___fetchData_projectId____', projectId);
        console.log('___fetchData_data____', data);
        const newPublishStatus = data?.publishStatus;

        setPublishStatus(newPublishStatus);

        if (newPublishStatus === 'PUBLISHED') {
          handlePublished();
          stopPolling();
        } else if (newPublishStatus === 'FAILED') {
          handleFailed();
          stopPolling();
        }
      } catch (error) {
        handleFailed();
        stopPolling();
      }
    };

    const handlePublished = () => {
      mixPanelUtils.track( MixpanelEvent.PROJECT_PUBLISHED, { projectId: projectId })
      // hideProgressModal();
      // redirectToPage(projectId);
      projectCreationStore.setIsProjectChangesExists(false);
      projectCreationStore.setPublishingState(false, PublishingStatus.Success);
      console.log('The project has been successfully published.', '');
    };

    const handleFailed = () => {
      // hideProgressModal();
      mixPanelUtils.track( MixpanelEvent.PROJECT_PUBLISH_FAILED, { projectId: projectId })
      projectCreationStore.setPublishingState(false, PublishingStatus.Failed);
      console.log('The project was not published properly. Please try again or contact the support team.', '');
    };

    const stopPolling = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };

    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        elapsedTime += pollingInterval;

        if (elapsedTime >= maxElapsedTime) {
          stopPolling();
          // hideProgressModal();
          // showErrorModal('The project publishing process timed out. Please try again or contact the support team.', '');
          mixPanelUtils.track( MixpanelEvent.PROJECT_PUBLISH_TIMEOUT, { projectId: projectId })
          projectCreationStore.setPublishingState(false, PublishingStatus.Timeout);
          console.log('The project publishing process timed out. Please try again or contact the support team.', '');
        } else {
          fetchData();
        }
      }, pollingInterval);
    }

    fetchData();
  };

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  return { publishStatus, startPublishStatusPooling };
};

export default usePublishStatusPooling;
