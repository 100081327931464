/* eslint-disable max-len */

import {Button, Typography} from 'antd';
import React, { useContext, useEffect, useRef } from 'react';

import './style.less';

import {Input} from '@pankod/refine-antd';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';

const ARView = observer(() => {
  const {projectCreationStore} = useContext(Context)

  const {cta, changeCta, ctaLink , changeCtaLink, projectId } = projectCreationStore

  // const hasEffectRun = useRef(false);
  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_AR_VISIT, { projectId: projectId });
  //     hasEffectRun.current = true;
  //   }
  // }, []);

  return (
    <>
      <div>
        <label htmlFor="premium" className='premium-button-pd'> Premium feature coming soon!</label>
        {/*<Button disabled={false}*/}
        {/*        className='premium-button-pd'*/}
        {/*        onClick={() => console.log('Upgrade to premium')}>*/}
        {/*    Premium feature coming soon!*/}
        {/*</Button>*/}

        <Typography.Paragraph style={{paddingTop: 10, fontSize: 14, opacity: 0.5}}>
                CTA
        </Typography.Paragraph>
        <Input placeholder="Learn More"
          disabled={true}
          value={cta}
          onChange={(event) => changeCta(event.target.value)}
        />

        <Typography.Paragraph style={{paddingTop: 10, fontSize: 14, opacity: 0.5}}>
                CTA Link
        </Typography.Paragraph>
        <Input placeholder="Enter URL"
          disabled={true}
          value={ctaLink}
          onChange={(event) => changeCtaLink(event.target.value)}/>
      </div>
    </>
  );
});

export default ARView;
