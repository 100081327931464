import {Button, message, Modal, Space, Tooltip, Typography} from 'antd';
import QRCodeIcon from '../../../../assets/Qr_Code.svg';
import LincIcon from '../../../../assets/link_icon.svg';
import DownloadIcon from '../../../../assets/download_icon.svg';

import * as util from '../../../../utils/util';
import './style.less';
import { MixpanelEvent, mixPanelUtils } from '../../../../pages/public-view/mixpanel/mixpanel';
import {Show} from '@pankod/refine-antd';
import {showErrorModal} from '../../../Modal/ShowErrorModal';
import copyProjectLinkIcon from '../../../../assets/copyProjectLinkIcon_white.svg';
import React, {useState} from 'react';

import { copyImageToClipboard } from 'copy-image-clipboard'
import { getQRCodeUrl } from '../../../../utils/util';

export default function QRCodeModalComponent({
  projectId,
  isQRModalOpen,
  setIsQRModalOpen,
  projectName,
}: any) {
  // eslint-disable-next-line max-len
  const qrCodeUrl = getQRCodeUrl(projectId);

  const [showCopiedQRCodeMessage, setShowQRCodeCopiedMessage] = useState(false)
  const [showCopiedLinkMessage, setShowCopiedLinkMessage] = useState(false)
  const handleCloseModal = () => {
    setIsQRModalOpen(false);
  }
  const generateBlob= async (response: any)=>{
    try {
      const blob = await response.blob();
      if (blob.ok) {
        generateInfo(response, blob)
      }else {
        if (blob.status && blob.statusText){
          console.log(response.status + ' ' + response.statusText)
        }
        nextActionIfError()
      }
    }catch (error){
      console.log(error);
    }
  }

  const generateInfo = (response: any, blob: any) => {
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = `QR_${projectId}.jpg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl);
  }

  // const handleDownloadQRCode = async () => {
  //     try {
  //         const response = await fetch(qrCodeUrl);
  //         if (response.ok) {
  //             generateBlob(response);
  //         }else {
  //             if (response.status && response.statusText){
  //                 console.log(response.status + " " + response.statusText)
  //             }
  //             nextActionIfError()
  //         }
  //
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  const handleDownloadQRCode = async () => {
    try {
      const cacheBustedUrl = `${qrCodeUrl}?cb=${new Date().getTime()}`;
      const response = await fetch(cacheBustedUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink.href = blobUrl;
      downloadLink.download = `QR_${projectId}.jpg`;

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  }

  const checkLinkForCopiedQRCode = async (link: string) => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_QR_COPY, {projectId: projectId})
    try {
      // const cache  = await caches.open("v1");
      // await cache.delete(link)
      const cacheBustedLink = `${link}?cb=${new Date().getTime()}`;
      await copyImageToClipboard(cacheBustedLink)
      console.log('Image Copied')
      setShowQRCodeCopiedMessage(true)
    } catch (e: any) {
      console.log('Image Copied Error: ', e.message)
    }
  }

  const nextActionIfError = () =>{
    showErrorModal('Sorry',`Error downloading QR code for ${projectName}`)
    handleCloseModal()
  }
  
  return (
    <Modal
      className='qr-modal'
      open={isQRModalOpen}
      footer={[]}
      onCancel={handleCloseModal}>
      <Typography.Title level={2} className='title'>Share {projectName}</Typography.Title>
      <div className='buttons-container'>

        {!showCopiedQRCodeMessage ?
          <Button
            className='share-btn'
            onClick={projectId !== undefined
              ? () => {
                checkLinkForCopiedQRCode(qrCodeUrl);
              }
              : () => { }
            }
            onMouseLeave={() => setShowQRCodeCopiedMessage(false)}
          >
            <img src={QRCodeIcon} />
            <Typography.Text disabled={projectId == undefined}>Copy QR Code</Typography.Text>
          </Button>
          :
          <Tooltip placement="bottomLeft" color="grey" zIndex={99999999999} title='QR Code copied!'>
            <Button
              className='share-btn'
              onClick={projectId !== undefined
                ? () => {
                  checkLinkForCopiedQRCode(qrCodeUrl);
                }
                : () => { }
              }
              onMouseLeave={() => setShowQRCodeCopiedMessage(false)}
            >
              <img src={QRCodeIcon} />
              <Typography.Text disabled={projectId == undefined}>Copy QR Code</Typography.Text>
            </Button>
          </Tooltip>
        }

        {!showCopiedLinkMessage ?
          <Button
            className='share-btn'
            onClick={projectId !== undefined
              ? () => {
                util.copyToClipboard(`${window.location.origin}/project/${projectId}`)
                setShowCopiedLinkMessage(true)
              }
              : () => { }
            }
            onMouseLeave={() => setShowCopiedLinkMessage(false)}
          >
            <img src={LincIcon} />
            <Typography.Text disabled={projectId == undefined}>Copy Link</Typography.Text>
          </Button>
          :
          <Tooltip placement="bottomLeft" color="grey" zIndex={99999999999} title='Link copied!'>
            <Button
              className='share-btn'
              onClick={projectId !== undefined
                ? () => {
                  util.copyToClipboard(`${window.location.origin}/project/${projectId}`)
                  setShowCopiedLinkMessage(true)
                }
                : () => { }
              }
              onMouseLeave={() => setShowCopiedLinkMessage(false)}
            >
              <img src={LincIcon} />
              <Typography.Text disabled={projectId == undefined}>Copy Link</Typography.Text>
            </Button>
          </Tooltip>
        }

      </div>
      <div className='qr-image-container'>
        <img
          className='qr-image'
          src={qrCodeUrl}
          alt='qr-modal'
        />

        <Space
          style={{cursor: 'pointer'}}
          className='ShareComponent_tools'
          onClick={projectId !== undefined
            ? handleDownloadQRCode
            : () => { }
          }
        >
          <img src={DownloadIcon} style={{cursor: 'pointer'}}/>
          <Typography.Text disabled={projectId == undefined}>Download QR Code</Typography.Text>
        </Space>
      </div>
    </Modal>
  )
}
