import React, { useEffect,useState } from 'react';

import { Card, Col, Row, Typography } from '@pankod/refine-antd';

import ProjectDescription from './components/project-description';
import ProjectHeader from '../components/project-header';
import {appUtils, dataUtils, jsonUtils} from '../../../utils/util';
import FeedbackForm from './components/feedback/feedback';
import ProjectModelPreview from './components/model-preview';
import MapView from './components/model-map';
import {mixPanelUtils, MixpanelEvent} from '../mixpanel/mixpanel';
import ProjectInfo from './components/project-info';
import {ProjectProps} from '../interfaces/project';
import {ModelStrings} from '../../../constants/index'
import './style.less';
import useProjectDetailsFetchByPartialId from '../../../hooks/useProjectDetailsFetchByPartialId';
import useAuth0Authorization from '../../../hooks/auth0/useAuth0Authorization';
import { useParams } from 'react-router-dom';
import useProjectDetailsFetch from '../../../hooks/useProjectDetailsFetch';
import PublicMapView from '../map';

// OLD code
const PublicViewComponent = (props: ProjectProps) => {
  const {
    projectId,
    descriptionData,
    feedbackVisible,
    setFeedbackVisible,
    isModelAvailable,
    usdzModel,
    glbModel,
  }: ProjectProps = props;

  return (
    <Row align='middle' justify='center'>
      <Card className='project-description-container-mobile' title={<ProjectHeader projectId={projectId}/>}>
        <Row gutter={[24, 48]} justify='center' className='container-row'>
          <Col xxl={12} xl={16} lg={16} md={20}>
            <Row gutter={[10,0]} className='grid-rows'>
              <ProjectInfo projectId={projectId} data={descriptionData} />
              <ProjectModelPreview
                scale={0.05} // 0.3
                modelPosition={[0, -3, 0]}
                isModelAvailable={isModelAvailable}
                projectId={projectId}
                usdzModel={usdzModel}
                glbModel={glbModel}
                autoGenerateModels={false}
              />
              <MapView
                projectId={projectId}
                data={descriptionData}
              />
              <Col span={24}>
                <ProjectDescription
                  projectId={projectId}
                  openFeedbackForm={()=>setFeedbackVisible(true)}
                  data={descriptionData}
                />
              </Col>
            </Row>
            <FeedbackForm
              projectId={projectId}
              visible={feedbackVisible}
              onClose={()=>setFeedbackVisible(false)}
            />
          </Col>
        </Row>
      </Card>
    </Row>
  );
}

function PublicViewDB() {
  let { id: projectIdFull }: any = useParams();

  const searchParams = new URLSearchParams(location.search);
  let projectIdShort = searchParams.get('id');

  if (projectIdFull && projectIdFull.length === 8) {
    projectIdShort = projectIdFull;
    projectIdFull = undefined;
  }

  const [descriptionData, setDescriptionData]: any = useState([]);
  const [feedbackVisible,setFeedbackVisible] =useState(false);
  const { performAuthorization } = useAuth0Authorization();

  const [isModelAvailable, setIsModelAvailable]: any = useState(false);

  /* eslint-disable react-hooks/rules-of-hooks */
  const [projectInfo, pageLoadStatus]: any = projectIdFull
    ? useProjectDetailsFetch({ id: projectIdFull })
    : useProjectDetailsFetchByPartialId({ id: projectIdShort });

  const performMixpanelTrack = (projectInfo: any) => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_VISIT, {projectId: projectInfo?.id, projectName: projectInfo?.name})
    appUtils.updatePreviewLinks(projectInfo)
  }

  useEffect(() => {
    const fetchData = async () => {
      const projectJSON = await jsonUtils.buildJSON(projectInfo)
      const jsonData = dataUtils.jsonToDataMobile(projectJSON)

      setDescriptionData(jsonData)

      // Load model data
      const projectJSONModel = await jsonUtils.buildJSONModel(projectInfo)
      setIsModelAvailable(projectJSONModel.is_model_available)
    }

    if (projectInfo) {
      performMixpanelTrack(projectInfo)
      fetchData()
        .catch(console.error);
    }
  },[projectInfo])

  const projectModelsList = projectInfo?.buildings?.[0]?.model3ds?.sort((a: any, b: any) => a.order - b.order);
  const usdzModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.USDZ);
  const glbModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.GLB);

  if(projectInfo) {
    return (
      <PublicViewComponent
        projectId={projectInfo?.id}
        descriptionData={descriptionData}
        feedbackVisible={feedbackVisible}
        setFeedbackVisible={async (e: boolean) => {
          if (e) {
            await performAuthorization()
          }
          setFeedbackVisible(e)
        }}
        type={ModelStrings.ModelFormat.GLTF}
        isModelAvailable={isModelAvailable}
        usdzModel={usdzModel}
        glbModel={glbModel}
      />
    )
  }

  return (
    <div>There is no project defined</div>
  )
}

export default function PublicView() {
  let { id: projectIdFull }: any = useParams();

  const searchParams = new URLSearchParams(location.search);
  let projectIdShort = searchParams.get('id');

  if (projectIdFull && projectIdFull.length === 8) {
    projectIdShort = projectIdFull;
    projectIdFull = undefined;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const [projectInfo, pageLoadStatus]: any = projectIdFull
    ? useProjectDetailsFetch({ id: projectIdFull })
    : useProjectDetailsFetchByPartialId({ id: projectIdShort });

  if(projectInfo) {
    return <PublicMapView projectId={projectInfo.id} projectData={projectInfo} />
  }
  return (
    <Row align='middle' justify='center' gutter={[24, 48]} className='container-row p-0 m-0'>
      <div>Loading...</div>
    </Row>
  )
  // return <PublicViewDB />
}
