import {useCreate, useDeleteMany, useUpdate, useDelete} from '@pankod/refine-core';
import React from 'react';
import { deleteDocumentsFromAWS, documentUploader } from '../api/document';
import { AWSDocumentNames } from '../enums';

async function uploadDocumentsFiles(projectId: string, documents: Array<any>) {
  const uploadedFileURLs: any = {}
  const files: Array<any> = []
  const filesIndexes: Array<any> = []

  if (projectId) {
    for (let i=0; i<documents.length; i++) {
      const document = documents[i]
      if (document.file?.originFileObj) {
        files.push(document.file)
        filesIndexes.push(i)
      }

      if (files.length > 0) {
        try {
          const response = await documentUploader(files, projectId);
          const {result} = await response.json();
          for (let j = 0; j < result.length; j++) {
            uploadedFileURLs[filesIndexes[j]] = result[j];
          }
        } catch (e) {
          console.log('ERROR uploading files:', e)
        }
      }
    }
  }
  return {
    uploadedFileURLs: uploadedFileURLs,
    files: files,
    filesIndexes: filesIndexes,
  }
}

export default function useUpdateDocuments() {
  const { mutate } = useUpdate();
  const { mutate: createDocument } = useCreate();
  const { mutate: deleteDocument } = useDelete();

  const saveDocuments = async ({ projectInfo, values }: any) => {

    const projectId = projectInfo?.id
    const projectData = projectInfo?.documents ?? []
    const valuesData = values?.documents?.createMany?.data ?? []

    const projectInfoIds = projectInfo?.documents?.filter((e: any) => e.name !== AWSDocumentNames.QR && e.name !== AWSDocumentNames.COVER_IMAGE).map((e: any) => e.id) ?? []
    const valuesDataIds = valuesData?.map((e: any) => e.id) ?? []

    const documentsToUpdate = valuesData.filter((e: any) => projectInfoIds.includes(e.id))
    const documentsToCreate = valuesData.filter((e: any) => !e.id)
    const documentsIDsToDelete = projectInfoIds.filter((id: any) => !valuesDataIds.includes(id))

    if (documentsToUpdate.length > 0) {
      const uploadedData = await uploadDocumentsFiles(projectId, documentsToUpdate)
      const uploadedFileURLs: any = uploadedData.uploadedFileURLs

      for (let i=0; i<documentsToUpdate.length; i++) {
        const document = documentsToUpdate[i]
        const uploadedFileURL = uploadedFileURLs[i] ?? undefined

        const documentValues: any = {
          name: document.name,
          url: uploadedFileURL ?? document.url,
        }
        mutate({
          resource: 'documents',
          id: document.id,
          values: documentValues,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }

    if (documentsIDsToDelete.length > 0) {
      for (const id of documentsIDsToDelete) {
        deleteDocument({
          resource: 'documents',
          id: id,
          values: {},
          metaData: {
            fields: ['id'],
          },
        });
      }
    }

    if (projectId) {
      const uploadedData = await uploadDocumentsFiles(projectId, documentsToCreate)
      const uploadedFileURLs: any = uploadedData.uploadedFileURLs

      for (let i=0; i<documentsToCreate.length; i++) {
        const document = documentsToCreate[i]
        const uploadedFileURL = uploadedFileURLs[i] ?? undefined

        const documentValues: any = {
          name: document.name,
          url: uploadedFileURL ?? document.url,
          project: {connect: {id: projectId}},
        }
        createDocument({
          resource: 'documents',
          values: documentValues,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }

    // Deleting files from aws
    if (documentsToUpdate.length > 0) {
      const documentsURLToUpdate = documentsToUpdate
        .filter((e: any) => e.url != '')
        .map((e: any) => e.url)
      const documentsUrlOLd = projectData.filter((e: any) => !documentsURLToUpdate.includes(e.url))
      const documentFilenames = documentsUrlOLd.filter((e: any) => e.url != '').map((e: any) => e.url)

      await deleteDocumentsFromAWS(documentFilenames, projectId)
    }

    if (documentsIDsToDelete.length > 0) {
      const filteredProjectData = projectData.filter((e: any) => documentsIDsToDelete.includes(e.id))
      const documentFilenames = filteredProjectData.map((e: any) => e.url)
      await deleteDocumentsFromAWS(documentFilenames, projectId)
    }
  };

  return [saveDocuments];
}

export function useUploadDocuments() {
  const { mutate } = useUpdate();

  const updateDocumentsUrls = async ({ projectInfo, values }: any) => {

    const projectId = projectInfo?.id
    const projectData = projectInfo?.documents ?? []
    const valuesData = values?.documents?.createMany?.data ?? []

    const documentsToUpload = valuesData;
    const documentsToUpdate = projectData;

    if (projectId) {
      const uploadedData = await uploadDocumentsFiles(projectId, documentsToUpload)
      const uploadedFileURLs: any = uploadedData.uploadedFileURLs

      for (let i=0; i<documentsToUpdate.length; i++) {
        const document = documentsToUpdate[i]
        const uploadedFileURL = uploadedFileURLs[i] ?? undefined

        const documentValues: any = {
          url: uploadedFileURL ?? document.url,
        }
        mutate({
          resource: 'documents',
          id: document.id,
          values: documentValues,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }

  };

  return [updateDocumentsUrls];
}

