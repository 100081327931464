/* eslint-disable max-len */
import React,{ Suspense, useState, useEffect, useRef } from 'react';
import { Row, Col, Space, Typography } from '@pankod/refine-antd';
// import ModelPreviewer from '../../ModelPreviewer';
import {Avatar, Image, Spin} from 'antd';

import { AWSDocumentNames } from '../../../enums';
import ProjectCardActions from './ProjectCardActions';
import {Link} from 'react-router-dom';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import UserIcon from '../../../assets/UserIcon.svg';
import TimeIcon from '../../../assets/TimeIcon.svg';
import RenderCreatedNamePerson from '../../Cell/RenderCreatedNamePerson';
import { getFormattedDate } from '../../../utils/time';

const { Title, Text } = Typography;

export default function ProjectInfo(props: { data: any;
    deleteProject: any;
    handleOpenProjectForm: any;
    userName: string;
    setChangeSize: any;
    changeSize: any;
setWidth: any;
setHeight: any
}) {
  const {
    data,
    deleteProject,
    handleOpenProjectForm,
    userName,
    setChangeSize,
    changeSize,
    setWidth,
    setHeight,
  } = props;
  const [screenshot, setScreenshot] = useState('');
  const canvasRef = useRef();

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const imageStr = `https://${process.env.REACT_APP_NAME_MAPS_PUBLIC}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${data.id}/${AWSDocumentNames.PREVIEW_MODEL_IMAGE}.png`
    setScreenshot(imageStr)
  }, [data]);

  const handleImageError = () => {
    setImageError(true);
  };

  const firstChar = userName.substr(0,1).toUpperCase()

  const ref = useRef(null)

  const www = () => {

  }
  useEffect(() => {
    if (!changeSize){
      // @ts-ignore
      console.log(ref.current.offsetHeight)
      setChangeSize(true)
      // @ts-ignore
      setHeight(ref.current.offsetHeight)
      // @ts-ignore
      setWidth(ref.current.offsetWidth)
    }

  }, [ref, changeSize]);

  return (

    <div ref={ref}>
      <Space direction="vertical" size="small" style={{ display: 'flex' }} >
        <div className="project-grid-model-view" >
          {/*<Avatar onClick={()=> www()} style={{ backgroundColor: '#d650f6', verticalAlign: 'middle' , margin: 20, position: 'absolute', zIndex: 2}} size="large" >*/}
          {/*  {firstChar}*/}
          {/*</Avatar>*/}
          {screenshot !== ''
            ? <Image
              width={'100%'}
              height={'200px'}
              wrapperStyle={{zIndex:2}}
              preview={false}
              onError={handleImageError}
              onClick={()=>handleOpenProjectForm(data.id)}
              style={{cursor: 'pointer'}}
              src={imageError ? require('../../../../src/assets/previewImgForIOS.png') : screenshot}
            />
            :  <div className='project-grid-spinning'>
              <Spin size='large' spinning={true}/>
            </div>
          }
          <Link to={'/project-edit/' + data.id}><div className='project-grid-mask'></div></Link>
          <div className='grid-card-actions'>
            <ProjectCardActions project={data} deleteProject={deleteProject} handleOpenProjectForm={handleOpenProjectForm} />
          </div>
        </div>

        <div className='project-info-details-container'>
          <Text style={{ color: 'black' }}>{data?.name}</Text>
          {/* <Title level={5} style={{color: '#5A5A5A'}}>{data?.name}</Title> */}
          <span className='project-info-details-text'><EnvironmentOutlined /><span
            className="project-info-details-location">{}{data?.address}</span></span>
          {data?.organization?.organizationUsers?.length > 0 &&
            <div className='project-info-details-text'>
              <img src={UserIcon} alt={''} width={12} height={12} />
              <div className="project-info-details-user-name"><span>
                <RenderCreatedNamePerson row={data} />
              </span></div>
            </div>
          }
          <div className='project-info-details-text'>
            <img src={TimeIcon} alt={''} width={12} height={12} />
            <div className="project-info-details-date"><span>
              { getFormattedDate(data.updatedAt) }
            </span></div>
          </div>
          {/*<span className='project-info-details-text'><ClockCircleOutlined /> Edited: {data?.timelineEvents?.[0]?.updatedAt}</span>*/}

        </div>
      </Space>
    </div>
  );
}
