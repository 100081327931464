import { buildModelFileData, getTemporaryUploadedModelFileLocationUrl } from '../utils/util';
import { modelUploaderToAWSTemporary } from '../api/model';
import useProjectSave from './useProjectSave';
import { createBuilding } from '../api/createBuilding';
import useSaveDefaultAnchoring from './useSaveDefaultAnchoring';
import { useUploadModel } from './useUploadModel';
import { useProjectPublish } from './useProjectPublish';
import { ProjectVisibility } from '../enums';
import { buildPreference } from '../pages/project-creation-step-composer/utils/preference';
import { ProjectValues } from '../interfaces/project';
import { getAWSUrl } from '../components/ProjectDetailsFormNew/utils/aws';
import { EXAMPLE_PROJECT } from '../constants/general';
import useUploadPreview from './useUpdatePreview';

const useCreateExampleProject = () => {
  const { publishProject, data: publishedProjectData, isLoading: isPublishing, error } = useProjectPublish();
  const [saveData] = useProjectSave(null, {});
  const [uploadModel] = useUploadModel();
  const [saveDefaultAnchoring] = useSaveDefaultAnchoring();
  const [savePreviewFromMap] = useUploadPreview();

  async function getBase64FromImageUrl(imageUrl: string) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result);
          } else {
            reject('Failed to convert image to Base64.');
          }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  }

  const createExampleProject = async () => {
    const model = await fetch(
      // `${getAWSUrl()}/default/clwqp81j20040zq02dzst81bk_0.glb`,
      `${getAWSUrl()}/default/washington_monument_example_project.glb`, //  clwqp81j20040zq02dzst81bk_0.glb
      // `${getAWSUrl()}/projects/clwtomck20000x402lwfdb2vk/clwtomd0d0006x402d1w5yes1/1717099354117.glb`,
    );
    const modelBlob = await model.blob();
    const modelFile = new File([modelBlob], 'model.glb', { type: 'model/gltf-binary' });

    const fileToUpload = { file: modelFile }
    const response = await modelUploaderToAWSTemporary(fileToUpload);
    console.log('response:', response)
    const modelUrl = getTemporaryUploadedModelFileLocationUrl(response);
    console.log('modelUrl:', modelUrl)
    const modelFileData = buildModelFileData(modelUrl);
    const modelsList = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '0': {
        glb: {
          ...modelFileData,
          isNew: true,
        },
      },
    };
    const buildingsList = [
      {
        modelsList,
      },
    ];

    const exampletData: ProjectValues = {
      name: 'Example Project',
      subtitle: '',
      latitude: '40.992029738143',
      description: '',
      longitude: '-73.88344385748877',
      altitude: '21.49',
      address:  '15 William St, Hastings On Hudson, NY, 10706, USA',
      origin: EXAMPLE_PROJECT,
      appClipSync: true,
    };

    exampletData.projectViewPreference = buildPreference(ProjectVisibility.PUBLIC, false, true);

    const project = await saveData(exampletData, buildingsList, null, [], false, 0);
    const building: any = await createBuilding(project.data.id);
    const rotation = 0;
    const coord = {
      latitude: 38.88947251919136,
      longitude: -77.03523870425434,
      altitude: 12.37,
    };

    await saveDefaultAnchoring({ buildingId: building?.id, rotation, coord });
    await uploadModel(project, modelsList, building?.id);

    const base64String = await getBase64FromImageUrl(`${getAWSUrl()}/default/washington_monument_example_project.png`)
    if(base64String) {
      await savePreviewFromMap(project.data, base64String as string, false);
    }
    await publishProject(project.data.id);

    return project;
  };

  return [createExampleProject];
}

export default useCreateExampleProject;
