/* eslint-disable max-len */

import {Typography} from 'antd';
import React, {useContext} from 'react';

import './style.less';
import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import QR_placeholder from '../../../../../assets/QR_placeholder.svg'
import download_icon from '../../../../../assets/download_icon.svg';

interface PropsQR {
    src: string,
    disabled: boolean,
}

const QRScan: React.FC<PropsQR> = observer((props: PropsQR) => {
  const { projectCreationStore } = useContext(Context);
  const {checkLinkForCopiedQRCode, handleDownloadQRCode } = projectCreationStore
  return (
    <>
      {/*<Typography.Paragraph style={{textAlign: 'center'}}>*/}
      {/*  <Typography.Text strong={true} style={{fontSize:14}}>*/}
      {/*              Scan to view in AR*/}
      {/*  </Typography.Text>*/}
      {/*  <br/>*/}
      {/*</Typography.Paragraph>*/}
      <div style={{cursor: 'pointer'}} onClick={()=>{checkLinkForCopiedQRCode(props.src)}}>
        { props.src.length > 0
          ? <img
            className="img-qr-code"
            src={props.src}
            alt='qr-modal'
          />
          : <img
            className="img-qr-code"
            src={QR_placeholder}
            alt='qr-placeholder'
          />
        }
      </div>
      {/*<Typography.Text*/}
      {/*  disabled={props.disabled}*/}
      {/*  onClick={async () => {*/}
      {/*    if(props.disabled) {*/}
      {/*      return*/}
      {/*    }*/}
      {/*    await handleDownloadQRCode()*/}
      {/*  }}*/}
      {/*  style={{fontSize:14, alignItems: 'center', display: 'flex', margin: 'auto', justifyContent: 'center'}}*/}
      {/*>*/}
      {/*  <img src={download_png}/>Download QR code*/}
      {/*</Typography.Text>*/}
      {/*<div style={{paddingLeft: 20}}>*/}
      {/*  <Typography.Paragraph style={{textAlign: 'left'}}>*/}
      {/*    <Typography.Text strong={true} style={{fontSize: 14}}>*/}
      {/*                  Links*/}
      {/*    </Typography.Text>*/}
      {/*    <br/>*/}
      {/*  </Typography.Paragraph>*/}
      {/*</div>*/}
    </>

  );
});

export default QRScan;
