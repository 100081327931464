import { Steps } from '@pankod/refine-antd';
import {QuestionCircleFilled} from '@ant-design/icons';
import {Typography} from 'antd';
import React, {useState} from 'react';
import { CONTACT_US } from '../../../../constants/links';

export default function ProjectSteps({ items, activeStep }: any) {
  const [open, setOpen] =useState(false)
  return (
    <>
      <Steps
        direction="vertical"
        current={activeStep}
        items={items}
        className="project-create-steps"
      />
      <div style={{position: 'absolute', bottom: 10,margin: 'auto', display: 'flex', cursor: 'pointer'}} onClick={()=> setOpen(!open)}>
        <QuestionCircleFilled style={{ fontSize: '20px', color: 'gray', marginLeft: -10 }} />
        <Typography.Paragraph style={{paddingTop:12, paddingLeft: 5, fontSize: 12, fontWeight: 600, color: '#727272' }}>
                    Help
        </Typography.Paragraph>
      </div>
      {open ?
        <div style={{
          width: 120,
          height: 67,
          backgroundColor: 'gray',
          borderRadius: 5,
          paddingLeft: 10,
          position: 'absolute',
          bottom: 70,
          margin:'auto'}}>
          <div style={{fontSize: 12, fontWeight: 600, paddingTop: 10, cursor: 'pointer' }}>
            <a style={{textDecoration: 'none', color: 'white'}} target="_blank" href={CONTACT_US}>
                            Contact support
            </a>
          </div>
          <div style={{fontSize: 12, fontWeight: 600,  color: 'white' ,paddingTop: 10, cursor: 'pointer'}}>
            <a style={{textDecoration: 'none',color: 'white'}} target="_blank" href="https://docs.google.com/document/d/1lrWH9YrytS5FCMTKT6_GS8FuoJpMT9HufOI1fOlr9jQ/edit#heading=h.32pp5ar0ay7x">
                            Documentation
            </a>
          </div>
        </div> : null}

    </>

  );
}
