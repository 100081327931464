import { Modal, message } from 'antd';
import { isBasicSubscriptionPro } from '../../utils/subscription';

export const showProjectDeleteConfirmModal = (projectId: string, deleteProject: Function) => {
  Modal.confirm({
    title: 'Delete Project',
    content: 'Are you sure you want to delete this project?',
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk: async () => {
      console.log('Deleting project with ID:', projectId);
      if (projectId) {
        try {
          await deleteProject(projectId);
          message.success('Project deleted successfully');
        } catch (err) {
          console.error('Error deleting project:', err);
          message.error('Failed to delete the project');
        }
      }
    },
    onCancel: () => {
      console.log('Deletion cancelled');
    },
  });
};
