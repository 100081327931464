import React from 'react';
import { Rate, Space } from '@pankod/refine-antd';
import './styles.less';
import '../../feedback/styles.less';

const CommentItem:React.FC<any>=(commentItem: any)=>{

  return (
    <div
      className='comment-items'>
      <b style={{textTransform: 'capitalize'}}>{`${commentItem.user.firstName} ${commentItem.user.lastName}`}</b>
      <span>{commentItem?.location?.[0]?.address}</span>
      <div
        className='comment-item-data'>
        <Rate
          className='rating-item'
          value={commentItem.rating}
        />
        <p>{commentItem.content}</p>
      </div>
    </div>
  )

}
const CommentsListings:React.FC<any>=({ comments })=>{

  return <Space direction='vertical' style={{ display: 'flex' }} size='middle'>
    {comments.count ? (
      <>
        <span><b className='cmnt_title'>Community Reviews</b> ({comments.count})</span>
        {
          comments.list.map((commentItem : any)=>
            <CommentItem key={commentItem.id} {...commentItem} />)
        }
      </>
    ) : (
      <span>No reviews yet, be first to review!</span>
    )}
  </Space>

  // return <Space direction='vertical' style={{ display: 'flex' }} size='middle'>
  //   <span><b>Community Comments</b> ({comments.length})</span>
  //   {
  //     comments.map((commentItem : any)=>
  //       <CommentItem key={commentItem.id} {...commentItem} />)
  //   }
  // </Space>
}

export default CommentsListings;
