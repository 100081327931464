import './ModelValidationErrors.less';
import InvalidIcon from '../../../assets/invalid_model.svg';
import { Button } from 'antd';
import UpArrowIcon from  '../../../assets/upArrowCircle.svg';

export default function ModelValidationErrors({
  errors,
  deleteModel,
}: {
  errors: Array<string>;
  deleteModel: () => void;
}) {
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <div className="validation-errors">
      <div className='validation-container'>
        <img src={InvalidIcon} alt='*' />
        <h2 className='validation-title'>Invalid model</h2>
        <div className='validation-description'>
          {errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      </div>
      <Button className='delete-model-btn' onClick={deleteModel} icon={<img src={UpArrowIcon} />}>Replace model</Button>
    </div>
  )
}
