import { useEffect, useRef, useState } from 'react';

import { appUtils, jsonUtils } from '../../../utils/util';
import { mixPanelUtils, MixpanelEvent } from '../mixpanel/mixpanel';

import { ModelStrings } from '../../../constants';
import ProjectView from './components/ProjectView';

import './style.less';
import useProjectInfoOnMapSelectionFetch from '../../../hooks/useProjectInfoOnMapSelectionFetch';

interface PublicViewInterface {
  projectId: string; // New callback prop
  isModelExpanded?: boolean;
  setIsModelExpanded?: (isExpanded: boolean) => void;
  isMobile?: boolean;
  isMaxHeight?: boolean;
  searchLocation?: any;
  goTo?: any;
  searchItems?: any;
  locate?: any;
}

const ProjectDemoView = ({
  projectId,
  isModelExpanded,
  setIsModelExpanded,
  isMobile,
  isMaxHeight,
  searchLocation,
  goTo,
  searchItems,
  locate,
}: PublicViewInterface) => {
  const projectIdFull = projectId;

  const [descriptionData, setDescriptionData]: any = useState([]);

  const [modelType, setModelType]: any = useState(null);
  const [isModelAvailable, setIsModelAvailable]: any = useState(false);

  const [feedbackVisible, setFeedbackVisible] = useState(false);

  /* eslint-disable react-hooks/rules-of-hooks */
  const [projectInfo, setProjectInfo]: any = useState(undefined);
  const [fetchProjectInfoOnMapSelection] = useProjectInfoOnMapSelectionFetch({ id: projectIdFull });

  // const [projectInfo, pageLoadStatus]: any = await fetchProjectInfo({ id: projectIdFull })
  // const [projectInfo, pageLoadStatus]: any = useProjectDetailsFetch({ id: projectIdFull })

  const previousProjectInfoRef = useRef(null);
  const performMixpanelTrack = (projectInfo: any) => {
    if (projectInfo && projectInfo !== previousProjectInfoRef.current) {
      console.log('>>>> performMixpanelTrack:', projectInfo)
      mixPanelUtils.track(MixpanelEvent.PROJECT_VISIT, { projectId: projectInfo?.id, projectName: projectInfo?.name })
      appUtils.updatePreviewLinks(projectInfo)

      previousProjectInfoRef.current = projectInfo;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log('>>>> before fetchData:', projectId)
      if (projectId) {

        console.log('>>>> fetchData:', projectId)
        const [projectData, pageLoadStatus]: Array<any> | undefined = await fetchProjectInfoOnMapSelection({ id: projectId });
        if (projectData) {
          console.log('>>>> projectData:', projectData)
          setProjectInfo(projectData);

          const projectJSON = await jsonUtils.buildJSON(projectData);
          setDescriptionData(projectJSON);

          const projectJSONModel = await jsonUtils.buildJSONModel(projectData);
          setIsModelAvailable(projectJSONModel.is_model_available);

          performMixpanelTrack(projectData);
        }
      }
    };

    fetchData().catch(console.error);
  }, [projectId]);

  const projectModelsList = projectInfo?.buildings?.[0]?.model3ds?.sort((a: any, b: any) => a.order - b.order);
  const usdzModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.USDZ);
  const glbModel = projectModelsList?.find((model: any) => model.type === ModelStrings.ModelFormat.GLB);
  console.log({projectInfo})
  if (projectInfo) {
    return (
      <ProjectView
        projectId={projectInfo?.id}
        descriptionData={descriptionData}
        feedbackVisible={feedbackVisible}
        usdzModel={usdzModel}
        glbModel={glbModel}
        setFeedbackVisible={setFeedbackVisible}
        type={modelType}
        isModelAvailable={isModelAvailable}
        isModelExpanded={isModelExpanded}
        setIsModelExpanded={setIsModelExpanded}
        isMobile={isMobile}
        isMaxHeight={isMaxHeight}
        autoGenerateModels={projectInfo.autoGenerateModels}
        searchLocation={searchLocation}
        goTo={goTo}
        searchItems={searchItems}
        locate={locate}
      />
    )
  }

  return (
    <div>There is no project defined</div>
  )
}

export default ProjectDemoView;

