import React from 'react';
import { Card, Col, Button, Typography, Divider, Row } from '@pankod/refine-antd';
import { DocumentsView } from '../../../components/document/index';

const { Paragraph } = Typography;
import './styles.less';
import {ProjectDetailDocumentsListKeys, ProjectDetailHideFromMobileListKeys} from '../../../constants';

interface ProjectDescriptionProps {
  projectId: string;
  data: any;
  openFeedbackForm?: () => void
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({projectId, data, openFeedbackForm}) => {

  const formatValue = (element: any) => {
    try {
      if (typeof element.value == 'object') {
        return element.value.map((item: any) => {
          return item.url?<a target='_blank' href={item.url}>{item.name}</a>:item;
        }).reduce((prev: any, curr: any) => [prev, ', ', curr]);
      } else {
        return element.value?.length > 0 ? element.value : '';
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='description-details-container'>
      {data.filter((item:any)=>!ProjectDetailHideFromMobileListKeys
        .includes(item?.key.toLowerCase()))
        .map((item: any) => {
          const dBlockItemLabels = [
            'Latest Update',
            'Upcoming',
          ].includes(item.key)
          return <Paragraph className={'project-info-p class_'+item.key} key={JSON.stringify(item)}>
            {item.key!=='Description' && item.value.length !== 0 &&
              <span
                className={`p-info-title ${dBlockItemLabels?'d-block':''}`}>
                {` ${item.key}`}
              </span>
            }
            <span
              style={item.key.toLowerCase().includes('status')?{backgroundColor:'#707070',color:'white',padding:'3px'}:{}}>
              {` ${formatValue(item)}`}
            </span>
          </Paragraph>
        })}
      <Divider />
      <DocumentsView list={data.filter((item:any)=>ProjectDetailDocumentsListKeys.includes(item.key.toLowerCase()))}  />
      <Button className='feedback-button'
        type='primary' onClick={openFeedbackForm} shape='round'>Submit Feedback</Button>
    </div>
  );
}
export default ProjectDescription;
