import { memo, useCallback, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Button, Modal } from 'antd';

interface Crop {
  unit: 'px' | '%';
  aspect: number;
  width?: number;
  minWidth?: number;
  height?: number;
  minHeight?: number;
  x?: number;
  y?: number;
}

interface ImageCropperProps {
  src?: string;
  saveImg?: any;
  onCancel?: any;

}
const ImageCropper = ({ src, saveImg, onCancel }: ImageCropperProps) => {
  const [imgRef, setImgRef] = useState(null);
  const [result, setResult] = useState<Blob>();
  const [crop, setCrop] = useState<any>({
    unit: 'px',
    aspect: 3 / 2,
    x: 0,
    y: 0,
    width: 472,
    minWidth: 275,
    height: 314,
    minHeight: 183,
  });
  const [formError, setFormError] = useState('');

  const getCroppedImg = useCallback(async (image: any, crop: any): Promise<Blob> => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement('canvas');
    canvas.width = 1800;
    canvas.height = 1200;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        1800,
        1200,
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(setFormError('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  }, []);

  const makeClientCrop = useCallback(
    async (crop: any) => {
      if (imgRef && crop.width && crop.height) {
        const croppedImageUrl = await getCroppedImg(imgRef, crop);
        setResult(croppedImageUrl);
      }
    },
    [imgRef, getCroppedImg],
  );

  const onCropComplete = useCallback(
    (crop: any) => {
      makeClientCrop(crop).catch(err => {
        setFormError(err.message);
      });
    },
    [makeClientCrop],
  );

  const onCropChange = (crop: any) => {
    setCrop({
      aspect: crop.aspect,
      unit: 'px',
      x: crop.x,
      y: crop.y,
      width: crop.width < 275 ? 275 : crop.width,
      height: crop.height < 183 ? 183 : crop.height,
    });
  };

  useEffect(() => {
    onCropComplete(crop);
  }, [imgRef, crop, onCropComplete]);

  const onImageLoaded = useCallback((image: any) => {
    setImgRef(image);
    const cropWidth = image.width / 1.5;

    setCrop({
      aspect: crop.aspect,
      unit: 'px',
      x: 0,
      y: image.height - cropWidth > 0 ? (image.height - cropWidth) / 2 : 0,
      width: image.width,
      height: cropWidth,
    });

    return false;
  }, []);

  const handleSaveClick = useCallback(() => saveImg(result), [saveImg, result]);

  return (
    <Modal
      open={!!src}
      onCancel={onCancel}
      onOk={handleSaveClick}
      title='Image Cropper'
    >
      {formError && <p className="error">{formError}</p>}
      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          keepSelection={true}
        />
      )}
    </Modal>
  );
};

export default memo(ImageCropper);
