import React, { useState } from 'react';
import {
  Pagination,Spin,
} from '@pankod/refine-antd';
import { Document, Page,pdfjs } from 'react-pdf';
import { LoadingOutlined } from '@ant-design/icons';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './styles.less';

interface PdfViewerProps{
    fileUrl: string;
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function PdfViewer({fileUrl}:PdfViewerProps) {
  const [numPages, setNumPages]:any = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded,setLoaded] = useState(false);

  function onDocumentLoadSuccess({ numPages }:any) {
    setNumPages(numPages);
    setLoaded(true);
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;

  return (
    <div>
      <Document
        renderMode='canvas'
        loading={()=><Spin className='pdf-loading' indicator={loadingIcon} />}
        file={fileUrl}
        onLoadError={(e)=>console.log(e)}
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      {loaded && <Pagination
        simple={true}
        size='small'
        showSizeChanger={false}
        responsive={true}
        onChange={(page)=>{
          setPageNumber(page);
        }}
        current={pageNumber}
        total={numPages} />}
    </div>
  );
}

export default PdfViewer;
