import mixpanel from 'mixpanel-browser';
import { authProvider } from '../../../authPrvider/authProvider';
/* eslint-disable */
export enum MixpanelEvent {
    // PROJECT_VISIT_TEST = 'visit',
    PROJECT_VISIT = 'Detail Page Opened',
    APP_STORE_HOME_VISIT = 'AppStore Home Visit',
    APP_STORE_VISIT = 'AppStore Visit',
    FEEDBACK_SENT = 'Review Sent',
    PROJECT_SHARE = 'Project Shared',

    AR_SESSION_START = 'Explore AR Started',
    AR_SESSION_END = 'AR Session',

    // admin
    PROJECT_GRIDVIEW = 'Projects GridView Visit',
    PROJECT_LISTVIEW = 'Projects ListView Visit',
    PROJECT_EDIT_VISIT = 'Project Edit Visit',
    PROJECT_CREATE_VISIT = 'Project Create Visit',
    PROJECT_CREATED = 'Project Created',
    PROJECT_UPDATED = 'Project Updated',
    PROJECT_PUBLISHED =  'Project Published',
    PROJECT_PUBLISH_FAILED =  'Project Publish Failed', // check
    PROJECT_PUBLISH_TIMEOUT = 'Project Publish Timeout', // check
    PROJECT_DELETED = 'Project Deleted',
    MODEL_UPLOADED = 'Model Uploaded',
    MODEL_REPLACED = 'Model Replaced',
    MODEL_RESET_POSITION = 'Model Reset Position',
    PROJECT_ADDRESS_CHANGED = 'Project Address Changed',

    PROJECT_SHARE_LINK = 'Project Shared Link Copied',
    PROJECT_QR_LINK = 'Project QR Link Copied',
    PROJECT_QR_COPY = 'Project QR Image Copied',
    COVER_IMAGE_UPLOADED = 'Cover Image Uploaded',
    COVER_IMAGE_DELETED = 'Cover Image Deleted',

    PROJECT_SEARCH = 'Project Search Performed',


    USER_LOGGED_IN = 'Logged In', // ok to check if created date
    USER_SIGNED_UP = 'Signed Up', // ok

    // Onboarding
    EMAIL_VERIFICATION = 'Email Verification Process Visit', // ok
    EMAIL_VERIFIED = 'Email Email Verified', // ok - add email
    ALL_SET = 'All Set Visit',
    WELCOME_BACK = 'Welcome Back Visit', // ok
    PRICING_PAGE = 'Pricing Page Visit', // ok

    // Project Wizard
    PROJECT_WIZARD_BACK_ACTION = 'Project Wizard Back Action', // ok
    PROJECT_WIZARD_NEXT_ACTION = 'Project Wizard Next Action', // ok

    // Map
    PROJECT_WIZARD_FOCUS_MAP_ACTION = 'Project Wizard Focus Map Action', // +
    PROJECT_WIZARD_ZOOM_IN_ACTION = 'Project Wizard Zoom In Map Action', // ok
    PROJECT_WIZARD_ZOOM_OUT_ACTION = 'Project Wizard Zoom Out Map Action',// ok
    PROJECT_WIZARD_COMPASS_MAP_ACTION = 'Project Wizard Map Compass Action', // ok
    PROJECT_WIZARD_USER_LOCATION_ACTION = 'Project Wizard User Location Action',// ok
    PROJECT_WIZARD_MAP_MOVEMENT_ACTION = 'Project Wizard Map Movement Action',// ok
    PROJECT_WIZARD_MAP_ROTATION_ACTION = 'Project Wizard Map Rotation Action',// ok

    PROJECT_WIZARD_MAP_SNAP_TO_GROUND = 'Snap To Ground Action',  // ok

    PROJECT_WIZARD_GENERAL_VISIT = 'Project Wizard General Visit', // ok
    PROJECT_WIZARD_APP_CLIP_VISIT = 'Project Wizard App Clip Visit', // ok
    PROJECT_WIZARD_AR_VISIT = 'Project Wizard AR Visit',  // ok

    PROJECT_WIZARD_WEB_PREVIEW = 'Project Wizard Web Preview', // ok
    PROJECT_WIZARD_IOS_PREVIEW = 'Project Wizard iOS Preview', // ok
    PROJECT_WIZARD_ANDROID_PREVIEW = 'Project Wizard Android Visit',// - not for now

    // User Account
    USER_ACCOUNT_PAGE = 'User Account Page Visit', // ok
    CHANGE_PLAN_ACTION = 'Change Plan Action', // ok
    ACCOUNT_NAME_CHANGE_ACTION = 'Account Name Change Action',// ok
    PASSWORD_CHANGE_ACTION = 'Password Change Action', // ok
}
/* eslint-disable */

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN ?? ''}`);

export const mixPanelUtils = {

  track: function track(eventName: string, props: any = undefined) {
    console.log('______eventName:', eventName)
    console.log('_______props:', props)
    if (process.env.REACT_APP_ANALYTICS_ENABLED?.toLowerCase() == 'true') {
      switch (eventName) {
          case MixpanelEvent.PROJECT_VISIT:
            mixPanel.trackProjectVisit(props)
            break;
          case MixpanelEvent.APP_STORE_HOME_VISIT:
            mixPanel.trackIncituHomeVisit(props)
            break;
          case MixpanelEvent.APP_STORE_VISIT:
            mixPanel.trackItunesVisit(props)
            break;
          case MixpanelEvent.FEEDBACK_SENT:
            mixPanel.trackFeedbackSent(props)
            break;
          case MixpanelEvent.PROJECT_SHARE:
            mixPanel.trackProjectShare(props)
            break;
          case MixpanelEvent.AR_SESSION_START:
            mixPanel.trackStartSessionAR(props)
            break;
          case MixpanelEvent.AR_SESSION_END:
            mixPanel.trackEndSessionAR(props)
            break;
          case MixpanelEvent.PROJECT_GRIDVIEW:
            mixPanel.trackProjectGridView(props)
            break;
          case MixpanelEvent.PROJECT_LISTVIEW:
            mixPanel.trackProjectListView(props)
            break;
          case MixpanelEvent.PROJECT_EDIT_VISIT:
            mixPanel.trackProjectEditOpened(props)
            break;
          case MixpanelEvent.PROJECT_CREATE_VISIT:
            mixPanel.trackProjectCreateOpened(props)
            break;

          case MixpanelEvent.PROJECT_CREATED:
            mixPanel.trackProjectCreated(props)
            break;
          case MixpanelEvent.PROJECT_UPDATED:
            mixPanel.trackProjectUpdated(props)
            break;
          case MixpanelEvent.PROJECT_PUBLISHED:
          case MixpanelEvent.PROJECT_PUBLISH_FAILED:
            mixPanel.trackProjectPublished(props, eventName)
            break;
          case MixpanelEvent.PROJECT_ADDRESS_CHANGED:
            mixPanel.trackProjectAddressChanged(props)
            break;
          case MixpanelEvent.MODEL_RESET_POSITION:
            mixPanel.trackMapReset(props)
            break;
          case MixpanelEvent.MODEL_UPLOADED:
            mixPanel.trackModelUploaded(props)
            break;
          case MixpanelEvent.MODEL_REPLACED:
            mixPanel.trackModelReplaced(props)
            break;
          case MixpanelEvent.COVER_IMAGE_UPLOADED:
            mixPanel.trackCoverImageUploaded(props)
            break;
          case MixpanelEvent.COVER_IMAGE_DELETED:
            mixPanel.trackCoverImageDeleted(props)
            break;
          case MixpanelEvent.PROJECT_DELETED:
            mixPanel.trackDeleteProject(props)
            break;
          case MixpanelEvent.PROJECT_SHARE_LINK:
            mixPanel.trackProjectShareLinkCopied(props)
            break;
          case MixpanelEvent.PROJECT_QR_LINK:
            mixPanel.trackProjectQRLinkOrImageCopied(props, MixpanelEvent.PROJECT_QR_LINK)
            break;
          case MixpanelEvent.PROJECT_QR_COPY:
            mixPanel.trackProjectQRLinkOrImageCopied(props, MixpanelEvent.PROJECT_QR_COPY)
            break;
          case MixpanelEvent.PROJECT_SEARCH:
            mixPanel.trackProjectSearch(props)
            break;
          case MixpanelEvent.USER_LOGGED_IN:
            mixPanel.trackUserLogin(props)
            break;
          case MixpanelEvent.USER_SIGNED_UP:
            mixPanel.trackUserSignUp(props)
            break;
          case MixpanelEvent.EMAIL_VERIFICATION:
          case MixpanelEvent.EMAIL_VERIFIED:
          case MixpanelEvent.ALL_SET:
          case MixpanelEvent.WELCOME_BACK:
          case MixpanelEvent.PRICING_PAGE:
            mixPanel.trackOnboardingSteps(props, eventName)
            break;
          case MixpanelEvent.PROJECT_WIZARD_BACK_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_NEXT_ACTION:
            mixPanel.trackProjectWizard(props, eventName)
            break;
          case MixpanelEvent.PROJECT_WIZARD_FOCUS_MAP_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_ZOOM_IN_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_ZOOM_OUT_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_COMPASS_MAP_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_USER_LOCATION_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_MAP_MOVEMENT_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_MAP_ROTATION_ACTION:
          case MixpanelEvent.PROJECT_WIZARD_MAP_SNAP_TO_GROUND:
            mixPanel.trackProjectWizardMapActions(props, eventName)
            break;

        case MixpanelEvent.PROJECT_WIZARD_GENERAL_VISIT:
        case MixpanelEvent.PROJECT_WIZARD_APP_CLIP_VISIT:
        case MixpanelEvent.PROJECT_WIZARD_AR_VISIT:
        case MixpanelEvent.PROJECT_WIZARD_WEB_PREVIEW:
        case MixpanelEvent.PROJECT_WIZARD_IOS_PREVIEW:
        case MixpanelEvent.PROJECT_WIZARD_ANDROID_PREVIEW:
          mixPanel.trackVisitPages(props, eventName)
          break;

        case MixpanelEvent.USER_ACCOUNT_PAGE:
          mixPanel.trackVisitPages(props, eventName)
          break;
        case MixpanelEvent.CHANGE_PLAN_ACTION:
        case MixpanelEvent.ACCOUNT_NAME_CHANGE_ACTION:
        case MixpanelEvent.PASSWORD_CHANGE_ACTION:
          mixPanel.trackActions(props, eventName)
          break;
      }
    }
  },
}


const buildPayload = (props: { [key: string]: any }, isAR: Boolean = false, isAdmin: Boolean = false, isOnboarding: Boolean = false) => {
  const user = authProvider.getInCituUser()
  return {
    ...props,
    'application': 'web',
    ...(
      isAR ? { 'ar_mode': 'offsite' } : {}
    ),
    ...(
      isAdmin ? {
        'admin_panel': 'true',
        user_id: user?.id ?? '',
        user_full_name: user ? `${user.firstName} ${user.lastName}` : '',
        email: user?.email ?? ''
      } : {}
    ),
    ...(
      isOnboarding ? { 'onboarding': 'true' } : {}
    )
  }
}

const buildAuthPayload = (props: { [key: string]: any }, isAdmin: Boolean = false) => {
  const user = authProvider.getInCituUser()
  return {
    ...props,
    'application': 'web',
    user_id: user?.id ?? '',
    email: user?.email ?? '',
    user_full_name: user ? `${user.firstName} ${user.lastName}` : '',
    ...(
      isAdmin ? { 'admin_panel': 'true' } : {}
    )
  }
}


const mixPanel = {
  trackProjectVisit: function trackProjectVisit(props: any) {
      if(props?.projectId && props?.projectName) {
        console.log('==== MixpanelEvent.PROJECT_VISIT ====', props)
        const initialPayload = {
          'projectId': props?.projectId || '',
          'projectName': props?.projectName || '',
        }
        const payload = buildPayload(initialPayload)
        mixpanel.track(MixpanelEvent.PROJECT_VISIT,
          payload,
        );
      }
  },
  trackItunesVisit: function trackItunesVisit(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.INCITU_HOME_VISIT ====', props)
      const initialPayload = {'projectId': props?.projectId || ''}
      const payload = buildPayload(initialPayload)
      mixpanel.track(MixpanelEvent.APP_STORE_VISIT, payload);
    }
  },
  trackIncituHomeVisit: function trackIncituHomeVisit(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.INCITU_HOME_VISIT ====', props)
      const initialPayload = {'projectId': props?.projectId || ''}
      const payload = buildPayload(initialPayload)
      mixpanel.track(MixpanelEvent.APP_STORE_HOME_VISIT, payload);
    }
  },
  trackFeedbackSent: function trackFeedbackSent(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.FEEDBACK_SENT ====', props)
      const initialPayload = {
        'projectId': props?.projectId || '',
        'rate': Number(props?.rate),
        'comment_text': props.feedback || '',
        'email': props.email || '',
      }
      const payload = buildPayload(initialPayload)
      mixpanel.track(MixpanelEvent.FEEDBACK_SENT,
        payload,
      );
    }
  },
  trackProjectShare: function trackProjectShare(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.PROJECT_SHARE ====', props)
      const initialPayload = {'projectId': props?.projectId || ''}
      const payload = buildPayload(initialPayload)
      mixpanel.track(MixpanelEvent.PROJECT_SHARE, payload);
    }
  },
  trackStartSessionAR: function trackStartSessionAR(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.AR_SESSION_START ====', props)
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, true)
      mixpanel.track(MixpanelEvent.AR_SESSION_START, payload);
    }
  },
  trackEndSessionAR: function trackEndSessionAR(props: any) {
    if(props?.projectId) {
      console.log('==== MixpanelEvent.AR_SESSION_END ====', props)
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, true)
      mixpanel.track(MixpanelEvent.AR_SESSION_END, payload);
    }
  },
  trackProjectGridView: function trackProjectGridView(props: any) {
      console.log('==== MixpanelEvent.PROJECT_GRIDVIEW ====', props)
      const payload = buildPayload(props, false, true)
      console.log('___MixpanelEvent.PROJECT_GRIDVIEW:', MixpanelEvent.PROJECT_GRIDVIEW,)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_GRIDVIEW, payload);
  },
  trackProjectListView: function trackProjectListView(props: any) {
    const payload = buildPayload(props, false, true)
    console.log('___MixpanelEvent.PROJECT_LISTVIEW,:', MixpanelEvent.PROJECT_LISTVIEW,)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.PROJECT_LISTVIEW, payload);
  },

  trackProjectEditOpened: function  trackProjectEditOpened(props: any) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_EDIT_OPENED:', MixpanelEvent.PROJECT_EDIT_VISIT)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_EDIT_VISIT, payload);
    }
  },
  trackProjectCreateOpened: function  trackProjectCreateOpened(props: any) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_CREATE_VISIT:', MixpanelEvent.PROJECT_CREATE_VISIT)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_CREATE_VISIT, payload);
  },
  trackProjectCreated: function trackProjectCreated(props: any) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_CREATED:', MixpanelEvent.PROJECT_CREATED)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_CREATED, payload);
    }
  },
  trackProjectUpdated: function trackProjectUpdated(props: any) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_UPDATED:', MixpanelEvent.PROJECT_UPDATED)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_UPDATED, payload);
    }
  },
  trackProjectPublished: function trackProjectPublished(props: any, eventName: string) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent PROJECT PUBLISH eventName:', eventName)
      console.log('___payload:', payload)
      mixpanel.track(eventName, payload);
    }
  },
  trackProjectAddressChanged: function trackProjectAddressChanged(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.PROJECT_ADDRESS_CHANGED:', MixpanelEvent.PROJECT_ADDRESS_CHANGED)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.PROJECT_ADDRESS_CHANGED, payload);
  },
  trackMapReset: function trackMapReset(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.MODEL_RESET_POSITION:', MixpanelEvent.MODEL_RESET_POSITION)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.MODEL_RESET_POSITION, payload);
  },
  trackModelUploaded: function trackModelUploaded(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.MODEL_UPLOADED:', MixpanelEvent.MODEL_UPLOADED)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.MODEL_UPLOADED, payload);
  },
  trackModelReplaced: function trackModelReplaced(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.MODEL_REPLACED:', MixpanelEvent.MODEL_REPLACED)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.MODEL_REPLACED, payload);
  },
  trackCoverImageUploaded: function trackCoverImageUploaded(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.COVER_IMAGE_UPLOADED:', MixpanelEvent.COVER_IMAGE_UPLOADED)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.COVER_IMAGE_UPLOADED, payload);
  },
  trackCoverImageDeleted: function trackCoverImageDeleted(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.COVER_IMAGE_DELETED:', MixpanelEvent.COVER_IMAGE_DELETED)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.COVER_IMAGE_DELETED, payload);
  },
  trackProjectShareLinkCopied: function trackProjectShareLinkCopied(props: any) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_SHARE_LINK:', MixpanelEvent.PROJECT_SHARE_LINK)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_SHARE_LINK, payload);
    }
  },
  trackProjectQRLinkOrImageCopied: function trackProjectQRLinkOrImageCopied(props: any, eventName: string) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent eventName:', eventName)
      console.log('___payload:', payload)
      mixpanel.track(eventName, payload);
    }
  },
  trackDeleteProject: function trackDeleteProject(props: any) {
    if(props?.projectId) {
      const initialPayload = {
        'projectId': props?.projectId || '',
      }
      const payload = buildPayload(initialPayload, false, true)
      console.log('___MixpanelEvent.PROJECT_DELETED,:', MixpanelEvent.PROJECT_DELETED)
      console.log('___payload:', payload)
      mixpanel.track(MixpanelEvent.PROJECT_DELETED, payload);
    }
  },
  trackProjectSearch: function trackProjectSearch(props: any) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true)
    console.log('___MixpanelEvent.PROJECT_SEARCH,:', MixpanelEvent.PROJECT_SEARCH)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.PROJECT_SEARCH, payload);
  },
  trackUserLogin: function trackUserLogin(props: any) {
    const initialPayload = {}
    const payload = buildAuthPayload(initialPayload, true)
    console.log('___MixpanelEvent.USER_LOGGED_IN,:', MixpanelEvent.USER_LOGGED_IN)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.USER_LOGGED_IN, payload);
  },
  trackUserSignUp: function trackUserSignUp(props: any) {
    const initialPayload = {
      'entryPoint': props?.entryPoint || '',
    }
    const payload = buildAuthPayload(initialPayload, true)
    console.log('___MixpanelEvent.USER_SIGNED_UP:', MixpanelEvent.USER_SIGNED_UP)
    console.log('___payload:', payload)
    mixpanel.track(MixpanelEvent.USER_SIGNED_UP, payload);
  },
  // Onboarding
  trackOnboardingSteps: function trackOnboardingSteps(props: any, eventName: string) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true, true)
    console.log('___MixpanelEvent.event:', eventName)
    console.log('___payload:', payload)
    mixpanel.track(eventName, payload);
  },

  trackProjectWizard: function trackProjectWizard(props: any, eventName: string) {
    const initialPayload = {
      ...props,
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true, false)
    console.log('___MixpanelEvent.event:', eventName)
    console.log('___payload:', payload)
    mixpanel.track(eventName, payload);
  },
  trackProjectWizardMapActions: function trackProjectWizardMapActions(props: any, eventName: string) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true, false)
    console.log('___MixpanelEvent.event:', eventName)
    console.log('___payload:', payload)
    mixpanel.track(eventName, payload);
  },
  trackVisitPages: function trackVisitPages(props: any, eventName: string) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true, false)
    console.log('___MixpanelEvent.event:', eventName)
    console.log('___payload:', payload)
    mixpanel.track(eventName, payload);
  },
  trackActions: function trackActions(props: any, eventName: string) {
    const initialPayload = {
      'projectId': props?.projectId || '',
    }
    const payload = buildPayload(initialPayload, false, true, false)
    console.log('___MixpanelEvent.event:', eventName)
    console.log('___payload:', payload)
    mixpanel.track(eventName, payload);
  },
}
