import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Rate,
  message,
  Spin,
  Typography,
  Space,
  Modal,
} from '@pankod/refine-antd';

import { MixpanelEvent, mixPanelUtils } from '../../../mixpanel/mixpanel';
import { authProvider } from '../../../../../authPrvider/authProvider';
import dataProvider from '../../../../../dataProvider/dataProvider';
import useAuth0Authorization from '../../../../../hooks/auth0/useAuth0Authorization';

import './styles.less';
import { checkIfAndroidInterfaceAvailable } from '../../../../../utils/checkMobile';

interface FeedbackFormProps {
  // Props go here
  projectId: string;
  visible?: boolean;
  onClose?: any;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ projectId, visible = false, onClose }) => {
  const { performAuthorization } = useAuth0Authorization();
  const { Text } = Typography;
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const ifAndroidInterfaceAvailable = checkIfAndroidInterfaceAvailable()

  const handleSubmit = async (event: React.FormEvent) => {
    // event.preventDefault();
    setSubmitting(true);

    if(!ifAndroidInterfaceAvailable) {
      try {
        await performAuthorization()
      } catch (error) {
        console.log('error')
        onClose();
        return
      }
    }

    const user = authProvider.getInCituUser()
    if (!user) {
      message.error('Please sign in to provide feedback');
      onClose();
      return
    }

    try {
      const commentValues = {
        content: feedback,
        rating: rating,
        user: { connect: { id: user.id } },
        project: { connect: { id: projectId } },
        hidden: false,
      }

      const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)

      const { data } = await dProvider.create({
        resource: 'comment',
        variables: commentValues,
        metaData: {
          fields: ['id'],
        },
      }).finally(() => {
        mixPanelUtils.track(MixpanelEvent.FEEDBACK_SENT, {
          projectId: projectId,
          rate: rating,
          feedback: feedback,
          email: user.email,
        })
        message.success('Thank you for your feedback!');
        // setEmail('');
        setRating(0);
        setFeedback('');

        onClose();
      });

    } catch (error: any) {
      message.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };
  const desc = ['disapprove', '', '', '', 'fully support'];

  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} onCancel={onClose} footer={[]} className='feedback-form-modal'>
      <Form layout='vertical' onFinish={handleSubmit} className='feedback-form'>
        <Form.Item
          label={
            <Space direction='vertical'>
              <span className='feedback-form-label'>
                How would you rate this development?
              </span>
              <Text className='rating-hint'>
                1 = disapprove, 5 = fully support
              </Text>
            </Space>
          }
          name='rating'
          rules={[{ required: true }]}
        >
          {/* <Text className='rating-hint' >
            1 = disapprove, 5 = fully support
          </Text> */}
          <Rate
            tooltips={desc}
            className='rating-item'
            value={rating}
            onChange={(value) => setRating(value)}
            disabled={submitting}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className='feedback-form-label'>
              Any thoughts you'd like to share?
            </span>
          }
          // label=''
          name='feedback'
          rules={[{ required: true }]}
        >
          <Input.TextArea
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
            disabled={submitting}
          />
        </Form.Item>
        <Form.Item>
          {submitting ? (
            <Spin />
          ) : (
            <div className='submit-btn-container'>
              <Button
                shape='round'
                className='submit-button'
                type='primary'
                htmlType='submit'
              >
                Submit
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackForm;
