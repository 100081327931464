import dataProvider from '../dataProvider/dataProvider';

export default function useProjectSearch() {

  const searchProject = async (keyword: any) => {

    const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
    try {
      const {
        data: projectInfo,
      }: any = await dProvider.getList<any>({
        resource: 'projects',
        pagination: { current: 1},
        filters: [{ field: 'id', operator: 'endswith', value: keyword }],
        metaData: {
          fields: [
            'id',

          ],
        },
      });

      return {data: projectInfo}
    } catch (error) {
      console.error('Error searching project:', error);
      return undefined;
    }
  }

  return [searchProject];
}
