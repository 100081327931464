import { Modal } from '@pankod/refine-antd';

export const showSuccessModal = async (title: string, message: string) => {

  Modal.success({
    title: title,
    content: message,
    okText: 'OK',
    onOk: () => {
    },
  });
};
