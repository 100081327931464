import { authProvider } from '../../authPrvider/authProvider';
import {
  callSignOutNativeAndroidApp,
  checkIfAndroidInterfaceAvailable,
} from '../../utils/checkMobile';

const performLogout = async (logout: any) => {
  if(checkIfAndroidInterfaceAvailable()) {
    callSignOutNativeAndroidApp();

    if(logout) {
      const returnTo = window.location.origin + '/map'
      logout({ returnTo });
    }
  } else {
    if(logout) {
      if(window.location.pathname === '/auto-logout') {
        // window.location.href = '/map';
        const returnTo = window.location.origin + '/map' // window.location.href
        logout({ returnTo });
      } else {
        const returnTo = window.location.pathname === '/map' ? window.location.href : window.location.origin;
        logout({ returnTo });
      }
    }
  }
  // logout({ returnTo: `${process.env.REACT_APP_BASE_URL}/landing` });
  await authProvider.removeFromLocalStorage();
}

export default performLogout

