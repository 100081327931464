import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, message, Row, Space, Typography, UploadFile } from '@pankod/refine-antd';

import Model from '../../../pages/public-view/project-mobile/components/model-preview/model';
import { getFileExtension } from '../../../utils/util';
import { ModelUploader } from './ModelUploader';
import { ModelFormat } from '../../../constants/model';
import EsriMapUploader from './ModelPosition';
import { validateBytes } from 'gltf-validator'
import ModelValidationErrors from './ModelValidationErrors';
import UpArrowIcon from '../../../assets/upArrowCircle.svg';
import AcceptedIcon from '../../../assets/accepted.svg';

import './ModelPreviewForm.less';
import { getAWSUrl } from '../utils/aws';
import { MixpanelEvent, mixPanelUtils } from '../../../pages/public-view/mixpanel/mixpanel';
import { filterUnsuportedFeatures } from '../utils/model';

export default function ModelPreviewForm(props: {
  models: any;
  setModelsList: (
    { file, url }: { file?: File; url?: string },
    buildingIndex: number,
    order: number
  ) => void;
  loading: boolean;
  projectId: any;
  order: number;
  buildingIndex: number;
  isNew: boolean;
  longitude?: number;
  latitude?: number;
  rotation?: number;
  searchedLocation: any;
  setSearchedLocation: any;
  altitude?: number;
  setInitLatitude: (latitude: number) => void;
  setInitLongitude: (longitude: number) => void;
  setInitAltitude: (altitude: number) => void;
  setInitRotation: (rotation: number) => void;
  onTakeScreenshot: boolean,
  onScreenshotTaken: (dataUrl: string) => void;
}) {
  const [newModelUrl, setNewModelUrl] = useState<any>(null);
  const [isModelReset, setIsModelReset] = useState<boolean>(false);
  const [modelValidationErrors, setModelValidationErrors] = useState<any>(null);

  const form = Form.useFormInstance();

  const {
    models,
    setModelsList,
    projectId,
    loading,
    order,
    isNew,
    buildingIndex,
    longitude,
    latitude,
    rotation,
    searchedLocation,
    setSearchedLocation,
    altitude,
    setInitLatitude,
    setInitLongitude,
    setInitAltitude,
    setInitRotation,
    onTakeScreenshot,
    onScreenshotTaken,
  } = props;

  const [currentLatitude, setCurrentLatitude] = useState<any>(latitude);
  const [currentLongitude, setCurrentLongitude] = useState<any>(longitude);
  const [isModelReplaced, setIsModelReplaced] = useState(false);

  // const esriMapUploaderRef = useRef();
  //
  // const handleTakeScreenshot = () => {
  //   console.log("____handleTakeScreenshot:", esriMapUploaderRef)
  //   if(esriMapUploaderRef) {
  //     // @ts-ignore
  //     esriMapUploaderRef.current.takeScreenshot();
  //   }
  // };

  // const [takeScreenshot, setTakeScreenshot] = useState(false);

  // const handleTakeScreenshot = () => {
  //   setTakeScreenshot(true);
  // };
  //
  // const handleScreenshotTaken = () => {
  //   // Handle the screenshot taken event here
  //   setTakeScreenshot(false);
  // };
  const getFileLocationUrl = (model: any) => {
    const fileLocation = model?.url ?? model?.fileLocation;

    return !model?.file && fileLocation ? `${getAWSUrl()}/${fileLocation}` : fileLocation;
  }

  const modelLocationUrl = getFileLocationUrl(models?.glb);

  const getBase64 = (file?: File) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // check if file extension exists for current order
      // if exists, replace the file
      // else, add new file to the list

      // const modelsList = [...models];

      // const isNewFile = !(modelsList.find(
      //   (model) => getFileExtension(model.file.name) === getFileExtension(file.name)));

      // let newFileList = [];

      // if (isNewFile) {
      //   newFileList = [...modelsList, {
      //     file,
      //     url: URL.createObjectURL(file),
      //     order,
      //   }];
      // } else {
      //   newFileList = modelsList.map((model) => {
      //     if (getFileExtension(model.file.name) === getFileExtension(file.name)) {
      //       return {
      //         file,
      //         url: URL.createObjectURL(file),
      //         order,
      //       };
      //     }
      //     return model;
      //   });
      // }
      setModelsList(
        {
          file,
          url: URL.createObjectURL(file),
        },
        buildingIndex,
        order,
      );
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const handleModelUpload = (file: File) => {
    mixPanelUtils.track(MixpanelEvent.MODEL_UPLOADED, { projectId: projectId })

    if (isModelReplaced) {
      mixPanelUtils.track(MixpanelEvent.MODEL_REPLACED, { projectId: projectId })
    }
    getBase64(file);
    setIsModelReplaced(false);
  }

  const handleDeleteModel = () => {
    setIsModelReplaced(true);
    const uploadButton = document.getElementById('model-upload-btn-new');

    if (uploadButton) {
      uploadButton.click();
    }
  };

  const handleResetModelPosition = () => {
    setSearchedLocation({
      latitude: +form.getFieldValue('latitude') + 0.1,
      longitude: +form.getFieldValue('longitude') + 0.1,
    });

    setTimeout(() => {
      form.setFieldValue('latitude', `${latitude}`);
      form.setFieldValue('longitude', `${longitude}`);
      form.setFieldValue('altitude', `${altitude}`);
      form.setFieldValue('rotation', `${rotation}`);

      if (latitude && longitude) {
        setSearchedLocation({
          latitude: +latitude,
          longitude: +longitude,
        });
      }
    })
  }

  const handleLatitudeChange = (latitude: any) => {
    form.setFieldValue('latitude', `${latitude}`);
    setCurrentLatitude(latitude);
  }

  const handleLongitudeChange = (longitude: any) => {
    form.setFieldValue('longitude', `${longitude}`);
    setCurrentLongitude(longitude);
  }

  const handleAltitudeChange = (altitude: any) => {
    form.setFieldValue('altitude', `${altitude}`);
  }

  const handleRotationChange = (rotation: any) => {
    form.setFieldValue('rotation', `${rotation}`);
  }

  const glbModelProps = {
    name: 'glbModelPreview',
    accept: '.glb',
    fileList: [],
    beforeUpload: (file: File) => {
      file?.arrayBuffer()?.then((data: ArrayBuffer) => {
        validateBytes(new Uint8Array(data))
          .then((result) => {
            setModelValidationErrors(null);

            const unsuportedFeatures = filterUnsuportedFeatures(result?.info?.extensionsRequired ?? []);

            if (result.issues.numErrors > 0 || result.issues.numWarnings > 0) {
              setModelValidationErrors(result.issues.messages.map((errorObj: any) => (errorObj.message)));
            } else if (unsuportedFeatures.length > 0) {
              setModelValidationErrors(['Model is not supported.'
                + ' Please remove the following features from the model: '
                + unsuportedFeatures.join(', '),
              ]);
            } else {
              const objectURL = URL.createObjectURL(file)
              handleModelUpload(file);
              getBase64(file);
              setNewModelUrl(objectURL);
            }
          })
          .catch((error: any) => {
            setModelValidationErrors([error]);
          });
      });

      return false;
    },
  };

  const glbModelPropsHidden = {
    name: 'glbModelPreview',
    accept: '.glb',
    fileList: [],
    beforeUpload: (file: File) => {
      file?.arrayBuffer()?.then((data: ArrayBuffer) => {
        validateBytes(new Uint8Array(data))
          .then((result) => {
            setModelValidationErrors(null);

            const unsuportedFeatures = filterUnsuportedFeatures(result?.info?.extensionsRequired ?? [])

            if (result.issues.numErrors > 0 || result.issues.numWarnings > 0) {
              setModelValidationErrors(result.issues.messages.map((errorObj: any) => (errorObj.message)));
            } else if (unsuportedFeatures.length > 0) {
              setModelValidationErrors(['Model is not supported.'
                + ' Please remove the following features from the model: '
                + unsuportedFeatures.join(', '),
              ]);
            } else {
              setModelsList({}, buildingIndex, order);
              setNewModelUrl(null);
              setModelValidationErrors(null);
              handleRotationChange(0);
              setTimeout(() => {
                const objectURL = URL.createObjectURL(file)

                handleModelUpload(file);
                getBase64(file);
                setNewModelUrl(objectURL);
              }, 200);
            }
          })
          .catch((error: any) => {
            setModelValidationErrors([error]);
          });
      });

      return false;
    },
  };

  useEffect(() => {
    if (isModelReset) {
      setInitLatitude(currentLatitude);
      setInitLongitude(currentLongitude);
      setInitRotation(0);
      setInitAltitude(0);

      form.setFieldValue('latitude', `${currentLatitude}`);
      form.setFieldValue('longitude', `${currentLongitude}`);
      form.setFieldValue('altitude', '0');
      form.setFieldValue('rotation', '0');

      setIsModelReset(false);
    }
  }, [isModelReset, currentLatitude, currentLongitude]);

  return (
    <>
      <Col span={24}>
        <Typography.Title className='required' level={4}>Upload model</Typography.Title>
        {!modelLocationUrl ? (
          <div className="mode-upload-container">
            <ModelUploader
              modelType='glb'
              modelName='Model'
              uploadProps={glbModelProps}
            />
            <ModelValidationErrors errors={modelValidationErrors} deleteModel={handleDeleteModel} />
          </div>
        ) : (
          <div className="esri-map-container">
            <EsriMapUploader
              onLatitudeChange={handleLatitudeChange}
              onLongitudeChange={handleLongitudeChange}
              onAltitudeChange={handleAltitudeChange}
              onRotationChange={handleRotationChange}
              onModelUpload={handleModelUpload}
              modelUrl={modelLocationUrl}
              longitude={longitude}
              latitude={latitude}
              altitude={altitude}
              rotation={rotation ?? 0}
              isEdit={!isNew && !isModelReset}
              searchedLocation={searchedLocation}
              newModelUrl={newModelUrl}
              onModelReset={handleResetModelPosition}
              setIsModelReset={setIsModelReset}
              onTakeScreenshot={onTakeScreenshot}
              onScreenshotTaken={onScreenshotTaken}
            />
            <Button
              className='delete-model-btn'
              onClick={handleDeleteModel}
              icon={<img src={UpArrowIcon} />}
            >Replace model</Button>
            <div className="validated-model-label">
              <img src={AcceptedIcon} alt="Validated" />
              <span>Model Validated</span>
            </div>
            <ModelValidationErrors errors={modelValidationErrors} deleteModel={handleDeleteModel} />
            <div className="mode-upload-container" style={{ visibility: 'hidden', height: 0 }}>
              <ModelUploader
                modelType='glb'
                modelName='Model'
                uploadProps={glbModelPropsHidden}
              />
            </div>
            {/*<button onClick={handleTakeScreenshot}>Take Screenshot</button>*/}
          </div>
        )}
      </Col>
    </>
  );
}
