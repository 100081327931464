import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

import Logo from '../../assets/pro-logo.svg';

import './style.less';
import { CONTACT_US } from '../../constants/links';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';

const ProjectLimitModal = ({ open, onCancel }: {
  open: boolean,
  onCancel: () => void
}) => {
  const navigate = useNavigate();

  const goToStripeCustomerPanel = () => {
    mixPanelUtils.track(MixpanelEvent.CHANGE_PLAN_ACTION);
    window.open('https://billing.stripe.com/p/login/8wM9CS3xY2nl9by4gg', '_blank', 'noreferrer')
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={436}
    >
      <div className="project-limit-modal">
        <div className="logo">
          <img src={Logo} />
        </div>
        <h1 className="title">
          You have reached your project limit
        </h1>
        <div className="description">To make new projects, upgrade your subscription</div>
        <Button
          className="upgrade-button"
          type="primary"
          onClick={goToStripeCustomerPanel}
        >
          Upgrade subscription
        </Button>
        <div>Questions? <Button type='link' className="link-button" onClick={()=> window.open(CONTACT_US, '_blank')}>Contact us</Button></div>
      </div>
    </Modal>
  );
}

export default ProjectLimitModal;
