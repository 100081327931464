import { getFileNameWithExtension, getFileURL} from '../../../utils/util';
import {buildDocumentQuery} from '../query/document';

export async function  buildDocuments(documents: any): Promise<any> {

  const documentsData = documents?.map((e: any, index: number) => {
    const item: any =  {
      name: e.title,
      url: '',
      file: e.file,
    }

    if (e.id) {
      const arrFileParts = e.file?.url?.split('/') ?? []
      let fileUrl = ''
      if(arrFileParts.length > 0) {
        fileUrl = arrFileParts[arrFileParts.length - 1]
        const arr = fileUrl.split('?')
        fileUrl = arr.length > 0 ? arr[0] : e.file?.url
      }

      item.id = e.id
      item.url = fileUrl
      item.file = e.file
    }
    return item
  })

  const documentsQuery = buildDocumentQuery(documentsData)

  return documentsQuery
}

export async function loadDocuments(projectDetailsInformation: any): Promise<any> {
  const documents: Array<any> = []
  for (const document of projectDetailsInformation.documents) {
    let fileURL: any = {}
    let filename = ''

    if (document.url && document.url.length > 0) {
      fileURL = await getFileURL(projectDetailsInformation.id, document)
      filename = getFileNameWithExtension(document)
    }
    const doc: any = {
      id: document.id,
      title: document.name,
      category: document.category,
      file: {
        url: `${fileURL?.url ?? ''}`,
        status: `${fileURL?.url ? 'done' : ''}` ,
        name: `${filename}`,
      },
    }
    documents.push(doc)
  }
  return documents
}

