import { authProvider } from '../authPrvider/authProvider';
import { gql, GraphQLClient } from 'graphql-request';
import performLoginSilently from '../hooks/auth0/useLoginSilently';

export async function modelUploaderToAWS(
  model: any,
  projectId: string,
  buildingsId: string,
): Promise<any> {
  try {
    const formData = new FormData();
    formData.append('files', model.file);
    // eslint-disable-next-line max-len
    const res = await fetch(`${process.env.REACT_APP_API_URL}/aws/multipart-models-upload/${projectId}/${buildingsId}`, {
      method: 'POST',
      body: formData,
    });

    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function modelUploaderToAWSTemporary(
  model: any,
): Promise<any> {
  try {
    const userId = authProvider.getInCituUser().id
    if(!userId) {
      throw new Error('User does not exists');
    }
    const formData = new FormData();
    formData.append('files', model.file);
    // eslint-disable-next-line max-len
    const response = await fetch(`${process.env.REACT_APP_API_URL}/aws/multipart-tmp-models-upload/${userId}`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    if (response.ok) {
      if(data.result?.length > 0) {
        return data.result[0]
      } else {
        throw new Error('Upload failed');
      }
    } else {
      throw new Error(data.message || 'Upload failed');
    }

    // return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function convertModel(
  projectData: any,
): Promise<any> {

  try {
    const data: any = {
      'id': projectData.id,
      'name': projectData.name,
      'status': projectData.status,
      'description': null,
      'address': null,
      'longitude': projectData.longitude,
      'latitude': projectData.latitude,
      'altitude':projectData.altitude,
      'geoidHeight': '0.00', // TODO: find to retrieve
      'city': null,
      'organization': null,
      'buildings': projectData.buildings,
    }

    console.log('CONVERT DATA:', data)
    console.log('CONVERT DATA EXPANDED:')
    console.dir(data)
    // eslint-disable-next-line max-len
    const res = await fetch('https://tnxaci2jvt.us-east-2.awsapprunner.com/convert', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    console.log('CONVERT RES:', res)
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function copyModelsToPermanentFolder(
  projectId: string,
  buildingId: string,
  filename: string,
): Promise<any> {
  try {
    const userId = authProvider.getInCituUser().id
    if(!userId) {
      throw new Error('User does not exists');
    }

    console.log('___filename:', filename)
    // eslint-disable-next-line max-len
    const response = await fetch(`${process.env.REACT_APP_API_URL}/aws/copy-models-to-permanent-folder/${userId}/${projectId}/${buildingId}/${filename}/`, {
      method: 'GET',
    });

    console.log('Files copied successfully: ', response)
    // const data = await response.json();
    // if (response.ok) {
    //   return data.result[0];
    // } else {
    //   throw new Error(data.message || 'Files copied failed');
    // }

    // return res;
    return response
  } catch (error) {
    console.log(error);
    return error;
  }
}

const endpoint = `${process.env.REACT_APP_API_URL}/graphql`;
export async function executeConvertModel(userId: string, modelLocation: string, getAccessTokenSilently?: () =>
  void, logout?: (options?: any) => void) {
  // const token = authProvider.getToken();
  const token = await performLoginSilently(getAccessTokenSilently, logout);

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const mutation = gql`
    mutation convertModel($userId: String!, $modelLocation: String!) {
      convertModel(userId: $userId, modelLocation: $modelLocation) {
        outputModel
      }
    }
  `;

  const variables = {
    userId: userId,
    modelLocation: modelLocation,
  };

  try {
    const result = await graphQLClient.request(mutation, variables);
    console.log('Success mutationFunction result:', result);
    return result; // ?.convertModel?.outputModel;
  } catch (error) {
    console.error('Error executing mutation:', error);
    throw error;
  }
}
