import React, {useEffect, useState} from 'react';
import { Button, Col, Row } from '@pankod/refine-antd';
import ProjectHeader from '../../pages/public-view/map/Header';
import ProjectsIcon from '../../assets/projects.svg';
import AccountIcon from '../../assets/account.svg';
import ProjectsIconColored from '../../assets/projects-colored.svg';
import AccountIconColored from '../../assets/account-colored.svg';
import { useNavigation } from '@pankod/refine-core';
import './style.less';
import DashboardHeader from '../DashboardHeader';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { CONTACT_US } from '../../constants/links';

const SideBarLayoutNew=({children}:any)=>{
  const { push } = useNavigation();
  const [open, setOpen] =useState(false)
  const [activeTabPath, setActiveTabPath] = useState('projects')
    
  const handleNavigation = (path: string) => {
    push(path);
    setActiveTabPath(path);
  };

  useEffect(() => {
    setActiveTabPath(window.location.pathname);
  }, [])
    
  const getIcon = (path: string) => {
    switch (path) {
      case '/projects':
        return activeTabPath === '/projects' ? ProjectsIconColored : ProjectsIcon;
      case '/account':
        return activeTabPath === '/account' ? AccountIconColored : AccountIcon;
      default:
        return ProjectsIcon // Default case
    }
  };
  
  const getButtonStyle = (path: string) => ({
    // TODO: change icon color also
    color: activeTabPath === path ? '#F98168' : '#000000',
  });

  return (
    <Row justify="center" style={{ backgroundColor: '#FAFAFA' }}>
      <Col className="header-column" xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
        {/*<ProjectHeader />*/}
        <DashboardHeader />
      </Col>
      <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24} className="new_sidebar_layout">
        <div className="project-details-container">
          <div className="user-actions-sidebar">
            <div className="actions-container">
              <Button
                className="action-button"
                icon={<img src={getIcon('/projects')} alt='projects' />}
                type="text"
                onClick={() => handleNavigation('/projects')}
                style={getButtonStyle('/projects')}
              >
                <b>My Projects</b>
              </Button>
              <Button
                className="action-button"
                icon={<img src={getIcon('/account')} alt='account' />}
                type="text"
                onClick={() => handleNavigation('/account')}
                style={getButtonStyle('/account')}
              >
                <b>Account</b>
              </Button>
              <div style={{
                display: 'flex',
                cursor: 'pointer',
                marginTop: 'auto',
                marginLeft: '25px',
                // padding: '20px 0 40px 26px',
              }}
              onClick={() => setOpen(!open)}>
                <QuestionCircleFilled style={{ fontSize: '20px', color: 'gray', marginLeft: -10 }} />
                <Typography.Paragraph
                  style={{ paddingTop: 12, paddingLeft: 5, fontSize: 12, fontWeight: 600, color: '#727272' }}>
              Help
                </Typography.Paragraph>
              </div>
              {open ?
                <div style={{
                  width: 120,
                  height: 67,
                  backgroundColor: 'gray',
                  borderRadius: 5,
                  paddingLeft: 10,
                  position: 'absolute',
                  bottom: 70,
                  margin:'auto'}}>
                  <div style={{fontSize: 12, fontWeight: 600, paddingTop: 10, cursor: 'pointer' }}>
                    <a style={{textDecoration: 'none', color: 'white'}} target="_blank" href={CONTACT_US}>
                  Contact support
                    </a>
                  </div>
                  <div style={{fontSize: 12, fontWeight: 600,  color: 'white' ,paddingTop: 10, cursor: 'pointer'}}>
                    <a style={{textDecoration: 'none',color: 'white'}} target="_blank" href="https://docs.google.com/document/d/1lrWH9YrytS5FCMTKT6_GS8FuoJpMT9HufOI1fOlr9jQ/edit#heading=h.32pp5ar0ay7x">
                  Documentation
                    </a>
                  </div>
                </div> : null}
            </div>
          </div>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default SideBarLayoutNew;
