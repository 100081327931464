import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Layout, Select, Spin, Typography } from 'antd';
import {SelectOption} from '../../interfaces/options'
import {useRegistrationProgress} from '../../hooks/useRegistrationProgressBar'
import {useUpdateUser} from '../../api/user'
import {authProvider} from '../../authPrvider/authProvider'
import {useNavigation} from '@pankod/refine-core'
import ErrorNew from '../../pages/registration/ErrorNew'
import { delay } from '../../utils/util';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import './style.less';
import { useAuth0 } from '@auth0/auth0-react';
import { updateOrganizationName } from '../../api/organisation';
import { LoadingOutlined } from '@ant-design/icons';
import performLogout from '../../hooks/auth0/useLogout';
import {
  isRequiresExampleProject,
  isUserHasActiveSubscription,
  isUserNeverHadSubscription,
} from '../../utils/subscription';
import useCreateExampleProject from '../../hooks/useCreateExampleProject';

const { Option } = Select

const userDescriptions: Array<SelectOption> = [
  { value: 'real-estate-developer', label: 'Real-Estate Developer' },
  { value: 'elected-official', label: 'Elected Official' },
  { value: 'architect-urban-designer', label: 'Architect/Urban Designer' },
  { value: 'community-organizer', label: 'Community Organizer' },
  { value: 'tech-professional', label: 'Tech Professional' },
  { value: 'resident', label: 'Resident' },
  { value: 'other', label: 'Other' },
]

interface FormValues {
  fullname: string
  description: string
  teamname: string
}

interface TellUsProps {
    mainTitle: string,
  secondTitle?: string
    username?: string
}

const ProfileDetailsForm: React.FC<TellUsProps> = ({
  mainTitle,
  secondTitle,
  username,
}) => {
  const [user, setUser] = useState(() => authProvider.getInCituUser());
  const {setProgress} = useRegistrationProgress()
  const { push } = useNavigation()
  const [fetchUserInfo] = useUserInfoFetch();
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [refetchCounter, setRefetchCounter] = useState(0)
  const [showError, setShowError] = useState(false)
  const [isExampleProjectLoadingStarted, setExampleProjectLoadingStarted] = useState(false)

  const [createExampleProject] = useCreateExampleProject();

  const {
    user: userAuth0,
    logout,
  } = useAuth0();

  const refetchUserCounter = 2

  useEffect(() => {

    const checkUser = async () => {
      let incituUser: any = authProvider.getInCituUser()
      if(incituUser && incituUser.externalId) {
        const fetchedUser: any = await fetchUserInfo(incituUser.externalId);
        setUser(fetchedUser.data)
      } else if (userAuth0 && userAuth0.sub) {
        const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
        if (fetchedUser) {
          authProvider.saveInCituUser(fetchedUser.data)
          setUser(fetchedUser.data)
        }
      } else {
        console.log('incituUser or userAuth0 is undefined, userAuth0:', userAuth0);
        console.log('incituUser or userAuth0 is undefined, incituUser:', incituUser);
      }

      incituUser = authProvider.getInCituUser()
      console.log('Welcome__incituUser:', incituUser)
      if (incituUser && incituUser?.externalId && refetchCounter < refetchUserCounter) {
        if(isRequiresExampleProject(incituUser) && !isExampleProjectLoadingStarted) {
          setExampleProjectLoadingStarted(true)
          await createExampleProject()
          await delay(5000)
        }
        setIsLoaded(true)
      } else if (refetchCounter >= refetchUserCounter) {
        console.log('Error on fetchingUser')
        setShowError(true)
      } else {
        await delay(5000)
        setRefetchCounter(prevCount => prevCount + refetchUserCounter)
      }
    }

    if(!isLoaded && refetchCounter <= 2) {
      setTimeout(checkUser, 5000)
    }
  }, [user, refetchCounter])

  useLayoutEffect(() => {
    setProgress(33)
  })
    
  const {
    updateUser,
    data,
  } = useUpdateUser()

  useEffect(() => {
    if (data) {
      console.log('Updated user details:', data)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      authProvider.saveInCituUser(data.updateUser)

      if(isUserNeverHadSubscription(authProvider.getInCituUser())) {
        push('/map')
      } else {
        push('/projects?upgraded=true')
      }
    }
  }, [data])

  const [form] = Form.useForm()
  const subTitle: JSX.Element | undefined = secondTitle
    ? <div className="div-second-title">{secondTitle}</div>
    : undefined

  const ErrorView = () => {
    return (
      <div className="div-error-new">
        <ErrorNew
          mainTitle={'Unrecognized user - Must log in first'}
          // backwardsRedirectionPath={'/login'}
          backwardsRedirectionPath={'/landing'}
        />
      </div>
    )
  }

  const handleSubmit = async (values: FormValues) => {
    console.log('Welcome__handleSubmit:', values)
    const fullName: Array<string> = values.fullname.split(' ')
    const payload = {
      firstName: {set: fullName.length > 0 ? fullName[0] ?? '' : '' },
      lastName: {set: fullName.length > 1 ? fullName[1] ?? '' : '' },
      description: {set: values.description ?? ''},
    }
    console.log('Welcome__handleSubmit__user:', user)
    if (user !== undefined) {
      await updateUser(user.externalId, payload)

      const incituUser = authProvider.getInCituUser()
      const organisationName = values.teamname ?? ''

      // if(isRequiresExampleProject(incituUser)) {
      //   await createExampleProject()
      //   // await delay(5000)
      // }
      if(organisationName.length > 0 && incituUser.organizationUsers && incituUser.organizationUsers.length > 0) {
        const organizationId = incituUser.organizationUsers[0].organizationId
        await updateOrganizationName(organizationId, organisationName)
      }
    }
    else {
      return <ErrorView />
    }
  }

  const generateSelectOptions = (options: Array<SelectOption>) => {
    return options.map(option => (
      <Option 
        key={option.value}
        value={option.value}
        style={{
          color: '#727272',
          fontFamily: 'Work Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '16px', /* 114.286% */
        }}
      >{option.label}</Option>
    ))
  }

  if(showError) {
    return <ErrorView />
  }

  return (
    <>
      {isLoaded ? (
        <Layout className="layout-style">
          <div className="div-main-title">
            <div className="main-title">{mainTitle}</div>
          </div>
          {subTitle}
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
              label={<div className="full-name">Full name</div>}
              name="fullname"
              rules={[
                { required: true, message: 'Please input your full name!' },
              ]}
            >
              <Input
                placeholder="First & last"
                value={username}
                className="select-input-style"
              />
            </Form.Item>
            {(user?.role === 'CLIENT' || user?.role === 'ADMIN') && (
              <>
                <Form.Item
                  label={<div className="full-name">Team name</div>}
                  name="teamname"
                >
                  <Input
                    placeholder="Company name, or team name"
                    value={username}
                    className="select-input-style"
                  />
                </Form.Item>
              </>
            )}
                <Form.Item
                  label={
                    <div className="full-name">What best describes you</div>
                  }
                  name="description"
                  rules={[
                    { required: true, message: 'Please select an option!' },
                  ]}
                >
                  <Select
                    placeholder="Select..."
                    bordered={false}
                    allowClear={true}
                    className="select-input-style"
                  >
                    {generateSelectOptions(userDescriptions)}
                  </Select>
                </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="button-style">
                <div className="continue-text-style">Continue</div>
              </Button>
            </Form.Item>
          </Form>

          <button
            className="logout-button"
            onClick={async () => {
              await performLogout(logout);
            }}
          >
            Sign out
          </button>
        </Layout>
      ) : (
        <Layout className="acc-loading-layout-style">
          <div className="acc-loading-layout-block">
            <div
              className="acc-loading-logo-container"
              style={{ width: '100%', height: '80px', position: 'relative' }}
            >
              <Spin
                style={{
                  position: 'absolute',
                  color: '#F98168',
                  opacity: 0.7,
                  fontSize: 10,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                indicator={
                  <LoadingOutlined style={{ fontSize: 75 }} spin={true} />
                }
              />
            </div>
            <Layout className="acc-loading-layout-body-style">
              <div className="div-main-title">
                <div className="acc-loading-main-title">
                  Loading your account
                </div>
              </div>
            </Layout>
          </div>
        </Layout>
      )}{' '}
    </>
  );
}

export default ProfileDetailsForm
