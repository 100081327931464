import React, { useEffect, useMemo, useState } from 'react';
import {authProvider} from '../../authPrvider/authProvider'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigation } from '@pankod/refine-core';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import { delay } from '../../utils/util';
import logo_Black from '../../assets/Logo_Black.png'
import performLogout from '../../hooks/auth0/useLogout';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import verifyingLogo from '../../assets/verifying-logo.svg';
import { fetchUsersProjects } from '../../api/project';

export const AccountLoading = () => {
  const { push } = useNavigation()
  //const [isLoadingUser, setIsLoadingUser] = useState(false)
  // const [isLoadedAuth0UserData, setIsLoadedAuth0UserData] = useState(false)

  const [fetchUserInfo] = useUserInfoFetch();

  const {
    user: userAuth0,
    isAuthenticated,
    logout,
  } = useAuth0();

  const redirectUser = async () => {
    let incituUser  = authProvider.getInCituUser()
    if(incituUser) {
      const fetchedUser: any = await fetchUserInfo(incituUser.externalId);
      authProvider.saveInCituUser(fetchedUser.data);
    }
    else if (userAuth0 && userAuth0.sub) {
      const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
      authProvider.saveInCituUser(fetchedUser.data);
    } else {
      await performLogout(logout)
      return
    }

    incituUser  = authProvider.getInCituUser()
    console.log('incituUser:', incituUser);

    const projects: any = await fetchUsersProjects(incituUser)
    if (projects && projects.data.length > 0) {
      push('/projects?upgraded=true')
    } else {
      // if(isRequiresExampleProject(incituUser)) {
      //   await createExampleProject()
      // }
      push('/account-details')
    }

    // const entryPoint = incituUser?.metadata?.entryPoint
    // if(entryPoint) {
    //   if (entryPoint?.includes("landing")) {
    //     const projects: any = await fetchUsersProjects(incituUser)
    //     if (projects.length > 1) {
    //       push('/projects')
    //     } else {
    //       push('/account-details')
    //     }
    //   } else {
    //     console.log("The string contains 'map'.");
    //     push('/map')
    //   }
    // }  else { // TODO: we need to store map or landing in metadata
    //   console.log("The string contains 'map'.");
    //   push('/map')
    // }

  }

  useEffect(() => {
    const checkUserRedirection = async () => {
      await delay(5000);
      await redirectUser()
    };

    checkUserRedirection();
  }, [userAuth0]);

  const RedirectionForm = ({ mainTitle, subTitle }: any) => {
    return (
      <Layout className="acc-loading-layout-style">
        <div className="acc-loading-layout-block">
          <div className="acc-loading-logo-container" style={{ width: '100%', height: '80px', position: 'relative' }}>
            <Spin style={{
              position: 'absolute',
              color: '#F98168',
              opacity: 0.7,
              fontSize: 10,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            indicator={<LoadingOutlined style={{ fontSize: 75 }} spin={true} />}
            />
          </div>
          <Layout className="acc-loading-layout-body-style">
            <div className="div-main-title">
              <div className="acc-loading-main-title">{mainTitle}</div>
            </div>
          </Layout>
        </div>
      </Layout>
    );
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#F0F0F0',
      flexShrink: 0,
    }}>
      <div>
        <RedirectionForm
          mainTitle="Loading your account"
          subTitle=''
        />
      </div>
    </div>
  );
}
