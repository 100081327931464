import React, { useEffect, useRef } from 'react';
import ProfileDetailsForm from '../../components/Registration/ProfileDetailsForm'

export const ProfileDetails = (): JSX.Element => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#F0F0F0',
      flexShrink: 0,
    }}>
      <div>
        <ProfileDetailsForm
          mainTitle='Account Details'
        />
      </div>
    </div>
  )
}
