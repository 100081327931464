import { Project } from '../dataProvider/gql/types';
import dataProvider from '../dataProvider/dataProvider';

export default function useProjectInfoFetch() {

  const fetchProjectInfo = async ({id}: any) => {
    if (!id) return undefined;

    const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
    try {
      const {
        data: projectInfo,
      }: any = await dProvider.getOne<Project>({
        resource: 'project',
        id,
        metaData: {
          fields: [
            'id',
            'name',
            'subtitle',
            'origin',
            'description',
            'status',
            'createdAt',
            'address',
            'longitude',
            'latitude',
            'altitude',
            'organizationId',
            // 'statusDetail',
            'borough',
            'ceqr',
            'bbl',
            'city{displayName}',
            // 'attributes',
            'appClipSync',
            'autoGenerateModels',
            'arcgisFeatureLayerId', 'arcgisSceneLayerId',
            'buildings{id buildingHeight '
            + 'defaultAnchoring{id rotation anchorCoordinate{id latitude longitude altitude}} '
            + 'model3ds{id type fileLocation externalId order fileSizeMb '
            + 'anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
            'organization{id organizationUsers{user{id email  firstName lastName}}}',
            'documents{id url name type category}',
            'timelineEvents{id name timelineEventType{id name}}',
            'projectViewPreference{id visibility isSnapToGround}',
          ],
        },
      });

      return projectInfo
    } catch (error) {
      console.error('Error fetching project details:', error);
      return undefined;
    }
  }

  return [fetchProjectInfo];
}
