import React, { useEffect, useRef, useState } from 'react';
import {useRegistrationProgress} from '../../hooks/useRegistrationProgressBar'
import {Button, Spin, Typography} from 'antd'

import {useNavigation} from '@pankod/refine-core'
import allSetImage from '../../assets/all-set-image.jpg'
import {authProvider} from '../../authPrvider/authProvider'
import ErrorNew from './ErrorNew'
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import checkMobile from '../../utils/checkMobile';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';

export const AllSet = () => {
  const { setProgress } = useRegistrationProgress()
  const { push } = useNavigation()
  const [fetchUserInfo] = useUserInfoFetch();
  const [canRender, setCanRender] = useState(false)
  const [showError, setShowError] = useState(false)
  const [subscriptionCheckCounter, setSubscriptionCheckCounter] = useState(0)

  const [user, setUser] = useState(() => authProvider.getInCituUser());

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.ALL_SET);
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {
    const {isMobile} = checkMobile()
    if (isMobile) {
      push('/coming-soon');
    }
  }, [user]);

  useEffect(() => {
    const checkSubscription = async () => {
      // TODO: Fix subscriptionPlan condition - maybe check its type
      let incituUser  = authProvider.getInCituUser()
      const fetchedUser: any = await fetchUserInfo(incituUser.externalId);
      console.log('AllSet__fetchedUser:', fetchedUser)
      if (fetchedUser) {
        authProvider.saveInCituUser(fetchedUser.data)
        setUser(fetchedUser.data)
      }

      incituUser  = authProvider.getInCituUser()
      if (incituUser && incituUser?.subscriptionPlan && subscriptionCheckCounter < 2) {
        setCanRender(true)
      } else if (subscriptionCheckCounter >= 2) {
        setShowError(true)
      } else {
        setTimeout(() => {
          setSubscriptionCheckCounter(prevCount => prevCount + 1)
        }, 5000) 
      }
    }

    if(!canRender && subscriptionCheckCounter <= 2) {
      setTimeout(checkSubscription, 5000)
    }
  }, [user, subscriptionCheckCounter])

  useEffect(() => {
    if (canRender) {
      setProgress(100)
    }
  }, [canRender, setProgress])

  if (!canRender) {
    if (showError) {
      return (
        <div className="main-div-error-new">
          <ErrorNew
            mainTitle={'We had trouble approving your subscription'}
            backwardsRedirectionPath={'/pricing'}
          />
        </div>
      )
    } else {
      return (
        <div className="div-spin-style">
          <Spin size='large'/>
        </div>
      )
    }
  }
    
  const continueToDashboard = () => {
    push('/projects')
  }

  return (
    <div className="main-div-all-set">
      <div className="main-div-all-set-container">
        <div className="title-all-set">You are all set!</div>
        <div className="sub-title-all-set">Your free 7-day trial starts now.</div>
        <div className="sub-title-all-set">
          Enjoy InCitu Pro!
          {/*Enjoy everything our {user?.subscriptionPlan?.type} membership has to offer.*/}
        </div>
        <img src={allSetImage} alt='All Set' className="img-all-set" />
        <Button type='primary' onClick={continueToDashboard} className="button-all-set-continue" >
        continue
        </Button>
      </div>
    </div>
  )
}
