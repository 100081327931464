import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useDelete, useNavigation } from '@pankod/refine-core';
import { observer } from 'mobx-react-lite';

import ProjectListingsNew from '../../components/ProjectListingsNew';
import { PlusOutlined, SearchOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Project as ProjectInstance } from '../../dataProvider/gql/types';
import ProjectGridListings from '../../components/ProjectGridListings';
import { authProvider } from '../../authPrvider/authProvider';
import ListView from '../../assets/List_view.svg';
import ListView1 from '../../assets/List_view_1.svg';
import SideBarLayoutNew from '../../components/SideBarLayoutNew';
import ProjectCreationStepComposer from '../project-creation-step-composer';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import CloseModalPopup from '../../pages/project-creation-step-composer/components/CloseModalPopup';
import UnstableConnectionPopup from '../../pages/project-creation-step-composer/components/UnstableConnectionPopup';
import { Context } from '../../index';
import useAuth0Authorization from '../../hooks/auth0/useAuth0Authorization';
import performLogout from '../../hooks/auth0/useLogout';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import ProjectLimitModal from '../../components/ProjectLimitModal/ProjectLimitModal';
import { isUserHasActiveSubscription, isSubscriptionProjectLimitsAchieved } from '../../utils/subscription';
import UpgradePopup from '../../components/UpgradePopup/UpgradePopup';
import UpgradedPlanModal from '../../components/UpgradedPlanModal/UpgradedPlanModal';

import './style.less';
import { useSearchParams } from 'react-router-dom';
import { fetchUsersProjects } from '../../api/project';
import NoActiveSubscriptionPopup from '../../components/NoActiveSubscriptionPopup/NoActiveSubscriptionPopup';
import PopupARCGIS from 'pages/project-creation-step-composer/components/PopupARCGIS';
import useCreateExampleProject from '../../hooks/useCreateExampleProject';
import LimitModalUploadPopup from '../project-creation-step-composer/components/LimitModalUploadPopup';

import { loadModules } from 'esri-loader';
import CloseFinishModalPopup from 'pages/project-creation-step-composer/components/CloseFinishModalPopup';
import {
  isArcGISAvailable,
  isArcGISImportProjectsAvailable,
  isArcGISImportProjectsAvailableAndAuthorised,
  signInToArcGIS,
} from '../../utils/arcgis';
import ArcGISImportingModal from '../../components/ArcGISImportingPopup/ArcGISImportingModal';
import HintPopover from 'pages/registration/HintPopover/HintPopover';
import { fetchUserAndSaveToLocalStorage } from '../../utils/user';
import { handleToken } from "../../utils/tokenHandler";

function ProjectComponent() {
  const [createExampleProject] = useCreateExampleProject();
  const { projectCreationStore } = useContext(Context);
  const [fetchUserInfo] = useUserInfoFetch();
  const [userInfo, setUserInfo] = useState<any>();
  const [showSubscriptionWarning, setShowSubscriptionWarning] = useState(false);
  const [searchParams] = useSearchParams();
  const isUpgraded = searchParams.get('upgraded');
  const [isUpgradePopupVisible, setIsUpgradePopupVisible] = useState(false);
  const [isUpgradedModalVisible, setIsUpgradedModalVisible] = useState(false);
  const [isNoActiveSubscriptionModalVisible, setIsNoActiveSubscriptionModalVisible] = useState(false);
  const [isArGISImportProjectsModalVisible, setIsArGISImportProjectsModalVisible] = useState(false);
  const [arGISImportStatus, setArGISImportStatus] = useState('none');

  const [openPopupArcGIS, setOpenPopupArcGISArcGIS] = useState(false);

  const {
    clearProjectData,
    isCreateModalVisible,
    setIsCreateModalVisible,
    userName,
    projectId: projectStoreId,
    setProjectId: setProjectIdToStore,
    setEditMode,
    isShowLimitModelUploadPopup,
    setIsShowLimitModelUploadPopup,
  } = projectCreationStore;

  const {
    user: auth0User,
    getAccessTokenSilently
  } = useAuth0();

  const [gridLayout, setGridLayout]: any = useState(true);
  const { mutate, status: deleteStatus } = useDelete();
  const { push } = useNavigation();

  // TODO: force refresh token
  // useEffect(() => {
  //   handleToken(getAccessTokenSilently);
  // }, []);


  useEffect(() => {
    const checkUserSubsription = async () => {
      await fetchUserAndSaveToLocalStorage(auth0User, fetchUserInfo)
      const userIncitu = authProvider.getInCituUser()
      if (userIncitu && userIncitu.externalId) {
        const userIncitu = authProvider.getInCituUser()
        const isHasActiveSubscription = isUserHasActiveSubscription(userIncitu);
        if (!isHasActiveSubscription) {
          setIsNoActiveSubscriptionModalVisible(true)
        }
      }
    }
    checkUserSubsription().then(r => {})
  }, []);

  useEffect(() => {
    const userIncitu = authProvider.getInCituUser()
    if (userIncitu) {
      fetchUserInfo(userIncitu.externalId).then(async (data: any) => {
        const userData: any = data.data

        authProvider.saveInCituUser(userData);
        setUserInfo(userData);
        const projects: any = await fetchUsersProjects(userData)
        if (isSubscriptionProjectLimitsAchieved(userData, projects)) {
          setIsUpgradePopupVisible(true);
        }
      });
    }
  }, [isUpgraded]);

  const { tableProps, tableQueryResult, searchFormProps } =
    useTable<ProjectInstance>({
      hasPagination: true,
      liveMode: 'auto',
      syncWithLocation: true,
      onSearch: (values: any) => {
        return [
          {
            field: 'name',
            operator: 'contains',
            value: values.name,
          },
        ];
      },
      metaData: {
        fields: [
          'id',
          'name',
          'subtitle',
          'origin',
          'longitude',
          'latitude',
          'address',
          'timelineEvents{id updatedAt}',
          'buildings{id model3ds{id type fileLocation}}',
          'organization{id name notes website}',
        ],
      },
    });
  const [componentKey, setComponentKey] = useState(0);
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [showConnectionWarning, setShowConnectionWarning] = useState(false);
  const [showPublishedRecommendations, setShowPublishedRecommendations] = useState(false);
  const [isExampleButtonDisabled, setIsExampleButtonDisabled] = useState(false);

  const prevSearchFormProps = useRef(undefined);
  const prevGridLayoutProps = useRef(undefined);
  const { performAuthorization } = useAuth0Authorization();

  const {
    logout,
    user,
  } = useAuth0();

  const openProjectWizard = (): void => {
    // TODO: get the number of projects on line 80
    const checkSubscription = async () => {
      await fetchUserAndSaveToLocalStorage(auth0User, fetchUserInfo)

      const inCituUser = authProvider.getInCituUser()
      const projects: any = await fetchUsersProjects(inCituUser)
      if (!isUserHasActiveSubscription(inCituUser)) {
        setIsNoActiveSubscriptionModalVisible(true)
      }
      else if (isSubscriptionProjectLimitsAchieved(inCituUser, projects)) {
        setShowSubscriptionWarning(true);
        setIsUpgradePopupVisible(true);
      } else {
        setProjectIdToStore('');
        setEditMode(false);
        clearProjectData();
        setIsCreateModalVisible(true);
      }
    }

    checkSubscription().then(r => {})
  };

  const performSetProjectId = (id: string) => {
    // setProjectId(id);
    setProjectIdToStore(id);
    setEditMode(true);
  };

  const checkUserAccess = () => {
    const incituUser = authProvider.getInCituUser();
    if ((incituUser?.role !== 'ADMIN' && incituUser?.role !== 'CLIENT') && incituUser?.organizationUsers?.length === 0) {
      performLogout(logout)
      return
    }
    if (!authProvider.getToken() || !incituUser || (incituUser?.subscriptionPlan == null && incituUser?.role !== 'ADMIN')) {
      performLogout(logout)
    }
  }

  useEffect(() => {
    const checkSubscription = async () => {

      await fetchUserAndSaveToLocalStorage(auth0User, fetchUserInfo)
      const incituUser = authProvider.getInCituUser();

      const fetchedUser = await fetchUserInfo(incituUser.externalId);
      if (fetchedUser) {
        const incituUser = fetchedUser.data
        authProvider.saveInCituUser(incituUser);
        checkUserAccess();
      } else {
        console.log('Pages CheckSubscription User not found');
        // Perform logout if user is not fetched successfully
        // performLogout(logout);
      }
    };

    checkSubscription().then(r => {});
  }, []);

  useEffect(() => {
    if(!authProvider.getToken()) {
      // window.location.href = '/login';
      // window.location.href = '/landing';
      performLogout(logout)
    }
  }, []);

  useEffect(() => {
    if (searchFormProps) {
      // @ts-ignore
      const searchValue = searchFormProps.form.getFieldsValue().name;
      if (searchValue !== prevSearchFormProps.current) {
        mixPanelUtils.track(MixpanelEvent.PROJECT_SEARCH, {
          term: searchValue,
        });
        prevSearchFormProps.current = searchValue;
      }
    }
  }, [searchFormProps]);

  const deleteProject = async (id: any) => {
    console.log('delete id:', id);
    try {
      await mutate({
        resource: 'projects',
        id: id,
        metaData: {
          fields: ['id', 'name'],
        },
      });
      mixPanelUtils.track(MixpanelEvent.PROJECT_DELETED, { projectId: id });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    message.config({
      top: 8,
    });
    if (deleteStatus == 'success') {
      message.success('Project deleted successfully!');

      // tableQueryResult.refetch();
    } else if (deleteStatus == 'error') {
      message.error('Error while deleting project!');
    }
  }, [deleteStatus]);

  useEffect(() => {
    if(!authProvider.getToken()) {
      // window.location.href = '/login';
      window.location.href = '/landing';
    }
  }, []);

  const performSetGridView = (isGridLayout: boolean) => {
    setGridLayout(isGridLayout);
  };

  useEffect(() => {
    if (gridLayout !== prevGridLayoutProps.current) {
      mixPanelUtils.track(
        gridLayout
          ? MixpanelEvent.PROJECT_GRIDVIEW
          : MixpanelEvent.PROJECT_LISTVIEW,
      );
      prevGridLayoutProps.current = gridLayout;
    }
  }, [gridLayout]);

  useEffect(() => {
    const checkSubscription = async () => {
      await fetchUserAndSaveToLocalStorage(auth0User, fetchUserInfo)
      if(isUpgraded) {
        setIsUpgradedModalVisible(true)
      }
    };

    checkSubscription().then(r => {});
  }, [isUpgraded]);

  const checkSubscriptionForLimits = async () => {
    await fetchUserAndSaveToLocalStorage(auth0User, fetchUserInfo)

    const inCituUser = authProvider.getInCituUser()
    const projects: any = await fetchUsersProjects(inCituUser)
    if (isSubscriptionProjectLimitsAchieved(inCituUser, projects)) {
      setIsUpgradePopupVisible(true);
    }
  }

  const closeModalPopup = (isRefetchData = false) => {
    setIsCreateModalVisible(false);
    setShowCloseWarning(false);
    setShowPublishedRecommendations(false);
    clearProjectData();
    if(isRefetchData) {
      refetchData();
      checkSubscriptionForLimits().then(r => {})
    }
  };

  const refetchData = () => {
    setComponentKey(prevKey => prevKey + 1);
  }

  const handleCloseUpgradePopup = () => {
    setIsUpgradePopupVisible(false);
  }

  const handleCloseUpgradedModal = () => {
    setIsUpgradedModalVisible(false);
  }

  const handleNoActiveSubscriptionModal = () => {
    setIsNoActiveSubscriptionModalVisible(false);
  }

  const handleArGISImportProjectsModal = () => {
    setIsArGISImportProjectsModalVisible(false);
  }

  const handleCloseSubscriptionWarning = () => {
    setShowSubscriptionWarning(false);
  }

  const handleOpenARProject = async () => {
    await signInToArcGIS();

    if(!authProvider.getArcGISToken()) {
      message.error('User is not authorised in ArcGIS system.');
      return;
    }
    setOpenPopupArcGISArcGIS(true)
  }

  const handleSetOpenPopupArcGIS = () => {
    setOpenPopupArcGISArcGIS(!openPopupArcGIS);
  }

  const handleDisplayProgressPopup= (status: string) => {
    setArGISImportStatus(status);
    setIsArGISImportProjectsModalVisible(true);
  }

  const goToStripeCustomerPanel = () => {
    mixPanelUtils.track(MixpanelEvent.CHANGE_PLAN_ACTION);
    window.open('https://billing.stripe.com/p/login/8wM9CS3xY2nl9by4gg', '_blank', 'noreferrer')
  }

  const popoverContent = useMemo(() => {
    return (
      <div>
        <div className='popup-popover-description'>
          <h1 className='arcgis-popup-popover-title'>Connect to ArcGIS in your Account page</h1>
        </div>
      </div>
    )
  }, [])

  return (
    <SideBarLayoutNew>
      <Space
        direction="vertical"
        size="middle"
        className="project-page-container"
        style={{ display: 'flex', width: '100%', padding: '24px' }}
      >
        {/* <Button
        className="grid-list-btn"
        onClick={() => setGridLayout(!gridLayout)}
        icon={gridLayout
          ? <AppstoreOutlined/>
          : <BarsOutlined/>}
      /> */}
        <Space
          style={{
            marginBottom: 16,
            width: '100%',
            justifyContent: 'space-between',
          }}
          direction="horizontal"
          size="large"
        >
          <Button
            onClick={openProjectWizard}
            icon={<PlusOutlined className="new_project_icon" />}
            type="primary"
            shape="round"
            className="new-project-btn"
          >
            New Project
          </Button>
         
          <HintPopover
            content={popoverContent}
            popoverClassName='popover-content-arcGis'
            // arrowBottom={false}
            // popoverInner={true}
          >
            <Button
              onClick={async () => {
                handleOpenARProject();
              }}
              icon={<ShareAltOutlined className="new_project_icon" />}
              type="primary"
              shape="round"
              disabled={!isArcGISImportProjectsAvailableAndAuthorised(authProvider.getInCituUser())}
              className={!isArcGISImportProjectsAvailableAndAuthorised(authProvider.getInCituUser()) ?
                'new-project-btn-not-activ' :
                'new-project-btn' }
            >
            New Project from ArcGIS
            </Button>
          </HintPopover>
         
          {/*<Button*/}
          {/*  disabled={isExampleButtonDisabled}*/}
          {/*  onClick={async () => {*/}
          {/*    setIsExampleButtonDisabled(true);*/}
          {/*    await createExampleProject()*/}
          {/*    refetchData();*/}
          {/*    setIsExampleButtonDisabled(false);*/}
          {/*  }}*/}
          {/*  icon={<PlusOutlined className="new_project_icon" />}*/}
          {/*  type="primary"*/}
          {/*  shape="round"*/}
          {/*  className="new-project-btn"*/}
          {/*>*/}
          {/*  Example Project*/}
          {/*</Button>*/}
          {searchFormProps && (
            <Form {...searchFormProps} layout="inline">
              <Form.Item name="name">
                <Input
                  className="search-input"
                  placeholder="Search by project name"
                  prefix={
                    <SearchOutlined onClick={searchFormProps.form?.submit} />
                  }
                />
              </Form.Item>
            </Form>
          )}
        </Space>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={3}>All Projects</Typography.Title>
          </Col>
          <Col>
            <span
              className="grid-switch"
              onClick={() => performSetGridView(!gridLayout)}
            >
              <img src={gridLayout ? ListView : ListView1} />
            </span>
          </Col>
        </Row>
        <PopupARCGIS
          openPopup={openPopupArcGIS}
          setOpenPopup={setOpenPopupArcGISArcGIS}
          handleSetOpenPopupArcGIS={handleSetOpenPopupArcGIS}
          handleDisplayProgressPopup={handleDisplayProgressPopup}
        />

        {!gridLayout ? (
          <ProjectListingsNew
            key={componentKey}
            isLoading={tableQueryResult.isLoading}
            deleteProject={deleteProject}
            searchFormProps={searchFormProps}
            toggleGrid={() => performSetGridView(!gridLayout)}
            setProjectId={performSetProjectId}
            setIsModalOpen={setIsCreateModalVisible}
            openProjectWizard={openProjectWizard}
          />
        ) : (
          <ProjectGridListings
            key={componentKey}
            deleteProject={deleteProject}
            searchFormProps={searchFormProps}
            setProjectId={performSetProjectId}
            setIsModalOpen={setIsCreateModalVisible}
            userName={userName}
            openProjectWizard={openProjectWizard}
          />
        )}
      </Space>
      {isCreateModalVisible && (
        <ProjectCreationStepComposer
          visible={isCreateModalVisible}
          projectId={projectStoreId}
          onCancel={() => setIsCreateModalVisible(false)}
          setShowCloseWarning={setShowCloseWarning}
          showCloseWarning={showCloseWarning}
          closeModalPopup={() => closeModalPopup(true)}
          goToStripeCustomerPanel={goToStripeCustomerPanel}
          setShowPublishedRecommendations={setShowPublishedRecommendations}
        />
      )}
      {isShowLimitModelUploadPopup && (
        <LimitModalUploadPopup
          showCloseWarning={() => {}}
          onContinue={() => {
            goToStripeCustomerPanel();
          }}
          onLeave={() => setIsShowLimitModelUploadPopup(false)}
        />
      )}
      {isCreateModalVisible && showCloseWarning && (
        <CloseModalPopup
          showCloseWarning={showCloseWarning}
          onContinue={() => {
            setShowCloseWarning(false);
            // refetchData();
          }}
          onLeave={() => closeModalPopup(true)}
        />
      )}
      {isCreateModalVisible && showPublishedRecommendations && (
        <CloseFinishModalPopup
          isOpen={showPublishedRecommendations}
          onContinue={() => {
            setShowPublishedRecommendations(false);
            // refetchData();
          }}
          onLeave={() => closeModalPopup(true)}
        />
      )}
      {showSubscriptionWarning && (
        <ProjectLimitModal
          open={showSubscriptionWarning}
          onCancel={handleCloseSubscriptionWarning}
        />
      )}
      {isUpgradedModalVisible && (
        <UpgradedPlanModal
          open={isUpgradedModalVisible}
          onCancel={handleCloseUpgradedModal}
        />
      )}
      <UnstableConnectionPopup
        visible={showConnectionWarning}
        onLeave={() => {
          setShowConnectionWarning(false);
          setIsCreateModalVisible(false);
        }}
      />
      {isUpgradePopupVisible && (
        <UpgradePopup
          isPopupVisible={isUpgradePopupVisible}
          onClose={handleCloseUpgradePopup}
        />
      )}
      {isNoActiveSubscriptionModalVisible && (
        <NoActiveSubscriptionPopup
          open={isNoActiveSubscriptionModalVisible}
          onCancel={handleNoActiveSubscriptionModal}
        />
      )}
      {isArGISImportProjectsModalVisible && (
        <ArcGISImportingModal
          status={arGISImportStatus}
          open={isArGISImportProjectsModalVisible && arGISImportStatus !== 'none'}
          onCancel={handleArGISImportProjectsModal}
          onNavigateToProfile={() => {
            handleArGISImportProjectsModal()
            push('/account');
          }}
          onNavigateToProjects={() => {
            refetchData()
            handleArGISImportProjectsModal()
            push('/projects');
          }}
        />
      )}
    </SideBarLayoutNew>
  );
}

const Project = observer(ProjectComponent);

export default Project;
