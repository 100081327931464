import React, { useEffect, useLayoutEffect, useRef } from 'react';
import {PricingTable} from '../../components/Registration/PricingTable'
import {useRegistrationProgress} from '../../hooks/useRegistrationProgressBar'
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';

export const PricingPage = () => {
  const {setProgress} = useRegistrationProgress()

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.PRICING_PAGE);
      hasEffectRun.current = true;
    }
  }, []);

  useLayoutEffect(() => {
    setProgress(66)
  })

  return (
    <div  style={{
      alignItems: 'center',
      display: 'flex',
      margin: 'auto',
      justifyContent: 'center'}}>
      <div className="sub-main-div-pricing-page">
        <PricingTable/>
      </div>
      <div className="div-for-background">
      </div>
    </div>
  )
}
