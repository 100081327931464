import { message } from '@pankod/refine-antd';
import { Project } from '../dataProvider/gql/types';
import dataProvider from '../dataProvider/dataProvider';

export default function useProjectInfoOnMapSelectionFetch({id}: any) {

  const fetchProjectInfoOnMapSelection = async ({ id }: { id: string }): Promise<[Project, any] | []> => {
    if (!id) return [];

    const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
    try {
      const {
        data: projectInfo,
        status: pageLoadStatus,
      }: any = await dProvider.getOne<Project>({
        resource: 'project',
        id,
        metaData: {
          fields: [
            'id',
            'name',
            'subtitle',
            'origin',
            'description',
            'status',
            'createdAt',
            'address',
            'longitude',
            'latitude',
            'altitude',
            'organizationId',
            // 'statusDetail',
            'borough',
            'ceqr',
            'bbl',
            // 'attributes',
            'appClipSync',
            'autoGenerateModels',
            // eslint-disable-next-line max-len
            'buildings{id buildingHeight model3ds{id type fileLocation fileSizeMb externalId order anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
            'organization{id organizationUsers{user{id email firstName lastName}}}',
            'documents{id url name type category}',
            'timelineEvents{id name timelineEventType{id name}}',
            'projectViewPreference{id visibility isAdditionalInfoVisible isSnapToGround}',
          ],
        },
      });

      if (!projectInfo) {
        message.error('Invalid projectd Id!', 1);
        // push('/projects');
        // push('/map');
        return [];
      }
      return projectInfo ? [projectInfo, pageLoadStatus] : [];
    } catch (error) {
      console.error('Error fetching project details:', error);
      return [];
    }
  }

  return [fetchProjectInfoOnMapSelection];
}
