import { memo, useEffect, useRef, useState } from 'react';
/* eslint-disable */
import { Col, Spin } from '@pankod/refine-antd';
import ArcGISMap from '@arcgis/core/Map.js';
import MapView from '@arcgis/core/views/MapView.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js'
import esriConfig from '@arcgis/core/config';
import Graphic from '@arcgis/core/Graphic'
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from "@arcgis/core/geometry/SpatialReference.js";
import * as locator from "@arcgis/core/rest/locator.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import ElevationLayer from "@arcgis/core/layers/ElevationLayer.js";
import Search from "@arcgis/core/widgets/Search";
import './styles.less';
/* eslint-disable */

export function EsriMap({ latitude, longitude,altitude, bbl, bin, isMobile = false, isColumn = true, isInteractive = false, handleSelected,address=null}: any) {


  esriConfig.apiKey
    = 'AAPKf090a4dc9a734410b7694a2edd72f75dLZYBMrS5DdrwbbiDi_bLpmDg4MAZ2XN6HeOXZ40T4FVjfTOBh1zRSEJqKFzcYOgQ'
  const theView = useRef(null);

  const [mapLoading,setMapLoading] = useState(false);
 

  useEffect(() => {
    if (theView && theView.current) {
      const map:any = new ArcGISMap({
        basemap: "arcgis-light-gray",
      });

      const zoomLevel = isMobile ? 16 : 17;

      const view:any = new MapView({
        map: map,
        container: theView.current,
        center: [longitude, latitude],
        zoom: zoomLevel,
      });


      if(isInteractive){
        const symbolPoint = {
          type: "simple-marker",
          color: [255, 87, 51, 0.5],
          outline: {
            color: [255, 87, 51],
            width: 0.5,
          },
        };
        const mapView = new MapView({
          container: theView.current,
          map,
          center: [longitude, latitude], // Default center (longitude, latitude)
          zoom: 4, // Default zoom level
        });
      
        const graphicsLayer = new GraphicsLayer();
        map.add(graphicsLayer);

        if(longitude && latitude && altitude){
            // Clear existing graphics
            graphicsLayer.removeAll();

            const pointGraphic = new Graphic({
              geometry: new Point({
                longitude: longitude,
                latitude: latitude,
                spatialReference: SpatialReference.WGS84,
                hasZ: false,
              }),
              symbol:symbolPoint
            });
            graphicsLayer.add(pointGraphic);
            
        }
  
        // Listen for click events on the map
        mapView.on("click", (event) => {
          // Clear existing graphics
          graphicsLayer.removeAll();
          handleSelected(null);
  
          // Get the clicked point's coordinates
          const point = event.mapPoint;
          const long = point.longitude.toFixed(6);
          const lat = point.latitude.toFixed(6);
          
          // Create a point graphic and add it to the graphics layer
          const pointGraphic = new Graphic({
            geometry: point,
            symbol:symbolPoint
          });
          graphicsLayer.add(pointGraphic);
  
          // Update the state with the coordinates
          locator
            .locationToAddress(
              "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
              { location: point }
            )
            .then(async (response) => {
              const address = response.address;
              const elevationLayer:any = new ElevationLayer({
                url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer', // Elevation service URL
              });
              setMapLoading(true)
              const elevationResponse = await elevationLayer.queryElevation(point);
              
              const elevation = elevationResponse.geometry.z.toFixed(2);
              setMapLoading(false)
               // Watch altitude changes for the clicked point
               handleSelected({address,latitude:lat,longitude:long,altitude:elevation})
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });

        const searchWidget = new Search({
          view: mapView,
        });
  
        // Start the search widget
        mapView.ui.add(searchWidget, "top-right");
      }


      // Get query layer and set up query
      const parcelLayer = new FeatureLayer({
        url:"https://services5.arcgis.com/GfwWNkhOj9bNBqoJ/arcgis/rest/services/MAPPLUTO/FeatureServer/0",
      });
      // const buildingsLayer = new FeatureLayer({
      //   url:"https://services6.arcgis.com/yG5s3afENB5iO9fj/arcgis/rest/services/BUILDING_view/FeatureServer/0"
      // })
      let whereClause;
      let queryLayer;
      if (bbl && bbl.length > 0) {
        let bbls = bbl.map(String);
        const preparedQuery = bbls.join(" OR BBL = ");
        whereClause = "BBL = " + preparedQuery;
        queryLayer = parcelLayer;
      }
      // BIN is currently not relaible, the Feature Layer has missing footprints
      // else if (bin && bin.length > 0) {
      //   let bins = bin.map(String)
      //   const preparedQuery = bins.join(' OR BIN = ')
      //   whereClause = 'BIN = ' + preparedQuery;
      //   queryLayer = buildingsLayer
      // }
      else {
        let lat = String(latitude).substring(0, 7);
        lat = lat + "%";
        let lon = String(longitude).substring(0, 8);
        lon = lon + "%";

        whereClause =
          "Latitude LIKE '" + lat + "' AND Longitude LIKE '" + lon + "'";
        queryLayer = parcelLayer;
      }
      const queryObj = {
        where: whereClause,
        geometry: view.extent, // Restricted to visible extent of the map
        outFields: [], // Attributes to return
        returnGeometry: true,
      };
      queryLayer
        .queryFeatures(queryObj)
        .then((results) => {
          console.log("Feature count: " + results.features.length);
          d(results);
        })
        .catch((error) => {
          console.log(error.error);
        });
      const d = function displayResults(results: any) {
        // Create a orange polygon
        const symbol = {
          type: "simple-fill",
          color: [255, 87, 51, 0.5],
          outline: {
            color: [255, 87, 51],
            width: 0.5,
          },
        };
        // Assign styles to features
        results.features.map((feature: any) => {
          feature.symbol = symbol;
          return feature;
        });
        // Clear display
        view.graphics.removeAll();
        if (results.features.length === 0) {
          const symbolPoint = {
            type: "simple-marker",
            color: [255, 87, 51, 0.5],
            outline: {
              color: [255, 87, 51],
              width: 0.5,
            },
          };
          const graphicPoint = new Graphic({
            geometry: new Point({
              longitude: longitude,
              latitude: latitude,
              spatialReference: SpatialReference.WGS84,
              hasZ: false,
            }),
            symbol: symbolPoint,
          });

          view.graphics.add(graphicPoint);
        }
      
        view.graphics.addMany(results.features);
      };
    }
  }, [theView, longitude, latitude, ]);

  if (isMobile) {
    return (
      <div ref={theView} className='incitu-custom-map'>
      </div>
    );
  }
 if(isColumn)
  return (
    <Col xl={8} xxl={8} lg={8} md={10} sm={10} xs={10}>
      <div ref={theView} className='incitu-custom-map'>
      </div>
    </Col>
  );
  else{
    return (
      <Spin spinning={mapLoading}>
        <div ref={theView} className='incitu-custom-map'>
        </div>
        </Spin>
    );
  }

}

export default memo(EsriMap);
