import Graphic from '@arcgis/core/Graphic';
import SelectedMarker from '../../assets/SelectedMarker.svg'
import SelectedLinkUSMapMarker from '../../assets/Selected_Map_Marker_LinkUS.png'
export function highlightSelected(mapView: any,latitude: number, longitude: number,tag: string) {

  let selectedMapMarkerImage = SelectedMarker
  let symbolWidht = '34.5px'
  let symbolHeight = '34px'

  if (tag == '/map/linkus')
  {
    selectedMapMarkerImage = SelectedLinkUSMapMarker
    symbolWidht = '26px'
    symbolHeight = '34px'
  }

  try{
    if (latitude && longitude){
      const point = {
        type: 'point',
        longitude:longitude,
        latitude: latitude,
      };

      const markerSymbol = {
        type: 'picture-marker',
        url: selectedMapMarkerImage,
        width: symbolWidht,
        height: symbolHeight,
        color: '',
      };

      const pointGraphic = new Graphic({
        // @ts-ignore
        geometry: point,
        symbol: markerSymbol,
      });

      mapView.graphics.removeAll()
      mapView.graphics.add(pointGraphic)
    }
  }
  catch (e: any)
  {
    console.log('Error highlighting the project: ' + e)
  }
}
