import { useRef, useState } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { Button } from 'antd';

import ProjectDemoView from '../../project-demo';
import Minimize from '../../../../assets/minimize.svg';

import 'react-spring-bottom-sheet/dist/style.css';
import './styles.less';

const HEADER_HEIGHT = 54;

const ProjectDrawerMobile = ({ projectId, setSelectedProjectId }: { projectId: string, setSelectedProjectId: any }) => {
  const [isModelExpanded, setIsModelExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [height, setHeight] = useState(0);
  const sheetRef = useRef<BottomSheetRef>();
  const isMaxHeight = height === maxHeight;

  const onClose = () => {
    setSelectedProjectId(null);
  }

  const snapTo = () => {
    sheetRef.current?.snapTo(({ snapPoints }) => snapPoints[1]);
  }

  return (
    projectId ? (
      <>
        <BottomSheet
          className={`project-drawer-mobile ${isMaxHeight ? 'maximized' : ''}`}
          open={true}
          blocking={false}
          onDismiss={() => {
            onClose();
          }}
          defaultSnap={({ maxHeight }) => maxHeight - HEADER_HEIGHT}
          snapPoints={({ maxHeight, height }) => {
            setMaxHeight(maxHeight - HEADER_HEIGHT);
            setHeight(height);
            return [
              maxHeight - HEADER_HEIGHT,
              325,
            ]
          }}
          ref={sheetRef as any}
        >
          <ProjectDemoView
            projectId={projectId}
            isModelExpanded={isModelExpanded}
            setIsModelExpanded={setIsModelExpanded}
            isMobile={true}
            isMaxHeight={isMaxHeight}
          />
          {isMaxHeight && (
            <Button
              shape='circle'
              className='collapse-mobile-btn'
              icon={<img src={Minimize} alt='*' />}
              onClick={snapTo} />
          )}
        </BottomSheet>
      </>
    ) : null);
};

export default ProjectDrawerMobile;