/* eslint-disable no-debugger */
import React, { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Button,
  Space,
  Row,
  message,
  Divider,
  Col,
  Card,
  Typography,
} from '@pankod/refine-antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigation } from '@pankod/refine-core';

import { KeyDate } from './components/KeyDates';
import { Document } from './components/Documents';
import { ProjectStrings } from 'constants/index';
import { ProjectValues } from '../../interfaces/project';
import { buildDocuments, loadDocuments } from './utils/document';
import { buildEvents, loadEvents } from './utils/event';
import { loadProjectDetails } from './utils/project';
import { Images } from './components/Images';
import { AssetCategory, ProjectVisibility } from '../../enums';
import AddClient from './components/AddClient/AddClient';
import ShareComponent from './components/ShareComponent/ShareComponent';
import { TitleInput } from './components/TitleInput';
import { buildCover } from './utils/cover';
import { getFileExtension, jsonUtils, projectUtils } from '../../utils/util';
import { buildPreference } from './utils/preference';

import './style.less';
import BuildingsTabs from './components/BuildingsTabs';
import ModelsTabs from './components/ModelsTabs';
import ModelPreviewForm from './components/ModelPreviewForm';
import ProjectInfo from './components/ProjectInfo';
import ProjectMainInfo from './components/ProjectMainInfo';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import QRCodeModalComponent from './components/QRCode/QRCodeComponent';
import { showPublishModal } from '../Modal/ShowPublishModal';
import { errorCommon } from '../../constants/messages/error/common';
import { errorToken } from '../../constants/messages/error/token';
import { errorJWTExpired } from '../../constants/messages/error/jwt';
import { errorDBConnection } from '../../constants/messages/error/db';
import { showErrorModal } from '../Modal/ShowErrorModal';
import { useProgressModal } from '../Modal/ProgressModal';
import { showSuccessModal } from '../Modal/ShowSuccessModal';
import { useProjectPublish } from '../../hooks/useProjectPublish';
import { getAWSUrl } from './utils/aws';
import useUploadPreview from '../../hooks/useUpdatePreview';
import dataProvider from '../../dataProvider/dataProvider';
import usePublishStatusPooling from 'hooks/usePublishStatusPooling';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';

dayjs.extend(customParseFormat);

export default function ProjectDetailsForm({
  projectDetailsInformation,
  projectLoading,
  projectSaveStatus,
  projectSaveError,
  saveInfo,
  type,
}: any) {
  const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)
  const [searchedLocation, setSearchedLocation] = useState<any>({});
  const [isFormValid, setIsFormValid] = React.useState(false);

  const [pressEnterEvent, setPressEnterEvent] = useState(false)

  message.config({
    top: 55,
  });

  const [form] = Form.useForm();

  const [keyDates, setKeyDates] = useState<Array<KeyDate>>([{}]);
  const [documents, setDocuments] = useState<Array<Document>>([{}]);
  const [buildingsList, setBuildingsList]: any = useState([]);
  const [projectVisibility, setProjectVisibility] = useState<any>(ProjectVisibility.RESTRICTED);
  const [isLoading, setIsLoading] = useState(projectLoading || false);
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [initLongitude, setInitLongitude] = useState<number>();
  const [initLatitude, setInitLatitude] = useState<number>();
  const [initAltitude, setInitAltitude] = useState<number>();
  const [initRotation, setInitRotation] = useState<number>();
  const rotation = projectDetailsInformation?.buildings[0]?.defaultAnchoring?.rotation
  const projectId = useMemo(() => projectDetailsInformation?.id, [projectDetailsInformation?.id]);

  const { showProgressModal, hideProgressModal, isProgressModalVisible } = useProgressModal();
  const { publishProject, data: publishedProjectData, isLoading: isPublishing, error } = useProjectPublish();
  const [savePreviewFromMap] = useUploadPreview();

  const redirectToPage = (resultedProjectId: string) => {
    if (resultedProjectId) {
      push(`/project-edit/${resultedProjectId}`);
    }
  }

  const {
    startPublishStatusPooling,
  } = usePublishStatusPooling(projectId, hideProgressModal, showSuccessModal, showErrorModal, redirectToPage);

  const [savedProjectData, setSavedProjectData]: any = useState(projectDetailsInformation);
  
  const isEdit = useMemo(() => type === 'edit', [type]);

  const { push } = useNavigation();
  // const [documentsRefreshKey, setDocumentsRefreshKey] = useState(Date.now);
  // const [owners, setOwners] = useState([{
  //   type: 'organization',
  //   website: '',
  // }]);

  const [takeScreenshot, setTakeScreenshot] = useState(false);

  const handleTakeScreenshot = () => {
    setTakeScreenshot(true);
  };
  
  useEffect(() => {
    form.setFieldsValue({
      documents: documents,
    });
    form.setFieldsValue({
      timelineEvents: keyDates,
    });
  });

  const handleScreenshotTaken = async (dataUrl: string) => {
    console.log('handleScreenshotTaken', dataUrl)
    if (savedProjectData) {
      try {
        const base64Image: string = dataUrl
        const res = await savePreviewFromMap(savedProjectData, base64Image, isEdit)
        console.error('handleScreenshotTaken res:', res)
      } catch (e) {
        console.error('handleScreenshotTaken:', e)
      }
    }
    setTakeScreenshot(false);
  };

  const displayErrorMessage = (): void => {
    const errMessage = projectUtils.getErrorMessage(projectSaveError)
    showErrorModal('Saving Project Error', errMessage);
  }

  useEffect(() => {
    // setLoading(saveStatus == 'loading' || pageLoadStatus == 'loading');
    console.log('PROJECT SAVE: projectSaveStatus:', projectSaveStatus)

    if (projectSaveStatus === 'loading' && !isProgressModalVisible) {
      showProgressModal('Project Saving', 'Please wait. The system is currently processing data.');
    }

    if (projectSaveStatus === 'error') {
      hideProgressModal();
      displayErrorMessage();
    }
  }, [projectSaveStatus]);

  useEffect(() => {
    if (isPublishing && !isProgressModalVisible) {
      showProgressModal('Publishing Project...', 'Please wait. The system is currently converting the model and publishing it to ArcGIS.');

      startPublishStatusPooling(projectId)
    }
  }, [isPublishing, publishedProjectData]);

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  useEffect(() => {
    const fetchData = async () => {

      console.log('______projectDetailsInformation:', projectDetailsInformation)
      const documents: Array<any> = await loadDocuments(projectDetailsInformation);
      const filteredDocuments = documents.filter((item: any) => item.category !== AssetCategory.IMAGE);

      if (filteredDocuments.length > 0) {
        setDocuments(filteredDocuments);
      }

      form.setFieldsValue({
        documents: filteredDocuments,
      });
      // setDocumentsRefreshKey(Date.now());
    };

    if (projectDetailsInformation) {
      const projectData = loadProjectDetails(projectDetailsInformation);

      if (projectData?.buildings?.length > 0) {
        setBuildingsList(projectData?.buildings);
      }

      // const anchorCoordinate = projectData?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
      const anchorCoordinate = projectDetailsInformation?.buildings?.[0]?.model3ds?.[0]?.anchoring?.anchorCoordinate || projectData?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;
      form.setFieldsValue({
        ...projectData,
        latitude: anchorCoordinate?.latitude || projectData.latitude,
        longitude: anchorCoordinate?.longitude  || projectData.longitude,
        altitude: anchorCoordinate?.altitude  || projectData.altitude,
      });

      // if (projectData?.publishStatus) {
      //   setProjectVisibility(projectData?.publishStatus);
      // }

      console.log('projectData?.publishStatus____:', projectData?.publishStatus)

      const loadedKeyDates: Array<any> = loadEvents(projectDetailsInformation);
      if (loadedKeyDates.length) {
        setKeyDates(loadedKeyDates);
        form.setFieldsValue({
          timelineEvents: loadedKeyDates,
        });
      }

      try {
        fetchData()
          .then((r) => {
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      handleSetModelsListEmptyItem(0, 0);
    }
  }, [projectDetailsInformation]);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        if (buildingsList[0]?.modelsList?.[0]?.glb) {
          setIsFormValid(true);
        }
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values, buildingsList[0]?.modelsList?.[0]?.usdz]);

  // const { longitude, latitude, altitude } = projectDetailsInformation?.buildings[0]?.defaultAnchoring?.anchorCoordinate || projectDetailsInformation || {};
  let longitude = 0, latitude = 0, altitude = 0;

  const anchorCoordinate = projectDetailsInformation?.buildings?.[0]?.model3ds?.[0]?.anchoring?.anchorCoordinate || projectDetailsInformation?.buildings?.[0]?.defaultAnchoring?.anchorCoordinate;

  if (anchorCoordinate) {
    ({ longitude, latitude, altitude } = anchorCoordinate);
  }

  const { appClipSync } = projectDetailsInformation || {};

  useEffect(() => {
    if (longitude && typeof initLongitude === 'undefined') {
      setInitLongitude(longitude);
    }
    if (latitude && typeof initLatitude === 'undefined') {
      setInitLatitude(latitude);
    }
    if (altitude && typeof initAltitude === 'undefined') {
      setInitAltitude(altitude);
    }
    if (rotation && typeof initRotation === 'undefined') {
      setInitRotation(rotation);
    }
  }, [
    longitude,
    latitude,
    initLongitude,
    initLatitude,
    altitude,
    initAltitude,
    initRotation,
    rotation,
  ]);

  const onFinish = async (values: any) => {
    if (pressEnterEvent){
      console.log('press enter event')
      return
    }
    const { publishStatus, channels } = values;

    // TODO: return
    const appClipSync = true; // channels?.includes('ios') || false;
    console.log('____appClipSync: ', appClipSync)
    try {
      setIsLoading(true);
      /* eslint-disable */
      const statusDetail = {
        upcoming: '',
        ulurp_status: '',
        latest_update: '',
        construction_status: 'UNDER_CONSTRUCTION',
      }

      const formValues: ProjectValues = {
        name: values.name,
        latitude: values.latitude || '0',
        description: values.description,
        longitude: values.longitude || '0',
        altitude: values.altitude || '0',
        address: values.address,
        // statusDetail: statusDetail,
        appClipSync: appClipSync,
      };

      let cover;
      const isCoverExist = values?.cover?.length > 0;

      if (values?.documents?.length > 0) {
        const documentsQuery = await buildDocuments(values.documents);
        formValues.documents = documentsQuery;
      }

      if (values?.cover?.length > 0 && values?.cover[0] instanceof File) {
        const coverQuery = await buildCover(values.cover[0]);
        cover = coverQuery;
      }

      //key events
      if (Object.prototype.hasOwnProperty.call(values, 'timelineEvents')) {
        formValues.timelineEvents = buildEvents(values);
      }

      formValues.projectViewPreference = buildPreference(publishStatus, isEdit);

      //organization payload
      // if (Object.prototype.hasOwnProperty.call(values, 'ownerName')) {
      //   formValues.organization = buildOrganization(values);
      // }

      //saving to DB
      const saveInfoPromise = saveInfo({ ...formValues }, buildingsList, cover, values.clients || [], isCoverExist, values.rotation);
      saveInfoPromise.then((data: any) => {
        console.log('________saveInfo:', data);
        hideProgressModal();

        const resultedProjectId = data.data.id;
        mixPanelUtils.track(isEdit ? MixpanelEvent.PROJECT_UPDATED : MixpanelEvent.PROJECT_CREATED, { projectId: resultedProjectId })

        if(!isEdit) {
          redirectToPage(resultedProjectId)
        }

        // saveThumbnailsPreview(data.data, isEdit)
        setSavedProjectData(data.data)
        handleTakeScreenshot()

        showPublishModal(resultedProjectId, publishProject);
      }).catch((error: any) => {
        console.error('Error for saveInfo occurred:', error);
        showErrorModal('Saving Project Error', errorCommon);
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSetModelsList = (newModel: any, buildingIndex: number, modelOrder: number) => {
    console.log('______handleSetModelsList newModel:', newModel)
    setBuildingsList((prevBuildings: any) => {
      const newBuildingsList = [...prevBuildings];
      const newModelsList = { ...(newBuildingsList[buildingIndex].modelsList || {}) };

      if (newModel.file) {
        newModelsList[modelOrder] = { ...(newModelsList[modelOrder] || {}) }
        newModelsList[modelOrder][getFileExtension(newModel.file.name)] = {
          ...newModelsList[modelOrder][getFileExtension(newModel.file.name)],
          ...newModel,
        }
      } else {
        newModelsList[modelOrder] = { glb: undefined, isNew: true };
      }

      newBuildingsList[buildingIndex].modelsList = newModelsList;
      console.log('______handleSetModelsList newBuildingsList:', newModel)
      return newBuildingsList;
    });
  };

  const handleSetModelsListEmptyItem = (buildingIndex: number, modelOrder: number) => {
    setBuildingsList((prev: any) => {
      const newBuildingsList = [...prev];
      newBuildingsList[buildingIndex] = { ...(newBuildingsList[buildingIndex] || {}) }
      newBuildingsList[buildingIndex].modelsList = newBuildingsList[buildingIndex].modelsList || {};
      newBuildingsList[buildingIndex].modelsList[modelOrder] = {};
      newBuildingsList[buildingIndex].modelsList[modelOrder].isNew = true;
      console.log('______handleSetModelsList newBuildingsList:', newBuildingsList)
      return newBuildingsList;
    });
  }

  const updateAnchorCoordinate = async (
    coordinate: { longitude: number, latitude: number, altitude: number },
    buildingIndex: number,
    modelOrder: number
  ) => {
    setBuildingsList((prev: any) => {
      const newBuildingsList = [...prev];
      newBuildingsList[buildingIndex].modelsList[modelOrder] = { ...(newBuildingsList[buildingIndex].modelsList[modelOrder] || {}) }
      newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz'] = { ...(newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz'] || {}) }
      newBuildingsList[buildingIndex].modelsList[modelOrder]['usdz'].anchorCoordinate = coordinate;

      return newBuildingsList;
    });
  }

  // const removeModelListItem = (buildingIndex: number, modelOrder: number) => {
  //   setBuildingsList((prev: any) => {
  //     const newBuildingsList = [...prev];
  //     delete newBuildingsList[buildingIndex].modelsList[modelOrder];

  //     return newBuildingsList;
  //   });
  // }

  // const addBuildingsListEmptyItem = (isInit?: boolean) => {
  //   setBuildingsList((prev: any) => {
  //     const newBuildingsList = prev && !isInit ? prev : [];
  //     newBuildingsList.push({ modelsList: {} });

  //     return newBuildingsList;
  //   });
  // }

  // const removeBuildingsListItem = (buildingIndex: number) => {
  //   setBuildingsList((prev: any) => {
  //     const newBuildingsList = [...prev];
  //     newBuildingsList.splice(buildingIndex, 1);
  //     return newBuildingsList;
  //   });
  // }

  const goToProjects = () => {
    push('/projects');
  }

  const handleShare = () => {
    if (projectId) {
      setIsQRModalOpen(true);
    }
  }

  const eventPressKey = (value: any) => {
      setPressEnterEvent(value.code === "Enter")
  }

  console.log('PROJECT initAltitude:', initAltitude)

  return (
    <Form
      form={form}
      name="basic"
      onKeyPress={(event) => eventPressKey(event)}
      initialValues={{
        remember: true,
        // documents: {},
        // timelineEvents: [{}],
      }} // todo fix documents
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="project-details-form-new"
    >

      <Space
        direction="vertical"
        size="large"
        style={{ display: 'flex', paddingBottom: '1rem' }}
      >
        <Row>
          <Col span={2}>
            <Button
              shape='circle'
              className='back-btn'
              icon={<LeftOutlined />}
              onClick={goToProjects}
            />
          </Col>
          <Col span={14} className='project-info-wrapper'>
            <Card className='project-card'>
              {/* <TitleInput loading={isLoading} /> */}
              {/* 3D Model and Location section */}
              <Row gutter={[24, 24]} justify="space-between" align="top">
                {/* <BuildingsTabs
              projectId={projectId}
              buildingsList={buildingsList}
              setModelsList={handleSetModelsList}
              addModelsListEmptyItem={handleSetModelsListEmptyItem}
              updateAnchorCoordinate={updateAnchorCoordinate}
              removeModelListItem={removeModelListItem}
              isLoading={isLoading}
              longitude={longitude}
              latitude={latitude}
              altitude={altitude}
              projectVisibility={projectVisibility}
              isEdit={isEdit}
              addBuildingsListEmptyItem={addBuildingsListEmptyItem}
              removeBuildingsListItem={removeBuildingsListItem}
            /> */}
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <ProjectMainInfo
                      projectId={projectId}
                      loading={isLoading}
                      setSearchedLocation={setSearchedLocation}
                      setInitLatitude={setInitLatitude}
                      setInitLongitude={setInitLongitude}
                      setInitAltitude={setInitAltitude}
                    />
                    <ModelPreviewForm
                      projectId={projectId}
                      models={buildingsList[0]?.modelsList[0]}
                      setModelsList={handleSetModelsList}
                      loading={isLoading}
                      longitude={initLongitude}
                      latitude={initLatitude}
                      altitude={initAltitude}
                      rotation={initRotation}
                      order={0}
                      isNew={!isEdit}
                      buildingIndex={0}
                      searchedLocation={searchedLocation}
                      setSearchedLocation={setSearchedLocation}
                      setInitLatitude={setInitLatitude}
                      setInitLongitude={setInitLongitude}
                      setInitAltitude={setInitAltitude}
                      setInitRotation={setInitRotation}
                      onTakeScreenshot={takeScreenshot}
                      onScreenshotTaken={handleScreenshotTaken}
                    />
                    <ProjectInfo
                      loading={isLoading}
                      longitude={longitude}
                      latitude={latitude}
                      altitude={altitude}
                      modelAnchorCoordinate={buildingsList[0]?.modelsList?.[0]?.usdz?.anchorCoordinate}
                      projectVisibility={projectVisibility}
                      isEdit={isEdit}
                      updateAnchorCoordinate={updateAnchorCoordinate}
                      order={0}
                      isNew={!isEdit}
                      buildingIndex={0}
                    />
                  </Row>
                </Col>
                {/* <Location loading={isLoading}/> */}
                {/* <Divider style={{ backgroundColor: 'grey' }} /> */}
                <Images loading={isLoading} projectId={projectId} />
                {/* <Divider style={{ backgroundColor: 'grey' }} />
                <AddClient isLoading={true} form={form} />
                <Divider style={{ backgroundColor: 'grey' }} /> */}
              </Row>
              <Row align="middle" justify="start">
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                >
                  Save
                </Button>
              </Row>
            </Card>
          </Col>
          <Col span={8} className='share-component-wrapper'>
          <div className='actions-buttons-container'>
            <Button
              disabled={!isEdit || !isFormValid}
              loading={isLoading}
              type="default"
              className="share-btn"
              onClick={handleShare}
            >
              Share
            </Button>

            <div className="save-btn-container">
              {projectSaveStatus === 'success' && <div className="saved-status"><CheckOutlined /> Project saved</div>}
              <Button
                disabled={!isFormValid}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="submit-btn"
              >
                Save
              </Button>
            </div>
          </div>
            {/*<ShareComponent*/}
            {/*  projectId={projectId}*/}
            {/*  appClipSync={appClipSync}*/}
            {/*  setProjectVisibility={setProjectVisibility}*/}
            {/*/>*/}
          </Col>
        </Row>
      </Space>
      <QRCodeModalComponent projectId={projectId} isQRModalOpen={isQRModalOpen} setIsQRModalOpen={setIsQRModalOpen} projectName={projectDetailsInformation?.name ?? ''} />
    </Form>
  );
}
