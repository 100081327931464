import React from 'react';
import './styles.less';
const ProjectDescriptionItems: React.FC<any> = (data: any) => {
  const projectStatus = data?.status?.status || data?.status?.ulurp_status;
  const buildingHeight = data?.building_height;
  const isAdditionalInfoVisible = data?.is_additional_info_visible;

  return (
    <>
      <div className='description-item'>{data?.description}</div>
      {isAdditionalInfoVisible && (
        <>
          <h2 className='description-title details'>Project Details</h2>
          {projectStatus &&
            <>
              <span className='description-title-item'>Status: </span>
              <span className='description-item'>{projectStatus}</span>
              <br />
            </>
          }
          {buildingHeight &&
            <>
              <span className='description-title-item'>Height: </span>
              <span className='description-item'>{buildingHeight}</span>
              <br />
            </>
          }
        </>
      )}
    </>
  )
}
export default ProjectDescriptionItems;

