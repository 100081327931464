import React, {useContext, useEffect, useState} from 'react';
import './style.less';

import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import BeingPublished from './components/BeingPublished';
import AttentionBeingPublished from './components/AttentionBeingPublished';
import WarningBeingPublished from './components/WarningBeingPublished';
import { PublishingStatus } from '../../../../../store/projectCreationStore';
import { delay } from '../../../../../utils/util';

const BeforeDownloadInfo = observer(() => {
  const { projectCreationStore } = useContext(Context);
  const {isProjectSaving, isPublishing: isProjectPublishing, publishingStatus: projectPublishingStatus} = projectCreationStore
  const [showAttention, setShowAttention] = useState(false);

  useEffect(() => {
    const handleDelay = async () => {
      if (projectPublishingStatus === PublishingStatus.InProgress && !showAttention) {
        await delay(10000);
        setShowAttention(true);
      }
    };

    handleDelay();
  }, [projectPublishingStatus, showAttention]);

  return (
    <>
      <div style={ !isProjectSaving && projectPublishingStatus === PublishingStatus.None ? { visibility: 'hidden' } : {}}>
        {
          !isProjectSaving && (projectPublishingStatus === PublishingStatus.Failed || projectPublishingStatus === PublishingStatus.Timeout)
            ? <WarningBeingPublished />
            :   <>
              { showAttention
                ? <AttentionBeingPublished />
                : <BeingPublished />
              }
            </>
        }
      </div>
    </>
  );
});

export default BeforeDownloadInfo;
