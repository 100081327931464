import React, { useEffect, useMemo, useState } from 'react';
import {authProvider} from '../../authPrvider/authProvider'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigation } from '@pankod/refine-core';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import { delay } from '../../utils/util';
import logo_Black from '../../assets/Logo_Black.png'
export const Redirect = () => {
  const { push } = useNavigation()
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [isLoadedAuth0UserData, setIsLoadedAuth0UserData] = useState(false)

  const [fetchUserInfo] = useUserInfoFetch();

  const {
    user: userAuth0,
    isAuthenticated,
  } = useAuth0();

  const checkSubscription = async () => {
    let userData: any;

    const fetchUser = async () => {
      if (userAuth0 && userAuth0.sub) {
        const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
        const incituUser = authProvider.getInCituUser();
        if (fetchedUser) {
          setIsLoadingUser(false);
          if (incituUser === undefined) {
            authProvider.saveInCituUser(fetchedUser.data);
          }
          userData = fetchedUser.data;
          return userData;
        }
      } else {
        return null
      }
    };

    userData = await fetchUser();

    if (userData && (userData?.subscriptionPlan || userData?.role === 'ADMIN')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const checkUserAuthentification = async () => {
      await delay(3000)
      console.log('Rechecking authentication...userAuth0:', userAuth0);
      console.log('Rechecking authentication...isAuthenticated:', isAuthenticated);
      console.log('Rechecking authentication...isLoadedAuth0UserData:', isLoadedAuth0UserData);
      if (userAuth0){
        console.log('Redirect userAuth0 exists:', userAuth0);
        if(await checkSubscription()) {
          push('/projects')
        } else {
          push('/map')
        }
        setIsLoadedAuth0UserData(true);
      } else {
        console.log('Redirect userAuth0 doe not exists:', userAuth0);
        push('/map')
      }

    };

    checkUserAuthentification();
  }, [userAuth0]);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#F0F0F0',
      flexShrink: 0,
    }}>
      <div style={{backgroundColor: 'white', padding: 40, borderRadius: 5 }}>
        <div style={{alignItems: 'center', justifyContent:'center', display: 'flex'}}>
          <img src={logo_Black}/>
        </div>
        <br/>
        <div style={{fontSize: 22, fontWeight: 'bold', fontFamily: 'Work Sans'}}>
              You are being redirected, please wait
        </div>
      </div>
      <button className="redirect-button" onClick={async () => {
        push('/redirect')
      }}>Redirect</button>
    </div>
  );

}
