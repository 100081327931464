import { Button, Input, Modal, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.less'
import EmailIcon from '../../../../../../assets/email_icon.svg';
import WhatsappIcon from '../../../../../../assets/whatsApp_icon.svg';
import LinkIcon from '../../../../../../assets/link_icon.svg';
import { copyToClipboard } from '../../../../../../utils/util';

import { LINKUS_PROJECT_LIST, LINKUS_URL } from '../../../../../../constants/linkus';

import checkMobile, {
  checkIfAndroidInterfaceAvailable,
} from '../../../../../../utils/checkMobile';

export default function ShareModel({visible, projectId, handleOpenShareModal}: any) {
  const { isMobile } = checkMobile();
  const isAndroidInterfaceAvailable =  checkIfAndroidInterfaceAvailable()
  const isMobileUI = isMobile || isAndroidInterfaceAvailable;
  const [projectUrl, setProjectUrl] = useState('');

  const buildUrl = (projId: string) => {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/project/${projId}`
  }

  useEffect(() => {
    const url = buildUrl(projectId);
    setProjectUrl(url);
  }, [projectId]);

  const shareEmail = ()=>{
    const mailtoURL = `mailto:?subject=&body=${encodeURIComponent(buildUrl(projectId))}`;
    window.location.href = mailtoURL;
  }
  const shareWhatsapp = ()=>{
    const wpURI = `https://api.whatsapp.com/send?text=${encodeURIComponent(buildUrl(projectId))}`;
    window.open(wpURI, '_blank');
  }

  return (
    <Modal open={visible} footer={[]} onCancel={()=>handleOpenShareModal(false)} style={{minWidth: isMobileUI ? 0 : 600}}>
      <Space
        direction="vertical"
        align="center"
        className={`p_d_share_modal_container ${isMobileUI ? 'mobile' : ''}`}
      >
        <span className='p_d_share_title'>Share this project</span>
        <div>
          <img className="p_d_share_icons" onClick={shareEmail} src={EmailIcon} />
          <img className="p_d_share_icons" onClick={shareWhatsapp} src={WhatsappIcon} />
        </div>
        <div className="p_d_share_copy" style={{width: 500, display: isMobileUI ? 'flex' : 'inline-block'}}>
          <Input
            key={projectId}
            className='p_d_share_input'
            prefix={<img src={LinkIcon} />}
            defaultValue={projectUrl}
            readOnly={true}
          />
          <Tooltip  placement="leftBottom" trigger='click' title='Share link copied!'>
            <Button style={{display: 'block',  margin: 'auto', marginTop: 10}} className={ LINKUS_PROJECT_LIST.includes(projectId) ? 'p_d_share_btn_linkus' : 'p_d_share_btn'} onClick={()=>{
              copyToClipboard(projectUrl)
            }} type="primary">copy</Button>
          </Tooltip>
        </div>
      </Space>
    </Modal>
  );
}
