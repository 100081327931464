import { useCreate, useUpdate } from '@pankod/refine-core';

export default function useAppClip() {
  const { mutate: updateProject } = useUpdate();

  const saveAppClip = async (projectInfo: any) => {

    const projectId = projectInfo?.id;
    if (projectId) {
      const values = {
        appClipSync: {
          set: true,
        },
      }

      updateProject({
        resource: 'project',
        id: projectId,
        values: values,
        metaData: {
          fields: ['id'],
        },
      });
    }
  }

  return [saveAppClip];
}
