import React, { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { resolutionValidator } from './resolutionValidator';
import ImageCropperModal from './ImageCropperModal';

import './styles.less';
import {Button, Typography} from 'antd';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';

import uploadImageSvg from '../../../src/assets/upload.svg'
import {buildCover} from '../../pages/project-creation-step-composer/utils/cover';
import {CloseCircleFilled, CloseCircleOutlined} from '@ant-design/icons';
const FormImageUploaderComponent = ({
  value = [],
  projectId,
  accept,
  multiple,
  maxCount,
  onDelete,
  onChange,
  isCover,
  version,
}: any) => {

  console.log('____value: ', value)
  const isUploaderDisabled = maxCount && value && value.length >= maxCount;
  const [croppImageSrc, setCropImageSrc] = useState<any>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  // here
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      let newFiles = acceptedFiles;

      if (isCover) {
        mixPanelUtils.track(MixpanelEvent.COVER_IMAGE_UPLOADED, { projectId: projectId })
        const validatedFiles = await Promise.allSettled(
          acceptedFiles.map((file: any) => resolutionValidator(file)),
        );

        newFiles = acceptedFiles.filter((file: any, index: number) => {
          if (validatedFiles[index].status === 'rejected') {
            setError((validatedFiles[index] as any).reason);

            return false;
          }

          setError(undefined);

          if ((validatedFiles[index] as any).value === 'shouldCrop') {
            setCropImageSrc(URL.createObjectURL(file));

            return false;
          }

          return true;
        });

      }

      if (maxCount && value.length + newFiles.length > maxCount) {
        newFiles = newFiles.slice(0, maxCount - value.length);
      } else {
        newFiles = [...value, ...newFiles];
      }
      console.log('___cover___222____')
      console.log('___cover___newFiles____', newFiles)
      // buildCover(newFiles);
      onChange(newFiles);

    },
    [maxCount, value],
  );

  const acceptMapped = accept?.reduce((acc: any, item: any) => ({ ...acc, [item]: [] }), {});

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptMapped,
    multiple,
    maxFiles: maxCount,
    disabled: isUploaderDisabled,
  });

  const handleDelete = (index: any, id: any) => {
    const files = value;
    mixPanelUtils.track(MixpanelEvent.COVER_IMAGE_DELETED, { projectId: projectId })
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    // buildCover(updatedFiles);
    onChange(updatedFiles);

    if (onDelete) {
      onDelete(id);
    }
  };

  return (
    <div  className={`form-image-uploader ${isUploaderDisabled ? 'disabled' : ''}`}>
      <div {...getRootProps()} className="form-image-uploader-content"
        style={version === 'NewNew' ? {border: '1px dashed grey', background: '#EBEBEB'}: {}}>
        <input {...getInputProps()} disabled={isUploaderDisabled} />
        {!isCover || isCover && value.length === 0
          ? (<div className="content-container">
            {version === 'NewNew'
              ? <>
                <img src={uploadImageSvg} style={{marginBottom: 10}}/>

                <div style={{fontSize: 12, fontWeight: 500}} >
                                  Drag and drop your file here
                </div>
                <div style={{fontSize: 12, fontWeight: 400, marginBottom: 10}} >
                              or use “upload file” button
                </div>
                <div className="content-hint" style={{borderRadius: 5}}>Upload file</div>
              </>
              : <div className="content-hint">Upload images</div>
            }
          </div>)
          : value.length > 0 && (<div className="cover-container">
            <img
              src={value[0].url ? value[0].url : URL.createObjectURL(value[0])}
              alt={value[0].name}
              className="file-thumb"
            />
          </div>)}
      </div>
      {isCover && value.length > 0 &&
          <CloseCircleFilled style={{position: 'absolute', top: 10, right: 15, color: 'white'}} onClick={() => { handleDelete(0, value[0].id) }}/>
        // <Button className='close-btn' style={{position: "absolute", top: 0, right: 15}} onClick={() => { handleDelete(0, value[0].id) }}>x</Button>
      }
      {/*{!isCover && (<div className="files-list">*/}
      {/*  {value && value.map((file: any, index: number) => (*/}
      {/*    <div key={index} className="file-item">*/}
      {/*      <div className="thumb-overlay">*/}
      {/*        <img*/}
      {/*          src={file.url ? file.url : URL.createObjectURL(file)}*/}
      {/*          alt={file.name}*/}
      {/*          className="file-thumb"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <Button className='close-btn' onClick={() => { handleDelete(index, file.id) }}>x</Button>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>)}*/}
      {error && <div className="error">{error}</div>}
      <ImageCropperModal
        src={croppImageSrc}
        onCancel={() => setCropImageSrc(undefined)}
        saveImg={(res: any) => {
          onChange([...value, new File([res], 'cover.jpg')]);
          // buildCover([...value, new File([res], 'cover.jpg')]);
          setCropImageSrc(undefined);
        }}

      />
    </div>
  );
};

export const FormImageUploader = memo(FormImageUploaderComponent);
