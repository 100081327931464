import React from 'react'
import { Button, Divider, Tooltip } from '@pankod/refine-antd'
import AR from '../../../../../../assets/AR.svg';
import Share from '../../../../../../assets/share.svg';
import Comment from '../../../../../../assets/comment.svg';
import { copyToClipboard } from '../../../../../../utils/util';
import { MixpanelEvent, mixPanelUtils } from '../../../../mixpanel/mixpanel';

import './styles.less';
import { CONTACT_US } from '../../../../../../constants/links';

import { LINKUS_PROJECT_LIST, LINKUS_URL } from '../../../../../../constants/linkus';
import { useNavigation } from '@pankod/refine-core';
import { isAndroid, checkIfAndroidInterfaceAvailable, openNativeAndroidApp } from '../../../../../../utils/checkMobile';

interface ActionsComponentProps {
  projectId: string;
  openQRModal: () => void;
  openFeedbackForm?: () => void;
  isMobile?: boolean;
  openShareModal: () => void;
}

const ActionsComponent: React.FC<ActionsComponentProps> = ({
  projectId,
  openQRModal,
  openFeedbackForm,
  isMobile,
  openShareModal,
}) => {
  const isAndroidInterfaceAvailable = checkIfAndroidInterfaceAvailable()
  const { push } = useNavigation();

  const onShareClick = () => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_SHARE, { projectId: projectId })
  }

  return (
    <div className='actions-container'>
      <div className='buttons-container'>
        <div className='left-buttons'>
          { /* System also get isMobile=false for Android Wrapper App */}
          {/*{!isMobile && isAndroidInterfaceAvailable && <Button*/}
          {/*  className={'qrButton' }*/}
          {/*  onClick={() => {openNativeAndroidApp(projectId)}}*/}
          {/*  icon={<img src={AR} alt='*' />}*/}
          {/*>*/}
          {/*  See in AR (Android)*/}
          {/*</Button>}*/}
          {/*{!isMobile && !isAndroidInterfaceAvailable && <Button*/}
          {/*  className={ LINKUS_PROJECT_LIST.includes(projectId) ? 'qrButtonLinkus' : 'qrButton' }*/}
          {/*  onClick={openQRModal}*/}
          {/*  icon={<img src={AR} alt='*' />}*/}
          {/*>*/}
          {/*  See in AR*/}
          {/*</Button>}*/}
          {!isMobile && <Button
            className={ LINKUS_PROJECT_LIST.includes(projectId) ? 'qrButtonLinkus' : 'qrButton' }
            onClick={ () => {
              if(isAndroidInterfaceAvailable) {
                openNativeAndroidApp(projectId)
              } else {
                openQRModal()
              }
            }}
            icon={<img src={AR} alt='*' />}
          >
            SEE IN AR
          </Button>}
        </div>

        <div className='right-buttons'>
          <Button
            className='feedback-button'
            onClick={() => {
              if(LINKUS_PROJECT_LIST.includes(projectId)) {
                // window.open(LINKUS_URL, '_blank');
                // location.href= LINKUS_URL;
                if(isAndroidInterfaceAvailable) {
                  // push(LINKUS_URL);
                  location.href = LINKUS_URL;
                } else {
                  window.open(LINKUS_URL, '_blank');
                }
              } else {
                if (openFeedbackForm) {
                  openFeedbackForm();
                }
              }
            }}
            icon={<img src={Comment} alt='*' />}
          />
          {/* <Tooltip placement="leftBottom" trigger='click' title='Share link copied!'> */}
          <Button
            className='copy-btn'
            onClick={() => {
              openShareModal()
              onShareClick()
            }}
            icon={<img src={Share} alt='*' />}
          />
          {/* </Tooltip> */}
        </div>
      </div>
    </div>
  )
}

export default ActionsComponent;
