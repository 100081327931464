import { CheckCircleFilled } from '@ant-design/icons';

const SuccessUploadView = () => (
  <>
    <p className="ant-upload-drag-icon">
      <CheckCircleFilled style={{ color: '#CBDB2A' }} />
    </p>
    <p className="ant-upload-text">Your model was</p>
    <p className="ant-upload-hint">successfully uploaded!</p>
  </>
);

export default SuccessUploadView;