import { customRequest } from '../dataProvider/dataProvider';
import { GraphQLClient } from 'graphql-request';
import { ProjectQueryType } from '../utils/util';

export default function useProjectsFetch() {
  const fetchProjects = async (currentPage = 0, queryType: ProjectQueryType, queryTag: string, visibility: any, privateVisibility: any, restrictedVisibility: any) => {
    const projectFields = `
    id
      longitude
      latitude
      arcgisSceneLayerId
      city{displayName}`;
    const withModelsWhere = `
   {
            buildings: {
              some: {
                model3ds: { every: { fileLocation: { startsWith: "projects" } } }
              }
            }
          }
          {
            buildings: {
              some: {
                model3ds: { some: { fileLocation: { startsWith: "projects" } } }
              }
            }
          }
          { projectViewPreference: { is: { visibility: { equals: $visibility } } } }`;

    const allPublicProjectsQuery = `
      query GetProjects($skip: Int!,$visibility: ProjectVisibilityType!) {
        projects(skip: $skip, where: {
            AND: [${withModelsWhere}]
          }) {
        ${projectFields}
        }
      }
    ` ;

    const withModelsWherePrivateAndPublic = `
   {
            buildings: {
              some: {
                model3ds: { every: { fileLocation: { startsWith: "projects" } } }
              }
            }
          }
          {
            buildings: {
              some: {
                model3ds: { some: { fileLocation: { startsWith: "projects" } } }
              }
            }
          }
          {
            projectViewPreference: {
              is: {
                OR: [
                  { visibility: { equals: $visibility } },
                  { visibility: { equals: $privateVisibility } },
                  { visibility: { equals: $restrictedVisibility } }
                ]
              }
            }
          }
          `;

    const allPublicAndPrivateProjectsQuery = `
      query GetProjects($skip: Int!,$visibility: ProjectVisibilityType!, $privateVisibility: ProjectVisibilityType, $restrictedVisibility: ProjectVisibilityType) {
        projects(skip: $skip, where: {
            AND: [${withModelsWherePrivateAndPublic}]
          }) {
        ${projectFields}
        }
      }
    ` ;

    const cityPublicProjectsQuery = `
  query GetCityProjects($skip: Int!,$visibility: ProjectVisibilityType!,$queryTag: String!) {
    projects(skip: $skip, where: {
        AND: [
          ${withModelsWhere}
          { city: {is:{displayName:{equals:$queryTag} } } }
        ]
      }) {
    ${projectFields}
    }
  }
`;
    const orgProjectsQuery = `
  query GetOrgProjects($skip: Int!,$queryTag: String!) {
    projects(skip: $skip, where: { organizationId:{equals:$queryTag }}) {
      ${projectFields}
    }
  }
`;
    const singleProjectsQuery = `
  query GetSingleProject($skip: Int!,$queryTag: String!) {
    projects(skip: $skip, where: { id:{equals:$queryTag }}) {
      ${projectFields}
    }
  }
`;
    let query = '';
    switch (queryType) {
      case ProjectQueryType.AllPublicAndPrivate:
        query = allPublicAndPrivateProjectsQuery;
        break;
      case ProjectQueryType.AllPublic:
        query = allPublicProjectsQuery;
        break;
      case ProjectQueryType.CityPublic:
        query = cityPublicProjectsQuery;
        break;
      case ProjectQueryType.Single:
        query = singleProjectsQuery;
        break
      case ProjectQueryType.Org:
        query = orgProjectsQuery;
        break;
    }
    console.log('query: ', query)

    const client = new GraphQLClient(`${process.env.REACT_APP_API_URL}/graphql`);
    const pageSize = 100;
    const offset = currentPage * pageSize;

    let data: Array<any> = [];

    try {
      const projectData = customRequest(client, query, {
        'skip': offset,
        'visibility': visibility,
        'queryTag': queryTag,
        'privateVisibility': privateVisibility,
        'restrictedVisibility': restrictedVisibility
      }) as Promise<any>;
      const response = await projectData;
      data = [...data, ...response.projects];
    } catch (e) {
      console.log('Error Fetching Projects ', e);
    }
    return { list: data };

  };
  return [fetchProjects];
}
