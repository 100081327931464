import { useMutation } from '@pankod/refine-core';
import { GraphQLClient, gql, Variables } from 'graphql-request';
import { authProvider } from '../authPrvider/authProvider';

const endpoint = `${process.env.REACT_APP_API_URL}/graphql`

const mutationFunction = async (variables: Variables | undefined) => {

  const token = authProvider.getToken();
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const mutation = gql`
        mutation publishProject($id: String!, $arcgisToken: String) {
            exportProjects(
                where: { id: { equals: $id } }
                batch: false
                arcgis: true
                arcgisToken: $arcgisToken
            ) {
                count
            }
        }
    `;

  return graphQLClient.request(mutation, variables);
};

export const useProjectPublish = () => {

  const {
    mutate,
    isLoading,
    error,
    data,
  } = useMutation(mutationFunction);

  const publishProject = async (projectId: string) => {

    let arcgisToken: string | undefined = authProvider.getArcGISToken()
    if(arcgisToken === undefined) {
      arcgisToken = ''
    }
    await mutate({ id: projectId, arcgisToken: arcgisToken });
  }

  return { publishProject, isLoading, error, data };
}
