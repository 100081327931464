
import {buildEventsQuery} from '../query/event';

export function buildEvents(values: any): any {
  const eventsData = values?.timelineEvents.map((e: any, index: number) => {
    const item: any =  {
      name: e.date,
      timelineEventTypeId: e.event,
    }

    if (e.id) {
      item.id = e.id
    }
    return item
  })

  const res = buildEventsQuery(eventsData)
  return  res
}

export function loadEvents(projectDetailsInformation: any): Array<any> {
  const events: Array<any> = projectDetailsInformation?.timelineEvents?.map((item: any) => ({
    id: item?.id,
    event: item?.timelineEventType?.id,
    date: item?.name,
  })) || [{}]
  return events
}
