/* eslint-disable max-len */

import {Button} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.less';

import laptopBottom from '../../../../../assets/laptop_mockup_2.png'
// @ts-ignore
import glbModel from '../../../../../assets/glbModel.glb'
import mapPrev from '../../../../../assets/mapPrev.png'
import Logo_Black from '../../../../../assets/Logo_Black.png'
import AR from '../../../../../assets/AR.svg';
import Comment from '../../../../../assets/comment.svg';
import Share from '../../../../../assets/share.svg';
import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';

const BrowserPreview  = observer(({modelFileForPrew}: any) => {

  const {projectCreationStore} = useContext(Context)

  const {
    projectId,
    projectSubTitle,
    projectName,
    projectDescription,
    address} = projectCreationStore

  // const hasEffectRun = useRef(false);
  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_WEB_PREVIEW, { projectId: projectId });
  //     hasEffectRun.current = true;
  //   }
  // }, []);

  const [showDetailsText, setShowDetailsText] = useState(true)

  return (
    <>
      <div>
        <div className="main-preview-div">
          <div>
            <div className="div-header">
              <span className="span-logo-black" >
                <img className="img-logo-black" src={Logo_Black}/>
              </span>
              <span className="text-sing-in" >
                                            SingIn
              </span>
            </div>
            <div style={{display: 'flex', margin: 'auto'}}>
              <span style={{width: '35%'}}>
                <div>
                  <model-viewer
                    src={modelFileForPrew ? modelFileForPrew : glbModel}
                    shadow-intensity='1'
                    camera-controls={true}
                    auto-rotate={true}
                    ar={true}
                    exposure='0.02'
                    ar-placement='floor'
                    ar-scale='auto'
                    loading='eager'
                    style={{width: '100%',
                      height: '10vw',
                    }}
                  >
                  </model-viewer>
                </div>
                <div>
                  <div style={{fontSize: 8 , paddingLeft: 5}}>
                    {projectName}
                  </div>
                  <div style={{fontSize: 6, paddingLeft: 5}}>
                    {address}
                  </div>
                  <div className='actions-container2'
                    style={{display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      margin:'auto',
                      paddingTop:10}}>
                    <div className='buttons-container'>
                      <div className='left-buttons' style={{marginLeft: 5}}>
                        <Button
                          className='qrButton'
                          onClick={()=>alert('See in AR')}
                          icon={<img style={{width: 5}} src={AR} alt='*'/>}
                        >
                                                    See in AR
                        </Button>
                      </div>
                      <div className='right-buttons' style={{float: 'right', marginLeft: 20}}>
                        <img style={{width: 7}} src={Comment} alt='*'/>
                        <img  style={{width: 7}} src={Share} alt='*'/>
                      </div>
                    </div>
                  </div>

                  <div className="tabs-menu-preview" >
                    <span onClick={()=> setShowDetailsText(!showDetailsText)} >
                                                    Details
                      <hr className={showDetailsText ? 'hr-active' : 'hr-no-active'}/>
                    </span>
                    <span onClick={()=> setShowDetailsText(!showDetailsText)} >
                                                    Reviews
                      <hr className={!showDetailsText ?  'hr-active' : 'hr-no-active'}/>
                    </span>
                  </div>
                  <div className="show-details-text" >
                    {showDetailsText ? projectDescription : projectSubTitle}
                  </div>
                </div>
              </span>
              <span className="map-prev" style={{backgroundImage: `Url(${mapPrev})`}}>
              </span>
            </div>
          </div>
        </div>
        <img src={laptopBottom} className="laptop-bottom" />
      </div>
    </>
  );
});

export default BrowserPreview;
