import React from 'react';

import performLogout from '../hooks/auth0/useLogout';

export function interceptFetch(logout: any) {
  const originalFetch = window.fetch;

  (window as any).fetch = async function(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const response = await originalFetch(input, init);
    if (response.status === 401) {
      console.log('Unauthorized access detected.');
      await performLogout(logout)
    }
    return response
  }
}
