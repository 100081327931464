import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';

export default function UnstableConnectionPopup({visible,onLeave}:any){
  return  <Modal className='close-popup-modal-wrapper' closeIcon={false} closable={false} open={visible} 
    style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',textAlign:'center'}}
    footer={[]}>
    <Typography.Title level={5}>Your connection is unstable</Typography.Title>

    <Typography.Paragraph>We are working to reconnect.<br/>This may take a few minutes</Typography.Paragraph>
    <Row align="middle" justify="center">
      <Col><Button onClick={onLeave} className='leave-btn'>Close Project</Button></Col>
    </Row>
  </Modal>
}