/* eslint-disable max-len */
import React, { useContext, useEffect, useRef } from 'react';
import './style.less';
import {observer} from 'mobx-react-lite';
import {Context} from '../../../../../index';
import iosPhone from '../../../../../assets/backgroundImage.png';
import arPreviewIOS from '../../../../../assets/arPreviewIOS.png';
import previewImgForIOS from '../../../../../assets/previewImgForIOS.png';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';

interface PropsIOSPreview {
    tabsKeyNumber: string
}

const IOSPreview = observer((props: PropsIOSPreview) => {

  const tabsKeyNumber = props.tabsKeyNumber

  const {projectCreationStore} = useContext(Context)

  const {projectId, projectSubTitle, projectName, previewImgDetailStep, removePreviewImgDetailStep, cover, projectDescription} = projectCreationStore

  // const hasEffectRun = useRef(false);
  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_IOS_PREVIEW, { projectId: projectId });
  //     hasEffectRun.current = true;
  //   }
  // }, []);

  return (
    <> {tabsKeyNumber === '3'
      ?
      <div className="ar-ios-main-div-preview " >
        <div>
          <img src={arPreviewIOS} className="img-ar-prev-style"/>
        </div>
        <div className="sub-text-after-img">
                      This is an example of how
                      AR could look in-person
        </div>
      </div>
      : <div className="div-for-project-and-subtitle">
        <div className="img-style">
          <img src={iosPhone} className="img-style"/>
          <div className="img-prev-style-wrapper">
            {previewImgDetailStep && previewImgDetailStep.length ? <>
              <img src={previewImgDetailStep}
                className="img-prev-style"/></> : <>
              <img src={cover && cover.length > 0 ? cover[0].url : previewImgForIOS} className="img-prev-style"/>
            </>}
            <div className="project-name-ios-preview">
              {projectName}
            </div>
            {/*<div className="project-description-ios-preview">*/}
            {/*  {projectDescription}*/}
            {/*</div>*/}
            <div className="project-subtitle-ios-preview">
              {projectSubTitle}
            </div>

          </div>
        </div>
      </div>}
    </>
  );
});

export default IOSPreview;
