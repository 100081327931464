import { useState, useCallback } from 'react';
import { ProjectQueryType } from '../utils/util';
import useOrgInfoFetch from './useOrgInfoFetch';
import useCityInfoFetch from './useCityInfoFetch';
import useProjectInfoFetch from './useProjectInfoFetch';

import useProjectSearch from './useProjectSearch';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { authProvider } from "../authPrvider/authProvider";
import { checkIfAndroidInterfaceAvailable } from "../utils/checkMobile";

// Define the type for the argument if it's specific. Here, I've used 'any' for generality.
type ArgumentType = any;

// Define the type for the result of the async operation.
type ResultType = any; // Replace 'any' with the actual type of your result

// Define the type for the error.
interface ErrorType {
  message: string;
}

const useMapEvaluateTag = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [fetchOrgInfo] = useOrgInfoFetch();
  const [fetchCityInfo] = useCityInfoFetch();
  const [fetchProjectInfo] = useProjectInfoFetch();
  const [searchProject] = useProjectSearch();

  const evaluateTag = useCallback(async (tag: string,mapView: any) => {
    setIsReady(false);

    try {
      console.log(tag)
      const splitTag = tag.split('/');
      let lastTag = splitTag[splitTag.length - 1];
      
      if (tag === '/map' || tag ==='/map/') {
        if(authProvider.getInCituUser()?.role === 'ADMIN' && checkIfAndroidInterfaceAvailable()) {
          return { queryType: ProjectQueryType.AllPublicAndPrivate, queryTag: tag, visibility: 'PUBLIC', privateVisibility:  'PRIVATE', restrictedVisibility:  'RESTRICTED' };
        }
        if(authProvider.getInCituUser()?.role === 'CLIENT' && checkIfAndroidInterfaceAvailable()) {
          return { queryType: ProjectQueryType.AllPublicAndPrivate, queryTag: tag, visibility: 'PUBLIC', privateVisibility:  'PRIVATE', restrictedVisibility:  'RESTRICTED' };
        }
        return { queryType: ProjectQueryType.AllPublic, queryTag: tag, visibility: 'PUBLIC' };
      }
      else if (tag.includes('/map/org/'))
      {
        const orgTagId = splitTag[3]
        const orgData = await fetchOrgInfo(orgTagId)
        if(orgData) {
          const orgId = orgData.data.id
          if (orgId) {
            let visibilityTag = splitTag[4]
            if (visibilityTag) {
              visibilityTag.toUpperCase()
            }
            if (visibilityTag !== 'PUBLIC' && visibilityTag !== 'PRIVATE' && visibilityTag !== 'RESTRICTED') {
              visibilityTag = 'PUBLIC'
            }

            return { queryType: ProjectQueryType.Org, queryTag: orgId, visibility: visibilityTag }

          }
          else
          {
            console.error('Organization Not Found!')

          }
        }
        return { queryType: ProjectQueryType.None, queryTag: '',visibility:'PUBLIC' };

      }
      else if (tag.includes('map/') && !tag.includes('map/org/')) {

        const cityData = await fetchCityInfo(lastTag);
        if (cityData && cityData.data.length > 0) {
          const centroid = cityData.data[0].centroid
          if(centroid)
          {
            mapView.center = [centroid.longitude,centroid.latitude]
          }
          return { queryType: ProjectQueryType.CityPublic,queryTag: cityData.data[0].displayName,visibility:'PUBLIC' };
        } else {
          console.error(`City: ${lastTag} Not Found! `);

          return null;
        }
      }
      else if (tag.includes('/project/')) {
        try {
          const href = window.location.href
          if(href.includes('/project/?id='))
          {

            const splitTagId = href.split('=');
            lastTag = splitTagId[splitTagId.length - 1];
            console.log(lastTag.length)
            if (lastTag.length === 8)
            {
              const searchResult = await searchProject(lastTag)
              if(searchResult)
              {
                lastTag = searchResult.data[0].id
              }
            }
          }
          console.log(lastTag);
          const projectData = await fetchProjectInfo({id: lastTag});
          if(projectData && projectData.latitude)
          {
            mapView.center = [projectData.longitude,projectData.latitude]
          }
          if (projectData.city && projectData.city.displayName) {
            const cityData = await fetchCityInfo(projectData.city.displayName);

            if (cityData && cityData.data.length > 0) {

              const displayName = cityData.data[0].displayName;
              if (displayName) {
                return {
                  queryType: ProjectQueryType.CityPublic,
                  queryTag: displayName,
                  visibility: 'PUBLIC',
                };
              }
            }
          } else {
            if (projectData.developer && projectData.organization.id) {
              return {

                queryType: ProjectQueryType.Org,
                queryTag: projectData.organization.id,
                visibility: 'PUBLIC',
              };
            } else {

              return {
                queryType: ProjectQueryType.Single,
                queryTag: lastTag,
                visibility: 'PUBLIC',
              };
            }

          }
        } catch (err) {
          console.error('Error single project query:', err);
        }
      }

      setIsReady(true);
    } catch (err: any) {
      // Handle errors
      setError({ message: err.message });
    }
  }, []); // Empty dependency array means this function is created once and never recreated

  return { evaluateTag, isReady, error };
};

export default useMapEvaluateTag;
