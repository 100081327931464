/* eslint-disable max-len */

import { Button, message, RadioChangeEvent, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import './style.less';
import CopyLink from './CopyLink';
import QRScan from './QRScan';
import BeforeDownloadInfo from './BeforeDownloadInfo';
import AfterDownloadInfo from './AfterDownloadInfo';
import RadioButtonGroup from './RadioButtonGroup';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import usePublishStatusPooling from '../../../../../hooks/usePublishStatusPooling';
import { PublishingStatus } from '../../../../../store/projectCreationStore';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';
import * as util from '../../../../../utils/util';
import download_icon from '../../../../../assets/download_icon_white.svg';
import copyProjectLinkIcon from '../../../../../assets/copyProjectLinkIcon_white.svg'
import { copyToClipboard } from '../../../../../utils/util';

const ModelShareStepView: React.FC = observer(() => {

  const { projectCreationStore } = useContext(Context);
  const { isProjectSaving, projectId } = projectCreationStore
  const { getProjectShareUrlLink, buildQRCodeURL, handleDownloadQRCode, isNotPublishedAndChanges, isDisableRightBlocks, projectSize, isProjectChangesExists } = projectCreationStore

  const { isPublishing, publishingStatus: publishingProjectStatus } = projectCreationStore
  const { isPoolingProgressStarted, setPoolingProgressStarted } = projectCreationStore
  const [value, setValue] = useState(2);

  const [sizeMb, setSizeMb] = useState('4.5Mb');
  const [premium, setPremium] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)
  // const [isProgressStarted, setProgressStarted] = useState(false);

  const {
    startPublishStatusPooling,
  } = usePublishStatusPooling(projectId);

  useEffect(() => {
    if (projectId && isPublishing && !isPoolingProgressStarted) {
      startPublishStatusPooling(projectId)
      setPoolingProgressStarted(true);
    }
  }, [projectId, isPublishing, isPoolingProgressStarted]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const isDisableLeftBlock = () => {
    const isDisableLeftBlock = (publishingProjectStatus === PublishingStatus.InProgress
      || publishingProjectStatus === PublishingStatus.Failed
      || publishingProjectStatus === PublishingStatus.Timeout)
    return isDisableLeftBlock
  }

  const isDisableRightBlock = () => {
    return isDisableRightBlocks()
  }

  const onChangeCopyMessage = () => {
    if (publishingProjectStatus !== PublishingStatus.Success && isProjectChangesExists) {
      setShowCopiedMessage(false)
    } else if (projectId !== undefined){
      util.copyToClipboard(`${window.location.origin}/project/${projectId}`)
      setShowCopiedMessage(true)
    }
  }
  // @ts-ignore
  return (
    <>
      <div className="main-div">
        <span className="span-left">
          <div
            style={isDisableLeftBlock() ? { opacity: 0.5 } : {}}
          >
            <RadioButtonGroup
              disabled={isDisableLeftBlock()}
              onChange={onChange}
              value={value}
              download={true}
              premium={premium} />
          </div>
        </span>

        <span
          className="span-right-share"
          style={isDisableRightBlock() ? { opacity: 0.5 } : {}}>
          <div className="sub-main-div">
            <div className="div-for-scan">
              {!isProjectSaving
                ? <div className="divAntTypographyH4" >{publishingProjectStatus === PublishingStatus.Success ? 'Share your project' : 'Publishing your project'}</div>
                : <div className="divAntTypographyH4" >{'Saving & publishing your project'}</div>
              }
              {isNotPublishedAndChanges()
                ? <BeforeDownloadInfo />
                : <AfterDownloadInfo publishingStatus={publishingProjectStatus}
                />
              }

              <QRScan src={buildQRCodeURL()} disabled={isNotPublishedAndChanges()} />
              <div
                className="share-buttons-container"
                style={isNotPublishedAndChanges() ? { opacity: 0.5 } : {}}
              >
                <div
                  // disabled={publishingProjectStatus !== PublishingStatus.Success}
                  className="copy-button-share-step"
                  onClick={async () => {
                    if (isNotPublishedAndChanges()) {
                      return
                    }
                    copyToClipboard(getProjectShareUrlLink())
                  }}
                >
                  {!showCopiedMessage ?
                    <Typography.Text
                      disabled={isNotPublishedAndChanges()}
                      style={{cursor: 'pointer'}}
                      className="copy-button-share-text-icon"
                      onClick={() => onChangeCopyMessage()}
                      onMouseLeave={() => setShowCopiedMessage(false)}
                    >
                      <img src={copyProjectLinkIcon} />
                      Copy project link
                    </Typography.Text>
                    :
                    <Tooltip placement="bottomLeft" color="grey" zIndex={99999999999} title='Link copied!'>
                      <Typography.Text
                        disabled={isNotPublishedAndChanges()}
                        style={{cursor: 'pointer'}}
                        className="copy-button-share-text-icon"
                        onClick={() => onChangeCopyMessage()}
                        onMouseLeave={() => setShowCopiedMessage(false)}
                      >
                        <img src={copyProjectLinkIcon} />
                        Copy project link
                      </Typography.Text>
                    </Tooltip>
                  }
                </div>
                <div
                  // disabled={publishingProjectStatus !== PublishingStatus.Success}
                  className="copy-button-share-step"
                  onClick={async () => {
                    if (isNotPublishedAndChanges()) {
                      return
                    }
                    await handleDownloadQRCode()
                  }}
                >
                  <Typography.Text
                    disabled={isNotPublishedAndChanges()}
                    className="copy-button-share-text-icon"
                    style={{cursor: 'pointer'}}
                  >
                    <img src={download_icon} />
                    Download QR code
                  </Typography.Text>
                </div>
              </div>

              <div className="project-size-text">
                <Typography.Paragraph style={{ fontSize: 12 }}>
                  Estimated project size: {Number(projectSize).toFixed(2) || 0}Mb
                </Typography.Paragraph>
              </div>
            </div>

          </div>
        </span>
      </div>
    </>
  );
});

export default ModelShareStepView;
