import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import ProfileDetailsForm from '../../components/Registration/ProfileDetailsForm'
import {authProvider} from '../../authPrvider/authProvider'
import { useAuth0 } from '@auth0/auth0-react';
import performLogout from '../../hooks/auth0/useLogout';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import { useRegistrationProgress } from '../../hooks/useRegistrationProgressBar';

export const WelcomeBack = () => {
  const {setProgress} = useRegistrationProgress()
  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])
  const {
    logout,
  } = useAuth0();

  useLayoutEffect(() => {
    setProgress(77)
  })

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.WELCOME_BACK);
      hasEffectRun.current = true;
    }
  }, []);

  const username = user ? user.firstName + ' ' + user.lastName : ''
  // const title = username ? `Welcome Back ${username}` : 'Welcome Back'
  const title = 'Tell us about yourself'
  
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#F0F0F0',
      flexShrink: 0,
    }}>
      <div>
        <ProfileDetailsForm
          mainTitle={title}
          secondTitle='' //'Tell us about yourself'
          username={username}
        />
      </div>
      {/*<button className="logout-button" onClick={async () => {*/}
      {/*  await performLogout(logout)*/}
      {/*}}>Sign out</button>*/}
    </div>
  )
}
