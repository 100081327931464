import React, { useEffect, useState } from 'react';
import { useList, useNavigation, useOne } from '@pankod/refine-core';
import { message } from '@pankod/refine-antd';
import dataProvider from '../dataProvider/dataProvider';

export default function useProjectDetailsFetchByPartialId({ id }: any) {
  const { push } = useNavigation();
  const [projectInfo, setProjectInfo] = useState(null);
  const [pageLoadStatus, setPageLoadStatus] = useState('loading');

  useEffect(() => {
    const fetchData = async () => {
      const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)
      const { data } = await dProvider.getList({
        resource: 'projects',
        filters: [{
          field: 'id',
          value: id,
          operator: 'endswith',
        }],
        metaData: {
          fields: [
            'id', 'name', 'description', 'status', 'createdAt', 'address', 'longitude', 'latitude',
            'altitude', 'organizationId',
            // 'statusDetail',
            'borough', 'ceqr', 'bbl',
            // 'attributes',
            'appClipSync',
            'autoGenerateModels',
            'buildings{id buildingHeight model3ds{id type fileLocation externalId order fileSizeMb}}',
            'organization{id name notes website}',
            'documents{id url name type}',
            'timelineEvents{id name timelineEventType{id name}}',
          ],
        },
      })

      const filteredProjects: any = data
      if (filteredProjects && filteredProjects.length > 0) {
        setProjectInfo(filteredProjects?.[0]);
        setPageLoadStatus('loaded');
      } else {
        message.error('Invalid projectd Id!', 1);
        push('/projects');
      }
    };

    fetchData().then();
  }, [id]);

  return [projectInfo, pageLoadStatus];
}

