import React from 'react';
import DashboardHeader from '../DashboardHeader';

const CreatedNamePerson = (props:any)=>{
  const personName = props.personName
  const firstChar = personName.substr(0,1).toUpperCase()
  return (
    <div>
      {personName}
    </div>
  )
}

const RenderCreatedNamePerson = (row: any) => {
  const ownerUsers = row?.row?.projectUsers?.filter((item: any) => item.relation === 'OWNER');

  if(ownerUsers?.length === 0) {
    return (
      <CreatedNamePerson personName={''}/>
    )
  }

  const ownerUser = ownerUsers[0];
  let ownerUserName = `${ownerUser.user.firstName} ${ownerUser.user.lastName}`
  if(ownerUserName?.length <= 1) {
    ownerUserName = ownerUser?.user?.email
  }
  return (
    <CreatedNamePerson personName={ownerUserName}/>
  );
};
export default RenderCreatedNamePerson;
