import { loadModules } from 'esri-loader';
import { authProvider } from '../authPrvider/authProvider';

export const signInToArcGIS = (destroyCredentials = false) => {
  return new Promise((resolve, reject) => {

    loadModules(['esri/identity/IdentityManager', 'esri/identity/OAuthInfo'])
      .then(([IdentityManager, OAuthInfo]) => {
        // Clear any existing credentials
        if(destroyCredentials) {
          authProvider.removeArcGISToken()
          authProvider.clearSessionStorage()
          IdentityManager.destroyCredentials();
        }

        const info = new OAuthInfo({
          appId: process.env.REACT_APP_ARCGIS_AUTH_APP_ID, // 'JoqSNK3FDHL9yC6X',
          popup: true,
          popupCallbackUrl: process.env.REACT_APP_ARCGIS_AUTH_CALLBACK, //'http://localhost:3000/callback',
        });

        IdentityManager.registerOAuthInfos([info]);

        IdentityManager.getCredential(info.portalUrl + '/sharing')
          .then((credential: any) => {
            console.log('User signed in to ArcGIS.');
            console.log('Token:', credential.token);
            authProvider.saveArcGISToken(credential.token)
            authProvider.saveArcGISToken(credential.token);
            resolve(credential.token);
          })
          .catch((error: any) => {
            authProvider.removeArcGISToken()
            console.error('Error signing in to ArcGIS:', error);
            reject(error);
          });
      });
  });
};

export const signOutFromArcGIS = () => {
  loadModules(['esri/identity/IdentityManager', 'esri/identity/OAuthInfo'])
    .then(([IdentityManager, OAuthInfo]) => {
      authProvider.removeArcGISToken()
      authProvider.clearSessionStorage()
      IdentityManager.destroyCredentials();
    })
}

export const checkIsArcGISAuthorised = (): boolean =>  {
  return Boolean(authProvider.getArcGISToken());
};

export const isArcGISAvailable = (user: any) => {
  if(user?.role === 'ADMIN') {
    return true
  }
  return user?.subscriptionPlan && user?.subscriptionPlan.active && user?.subscriptionPlan.type === 'PRO';
};

export const isArcGISImportProjectsAvailable = (user: any) => {
  if(user?.role === 'ADMIN') {
    return true
  }
  return user?.subscriptionPlan && user?.subscriptionPlan.active && user?.subscriptionPlan.type === 'PRO';
};

export const isArcGISImportProjectsAvailableAndAuthorised = (user: any) => {
  if (!checkIsArcGISAuthorised()) {
    return false;
  }
  if(user?.role === 'ADMIN') {
    return true
  }
  return user?.subscriptionPlan && user?.subscriptionPlan.active && user?.subscriptionPlan.type === 'PRO';
};
