import React from 'react';
import { Col, Button, Typography, Divider, Row, Tabs, Space } from '@pankod/refine-antd';
import ActionsComponent from './Actions';
import CommentsListings from './Comments';
import ProjectDescriptionItems from './DescriptionDetails';
import ProjectDescriptionOfficialsItems from './OfficialDetails';

import './styles.less';
import ShareModel from './ShareModal';

import { LINKUS_PROJECT_LIST } from '../../../../../constants/linkus';

const { Title, Paragraph, Text } = Typography;

interface ProjectDescriptionProps {
  projectId: string;
  data: any;
  openQRModal: () => void;
  openFeedbackForm?: () => void;
  getAllComments: () => void;
  isMobileMinimized?: boolean;
  isMobile?: boolean;
  isModelExpanded?: boolean;
  comments?: any;
  openShareModal?: boolean;
  handleOpenShareModal: any;
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({
  projectId,
  data,
  openFeedbackForm,
  openQRModal,
  isMobileMinimized,
  isMobile,
  comments,
  getAllComments,
  openShareModal,
  handleOpenShareModal,
  isModelExpanded,
}) => {
  const canFetchMoreComments = comments?.list?.length < comments?.count;
  /*
  const formatValue = (element: any) => {
    try {
      if (typeof element?.value == 'object') {
        const res = element?.value?.map((item: any) => {
          return item.url
            ? <a target='_blank' href={item.url}>{item.name}</a>
            : item;
        })

        return element?.value?.length > 0
          ? res?.reduce((prev: any, curr: any) => [prev, ', ', curr])
          : ''
      } else {
        return element?.value?.length > 0 ? element.value : '';
      }
    } catch (err) {
      console.error(err);
    }
  };
   */
  let tabItems = [
    {
      label: 'Details', key: 'details', children: !isMobileMinimized && (
        <>
          <ProjectDescriptionItems {...data} />
          <ProjectDescriptionOfficialsItems {...data} />
        </>
      ),
    },
    {
      label: 'Reviews', key: 'reviews', children: <Space direction='vertical' style={{ display: 'flex' }}>
        <CommentsListings comments={comments} />
        <Space className='comments-actions'>
          {canFetchMoreComments ? <a onClick={getAllComments}><b>See all comments</b></a> : null}
          <Button
            className='addButton'
            onClick={openFeedbackForm}
          // icon={<img src={AddComment} alt='*' />}
          >
            Add a comment
          </Button>
        </Space>
      </Space>,
    },
  ];

  if(LINKUS_PROJECT_LIST.includes(projectId)) {
    tabItems = [
      {
        label: 'Details', key: 'details', children: !isMobileMinimized && (
          <>
            <ProjectDescriptionItems {...data} />
            <ProjectDescriptionOfficialsItems {...data} />
          </>
        ),
      },
    ];
  }

  return (
    <div className="description-details-container">
      <Row align="middle" justify="space-between" style={{ paddingBottom: '4rem' }}>
        {!isModelExpanded && <Divider />}
        <Col span={24} style={{ padding: '28px', paddingTop: '11px', top: '10px' }}>
          <Title className="project-title">{data?.title}</Title>
          <div className="address-line">{data?.address}</div>
          <ActionsComponent
            projectId={projectId}
            openQRModal={openQRModal}
            openFeedbackForm={openFeedbackForm}
            isMobile={isMobile}
            openShareModal={handleOpenShareModal}
          />
        </Col>
        <Col span={24} style={{ padding: '28px' }}>
          <Tabs className={LINKUS_PROJECT_LIST.includes(projectId) ? 'project-map-tabs-linkus' : 'project-map-tabs' }  items={tabItems} />
        </Col>
      </Row>
      <ShareModel visible={openShareModal} projectId={projectId} handleOpenShareModal={handleOpenShareModal} />
      {/* {data.filter((item: any) => !ProjectDetailHideFromListKeys.includes(item?.key?.toLowerCase())).map((item: any) => {
        console.log('t', item)
        return(
        <Paragraph className={'project-info-p class_' + item.key} key={JSON.stringify(item)}>
          {item.key.toLowerCase() !== 'description' && item.value.length !== 0 &&
            <span className='p-info-title'>{` ${item.key}`} </span>
          }
        </Paragraph>
      )})} */}
      {/* <Divider /> */}
      {/* <Button className='feedback-button'
        type='primary' onClick={openFeedbackForm} shape='round'>Submit Feedback</Button> */}
    </div>
  );
}
export default ProjectDescription;

