import { authProvider } from '../authPrvider/authProvider';

export const fetchUserAndSaveToLocalStorage = async (auth0User: any, fetchUserInfo: any) => {
  const userIncitu = authProvider.getInCituUser()
  if (userIncitu && userIncitu.externalId) {
    const fetchedUser: any = await fetchUserInfo(userIncitu.externalId)
    authProvider.saveInCituUser(fetchedUser.data)
  } else if (auth0User && auth0User.sub) {
    const fetchedUser: any = await fetchUserInfo(auth0User.sub);
    authProvider.saveInCituUser(fetchedUser.data);
  } else {
    console.log('Refetch User not found');
    return
  }
}
