import { authProvider } from '../authPrvider/authProvider';
import { fetchUsersByExternalId } from '../api/user';

export default function useUserInfoFetch() {
  const fetchUserInfo = async (externalId: string) => {
    try {
      const fetchedUser = await fetchUsersByExternalId(externalId)
      return { data: fetchedUser?.data };
    } catch (error) {
      console.error('Error fetching user details:', error);
      return undefined;
    }
  };

  return [fetchUserInfo];
}

export const fetchUserInfo = async (externalId: string) => {
  try {
    const fetchedUser = await fetchUsersByExternalId(externalId)
    return { data: fetchedUser?.data };
  } catch (error) {
    console.error('Error fetching user details:', error);
    return undefined;
  }
};
