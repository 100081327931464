export const findAddressCandidates = async (text: string, magicKey: string) => {
  const endpoint = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates';
  const params = new URLSearchParams({
    SingleLine: text,
    magicKey: magicKey,
    f: 'json',
  });

  try {
    const response = await fetch(`${endpoint}?${params.toString()}`);
    if (!response.ok) {
      console.error('Network response was not ok');
      return { location: { x: 0, y: 0 }}
    }
    const data = await response.json();

    console.log(data);
    if(data?.candidates?.length > 0) {
      return { location: data?.candidates?.[0].location }
    }
    return { location: { x: 0, y: 0 }}
  } catch (error) {
    console.error('Error fetching address candidates:', error);
    return { location: { x: 0, y: 0 }}
  }
};
