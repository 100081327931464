import { awsUtils } from '../../../utils/aws';

export function buildNewModelQueryWith3D(
  projectId: string,
  buildingId: string,
  modelfileName: any,
  anchorCoordinate: any,
  order: any,
): any {
  const buildingsQuery: any = {
    create: {
      model3ds: {
        create: {
          type: modelfileName.split('.')[1],
          fileLocation: awsUtils.getModelFileLocation(projectId, buildingId, modelfileName),
          externalId: '',
          order: +order,
          building: { connect: { id: buildingId } },
        },
      },
    },
  };

  if (anchorCoordinate && (anchorCoordinate.latitude || anchorCoordinate.longitude || anchorCoordinate.altitude)) {
    buildingsQuery.create.model3ds.create.anchoring = {
      create: {
        anchorCoordinate: {
          create: {
            latitude: anchorCoordinate.latitude ?? 0,
            longitude: anchorCoordinate.longitude ?? 0,
            altitude: anchorCoordinate.altitude ?? 0,
          },
        },
      },
    };
  }
  return buildingsQuery
}

export function buildUpdateModelQueryWith3D(
  projectId: string,
  buildingId: string,
  modelfileName: any,
  anchorCoordinate: any,
  previousAnchorCoordinate?: any,
): any {
  const buildingsQuery: any = {
    update: {
      model3ds: {
        update: {
          fileLocation: {
            set: awsUtils.getModelFileLocation(projectId, buildingId, modelfileName),
          },
        },
      },
    },
  };

  const hasAnchorCoordinate = anchorCoordinate && !previousAnchorCoordinate
    && (anchorCoordinate.latitude || anchorCoordinate.longitude || anchorCoordinate.altitude);
  const hasNewAnchorCoordinate = anchorCoordinate && previousAnchorCoordinate
    && (
      anchorCoordinate.latitude !== previousAnchorCoordinate.latitude
      || anchorCoordinate.longitude !== previousAnchorCoordinate.longitude
      || anchorCoordinate.altitude !== previousAnchorCoordinate.altitude
    );

  if (hasAnchorCoordinate) {
    buildingsQuery.update.model3ds.update.anchoring = {
      create: {
        anchorCoordinate: {
          create: {
            latitude: anchorCoordinate.latitude ?? 0,
            longitude: anchorCoordinate.longitude ?? 0,
            altitude: anchorCoordinate.altitude ?? 0,
          },
        },
      },
    };
  }

  if (hasNewAnchorCoordinate) {
    buildingsQuery.update.model3ds.update.anchoring = {
      update: {
        anchorCoordinate: {
          update: {
            latitude: {
              set: anchorCoordinate.latitude ?? 0,
            },
            longitude: {
              set: anchorCoordinate.longitude ?? 0,
            },
            altitude: {
              set: anchorCoordinate.altitude ?? 0,
            },
          },
        },
      },
    };
  }

  return buildingsQuery
}

export function buildMockQuery(): any {
  const buildingsQuery = {
    create: {
    },
  };
  return buildingsQuery
}
