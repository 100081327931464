import SceneLayer from '@arcgis/core/layers/SceneLayer';
import SceneView from '@arcgis/core/views/SceneView';
import PortalItem from '@arcgis/core/portal/PortalItem';
import Portal from '@arcgis/core/portal/Portal';
import Layer from '@arcgis/core/layers/Layer';

export async function createSceneView3D(projects: any, sceneView: SceneView, onProjectClick: any,meshStyle: boolean) {
  try {

    const portal = new Portal({url: 'https://incitu.maps.arcgis.com'});
    const addedLayers = new Set<string>([]);
    const osmLayerUrl = 'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_Buildings_v1/SceneServer'
    const sceneLayerOSM = createBaseLayer(osmLayerUrl);
    sceneView.map.layers.add(sceneLayerOSM);

    await sceneView.when(() => {
      sceneView.map.allLayers.forEach(layer => {
        if (layer.get('url') == osmLayerUrl) {
          layer.opacity = 0.2

        }
      })
    })

    if (projects && projects.length > 0) {
      for (const project of projects) {
        await addProjectLayer(project, sceneView, portal, addedLayers, onProjectClick, meshStyle);
      }
    }
  } catch (error) {
    console.error('Error in 3D Layer Creation:', error);
  }
}
function createBaseLayer(layerUrl: string): SceneLayer {
  return new SceneLayer({
    url: layerUrl,
    renderer: {
      // @ts-ignore
      type: 'simple',
      symbol: {
        type: 'mesh-3d',
        symbolLayers: [{ type: 'fill', material: { color: [255, 255, 255, 0.2], colorMixMode: 'replace' } }],
      },
    },
    elevationInfo: { mode: 'absolute-height', offset: -8 },
    minScale: 30000,
    maxScale: 0,
    popupEnabled: false, // @ts-ignore
  });
}

function setupClickHandler(sceneView: SceneView, onProjectClick: any) {
  sceneView.on('click', async (hitEvent: any) => {
    try {
      const response = await sceneView.hitTest(hitEvent);
      const feature = response.results.find((result: any) => result.graphic && result.graphic.layer.type === 'SceneLayer');

      if (feature && response.results[0].type === 'graphic') {
        const projectId = response.results[0].graphic.attributes.projectId;
        handleClicks(projectId, onProjectClick);
      } else {
        console.log('No feature matched the clicked layer.');
      }
    } catch (error) {
      console.error('Error in click event handler:', error);
    }
  });
}

async function addProjectLayer(project: any, sceneView: SceneView, portal: Portal, addedLayers: Set<string>,onProjectClick: any, meshStyle: boolean) {
  const arcgisSceneLayerId: string = project.arcgisSceneLayerId;
  if (!arcgisSceneLayerId || addedLayers.has(arcgisSceneLayerId)) {
    return;
  }

  try {
    const portalItem = new PortalItem({ portal: portal, id: arcgisSceneLayerId });
    await portalItem.load();

    const sceneLayerNew3d = createNewLayer(portalItem,meshStyle);
    sceneView.map.layers.add(sceneLayerNew3d);
    addedLayers.add(arcgisSceneLayerId);
    await sceneView.whenLayerView(sceneLayerNew3d);
    sceneLayerNew3d.outFields = ['*'];
    sceneView.on('click', async (hitEvent: any) => {
      try {
        const response = await sceneView.hitTest(hitEvent);

        if (response.results.length > 0) {
          const feature = response.results.find((result: any) => result.graphic.layer === sceneLayerNew3d);
          if (feature) {
            if(response.results[0].type === 'graphic')
            {
              const projectId = response.results[0].graphic.attributes.projectId;
              handleClicks(projectId, onProjectClick);
            }

          } else {
            console.log('No feature matched the clicked layer.');
          }
        } else {
          console.log('No features found in HitTest response.');
        }
      } catch (error) {
        console.error('Error in click event handler:', error);
      }
    });
  } catch (error) {
    console.error('Error adding new layer:', error);
  }
}

function createNewLayer(portalItem: PortalItem,meshStyle: boolean): SceneLayer {

  const symbolLayer = meshStyle ? {
    type: 'fill',
    material: { color: [200, 218, 42], colorMixMode: 'replace' },
    edges: { type: 'solid', color: [133, 108, 62, 0.5], size: 1 },
  } : {};

  return new SceneLayer({
    portalItem: portalItem,
    renderer: {
      // @ts-ignore
      type: 'simple',
      symbol: {
        type: 'mesh-3d',
        symbolLayers: [
          symbolLayer,
        ],
      },
    },
    elevationInfo: { mode: 'absolute-height', offset: 0 },
    minScale: 30000,
    maxScale: 0,
    // @ts-ignore
    popupTemplate:'',
  });
}

function handleClicks(projectId: string, onProjectClick: any) {
  if (onProjectClick && projectId) {
    console.log('NEW_3D_FEATURE projectId:', projectId);
    onProjectClick(projectId);
  }
}
