import { Button } from '@pankod/refine-antd';
import UploadIcon from '../../../../../../assets/Upload.png';

const DefaultUploadView = () => (
  <>
    <p className="ant-upload-drag-icon">
      <img src={UploadIcon} alt="upload" />
    </p>
    <p className="ant-upload-text" style={{fontSize: 12, fontFamily: 'Work Sans'}}>Drag and drop your file here</p>
    <p className="ant-upload-hint" style={{fontSize: 12}}>or use "upload file" button</p>
    <Button className="upload-file-btn" style={{fontSize: 12}}>Upload file</Button>
  </>
);

export default DefaultUploadView;
