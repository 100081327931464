import { useAuth0 } from '@auth0/auth0-react';

export const ACCESS_TOKEN_KEY = 'access-token';
export const INCITU_USER_KEY = 'incitu_user';
export const SHOW_HINT_POPOVER = 'show_hint_popover';

export const SIGNUP_FOR_NEWSLETTER = 'signup_for_newsletter';
export const LAST_DISPLAY_MODAL_REPLACE_WARNING_TIME= 'last_display_modal_replace_warning_time';
const USER_ENTRY_POINT = 'user_entry_point';

export const ARCGIS_TOKEN = 'arcgis_token'

export const EA_CODE_KEY = 'eaCode';
export const authProvider = {
  isTokenExists: () => {
    const savedToken = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
    return savedToken != null;
  },
  saveToken: async (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    return Promise.resolve();
  },
  getToken: () => {
    const savedToken = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
    return savedToken
  },
  removeFromLocalStorage: async () => {
    await authProvider.removeToken();
    authProvider.removeInCituUser();
    authProvider.removeLastDisplayModalReplaceWarningTime();
    authProvider.removeArcGISToken();

    authProvider.clearLocalStorage();
    authProvider.clearSessionStorage();
    authProvider.removeShowHintPopover();
  },
  clearLocalStorage: () => {
    localStorage.clear();
  },
  clearSessionStorage: () => {
    sessionStorage.clear();
  },
  removeToken: () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    return Promise.resolve();
  },
  saveUserEntryPoint: (userEntryPoint: string) => {
    localStorage.setItem(USER_ENTRY_POINT, userEntryPoint);
  },
  removeUserEntryPoint: () => {
    localStorage.removeItem(USER_ENTRY_POINT);
  },
  getUserEntryPoint: () => {
    return localStorage.getItem(USER_ENTRY_POINT) || undefined;
  },

  saveSignUpForNewsLetter: (isSubscribed: string) => {
    localStorage.setItem(SIGNUP_FOR_NEWSLETTER, isSubscribed);
  },
  removeSignUpForNewsLetter: () => {
    localStorage.removeItem(SIGNUP_FOR_NEWSLETTER);
  },
  getSignUpForNewsLetter: () => {
    return localStorage.getItem(SIGNUP_FOR_NEWSLETTER) || undefined;
  },

  saveLastDisplayModalReplaceWarningTime: () => {
    localStorage.setItem(LAST_DISPLAY_MODAL_REPLACE_WARNING_TIME,  new Date().toISOString());
  },
  removeLastDisplayModalReplaceWarningTime: () => {
    localStorage.removeItem(LAST_DISPLAY_MODAL_REPLACE_WARNING_TIME);
  },
  getLastDisplayModalReplaceWarningTime: () => {
    return localStorage.getItem(LAST_DISPLAY_MODAL_REPLACE_WARNING_TIME) || undefined;
  },

  removeInCituUser: () => {
    localStorage.removeItem(INCITU_USER_KEY);
    authProvider.removeSignUpForNewsLetter();
    authProvider.removeUserEntryPoint();
  },
  saveInCituUser: (user: any) => {
    localStorage.setItem(INCITU_USER_KEY, JSON.stringify(user));
    return true;
  },
  getInCituUser: () => {
    const user = localStorage.getItem(INCITU_USER_KEY);
    if (!user || user === 'undefined') {
      return undefined;
    }
    return user ? JSON.parse(user) : undefined;
  },

  saveArcGISToken: (token: string) => {
    localStorage.setItem(ARCGIS_TOKEN, token);
  },
  removeArcGISToken: () => {
    localStorage.removeItem(ARCGIS_TOKEN);
  },
  getArcGISToken: () => {
    return localStorage.getItem(ARCGIS_TOKEN) || undefined;
  },

  saveEACode: (code: string) => {
    localStorage.setItem(EA_CODE_KEY, code);
    return true;
  },

  getEACode: () => {
    const savedCode = localStorage.getItem(EA_CODE_KEY) || null;
    return savedCode
  },
  removeEACode: () => {
    localStorage.removeItem(EA_CODE_KEY);
  },

  saveShowHintPopover: (isShowHintPopover: string) => {
    localStorage.setItem(SHOW_HINT_POPOVER, isShowHintPopover);
  },

  getShowHintPopover: () => {
    const showHintPopover = localStorage.getItem(SHOW_HINT_POPOVER) || null;
    return showHintPopover;
  },

  removeShowHintPopover: () => {
    localStorage.removeItem(SHOW_HINT_POPOVER);
  },

  // checkError: () => Promise.resolve(),
  // checkAuth: () => {
  //   const token = localStorage.getItem(TOKEN_KEY);
  //   if (token) {
  //     return Promise.resolve();
  //   }
  //
  //   return Promise.reject();
  // },
  // getPermissions: () => Promise.resolve(),
  // getUserIdentity: async () => {
  //   const token = localStorage.getItem(TOKEN_KEY);
  //   if (!token) {
  //     return Promise.reject();
  //   }
  //
  //   return Promise.resolve({
  //     id: 1,
  //     name: "James Sullivan",
  //     avatar: "https://i.pravatar.cc/150",
  //   });
  // },
};
