import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import {authProvider} from '../../authPrvider/authProvider'
import { useAuth0 } from '@auth0/auth0-react';
import performLogout from '../../hooks/auth0/useLogout';
import { Layout , Spin } from 'antd';
import completedVerificationLogo from '../../../src/assets/completed-icon.svg'
import { useNavigation } from '@pankod/refine-core';
import { LoadingOutlined } from '@ant-design/icons';
import verifyingLogo from '../../assets/verifying-logo.svg';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import { delay } from '../../utils/util';
import { useSearchParams } from 'react-router-dom';
import { useRegistrationProgress } from '../../hooks/useRegistrationProgressBar';
import { isUserHasActiveSubscription } from '../../utils/subscription';
import { refetchUser, processEntryPoint } from '../../utils/registration';

export const EmailVerificationConfirmation = () => {
  const { push } = useNavigation()
  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])
  const {
    user: auth0User,
    logout,

  } = useAuth0();
  const [searchParams] = useSearchParams();

  const {setProgress} = useRegistrationProgress()
  useLayoutEffect(() => {
    setProgress(55)
  })

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.EMAIL_VERIFIED, { email: auth0User?.email });
      hasEffectRun.current = true;
    }
  }, []);
  const [fetchUserInfo] = useUserInfoFetch();

  const redirectUser = async () => {
    if(searchParams.get('verified')) {
      // push('/email-verification-confirmation')
      return
    }

    const incituUser = await refetchUser(fetchUserInfo, auth0User);
    // TODO: onboarding step
    const entryPoint = incituUser?.metadata?.entryPoint
    if(entryPoint) {
      authProvider.removeUserEntryPoint()
      // TODO: onboarding step
      const isEmailVerified = true
      const redirectUrl = processEntryPoint(entryPoint, incituUser, auth0User, isEmailVerified)
      push(redirectUrl)
      return
    }

    if(!incituUser) {
      push('/landing')
    }
  }

  useEffect(() => {
    const checkUserRedirection = async () => {
      await delay(3000)
      await redirectUser()

    };

    checkUserRedirection();
  }, [auth0User]);

  const EmailConfirmationForm = ({ mainTitle, subTitle }: any) => {
    return (
      <Layout className="verification-layout-style">
        <div className="verification-layout-block">
          <div className="verification-layout-block-container">
            <div className="verification-logo-container">
              <img
                src={completedVerificationLogo}
                style={{ width: 80, height: 70 }}
              />
            </div>
            <Layout
              className="verification-layout-body-style"
              style={{ marginTop: '20px' }}
            >
              <div className="div-main-title">
                <div className="verification-main-title">{mainTitle}</div>
              </div>
              <div className="verification-sub-title">{subTitle}</div>
            </Layout>
          </div>
        </div>
      </Layout>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F0F0F0',
        flexShrink: 0,
      }}
    >
      <div>
        <EmailConfirmationForm
          mainTitle="Your email has been verified!"
          subTitle={searchParams.get('verified')
            ? 'You can close this tab' //"Navigate to sign up tab to continue creating your account."
            : '' //"Your account has been verified, you can close this tab"
          }
        />
      </div>

      <button
        className="logout-button"
        onClick={async () => {
          await performLogout(logout);
        }}
      >
        Sign out
      </button>
    </div>
  );

}
