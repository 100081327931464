import React from 'react';
import {Button} from '@pankod/refine-antd';
import {PlusOutlined} from '@ant-design/icons';

export default function TableWithButtonNewProject(props:any) {

  return (
    <>
      <div className="ant-table-wrapper">
        <div className="ant-spin-nested-loading">
          <div className="ant-spin-container">
            <div className="ant-table ant-table-empty">
              <div className="ant-table-container">
                <div className="ant-table-content">
                  <table>
                    <colgroup></colgroup>
                    <thead className="ant-table-thead">
                      <tr>
                        {props.columns.map((item: any, index: number)=> (
                          <th key={index} className="ant-table-cell">{item.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{
        width: '100%',
        border: '1px dashed #ff7875',
      }}>
        <Button
          style={{
            marginTop: 10,
            marginLeft: 10,
            marginBottom: 10,
          }}
          onClick={props.openProjectWizard}
          icon={<PlusOutlined className='new_project_icon'/>}
          type="primary"
          shape='round'
          className="new-project-btn"
        >
                    New Project
        </Button>
      </div>
    </>
  );
}
