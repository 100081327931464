import React from 'react';

function ErrorPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Oops! Something went wrong.</h1>
      <p>{'An unexpected error has occurred.'}</p>
    </div>
  );
}

export default ErrorPage;
