import { useState } from 'react';
import { AutoComplete, Button, Drawer, Input } from 'antd';
import ProjectDemoView from '../../project-demo';
import { CloseOutlined as CloseIcon } from '@ant-design/icons';

import './styles.less';

const ProjectDrawer = ({
  projectId,
  setSelectedProjectId,
  searchLocation,
  goTo,
  searchItems,
  locate,
}: {
  projectId: string,
  setSelectedProjectId: any,
  searchLocation: any,
  goTo: any,
  searchItems: any,
  locate: any,
}) => {
  const [isModelExpanded, setIsModelExpanded] = useState(false);

  const onClose = () => {
    setSelectedProjectId(null);
  }

  console.log('____DRawer____')
  return (
    projectId ? (
      <Drawer
        open={!!projectId}
        onClose={onClose}
        footer={[]}
        className={`project-view-drawer ${isModelExpanded ? 'expanded' : ''}`}
        placement='left'
      >
        <ProjectDemoView
          projectId={projectId}
          isModelExpanded={isModelExpanded}
          setIsModelExpanded={setIsModelExpanded}
          searchLocation={searchLocation}
          goTo={goTo}
          searchItems={searchItems}
          locate={locate}
        />
        {!isModelExpanded && (
          <Button
            shape='circle'
            type='default'
            className='close-btn'
            onClick={onClose}
            icon={<CloseIcon />}
          />
        )}
      </Drawer>
    ) : null);
};

export default ProjectDrawer;
