import { ModelFormat } from 'constants/model';
import { buildMockQuery, buildNewModelQueryWith3D, buildUpdateModelQueryWith3D } from '../query/model';
import { getFileExtension } from '../../../utils/util';

export async function buildModelWith3D(
  projectDetailsInformation: any,
  modelFileName: any,
  anchorCoordinate: any,
  order: number,
  buildingId = '',
  isNewModel?: boolean,
): Promise<any> {
  try {
    const projectBuilding = projectDetailsInformation?.buildings?.find((building: any) => building.id === buildingId);
    const previousAnchorCoordinate =
      projectBuilding?.model3ds
        ?.find((model: any) => model.order === order && model.type === ModelFormat.USDZ)?.anchoring?.anchorCoordinate;

    const buildings = isNewModel ? buildNewModelQueryWith3D(
      projectDetailsInformation.id,
      buildingId,
      modelFileName,
      anchorCoordinate,
      order,
    ) : buildUpdateModelQueryWith3D(
      projectDetailsInformation.id,
      buildingId,
      modelFileName,
      anchorCoordinate,
      previousAnchorCoordinate,
    );

    return buildings;
  } catch (e) {
    console.error(e)
    return []
  }
}

export function buildModel(projectDetailsInformation: any): any {
  const buildings = buildMockQuery()
  return buildings
}

export async function loadModel(projectDetailsInformation: any): Promise<any> {
  if (projectDetailsInformation.buildings.length > 0
    && projectDetailsInformation.buildings[0].model3ds
    && projectDetailsInformation.buildings[0].model3ds.length > 0) {
    try {
      const filteredModels =
        projectDetailsInformation.buildings[0].model3ds?.filter((e: any) => (e.type !== 'usdz'))

      if (filteredModels.length == 0) {
        return {}
      }
      const model = filteredModels[0]
      return {
        modelType: model.type,
      }
    } catch {
      return {}
    }
  }
}

export const checkUsdzModelExist = (projectDetailsInformation: any, modelsList: Array<any>): boolean =>
  modelsList.some((model: any) =>
    getFileExtension(model.file.name) === ModelFormat.USDZ
    || projectDetailsInformation?.buildings?.[0]?.model3ds?.some((model: any) => model.type === ModelFormat.USDZ),
  );

export const getAnchorCoordinate = (hasUsdzModel: any, modelsList: Array<any>) => {
  if (!hasUsdzModel) {
    return null;
  }
  const usdzModel = modelsList.find((model: any) => getFileExtension(model.file.name) === ModelFormat.USDZ);

  return {
    longitude: usdzModel?.anchorCoordinate?.longitude,
    latitude: usdzModel?.anchorCoordinate?.latitude,
    altitude: usdzModel?.anchorCoordinate?.altitude,
  };
};

export const filterUnsuportedFeatures = (features: Array<string>) => features.filter((feature) =>
  feature === 'KHR_mesh_quantization'
  || feature === 'KHR_draco_mesh_compression'
  || feature === 'KHR_texture_basisu',
);
