import { useCreate, useDelete } from '@pankod/refine-core';
import { useUploadModel } from './useUploadModel';
import { Building, Model3D } from '../dataProvider/gql/types';
import { createBuilding } from '../api/createBuilding';
import useSaveDefaultAnchoring from './useSaveDefaultAnchoring';
import { useProjectPublish } from './useProjectPublish';
import useSaveAnchoring from './useSaveAnchoring';

export const useSaveBuilding = () => {
  const [uploadModel] = useUploadModel();
  const [saveDefaultAnchoring] = useSaveDefaultAnchoring();
  const [saveAnchoring] = useSaveAnchoring();
  const { mutate: deleteBuilding } = useDelete<Building>();
  // const { publishProject, data: publishedProjectData, isLoading, error } = useProjectPublish();

  const saveBuilding = async (item: any, buildingsList: any, rotation?: number) => {
    console.log('----> saveBuilding data:', item);
    if (item.data) {
      if (buildingsList?.length > 0) {
        for (const building of buildingsList) {
          const buildingId = building.id;
          const modelsList = building.modelsList;
          console.log('----> saveBuilding:', building);
          const defaultAnchoringId = building.defaultAnchoring?.id;

          console.log('----> item data:', item);
          let modelUSDZAnchoringId // building.defaultAnchoring?.id;
          const usdzModel3ds = item.data?.buildings?.filter((model3d: Model3D) => model3d.type === 'usdz');

          if(usdzModel3ds?.length > 0) {
            modelUSDZAnchoringId = usdzModel3ds[0]?.anchoring?.id;
          }
          // let modelGLBAnchoringId
          // const glbModel3ds = building.model3ds.filter((model3d: Model3D) => model3d.type === 'glb');
          // if(glbModel3ds?.length > 0) {
          //   modelGLBAnchoringId = glbModel3ds[0]?.anchoring?.id;
          // }
          const coord = {
            longitude: item.data.longitude || 0,
            latitude: item.data.latitude || 0,
            altitude: item.data.altitude || 0,
          }
          console.log('----> coord:', coord);
          console.log('----> saveBuilding modelsList:', modelsList);
          if (buildingId) {
            await saveDefaultAnchoring({ buildingId, rotation, coord, defaultAnchoringId });
            if(modelUSDZAnchoringId) {
              await saveAnchoring({ buildingId, rotation, coord, anchoringId: modelUSDZAnchoringId });
            }
            await uploadModel(item, modelsList, buildingId);
          } else {
            try {
              const data: any = await createBuilding(item.data.id);
              console.log('----> createBuilding:', data);

              await saveDefaultAnchoring({ buildingId: data?.id, rotation, coord });
              console.log('----> saveDefaultAnchoring:');

              await uploadModel(item, modelsList, data?.id);
              console.log('----> uploadModel:');

            } catch (error) {
              console.error('Error for uploadModel and saveDefaultAnchoring occurred:', error);
            }
          }
        }
      }

      // Delete buildings
      const buildingsToDelete = buildingsList.length ? item.data?.buildings?.filter((building: any) => {
        const buildingExist = buildingsList.find((item: any) => item.id === building.id);
        return !buildingExist;
      }) : item.data.buildings;

      if(buildingsToDelete?.length > 0) {
        for (const building of buildingsToDelete) {
          await deleteBuilding({
            resource: 'buildings',
            id: building.id,
            metaData: {
              fields: ['id'],
            },
          });
        }
      }
    }
  }

  return [saveBuilding];
}
