import dataProvider from '../dataProvider/dataProvider';

export default function useCityInfoFetch() {

  const fetchCityInfo = async (cityName: any) => {

    const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`);
    try {
      const {
        data: cityInfo,
      }: any = await dProvider.getList<any>({
        resource: 'cities',
        pagination: { current: 1},
        filters: [{ field: 'displayName', operator: 'eq', value: cityName }],
        metaData: {
          fields: [
            'displayName',
            'centroid{latitude longitude}',
          ],
        },
      });

      return {data: cityInfo}
    } catch (error) {
      console.error('Error fetching project details:', error);
      return undefined;
    }
  }

  return [fetchCityInfo];
}
