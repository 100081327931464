function base64ToBlob(base64: string, mimeType: string) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
}

export async function previewUploader(projectId: string, base64Image: string): Promise<any> {
  try {
    const fetchResponse = await fetch(base64Image);
    const mimeType = 'image/png' // 'image/jpeg'

    // const blob = base64ToBlob(base64Image, mimeType);
    const blob = await fetchResponse.blob();
    console.log('____previewUploader blob:', blob)

    // Create a file-like object from Blob
    const file: any = new File([blob], 'preview.png', { type: mimeType});
    const formData = new FormData();
    formData.append('file', file);

    console.log('____previewUploader aws url:', `${process.env.REACT_APP_API_URL}/aws/multipart-preview-upload/${projectId}`)
    const res = await fetch(`${process.env.REACT_APP_API_URL}/aws/multipart-preview-upload/${projectId}`, {
      method: 'POST',
      body: formData,
    });
    console.log('____previewUploader res:', res)
    return res;
  } catch (error) {
    console.error('____previewUploader error:', error)
    console.error(error);
    return error;
  }
}
