import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Space, Typography, Form, Spin } from '@pankod/refine-antd';
import './style.less';
import ProjectCard from './components/ProjectCard';
import ProjectInfo from './components/ProjectInfo';
import { useNavigation, useInfiniteList, BaseRecord, HttpError, CrudFilter } from '@pankod/refine-core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseInfiniteListProps } from '@pankod/refine-core/dist/hooks/data/useInfiniteList';
import { authProvider } from '../../authPrvider/authProvider';
import {observer} from 'mobx-react-lite';
import {PlusOutlined} from '@ant-design/icons';

function ProjectGridListings (props: any) {

  const [search, setSearch] = useState('');
  const [projects, setProjects]:any = useState([]);

  const [width, setWidth] = useState(400)
  const [height, setHeight] = useState(300)
  const [changeSize, setChangeSize] = useState(false)

  const { searchFormProps, deleteProject, setProjectId, setIsModalOpen, userName } = props;

  const inCituUser = authProvider.getInCituUser();
  let userOrganizationId;

  if (inCituUser?.role !== 'ADMIN') {
    if(inCituUser?.organizationUsers.length > 0) {
      userOrganizationId = inCituUser.organizationUsers[0].organizationId;
    }
  }

  const filters: Array<CrudFilter | undefined> = [
    search ? { field: 'name', operator: 'contains', value: search } : undefined,
    userOrganizationId
      ? { field: 'organizationId', operator: 'eq', value: userOrganizationId }
      : undefined,
  ];
  const filteredFilters = filters.filter((filter): filter is CrudFilter => filter !== undefined);

  const listProps: UseInfiniteListProps<BaseRecord, HttpError> = {
    resource: 'projects',
    config: {
      hasPagination: false,
      filters: filteredFilters,
      sort: [{ field: 'updatedAt', order: 'desc' }],
    },
    metaData: {
      fields: [
        'id',
        'name',
        'subtitle',
        'origin',
        'description',
        'status',
        'createdAt',
        'updatedAt',
        'address',
        'longitude',
        'latitude',
        'altitude',
        'organizationId',
        // 'statusDetail',
        'borough',
        'ceqr',
        'bbl',
        // 'attributes',
        'appClipSync',
        'autoGenerateModels',
        // eslint-disable-next-line max-len
        'buildings{id buildingHeight model3ds{id type fileLocation externalId order anchoring{rotation anchorCoordinate{id latitude longitude altitude}}}}',
        'organization{id organizationUsers{user{id email firstName lastName }}}',
        'projectUsers { relation user { firstName lastName email }}',
        'documents{id url name type category}',
        'timelineEvents{id name timelineEventType{id name}}',
        'projectViewPreference{id visibility}',
      ],
    },
  };
  const { data, isError, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage }: any
    = useInfiniteList(listProps as UseInfiniteListProps<BaseRecord, HttpError>);

  useEffect(()=>{
    if(data){
      const projects = data?.pages.flatMap((page: any) => page.data) || [];
      setProjects(projects)
    }
  },[data])

  useEffect(()=>{
    const searchValue = searchFormProps.form.getFieldsValue().name;
    setSearch(searchValue);

  },[searchFormProps])

  const handleOpenModal = (projectId: string) => {
    setProjectId(projectId);
    setIsModalOpen(true);
  }
  
  return (
    <div id="scrollableDiv" className='scroll-infinite-container'>
      <InfiniteScroll
        dataLength={10}
        next={()=>fetchNextPage()}
        hasMore={hasNextPage}
        loader={isFetchingNextPage && <Spin />}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b></b>
          </p>
        }
        pullDownToRefresh={false}
      >
        <Row
          gutter={[24, 24]}
          justify="start"
          wrap={true}
          className="project-grid-row"
        >
          <div style={
            projects.length == 0 ? {border: '1px dashed #F98168', minWidth: 200, width: '30%', height: height, marginLeft: 15,marginRight: 10}:{display: 'none'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Button
                onClick={props.openProjectWizard}
                icon={<PlusOutlined className='new_project_icon' />}
                type="primary"
                shape='round'
                className="new-project-btn"
              >
                New Project
              </Button>
            </div>
          </div>
          {projects.map((_: any, index: number) => (
            <ProjectCard  className="project-grid-card-view" key={JSON.stringify(_)}>
              <ProjectInfo deleteProject={deleteProject} data={_} handleOpenProjectForm={handleOpenModal}
                userName={userName}
                setChangeSize={setChangeSize}
                changeSize={changeSize}
                setWidth={setWidth}
                setHeight={setHeight}
              />
            </ProjectCard>
          ))}
        </Row>
      </InfiniteScroll>
    </div>
  );
}
export default ProjectGridListings;
