import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, Input, Checkbox } from 'antd';
import { debounce } from 'lodash';

import { Context } from '../../../../../index';
import EsriMap from './EsriMap';
import { MixpanelEvent, mixPanelUtils } from '../../../../../pages/public-view/mixpanel/mixpanel';
import { findAddressCandidates } from '../../../../../api/address';
import useUploadPreview from '../../../../../hooks/useUpdatePreview';
import { getElevation } from '../../../../../api/elevation';
import HintPopover from '../../../../registration/HintPopover/HintPopover';
import { isExampleProjectByOrigin } from '../../../../../utils/util';
// import { CloseOutlined } from '@ant-design/icons';

const debounceGetElevation: any = debounce(getElevation, 500);

const ModelPlacementStepView = observer(({
  isEdit,
  models,
  newModelUrl,
  projectDetailsInformation,
  projectId,
  onModelReplace,
  // setCloseWarningPopup,
}: {
  isEdit: boolean;
  models: any;
  newModelUrl?: string;
  projectDetailsInformation?: any;
  projectId?: string;
  onModelReplace: () => void;
  // setCloseWarningPopup: ()=> void;
}) => {
  const [savePreviewFromMap] = useUploadPreview();
  const { projectCreationStore } = useContext(Context)

  const { takeScreenshot, onScreenshotTaken, projectOrigin } = projectCreationStore

  const [newRotation, setNewRotation] = useState<number>();
  const projectRotation = projectDetailsInformation?.buildings[0]?.defaultAnchoring?.rotation || 0;

  const handleScreenshotTaken = (dataUrl: string) => {
    if (dataUrl) {
      onScreenshotTaken(dataUrl, savePreviewFromMap);
    }
  }
  const {
    setLatitude,
    setLongitude,
    setAltitude,
    setRotation,
    latitude,
    longitude,
    altitude,
    rotation,
    setAddress,
    address,
    isSnapToGround,
    setIsSnapToGround,
    initLatitude,
    setInitLatitude,
    initLongitude,
    setInitLongitude,
    initAltitude,
    setInitAltitude,
    initRotation,
    setInitRotation,
  } = projectCreationStore

  const {
    longitude: projectLongitude,
    latitude: projectLatitude,
    altitude: projectAltitude,
  } = projectDetailsInformation?.buildings[0]?.defaultAnchoring?.anchorCoordinate || projectDetailsInformation || {};

  const [addressOptions, setAddressOptions] = useState<any>([]);

  const handleSetAddress = async (text: any) => {
    const suggestionData: any = JSON.parse(text)

    const addressData = await findAddressCandidates(suggestionData.text, suggestionData.magicKey)
    const addressAltitude = await getElevation(
      addressData?.location?.x,
      addressData?.location?.y,
    );
    const locationY = addressData?.location?.y ?? 0
    const locationX = addressData?.location?.x ?? 0
    if (projectId) {
      mixPanelUtils.track(
        MixpanelEvent.PROJECT_ADDRESS_CHANGED,
        {
          projectId,
          'latitude': String(locationY),
          'longitude': String(locationX),
        },
      );
    }
    setAddress(suggestionData?.text);

    // form.setFieldValue('altitude', String(addressData?.altitude?.y ?? 0));
    setAltitude(addressAltitude);
    setInitAltitude(addressAltitude);
    setLatitude(String(locationY));
    setInitLatitude(locationY);
    setLongitude(String(locationX));
    setInitLongitude(locationX);

    setSearchedLocation({
      latitude: addressData?.location?.y,
      longitude: addressData?.location?.x,
    });
  }

  const getAddressValues = async (address: any) => {
    const endpoint = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest';
    const params = new URLSearchParams({
      text: address,
      f: 'json',
    });

    try {
      const response = await fetch(`${endpoint}?${params.toString()}`);
      if (!response.ok) {
        console.error('Network response was not ok');
        return
      }
      const data = await response.json();
      setAddressOptions(
        data.suggestions.map((suggestion: any) => ({
          value: JSON.stringify(suggestion),
          label: suggestion.text,
        })),
      );
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  useEffect(() => {
    if (projectLongitude && typeof initLongitude === 'undefined') {
      setInitLongitude(projectLongitude);
    }
    if (projectLatitude && typeof initLatitude === 'undefined') {
      setInitLatitude(projectLatitude);
    }
    if (projectAltitude && typeof initAltitude === 'undefined') {
      setInitAltitude(projectAltitude);
    }
    if (projectRotation && typeof initRotation === 'undefined') {
      setInitRotation(projectRotation);
    }
  }, [
    projectLongitude,
    projectLatitude,
    projectAltitude,
    projectRotation,
    initLongitude,
    initLatitude,
    initAltitude,
    initRotation,
  ]);

  useEffect(() => {
    if (newRotation === undefined) return;

    const rotationDiff = (+rotation ? +rotation : 0) - newRotation;

    if (rotationDiff > 180) {
      setRotation(String(rotationDiff - 360));
    } else if (rotationDiff < -180) {
      setRotation(String(rotationDiff + 360));
    } else {
      setRotation(String(rotationDiff));
    }

    setNewRotation(undefined);
  }, [newRotation]);

  const [isModelReset, setIsModelReset] = useState(false);
  const [currentLatitude, setCurrentLatitude] = useState(initLatitude);
  const [currentLongitude, setCurrentLongitude] = useState(initLongitude);
  const [searchedLocation, setSearchedLocation] = useState<any>({});
  const [requestedCoordinates, setRequestedCoordinates] = useState<any>({});

  const handleResetModelPosition = () => {
    mixPanelUtils.track(MixpanelEvent.MODEL_RESET_POSITION, { projectId: projectId })
    setSearchedLocation({
      latitude: +latitude + 0.1,
      longitude: +longitude + 0.1,
      altitude,
    });

    setTimeout(() => {
      setLongitude(`${initLongitude}`);
      setAltitude(`${initAltitude}`);
      setRotation(`${initRotation}`);
      setLatitude(`${initLatitude}`);

      if (initLatitude && initLongitude) {
        setSearchedLocation({
          latitude: initLatitude,
          longitude: initLongitude,
          altitude,
        });
      }
    }, 100);
  }

  const handleAltitudeRequestChange = (longitude: number, latitude: number, altitude: number) => {
    setAltitude(String(altitude));
    setRequestedCoordinates({
      latitude: latitude,
      longitude: longitude,
      altitude: altitude,
    });
  }

  useEffect(() => {
    if (isSnapToGround && !(latitude === '0' && longitude === '0')) {
      debounceGetElevation(
        +longitude,
        +latitude,
        handleAltitudeRequestChange,
      )
    }
  }, [isSnapToGround, latitude, longitude]);

  const handleModelMove = (longitude: number, latitude: number) => {
    setLongitude(String(longitude));
    setCurrentLongitude(longitude);
    setLatitude(String(latitude));
    setCurrentLatitude(latitude);
  }

  const handleAltitudeChange = (altitude: number) => {
    setAltitude(String(altitude));
    setIsSnapToGround(false);
  }

  const handleRotationChange = (rotation: number) => {
    setNewRotation(rotation);
  }

  useEffect(() => {
    if (isModelReset) {
      setInitLatitude(currentLatitude);
      setInitLongitude(currentLongitude);
      setInitRotation(0);
      setInitAltitude(0);

      setLatitude(`${currentLatitude || 0}`);
      setLongitude(`${currentLongitude || 0}`);
      setAltitude('0');
      setRotation('0');

      setIsModelReset(false);
    }
  }, [isModelReset, currentLatitude, currentLongitude]);

  const handleSnapToGround = async () => {
    mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_MAP_SNAP_TO_GROUND, { projectId: projectId });

    const newSnapToGround = !isSnapToGround;
    setIsSnapToGround(newSnapToGround);

    if (newSnapToGround) {
      setRequestedCoordinates({
        latitude: latitude,
        longitude: longitude,
        altitude: altitude + 0.1,
      });

      const requestAltitude = await getElevation(
        +longitude,
        +latitude,
      );

      setRequestedCoordinates({
        latitude: latitude,
        longitude: longitude,
        altitude: requestAltitude,
      });
      setAltitude(String(requestAltitude));
    }
  }

  const popoverContent = useMemo(() => {
    return (
      <div>
        {
          isExampleProjectByOrigin(projectOrigin)
            ? <div>
              <h1 className="example-popup-popover-title">
                Place the model near you
              </h1>
              <div className="example-popup-popover-description">
                Change the address to see the model near you
              </div>
            </div>
            : <h1 className="project-popup-popover-title">
              Choose an address to place your model
            </h1>
        }
      </div>
    )
  }, [])

  return (
    <div className="map-container">
      <EsriMap
        isEdit={isEdit}
        newModelUrl={newModelUrl}
        rotation={+rotation}
        longitude={initLongitude}
        latitude={initLatitude}
        currentLongitude={currentLongitude}
        currentLatitude={currentLatitude}
        altitude={initAltitude}
        onModelReset={handleResetModelPosition}
        setIsModelReset={setIsModelReset}
        models={models}
        onAltitudeChange={handleAltitudeChange}
        onModelMove={handleModelMove}
        requestedCoordinates={requestedCoordinates}
        onRotationChange={handleRotationChange}
        searchedLocation={searchedLocation}
        isAddressSet={!!address}
        takeScreenshot={takeScreenshot}
        onScreenshotTaken={handleScreenshotTaken}
        onModelReplace={onModelReplace}
        projectId={projectId}
        // setCloseWarningPopup={setCloseWarningPopup}
      />
      <div className="address-search-container">
        <HintPopover
          content={popoverContent}
          style={{
            width: '345px',
          }}
          open={isExampleProjectByOrigin(projectOrigin)}
        >
          <AutoComplete
            placeholder="Search for address"
            data-testid="project_address"
            options={addressOptions}
            onSelect={handleSetAddress}
            value={address}
            className="address-auto"
            onSearch={(text) => text && getAddressValues(text)}
            onChange={(text) => {
              if (text.includes('{')) return;
              setAddress(text);
            }}
          />
        </HintPopover>
      </div>
      <div className="coordinates-container">
        <div className="main-label">Coordinates</div>
        <div className="inputs-container">
          <div className="input-container latitude">
            <div className="label">latitude</div>
            <Input
              className="input"
              value={latitude}
              onChange={(e) => {
                setSearchedLocation({
                  latitude: +e.target.value,
                  longitude: +longitude,
                });
                setLatitude(e.target.value);
              }}
            />
          </div>
          <div className="input-container longitude">
            <div className="label">longitude</div>
            <Input
              className="input"
              value={longitude}
              onChange={(e) => {
                setSearchedLocation({
                  latitude: +latitude,
                  longitude: +e.target.value,
                });
                setLongitude(e.target.value);
              }}
            />
          </div>
          <div className="altitude-container">
            <div
              className={`input-container altitude ${
                !isSnapToGround ? '' : 'inactive'
              }`}
            >
              <div className="snap-to-ground">
                <Checkbox
                  type="checkbox"
                  className="snap-to-ground-checkbox"
                  name="snap-to-ground"
                  checked={isSnapToGround}
                  onChange={handleSnapToGround}
                >
                  <span className="label">snap to ground</span>
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModelPlacementStepView;
