import { Modal, Spin } from '@pankod/refine-antd';
import { useRef, useState } from 'react';

export const useProgressModal = () => {
  const modalInstanceRef = useRef<any>(null);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState(false);

  const hideProgressModal = async () => {
    if (modalInstanceRef.current) {
      modalInstanceRef?.current?.destroy();
      modalInstanceRef.current = null;
      setIsProgressModalVisible(false);
    }
  };

  const showProgressModal = (title: string, details: string) => {
    const modal = Modal.info({
      title: title,
      content: (
        <div style={{ textAlign: 'center' }}>
          <span>{details}</span>
          <Spin size="large" tip="Processing..." />
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
      closable: false,
    });

    modalInstanceRef.current = modal;
    setIsProgressModalVisible(true);
  };

  return { showProgressModal, hideProgressModal, isProgressModalVisible };
};
