/* eslint-disable import/extensions */
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  AutoComplete,
} from '@pankod/refine-antd';

import { Validation } from './validation';
import EsriMap from '../../../../pages/public-view/components/esri-map';
import { ProjectVisibility } from '../../../../enums';
import { useEffect, useState } from 'react';
import AnchorCoordinateInput from './AnchorCoordinateInput';
import MapLocationSelectModal from './MapLocationSelectModal';

export default function ProjectInfo(props: any) {
  const {
    loading,
    longitude,
    latitude,
    altitude,
    updateAnchorCoordinate,
    modelAnchorCoordinate,
    isEdit,
    isNew,
    order,
    buildingIndex,
  } = props;
  const form = Form.useFormInstance();
  const [anchorCoordinate, setAnchorCoordinate] = useState<any>({});
  const [isAnchorEdited, setIsAnchorEdited] = useState<any>(false);
  const [mapSelectorIsOpen, setMapSelectorIsOpen] = useState<boolean>(false);
  
  // useEffect(() => {
  //   if (
  //     modelAnchorCoordinate
  //     && !anchorCoordinate.latitude
  //     && !anchorCoordinate.longitude
  //     && !anchorCoordinate.altitude
  //   ) {
  //     setAnchorCoordinate(modelAnchorCoordinate);

  //     form.setFieldValue(`anchorCoordinate[${order}][${buildingIndex}].latitude`, modelAnchorCoordinate?.latitude);
  //     form.setFieldValue(`anchorCoordinate[${order}][${buildingIndex}].longitude`, modelAnchorCoordinate?.longitude);
  //     form.setFieldValue(`anchorCoordinate[${order}][${buildingIndex}].altitude`, modelAnchorCoordinate?.altitude);
  //   }
  // }, [modelAnchorCoordinate]);

  const handleAnchorCoordinateChange = (e: any) => {
    const { name, value } = e.target;

    setAnchorCoordinate({
      ...anchorCoordinate,
      [name]: value,
    });

    updateAnchorCoordinate({
      ...anchorCoordinate,
      [name]: value,
    }, buildingIndex, order);

    setIsAnchorEdited(true);
  };

  const toggleMapSelector = () => {
    setMapSelectorIsOpen(!mapSelectorIsOpen)
  }

  const handleSelectedMapLocation = (data: any) => {
    form.setFieldValue('address', data?.address);
    form.setFieldValue('latitude', data?.latitude);
    form.setFieldValue('longitude', data?.longitude);
    form.setFieldValue('altitude', data?.altitude);
    toggleMapSelector();
  }

  return (
    <Col span={24} style={{ paddingLeft: '12px' }}>
      {/* <Typography.Title
        // style={{ paddingBottom: '1rem', paddingTop: '3rem' }}
        level={4}
      >
        Anchor coordinates
      </Typography.Title> */}
      {/* <Row gutter={[24, 24]}>
        <Col md={8} lg={8} xl={8} xxl={8} xs={24}>
          <Form.Item
            name={`anchorCoordinate[${order}][${buildingIndex}].latitude`}
            label="Latitude"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                validator: Validation.validAnchorLatitude,
              },
            ]}
          >
            <AnchorCoordinateInput
              name="latitude"
              handleAnchorCoordinateChange={handleAnchorCoordinateChange}
              loading={loading}
            />
          </Form.Item>
        </Col>
        <Col md={8} lg={8} xl={8} xxl={8} xs={24}>
          <Form.Item
            name={`anchorCoordinate[${order}][${buildingIndex}].longitude`}
            label="Longitude"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                validator: Validation.validAnchorLongitude,
              },
            ]}
          >
            <AnchorCoordinateInput
              name="longitude"
              handleAnchorCoordinateChange={handleAnchorCoordinateChange}
              loading={loading}
            />
          </Form.Item>
        </Col>
        <Col md={8} lg={8} xl={8} xxl={8} xs={24}>
          <Form.Item
            name={`anchorCoordinate[${order}][${buildingIndex}].altitude`}
            label="Altitude"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                validator: Validation.validAnchorAltitude,
              },
            ]}
          >
            <AnchorCoordinateInput
              name="altitude"
              value={anchorCoordinate?.altitude}
              handleAnchorCoordinateChange={handleAnchorCoordinateChange}
              loading={loading}
            />
          </Form.Item>
        </Col>
      </Row> */}
      <MapLocationSelectModal
        longitude={form.getFieldValue('longitude')}
        latitude={form.getFieldValue('latitude')}
        altitude={form.getFieldValue('altitude')}
        handleClose={toggleMapSelector}
        handleSelector={handleSelectedMapLocation}
        visible={mapSelectorIsOpen}
      />
    </Col>
  );
}
