import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Card, Row, message, Modal, Layout, Spin } from 'antd';

import ProjectHeader from './Header';
import ArcGISMapComponent from '../components/esri-search-map';
import ProjectDrawer from './ProjectDrawer';

import './style.less';
import ProjectDrawerMobile from './ProjectDrawerMobile';
import checkMobile from '../../../utils/checkMobile';
import closeIcon from '../../../assets/close-icon.svg';

import { authProvider } from '../../../authPrvider/authProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigation } from '@pankod/refine-core';
import { delay, isLinkUsPage } from '../../../utils/util';
import useUserInfoFetch from '../../../hooks/fetchUserInfo';
import { MixpanelEvent, mixPanelUtils } from '../mixpanel/mixpanel';
import { useUpdateUserMetadata } from '../../../api/user';
import { checkSubscription, handleEntryPoint, updateEntryPoints } from '../../../utils/registration';
import { isUserHasActiveSubscription } from '../../../utils/subscription';
import { LoadingOutlined } from '@ant-design/icons';
import { useRegistrationProgress } from '../../../hooks/useRegistrationProgressBar';
import performLogout from 'hooks/auth0/useLogout';
import { handleToken } from "../../../utils/tokenHandler";

interface MapComponentProps {
  projectId?: boolean;
  projectData?: any;
}
const PublicViewComponent = ({ projectId, projectData }: MapComponentProps) => {
  const { push } = useNavigation();
  const [isLoadedAuth0UserData, setIsLoadedAuth0UserData] = useState(false)
  const [isUserInitializationProcess, setIsUserInitializationProcess] = useState(false)

  // const {setProgress} = useRegistrationProgress()
  // useLayoutEffect(() => {
  //   setProgress(isUserInitializationProcess ? 55 : 0)
  // },[isUserInitializationProcess])


  window.addEventListener('message', function (event) {
    if (event.data.type === 'signupForNewsletterSubscription') {
      console.log('Map signupForNewsletterSubscription_map:', event.data);
      const isSubscribed = event.data.subscribed;
      authProvider.saveSignUpForNewsLetter(isSubscribed);
      console.log('User is subscribed to the newsletter:', isSubscribed);

      // todo save entry_point
      console.log('window.location:', window.location);
      console.log('window.location.href:', window.location.href);
      authProvider.saveUserEntryPoint(window.location.href);
    }
  });

  // Pay attention, here is mobile definition for /map/<projectId>
  const { isMobile } = checkMobile();
  const [selectedProjectId, setSelectedProjectId]: any = useState(projectId || null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUserFetched, setIsUserFetched] = useState(false);
  const { updateUserMetadata, isLoading, error, data } = useUpdateUserMetadata()
  const [fetchUserInfo] = useUserInfoFetch();
  const isLinkUs = location.pathname.includes('/map/linkus');

  const {
    logout,
    user: auth0User,
    getAccessTokenSilently,
  } = useAuth0();

  // TODO: force refresh token
  // useEffect(() => {
  //   handleToken(getAccessTokenSilently);
  // }, []);

  const handleUserRedirect = async (userAuth0: any) => {
    if (!userAuth0 || !userAuth0.sub) {
      push('/email-verification');
      return;
    }

    const fetchedUser: any = await fetchUserInfo(userAuth0.sub);
    authProvider.saveInCituUser(fetchedUser.data);
    const incituUser = authProvider.getInCituUser();
    const entryPoint = incituUser?.metadata?.entryPoint || authProvider.getUserEntryPoint();

    if (!entryPoint) {
      console.log('get from local db entryPoint:', entryPoint);
    }

    // TODO: check onboarding step

    const redirectURL = handleEntryPoint(userAuth0, incituUser, entryPoint);
    console.log('redirectURL:', redirectURL);
    if(redirectURL !== undefined) {
      push(redirectURL);
    }
  };

  useEffect(() => {
    const checkEmailVerification = async () => {
      console.log('Rechecking authentication...userAuth0:', auth0User);
      console.log('Rechecking authentication...authProvider.getInCituUser():', authProvider.getInCituUser());
      if (auth0User !== undefined && !isLoadedAuth0UserData && authProvider.getInCituUser() == undefined){
        // setIsLoadingUser(true);
        setIsUserInitializationProcess(true);
        await delay(4000)
        // TODO: onboarding step
        await checkSubscription(fetchUserInfo, updateUserMetadata, auth0User)
        await updateEntryPoints(fetchUserInfo, updateUserMetadata, String(auth0User.sub))

        await handleUserRedirect(auth0User);
        setIsLoadedAuth0UserData(true);

        setIsUserInitializationProcess(false);
        if (!isUserHasActiveSubscription(authProvider.getInCituUser())) {
          setIsPopupVisible(true);
        }

      } else {
        if (!isUserHasActiveSubscription(authProvider.getInCituUser())) {
          setIsPopupVisible(true);
        }
      }
    };

    checkEmailVerification();
  }, [auth0User]);

  const hideCTAFloatingDiv = () => {
    setIsPopupVisible(false);
  };

  message.config({
    top: 55,
  });

  const redirectToPro = () => {
    if (auth0User || authProvider.getInCituUser()) {
      push('/pricing')
    } else {
      push('/landing')
    }
  };

  return ( isUserInitializationProcess ? (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#F0F0F0',
      flexShrink: 0,
    }}>
      <div>
        <Layout className="acc-loading-layout-style">
          <div className="acc-loading-layout-block">
            <div
              className="acc-loading-logo-container"
              style={{ width: '100%', height: '80px', position: 'relative' }}
            >
              <Spin
                style={{
                  position: 'absolute',
                  color: '#F98168',
                  opacity: 0.7,
                  fontSize: 10,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                indicator={
                  <LoadingOutlined style={{ fontSize: 75 }} spin={true} />
                }
              />
            </div>
            <Layout className="acc-loading-layout-body-style">
              <div className="div-main-title">
                <div className="acc-loading-main-title">
                Loading your account
                </div>
              </div>
            </Layout>
          </div>
        </Layout>
        <button
          className="logout-button"
          onClick={async () => {
            await performLogout(logout);
          }}
        >
        Sign out
        </button>
      </div>
    </div>
  ) : (
    <Row align='middle' justify='center' className='container-row p-0 m-0'>
      <Card className='map-container' title={<ProjectHeader />}>
        <ArcGISMapComponent
          onProjectClick={(id) => setSelectedProjectId(id)}
          isMobile={isMobile}
          projectId={selectedProjectId}
          projectData={projectData}
          setSelectedProjectId={setSelectedProjectId}
        />
        {isPopupVisible && !isLinkUsPage() && (
          <div className="cta-floating-div" onClick={redirectToPro}>
            <button className="close-button" onClick={(e) => {
              e.stopPropagation();
              hideCTAFloatingDiv();
            }}>
              <img src={closeIcon} alt="Close" />
            </button>
            <div className="cta-content">
              <p>Try inCitu Pro for free today!</p>
            </div>
          </div>
        )}
      </Card>
    </Row>)
  );
}

function ViewMap({ projectId, projectData }: MapComponentProps) {

  return (
    <PublicViewComponent
      projectId={projectId}
      projectData={projectData} />
  )
}

export default function PublicMapView({ projectId, projectData }: MapComponentProps) {
  return <ViewMap projectId={projectId} projectData={projectData} />
}
