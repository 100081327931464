import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import { Button } from '@pankod/refine-antd';
import { CONTACT_US, DOCUMENTATION_PATH } from '../../../../../../constants/links';

interface ErrorUploadViewProps {
  errorMessage?: string;
}

const ErrorUploadView: React.FC<ErrorUploadViewProps> = ({ errorMessage = 'unsupported file type.'}) => {
  return (
    <div className="upload-mini-dialog-container">
      <div
        className="upload-mini-dialog"
        style={{
          backgroundColor: 'white',
          // border: "1px solid grey",
          borderRadius: '5px',
          padding: '1rem 1.5rem',
        }}
      >
        <p className="ant-upload-drag-icon">
          <WarningFilled style={{ color: 'red' }} />
        </p>
        <p className="upload-text">
          <span className="upload-error">Error: </span>
          {errorMessage.includes('25mb')
            ? 'The model you are trying to upload is too large.'
            : 'Model upload failed' }
            {/*  'You are trying to upload an'*/}
        </p>
        <span style={{ textDecoration: 'underline' }}>
          {errorMessage.includes('25mb') ? (
            <a
              className="support-link"
              onClick={() => window.open(CONTACT_US, '_blank')}
              rel="noopener noreferrer"
            >
                Contact Support
            </a>
          ) : <a
            className="support-link"
            onClick={(e) => {
              e.stopPropagation();
              window.open(DOCUMENTATION_PATH, '_blank');
            }
            } rel="noopener noreferrer">
              Learn More
          </a>}
        </span>
        <p
          className={`${
            errorMessage.includes('25mb') ? 'upload-text' : 'ant-upload-hint'
          } upload-error-message`}
        >
          {errorMessage}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '12px',
          }}
        >

          <Button className="upload-file-btn">Re-Upload Model</Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorUploadView;
