import React, { useContext, useEffect, useRef, useState } from 'react';
import { message } from '@pankod/refine-antd';
import './style.less';
import useProjectSave from '../../hooks/useProjectSave';
import { useNavigation } from '@pankod/refine-core';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import ProjectCreationStepModal from './components/ProjectCreationStepModal';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { PublishingStatus } from '../../store/projectCreationStore';
import useProjectInfoFetch from '../../hooks/useProjectInfoFetch';

const ProjectCreationStepComposer = observer(({
  visible,
  onCancel,
  showCloseWarning, closeModalPopup,
  setShowCloseWarning,
  projectId,
  goToStripeCustomerPanel,
  setShowPublishedRecommendations,
}: any) => {
  const [loading, setLoading] = useState(false);

  const { projectCreationStore } = useContext(Context);
  const { projectId: projectStoreId,
    setEditMode,
    publishingStatus,
    // setValueForObgForCheckChanges
  } =  projectCreationStore

  const [ fetchProjectInfo] = useProjectInfoFetch();

  const [projectInfo, setProjectInfo] = useState(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      console.log('fetchProjectDetails initial:', projectStoreId);
      const data = await fetchProjectInfo({ id: projectStoreId });
      setProjectInfo(data);
      // setValueForObgForCheckChanges(data)
    };

    fetchProjectDetails().then(r => {});
  }, []);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (publishingStatus === PublishingStatus.Success && projectStoreId) {
        console.log('fetchProjectDetails on success:');
        const data = await fetchProjectInfo({ id: projectStoreId });
        setProjectInfo(data);
      }
    };

    fetchProjectDetails().then(r => {});
  }, [projectStoreId, publishingStatus]);

  message.config({
    top: 55,
  });

  const [saveData, projectSaveStatus, projectSaveError] = useProjectSave(
    projectStoreId,
    projectInfo,
  );

  const { push } = useNavigation();

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(
        projectId
          ? MixpanelEvent.PROJECT_EDIT_VISIT
          : MixpanelEvent.PROJECT_CREATE_VISIT,
        { projectId },
      );
      hasEffectRun.current = true;
    }
  }, []);

  return (
    <ProjectCreationStepModal
      projectDetailsInformation={projectInfo}
      projectLoading={loading}
      projectSaveStatus={projectSaveStatus}
      projectSaveError={projectSaveError}
      saveInfo={saveData}
      visible={visible}
      setShowCloseWarning={setShowCloseWarning}
      closeModalPopup={closeModalPopup}
      goToStripeCustomerPanel={goToStripeCustomerPanel}
      setShowPublishedRecommendations={setShowPublishedRecommendations}
    />
  );
});

export default ProjectCreationStepComposer;
