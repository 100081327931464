/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore

import {
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider,
  LogicalFilter,
} from '@pankod/refine-core';
import { GraphQLClient } from 'graphql-request';
import * as gql from 'gql-query-builder';
import pluralize from 'pluralize';
// eslint-disable-next-line no-restricted-imports
import { camelCase, isEmpty, mapValues, upperFirst } from 'lodash';
import { ASSOCIATION_RESOURCES } from 'constants/resources/resources';
import { authProvider } from '../authPrvider/authProvider';
import performLoginSilently from '../hooks/auth0/useLoginSilently';

export const customRequest =
  async (client: GraphQLClient, query: string, variables?: any, getAccessTokenSilently?: () =>
    void, logout?: (options?: any) => void) => {

    try {
      let token = authProvider.getToken();
      if (token) {
        if(getAccessTokenSilently) {
          token = await performLoginSilently(getAccessTokenSilently, logout);
        }
        client.setHeader('Authorization', `Bearer ${token}`);
      }

      const response = await client.request(query, variables);
      return response;
    } catch (error: any) {
      console.log('___error___', error);
      if (error.response && error.response.errors) {
        const errors = error.response.errors;
        for (const err of errors) {
          if (err.message?.includes('jwt expired')) {
            console.log('___error_ JWT:', err);
          }
        }
      }
      throw error;
    }
  };

const dataProvider = (url: string, getAccessTokenSilently?: (options?: any) =>
  void, logout?: (options?: any) => void): Required<DataProvider> => {

  const client = new GraphQLClient(url, {});
  const token = authProvider.getToken();

  if (token) {
    client.setHeader('Authorization', `Bearer ${token}`);
  }

  return {
    getList: async ({
      resource,
      hasPagination = true,
      pagination = {
        current: 1,
        pageSize: 10,
      },
      sort,
      filters,
      metaData,
    }) => {
      const { current = 1, pageSize = 10 } = pagination ?? {};

      const singularResource = pluralize.singular(resource);
      const camelResource = camelCase(resource);
      const whereInputType = pascalCase(`${singularResource}-where-input`);

      const orderBy = generateSort(singularResource, sort);
      const filterBy = generateFilter(filters);

      const operation = metaData?.operation ?? camelResource;

      const { query, variables } = gql.query({
        operation,
        variables: {
          ...metaData?.variables,
          ...(orderBy && { orderBy }),
          where: {
            name: 'where',
            value: filterBy,
            type: whereInputType,
            comparisonOption: 'insensitive',
          },
          ...(hasPagination
            ? {
              skip: (current - 1) * pageSize,
              take: pageSize,
            }
            : {}),
        },
        fields: metaData?.fields,
      });

      console.log('______',  variables)
      if(variables.where.name) {
        variables.where.name.mode = 'insensitive';
      }
      const response = await customRequest(client, query, variables, getAccessTokenSilently, logout);

      return {
        // @ts-ignore
        data: response[operation],
        // @ts-ignore
        total: response[operation]?.length,
      };
    },

    getMany: async ({ resource, ids, metaData }) => {
      // eslint-disable-next-line no-param-reassign
      ids = ids.filter((id) => !!id);
      if (isEmpty(ids)) {
        return {
          data: [],
        };
      }

      const singularResource = pluralize.singular(resource);
      const camelResource = camelCase(resource);
      const whereInputType = pascalCase(`${singularResource}-where-input`);

      const operation = metaData?.operation ?? camelResource;

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            name: 'where',
            value: { id: { in: ids } },
            type: whereInputType,
          },
        },
        fields: metaData?.fields,
      });

      const response = await customRequest(client, query, variables, getAccessTokenSilently, logout);

      return {
        // @ts-ignore
        data: response[operation],
      };
    },

    create: async ({ resource, variables, metaData }) => {
      const { operation, response } = await createImpl(
        resource,
        metaData,
        variables,
        client,
      );

      return {
        // @ts-ignore
        data: response[operation],
      };
    },

    createMany: async ({ resource, variables, metaData }) => {
      const response = await Promise.all(
        variables.map(async (param) => {
          return createImpl(resource, metaData, param, client);
        }),
      );
      return {
        data: response.map((r) => {
          const operation = r.operation;
          return {
            // @ts-ignore
            ...r.response[operation],
          };
        }),
      };
    },

    update: async ({ resource, id, variables, metaData }) => {
      const { operation, response } = await updateImpl(
        resource,
        metaData,
        id,
        variables as Record<string, unknown>,
        client,
      );

      return {
        // @ts-ignore
        data: response[operation],
      };
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          return updateImpl(
            resource,
            metaData,
            id,
            variables as Record<string, unknown>,
            client,
          );
        }),
      );
      return {
        data: response.map((r) => {
          const operation = r.operation;
          return {
            // @ts-ignore
            ...r.response[operation],
          };
        }),
      };
    },

    getOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelResource = camelCase(singularResource);
      const operation = metaData?.operation ?? camelResource;
      const whereInputType = pascalCase(
        `${singularResource}-where-unique-input`,
      );

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            type: whereInputType,
            name: 'where',
            required: true,
            value: {
              id,
            },
          },
        },
        fields: metaData?.fields,
      });

      const response = await customRequest(client, query, variables, getAccessTokenSilently, logout);

      return {
        // @ts-ignore
        data: response[operation],
      };
    },

    deleteOne: async ({ resource, id, metaData }) => {
      const singularResource = ASSOCIATION_RESOURCES.includes(resource)
        ? resource
        : pluralize.singular(resource);
      const camelResource = camelCase(singularResource);
      const camelOperationName = camelCase(`remove-${singularResource}`);
      const operation = metaData?.operation ?? camelOperationName;
      const whereInputType = pascalCase(
        `${singularResource}-where-unique-input`,
      );

      const { query, variables } = gql.mutation({
        operation: camelOperationName,
        variables: {
          where: {
            type: whereInputType,
            name: 'where',
            required: true,
            value: {
              id,
            },
          },
        },
        fields: metaData?.fields,
      });

      const response = await customRequest(client, query, variables, getAccessTokenSilently, logout);

      return {
        // @ts-ignore
        data: response[operation],
      };
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const singularResource = ASSOCIATION_RESOURCES.includes(resource)
        ? resource
        : pluralize.singular(resource);
      const camelDeleteName = camelCase(`remove-${singularResource}`);

      const operation = metaData?.operation ?? camelDeleteName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id } },
                type: `${camelDeleteName}Input`,
              },
            },
            fields: metaData?.fields ?? [
              {
                operation: singularResource,
                fields: ['id'],
                variables: {},
              },
            ],
          });
          const result = await customRequest(client, query, gqlVariables, getAccessTokenSilently, logout);
          // @ts-ignore
          return result[operation][singularResource];
        }),
      );
      return {
        // @ts-ignore
        data: response,
      };
    },

    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.');
    },

    custom: async ({ url, method, headers, metaData }) => {
      let gqlClient = client;

      if (url) {
        gqlClient = new GraphQLClient(url, { headers });
      }

      if (metaData) {
        if (metaData.operation) {
          if (method === 'get') {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await customRequest(gqlClient, query, variables, getAccessTokenSilently, logout);

            return {
              // @ts-ignore
              data: response[metaData.operation],
            };
          }
          const { query, variables } = gql.mutation({
            operation: metaData.operation,
            fields: metaData.fields,
            variables: metaData.variables,
          });

          const response = await customRequest(gqlClient, query, variables, getAccessTokenSilently, logout);

          return {
            // @ts-ignore
            data: response[metaData.operation],
          };
        }
        throw Error('GraphQL operation name required.');
      } else {
        throw Error(
          'GraphQL need to operation, fields and variables values in metaData object.',
        );
      }
    },
  };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
async function updateImpl<TVariables extends Record<string, unknown>>(
  resource: string,
  metaData: any,
  id: string | number,
  variables: TVariables,
  client: GraphQLClient,
) {
  const singularResource = ASSOCIATION_RESOURCES.includes(resource)
    ? resource
    : pluralize.singular(resource);
  const camelCreateName = camelCase(`update-${singularResource}`);

  const operation = metaData?.operation ?? camelCreateName;
  const inputName = `${camelCreateName}Input`;
  const inputType = pascalCase(`${singularResource}-update-input`);
  const whereInputType = pascalCase(`${singularResource}-where-unique-input`);

  const { query, variables: gqlVariables } = gql.mutation({
    operation,
    variables: {
      where: {
        type: whereInputType,
        name: 'where',
        required: true,
        value: {
          id,
        },
      },
      [inputName]: {
        value: mapValues(variables, (v) =>
          typeof v == 'string' ? { set: v } : v,
        ),
        type: inputType,
        required: true,
      },
    },
    fields: metaData?.fields ?? [
      {
        operation: singularResource,
        fields: ['id'],
        variables: {},
      },
    ],
  });
  const response = await client.request(query, gqlVariables);
  // @ts-ignore
  return {
    operation,
    response,
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
async function createImpl<TVariables>(
  resource: string,
  metaData: any,
  variables: TVariables,
  client: GraphQLClient,
) {
  const singularResource = ASSOCIATION_RESOURCES.includes(resource)
    ? resource
    : pluralize.singular(resource);
  const camelCreateName = camelCase(`create-${singularResource}`);

  const operation = metaData?.operation ?? camelCreateName;
  const inputName = `${camelCreateName}Input`;
  const inputType = pascalCase(`${singularResource}-create-input`);

  const { query, variables: gqlVariables } = gql.mutation({
    operation,
    variables: {
      [inputName]: {
        value: { ...variables },
        type: `${inputType}!`,
      },
    },
    fields: metaData?.fields ?? ['id'],
  });
  const response = await client.request(query, gqlVariables);
  // @ts-ignore
  return {
    operation,
    response,
  };
}

export function generateSort(singularResource: string, sorting?: CrudSorting) {
  const orderInputType = pascalCase(
    `${singularResource}-order-by-with-relation-input`,
  );
  if (sorting && sorting?.length > 0) {
    const sortQuery = sorting.map((crudSort) => {
      // @ts-ignore
      return { [crudSort.field]: crudSort.order };
    });
    const orderInput = {
      name: 'orderBy',
      value: sortQuery,
      type: `[${orderInputType}!]`,
    };
    // @ts-ignore
    return orderInput;
  }

  return undefined;
}

export function generateFilter(filters?: CrudFilters) {
  const queryFilters: { [key: string]: any } = {};
  if (filters) {
    filters.map((filter) => {
      if (
        filter.operator !== 'or' &&
        filter.operator !== 'and' &&
        'field' in filter
      ) {
        const { field, operator, value } = filter;

        queryFilters[`${field}`] = { [convertOperator(operator)]: value };
      } else {
        const value = filter.value as Array<LogicalFilter>;

        const orFilters: Array<any> = [];
        value.map((val) => {
          orFilters.push({
            [`${val.field}_${val.operator}`]: val.value,
          });
        });

        // eslint-disable-next-line no-underscore-dangle
        queryFilters._or = orFilters;
      }
    });
  }

  return queryFilters;
}

function convertOperator(operator: Exclude<CrudOperators, 'or' | 'and'>) {
  switch (operator) {
    case 'eq':
      return 'equals';
    case 'lt':
      return 'lt';
    case 'gt':
      return 'gt';
    case 'lte':
      return 'lte';
    case 'gte':
      return 'gte';
    case 'in':
      return 'in';
    case 'nin':
      return 'notIn';
    case 'contains':
      return 'contains';
    case 'startswith':
      return 'startswith';
    case 'endswith':
      return 'endsWith';
    // case 'ne':
    //   break; // TODO: implement
    default:
      throw Error('Unknown operator');
  }
}

function pascalCase(string?: string) {
  return upperFirst(camelCase(string));
}

export default dataProvider;
