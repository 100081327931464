/* eslint-disable max-len */

import { useContext, useState } from 'react';
import './style.less';
import { Input, Form } from '@pankod/refine-antd';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import { MixpanelEvent, mixPanelUtils } from '../../../../public-view/mixpanel/mixpanel';
const { TextArea } = Input;

const GeneralView = observer(() => {

  const { projectCreationStore } = useContext(Context)

  const {
    projectId,
    projectName,
    changeProjectName,
    projectDescription,
    changeProjectDescription, showErrorMessage } = projectCreationStore

  const [validation, setValidation] = useState(false)
  // const hasEffectRun = useRef(false);
  // useEffect(() => {
  //   if (!hasEffectRun.current) {
  //     mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_GENERAL_VISIT, { projectId: projectId });
  //     hasEffectRun.current = true;
  //   }
  // }, []);

  const changeProjectInputValue = (value: string) => {
    if (!validateString(value)) {
      setValidation(false)
      changeProjectName(value)
    } else {
      setValidation(true)
    }
  }

  function validateString(input: string) {
    const forbiddenChars = /[<>:"/\\|?*']/;
    return forbiddenChars.test(input);
  }

  return (
    <>
      <div >
        <div className='description-text'>
          Project Name *
        </div>
        <div className='character-text'>
          Character limit 30
        </div>
        {validation ?
          <div className='validation-warning-text'>
            The following characters are not supported: ${'<>:"/\\\\|?*\''}
          </div> : null}

        {showErrorMessage ?
          <div className='validation-warning-text'>
            Please, enter Project Name (required)
          </div> : null}

        <Input style={{ fontSize: 14 }} placeholder="New project (required)"
          className="project-name-input"
          value={projectName}
          onChange={(event) => changeProjectInputValue(event.target.value)}
          maxLength={30}
          showCount={true}
        />

        <div className='description-text'>
          Description
        </div>
        <TextArea
          className="description-text-area"
          value={projectDescription}
          showCount={true}
          // maxLength={1000}
          onChange={(event) => changeProjectDescription(event.target.value)}
          placeholder="A short project description explaining your project (optional)"
          style={{ height: 120, resize: 'none' }}
        />
      </div>
    </>
  );
});

export default GeneralView;
