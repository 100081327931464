
export async function sendArcGISConvertRequest(
  itemId: string,
  incituOrgId: string,
  arcgisToken: string,
  userId: string,
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PUBLISHER_SERVICE_URL}/arcgisconvert`;

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'item_id': itemId,
        'incitu_org_id': incituOrgId,
        'arcgis_token': arcgisToken,
        'user_id': userId,
      }),
    });

    console.log('Successfully sent sendArcGISConvertRequest:', res)
    return res.json();
  } catch (error) {
    console.log(error);
    return error;
  }
}
