import {useMutation} from '@pankod/refine-core'
import {gql, GraphQLClient, Variables} from 'graphql-request'
import {authProvider} from '../authPrvider/authProvider'

interface UserUpdatePayload {
    firstName?: {set: string}
    lastName?: {set: string}
    role?: {set: string}
}
const endpoint = `${process.env.REACT_APP_API_URL}/graphql`

const mutationFunction = async (variables: Variables | undefined) => {

  const token = authProvider.getToken()
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  const mutation = gql`
  mutation updateUser($where: UserWhereUniqueInput!, $updateUserInput: UserUpdateInput!) {
    updateUser(where: $where, updateUserInput: $updateUserInput) {
      id
      externalId
      firstName
      lastName
      email
      role
      description
      subscriptionPlan { id type active planDetails modelUploads } 
      organizationUsers { id organizationId }
      metadata
    }
  }
`;

  return graphQLClient.request(mutation, variables)
}

export const useUpdateUser = () => {

  const {
    mutate,
    isLoading,
    error,
    data,
  } = useMutation(mutationFunction)

  const updateUser = async (userID: string, payload: UserUpdatePayload) => {
    return mutate({where: {externalId: userID}, updateUserInput: payload});
  }

  return { updateUser: updateUser, isLoading, error, data }
}

export const useUpdateUserMetadata = () => {
  const {
    mutate,
    isLoading,
    error,
    data,
  } = useMutation(mutationFunction);

  const updateUserMetadata = async (userID: string, metadata: any) => {
    const payload = {
      metadata: metadata,
    };
    return mutate({ where: { externalId: userID }, updateUserInput: payload });
  };

  return { updateUserMetadata: updateUserMetadata, isLoading, error, data };
};

const GET_USER_BY_EXTERNAL_ID = gql`
  query GetUserByExternalId($externalId: String!) {
    user(where: { externalId: $externalId }) {
      id
      externalId
      firstName
      lastName
      email
      role
      description
      subscriptionPlan {
        id
        type
        active
        planDetails
        modelUploads
      }
      organizationUsers {
        id
        organizationId
      }
      metadata
      createdAt
    }
  }
`;

export const fetchUsersByExternalId = async (externalId: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/graphql`;
  const token = authProvider.getToken(); // Assuming you have a way to get the auth token
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  try {
    const data: any = await graphQLClient.request(GET_USER_BY_EXTERNAL_ID, { externalId });

    console.log('fetchUserInfo: ', data);
    return { data: data?.user };
  } catch (error) {
    console.error('Error fetching user details:', error);
    return undefined;
  }
};
