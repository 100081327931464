import documentIcon from '../../../../assets/document_icon.svg';
import {Col, Row, Typography} from '@pankod/refine-antd';
import React,{useState} from 'react';
const { Title, Paragraph,Text } = Typography;

import './styles.less';
import DocumentViewer from '../documentViewer';

const SourceComponent = ({ list }: { list?: any }) => {
  return <div>
    <img className='document-icon' src={documentIcon} alt='*' />
    <span className='p-info-title'>Documents</span>
    {/* {list[0]?.value?.map((item: any, index: number) => (
      <a target='_blank' href={item.url}>{`${item.name.toUpperCase()}`}{ index < list[0]?.value?.length - 1
        ? ', '
        : ''}
      </a>
    ))} */}
  </div>
}

const DataSourceComponent = ({ list }: { list?: any }) => {
  return <div>
    <span className='document-sources'>Document Source: </span>
    { list[0]?.value?.map((item: any, index: number) => (
      <a
        className='document-sources'
        target='_blank'
        href={item.url}>
        {`${item.name.toUpperCase()}`}
        { index < list[0]?.value?.length - 1
          ? ', '
          : ''
        }
      </a>
    ))}
  </div>
}
export const DocumentsView = ({ list }: { list?: any }) => {
  const [activeDocument,setActiveDocument] = useState(null);
  const handleDocumentClick = (e:any,item:any) => {
    e.preventDefault();
    setActiveDocument(item);
  }

  const listItems = {
    'datasource': 0,
    'documents': 1,
  }
  return (<div>
    <Paragraph className={'project-info-p'}>
      { list?.length > 1 && list?.[listItems.documents]?.value?.length > 0 &&
        <SourceComponent list={list}/>
      }
      <Row className='files-list' gutter={[24,24]}>
        {list?.[1]?.value?.map((item: any) => (
          <Col xl={5} xxl={5} lg={5} md={8} sm={8} xs={8}>
            <div className={`document-item ${item.name.length > 20 ? 'document-item-smaller' : ''}`}>
              <a onClick={(e)=>handleDocumentClick(e,item)} href={`${item.name}`}>{item.name}</a>
            </div>
          </Col>
        ))}
      </Row>
    </Paragraph>
    { list?.length > 1 && list?.[listItems.documents]?.value?.length > 0 &&

      <DataSourceComponent list={list} />
    }
    <DocumentViewer item={activeDocument} onClose={()=>{setActiveDocument(null)}} />
  </div>)
};
