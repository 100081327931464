import { useAuth0 } from '@auth0/auth0-react';
import { authProvider } from '../../authPrvider/authProvider';
import performLogout from './useLogout';

const performLoginSilently = async (getAccessTokenSilently: any, logout?: (options?: any) => void) => {
  let token = ''
  
  try {
    token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
    console.log('TRY DEBUG performLoginSilently and save:');
    await authProvider.saveToken(token);
  } catch (e: any) {
    // TODO: display popup "Please make sure to enable popups"
    console.log('CATCH DEBUG performLoginSilently:', e);
    console.dir(e);
    if (e.error === 'login_required') {
      await performLogout(logout)
      return ''
    }
    if (e.error === 'consent_required') {
      await performLogout(logout)
      return ''
    }
    await authProvider.removeToken();
    return ''
  }

  return token
}

export default performLoginSilently
