import {Button, message, Modal, Space, Tooltip, Typography} from 'antd';

import * as util from '../../../../utils/util';
import QRCodeIcon from '../../../../assets/Qr_Code.svg';
import React, {useState} from 'react';
import { getQRCodeUrl } from '../../../../utils/util';

export default function QRCodeModalComponent({
  projectId,
  isQRModalOpen,
  setIsQRModalOpen,
}: any) {

  const [showCopiedLinkMessage, setShowCopiedLinkMessage] = useState(false)
  const handleCloseModal = () => {
    setIsQRModalOpen(false);
  }

  return (
    <Modal
      title={'SEE IN AR'}
      open={isQRModalOpen}
      footer={[]}
      onCancel={handleCloseModal}>
      <img
        width={'100%'}
        src={getQRCodeUrl(projectId)}
        alt='qr-modal'
      />

      {!showCopiedLinkMessage ?
        <Space
          className={'ShareComponent_tools'}
          onClick={projectId !== undefined
            ? () => {
              util.copyToClipboard(getQRCodeUrl(projectId))
              setShowCopiedLinkMessage(true)
            }
            : () => { }
          }
          onMouseLeave={() => setShowCopiedLinkMessage(false)}
        >
          <img src={require('../../../../assets/link.png')} />
          <Typography.Text disabled={projectId == undefined} style={{cursor: 'pointer'}}>Copy Link to Share</Typography.Text>
        </Space>
        :
        <Tooltip placement="bottomLeft" color="grey" zIndex={99999999999} title='Link copied!'>
          <Space
            className={'ShareComponent_tools'}
            onClick={projectId !== undefined
              ? () => {
                util.copyToClipboard(getQRCodeUrl(projectId))
                setShowCopiedLinkMessage(true)
              }
              : () => { }
            }
            onMouseLeave={() => setShowCopiedLinkMessage(false)}
          >
            <img src={require('../../../../assets/link.png')} />
            <Typography.Text disabled={projectId == undefined} style={{cursor: 'pointer'}}>Copy Link to Share</Typography.Text>
          </Space>
        </Tooltip>
      }
    </Modal>
  )
}
