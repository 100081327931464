import { ModelFormat } from 'constants/model';
import { getFileExtension, getFileName } from '../../../utils/util';
import { AWSDocumentNames } from '../../../enums';

export function loadProjectDetails(projectDetailsInformation: any): any {
  const cover = projectDetailsInformation.documents.find((item: any) => item.name === AWSDocumentNames.COVER_IMAGE);

  const projectDetails: any = {
    name: projectDetailsInformation.name,
    address: projectDetailsInformation.address,
    ownerName: projectDetailsInformation.organization?.name,
    latitude: projectDetailsInformation.latitude,
    longitude: projectDetailsInformation.longitude,
    rotation: projectDetailsInformation.buildings[0]?.defaultAnchoring?.rotation,
    description: projectDetailsInformation.description,
    altitude: projectDetailsInformation.altitude,
    website: projectDetailsInformation.organization?.website,
    timelineEvents: projectDetailsInformation?.timelineEvents[0] ?
      projectDetailsInformation?.timelineEvents.map((item: any) => ({
        event: item.timelineEventType?.id,
        date: item?.name,
      })) : [{}],
    disableGeoAnchor: [
      projectDetailsInformation.latitude,
      projectDetailsInformation.altitude,
      projectDetailsInformation.longitude,
    ].filter(item => item == '0').length >= 2,
    cover: cover ? [{
      uid: cover.id,
      name: cover.name,
      url: cover.url,
      previewFile: cover.url,
    }] : [],
    clients: projectDetailsInformation.organization?.organizationUsers?.map((item: any) => ({
      id: item.user.id,
      email: item.user.email,
    })) ?? [],
    publishStatus: projectDetailsInformation?.projectViewPreference?.visibility,
    buildings: projectDetailsInformation?.buildings?.map((item: any) => ({
      ...item,
      modelsList: item?.model3ds?.reduce((acc: any, item: any) => {
        const modelOrder = item.order;
        const modelExtension = getFileExtension(item?.fileLocation);

        if (!isNaN(Number(modelOrder))) {
          acc[modelOrder] = acc[modelOrder] || {};
          acc[modelOrder][modelExtension] = {
            id: item.id,
            type: item.type,
            url: item.fileLocation,
            externalId: item.externalId,
          };

          if (modelExtension === ModelFormat.USDZ) {
            acc[modelOrder][modelExtension].anchorCoordinate = {
              latitude: item?.anchoring?.anchorCoordinate?.latitude ?? null,
              longitude: item?.anchoring?.anchorCoordinate?.longitude ?? null,
              altitude: item?.anchoring?.anchorCoordinate?.altitude ?? null,
            };
          }
        }

        return acc;
      }, {}),
    })) ?? [],
    initModelUrl: projectDetailsInformation?.buildings?.[0]?.model3ds?.[0]?.fileLocation,
  };

  return projectDetails
}
