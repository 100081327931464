import { useAuth0 } from '@auth0/auth0-react';
import {observer} from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {Context} from '../../index';
import { Tabs } from '@pankod/refine-antd';
import TabPane = Tabs.TabPane;
import { useNavigation } from '@pankod/refine-core';
import './styles.less';
import { authProvider } from '../../authPrvider/authProvider';
import { isUserHasActiveSubscription } from '../../utils/subscription';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import checkMobile from '../../utils/checkMobile';
import { MAP_PATH } from '../../constants/links';

const NavigationTabBar = observer(({ dashboard } : {dashboard: boolean}) => {
  const { projectCreationStore } = useContext(Context);
  const {setUserInfo } =  projectCreationStore

  const [isUserFetched, setIsUserFetched] = useState(false);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isDashboardVisible, setIsDashboardVisible] = useState(false);
  const [fetchUserInfo] = useUserInfoFetch();
  const refetchUserCounter =  3;

  const userIncitu = authProvider.getInCituUser()
  const {
    user: auth0User,
  } = useAuth0();

  const { push } = useNavigation();

  useEffect(() => {
    if (auth0User) {
      setIsUserFetched(false);
      // setIsDashboardVisible(true);
      setRefetchCounter(0);
    } else {
      setIsDashboardVisible(false);
    }
  }, [auth0User]);

  useEffect(() => {

    const checkUserIncitu = async () => {
      let userIncitu = authProvider.getInCituUser();

      if((auth0User && auth0User.sub) && !userIncitu) {
        const fetchedUser: any = await fetchUserInfo(auth0User.sub);
        console.log('Map fetchUserInfo:', fetchedUser)
        authProvider.saveInCituUser(fetchedUser.data);
        userIncitu = fetchedUser.data
      }

      if (userIncitu && userIncitu.externalId) {
        const isUserHasSubscription = isUserHasActiveSubscription(userIncitu);
        console.log('isUserHasSubscription:', isUserHasSubscription);
        const isCTAVisible = isUserHasSubscription;
        setIsDashboardVisible(isCTAVisible);
        setIsUserFetched(true);

        return;
      } else {
        console.log('userIncitu is undefined:', userIncitu);

        if (refetchCounter < refetchUserCounter) {
          setRefetchCounter(prevCount => prevCount + 1);
        } else {
          console.log('Error: Failed to fetch userIncitu after ' + refetchUserCounter + ' attempts');

          setIsDashboardVisible(false);
        }
      }
    };

    if (refetchCounter <= refetchUserCounter && !isUserFetched) {
      setTimeout(checkUserIncitu, 3000);
    }

  }, [auth0User, refetchCounter, isUserFetched]);

  useEffect(() => {
    setUserInfo(auth0User)
  }, [auth0User]);

  const handleTabClick = (key: string) => {
    if (key === '1') {
      push('/map');
      return
    }
    if (key === '2') {
      push('/projects');
      return
    }
  };

  // here
  if(dashboard === true) {
    return (
      <div className='header-tab-bar'>
        <>
          {auth0User && !checkMobile().isMobile ? <Tabs defaultActiveKey="1" size="large" className='navigation-tabs' onTabClick={handleTabClick}>
            <TabPane tab="Public Map" key="1" />
            {/*{ isUserHasSubscription(userIncitu) && (*/}
            {/*  <TabPane tab="My Projects" key="2" />*/}
            {/*)}*/}
          </Tabs>
            : <></>
          }
        </>
      </div>
    );
  }

  console.log('___userIncitu:', userIncitu)
  return (
    <div className='header-tab-bar'>
      <>
        {auth0User ? <Tabs defaultActiveKey="1" size="large" className='navigation-tabs' onTabClick={handleTabClick}>
          {!MAP_PATH.some((suffix: string) => location.pathname.includes(suffix)) && isUserHasActiveSubscription(userIncitu) && (
            <TabPane tab="Public Map" key="1" />
          )}
          { isDashboardVisible &&
            <TabPane tab="My Projects" key="2" />
          }
        </Tabs>
          : <></>
        }
      </>
    </div>
  );
})

export default NavigationTabBar;
