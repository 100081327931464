import { useCreate, useDelete } from '@pankod/refine-core';
import { coverUploader } from '../api/cover';
import { AWSDocumentNames } from '../enums';
import { previewUploader } from '../api/preview';
import { modelToImage } from '../components/ProjectGridListings/components/modelToImage';
import { ModelFormat } from '../constants/model';
import useProjectDetailsFetch from './useProjectDetailsFetch';
import useSaveDefaultAnchoring from './useSaveDefaultAnchoring';
import useProjectInfoFetch from './useProjectInfoFetch';

async function uploadPreviewFile(projectId: string, base64Image: string) {
  let uploadedFileURL: any;

  if (projectId && base64Image) {
    try {
      const response = await previewUploader(projectId, base64Image);
      console.log('111_uploadPreviewFile response:', response)
      const { result } = await response.json();
      console.log('111_uploadPreviewFile result:', result)
      uploadedFileURL = result;
    } catch (e) {
      console.error('ERROR uploading preview file:', e)
    }
  }
  return {
    uploadedFileURL: uploadedFileURL,
  }
}

export default function useUploadPreview() {
  const { mutate: createCover } = useCreate();
  const { mutate: deleteCover } = useDelete();
  const [ fetchProjectInfo] = useProjectInfoFetch();
  const savePreview = async (projectInfo: any, isUpdate?: boolean) => {
    let isPreviewExist = false
    let newbase64Image: string | undefined = undefined
    const updatedProjectInfo = await fetchProjectInfo({ id: projectInfo.id });

    const modelPreview = updatedProjectInfo?.buildings[0]?.model3ds.find((e: any) => e.type === ModelFormat.GLB);
    const projectId = projectInfo?.id;
    if (modelPreview) {
      isPreviewExist = true
      const s3Link = `https://${process.env.REACT_APP_AWS_BUCKET_NAME_MODELS}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/` + modelPreview.fileLocation
      // const s3Link = 'https://incitu-3d-model-staging.s3.us-east-2.amazonaws.com/projects/clpaai5dd006k3kau5uh9mxjq/clpaai6bk006s3kauor1lxx3p/2425713beb3942d4ad6a08e1e490e53c_0.glb';
      newbase64Image = await modelToImage(s3Link || '')
    }

    if (projectId) {
      if (isUpdate) {
        const projectPreview = projectInfo?.documents?.find((e: any) => e.name === AWSDocumentNames.PREVIEW_MODEL_IMAGE);
        const shouldReplacePreview = projectPreview && newbase64Image;
        const shouldDeletePreview = !isPreviewExist && projectPreview;

        if (shouldReplacePreview || shouldDeletePreview) {
          deleteCover({
            resource: 'documents',
            id: projectPreview.id,
            metaData: {
              fields: ['id'],
            },
          });
        }
      }

      if (newbase64Image) {
        const uploadedData = await uploadPreviewFile(projectId, newbase64Image)
        const uploadedFileURL: any = uploadedData.uploadedFileURL
        const coverValue: any = {
          name: AWSDocumentNames.PREVIEW_MODEL_IMAGE,
          url: uploadedFileURL,
          project: { connect: { id: projectId } },
        }
        createCover({
          resource: 'documents',
          values: coverValue,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }
  }

  const savePreviewFromMap = async (projectInfo: any,  newbase64Image: string, isUpdate?: boolean) => {
    const isPreviewExist = false
    const updatedProjectInfo = await fetchProjectInfo({ id: projectInfo.id });

    console.log('====== updatedProjectInfo:', updatedProjectInfo)
    const projectId = projectInfo?.id;

    if (projectId) {
      if (isUpdate) {
        console.log('____0000_isUpdate:', isUpdate)
        console.log('____1111_isUpdate_projectInfo:', projectInfo)
        const projectPreview = projectInfo?.documents?.find((e: any) => e.name === AWSDocumentNames.PREVIEW_MODEL_IMAGE);
        const shouldReplacePreview = projectPreview && newbase64Image;
        const shouldDeletePreview = !isPreviewExist && projectPreview;

        if (shouldReplacePreview || shouldDeletePreview) {
          console.log('____2222_shouldReplacePreview || shouldDeletePreview')
          deleteCover({
            resource: 'documents',
            id: projectPreview.id,
            metaData: {
              fields: ['id'],
            },
          });
        }
      }

      if (newbase64Image) {
        const uploadedData = await uploadPreviewFile(projectId, newbase64Image)
        const uploadedFileURL: any = uploadedData.uploadedFileURL
        console.log('____0000_uploadedFileURL:', uploadedFileURL)
        const coverValue: any = {
          name: AWSDocumentNames.PREVIEW_MODEL_IMAGE,
          url: uploadedFileURL,
          project: { connect: { id: projectId } },
        }
        createCover({
          resource: 'documents',
          values: coverValue,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }
  }

  // return [savePreview];
  return [savePreviewFromMap];
}
