import { Button, Modal } from 'antd';

import Logo from '../../assets/pro-logo.svg';

import './style.less';
import { useMemo } from 'react';
import { authProvider } from '../../authPrvider/authProvider';
import { capitalizeFirstLetter } from '../../utils/util';
import {
  CONTACT_US,
  DOCUMENTATION_PATH_BASE,
  IOS_PATH
} from "../../constants/links";
import googlePlay from '../../../src/assets/googlePlay.svg'
import appStore from '../../../src/assets/appStore.svg'


const UpgradedPlanModal = ({ open, onCancel }: {
  open: boolean,
  onCancel: () => void
}) => {
  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])

  return (
    <Modal open={open} onCancel={onCancel} footer={null} width={436}>
      <div className="project-limit-modal">
        <div className="logo">
          <img src={Logo} />
        </div>
        <h1 className="title">You are all set!</h1>
        <div className="sub-title-welcome">
          Thank you for choosing the {user.subscriptionPlan?.type ? capitalizeFirstLetter(user.subscriptionPlan?.type) : ''} plan
        </div>
        <div className="description">
          To get the most out of your experience, we recommend downloading the
          inCitu mobile app which allows you to test and adjust your models
          onsite using
          <span style={{ fontWeight: "bold" }}>
            &nbsp; AR Edit mode.&nbsp;
          </span>
          <a
            href={`${DOCUMENTATION_PATH_BASE}/edit#heading=h.czc68m7t0tmv`}
            style={{ textDecoration: "underline", color: "black" }}
            target="_blank"
          >
            Learn more here
          </a>
          .
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: 250,
            marginBottom: 28,
          }}
        >
          {/*<a*/}
          {/*  href="https://play.google.com/store"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src={googlePlay}*/}
          {/*    alt="Google Play"*/}
          {/*    style={{ cursor: "pointer" }}*/}
          {/*  />*/}
          {/*</a>*/}
          <a
            href={IOS_PATH}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStore} alt="App Store" style={{ cursor: "pointer" }} />
          </a>
          {/*<img src={googlePlay} alt='googlePlay'/>*/}
          {/*<img src={appStore} alt='appStore'/>*/}
        </div>

        <Button className="upgrade-button" type="primary" onClick={onCancel}>
          Take me to my dashboard
        </Button>
        <div>
          Questions? &nbsp;
          <Button
            type="link"
            className="link-button"
            onClick={() => window.open(CONTACT_US, "_blank")}
          >
            Contact us
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UpgradedPlanModal;
