import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Divider, Input, Space, Typography } from 'antd'
import axios from 'axios'
import { InfoCircleOutlined, SendOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react';
import { authProvider } from '../../authPrvider/authProvider'
import { useUpdateUser } from '../../api/user'
import SideBarLayoutNew from '../../components/SideBarLayoutNew'
import mapMarker from '../../assets/Map_Marker.png'
import { fetchUsersProjects } from '../../api/project';
import performLogout from '../../hooks/auth0/useLogout';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';
import { Building, Model3D, Project } from '../../dataProvider/gql/types';
import { loadModules } from 'esri-loader';
import successLogo from '../../assets/completed-icon.svg';

import './style.less'
import {
  checkIsArcGISAuthorised,
  isArcGISAvailable,
  isArcGISImportProjectsAvailable,
  signInToArcGIS,
  signOutFromArcGIS,
} from '../../utils/arcgis';

const AccountDetails = (): JSX.Element => {
  const [usedStorageMb, setUsedStorageMb] = useState<any>();
  const {
    updateUser,
    data,
  } = useUpdateUser()

  const {
    logout,
  } = useAuth0();

  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])
  const usedStoragePercentage = user?.subscriptionPlan?.planDetails ? usedStorageMb / user?.subscriptionPlan?.planDetails?.cloudStorageLimitInMb * 100 : 0;

  const hasEffectRun = useRef(false);

  const [isArcGISAuthorised, setIsArcGISAuthorised] = useState(false);

  useEffect(() => {
    setIsArcGISAuthorised(checkIsArcGISAuthorised());
  }, []);

  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.USER_ACCOUNT_PAGE);
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {
    if (user && !usedStorageMb) {
      fetchUsersProjects(user).then((res) => {
        if (res && res.data?.length > 0) {
          const totalUsedMb = res.data.reduce((acc: number, project: Project) => {
            if (!project.buildings) {
              return acc;
            }
            
            const projectMb = project.buildings.reduce((acc: number, building) => {
              if (!building.model3ds) {
                return acc;
              }

              const model3ds =  building.model3ds.filter((model3d: Model3D) => model3d.type === 'usdz');
              if(model3ds.length === 0) {
                return acc;
              }
              return acc + model3ds[0].fileSizeMb;

              // return building.model3ds.reduce((acc: number, model3d: Model3D) => {
              //   if (!model3d.fileSizeMb) {
              //     return acc;
              //   }
              //
              //   return acc + model3d.fileSizeMb;
              // }, acc);
            }, 0);

            if (!projectMb) {
              return acc;
            }

            return acc + projectMb;
          }, 0);

          setUsedStorageMb(totalUsedMb);
        }
      });
    }
  }, [user])

  useEffect(() => {
    // TODO: discuss do we need to logout user if no subscriptionPlan == null
    if (!authProvider.getToken() || !user || (user?.subscriptionPlan == null && user?.role !== 'ADMIN')) {
      performLogout(logout)
    }
  }, [])

  // const username = user.firstName + ' ' + user.lastName

  const username = useMemo(() => {
    return user ? user.firstName + ' ' + user.lastName : ''
  }, [user])

  const [fullName, setFullName] = useState(username)
  const [inputValue, setInputValue] = useState('')
  const [resetPasswordStatus, setResetPasswordStatus] = useState('')
  const [disableArcGIS , setDisableArcGIS] = useState(true)

  useEffect(() => {
    setDisableArcGIS(isArcGISImportProjectsAvailable(authProvider.getInCituUser()) )
  }, [])

  useEffect(() => {
    if (username !== fullName && fullName !== '') {
      setInputValue('')

      void updateUserFullName()
    }
  }, [fullName])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (data) {
      console.log('Updated user details:', data)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      authProvider.saveInCituUser(data.updateUser)
    }
  }, [data])

  const handleFullNameChanged = () => {
    if (inputValue !== undefined) {
      mixPanelUtils.track(MixpanelEvent.ACCOUNT_NAME_CHANGE_ACTION);
      setFullName(inputValue)
    }
  }

  const updateUserFullName = async () => {
    console.log('Welcome__updateUserFullName:', fullName)
    const fullNameParts: Array<string> = fullName.split(' ')
    const payload = {
      firstName: { set: fullNameParts.length > 0 ? fullNameParts[0] ?? '' : '' },
      lastName: { set: fullNameParts.length > 1 ? fullNameParts[1] ?? '' : '' },
    }
    console.log('Welcome__updateUserFullName__user:', user)
    if (user !== undefined) {
      await updateUser(user.externalId, payload)
    }
  }

  const goToStripeCustomerPanel = () => {
    mixPanelUtils.track(MixpanelEvent.CHANGE_PLAN_ACTION);
    window.open('https://billing.stripe.com/p/login/8wM9CS3xY2nl9by4gg', '_blank', 'noreferrer')
  }

  const resetPassword = () => {
    mixPanelUtils.track(MixpanelEvent.PASSWORD_CHANGE_ACTION);
    setResetPasswordStatus('')
    const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: user.email,
      connection: 'Username-Password-Authentication',
    }
    const config = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { 'content-type': 'application/json' },
    }

    axios.post(url, data, config).then(function (response) {
      console.log('Success', response.data)
      setResetPasswordStatus('Reset password email sent')
    }).catch(function (error) {
      console.error('Error:', error)
      setResetPasswordStatus('Error occurred')
    })
  }

  const handleArcGISButtonClick = async () => {
    if (isArcGISAuthorised) {
      signOutFromArcGIS();
      setIsArcGISAuthorised(false);
    } else {
      await signInToArcGIS();
      setIsArcGISAuthorised(checkIsArcGISAuthorised());
    }
  };

  let userPlan = ''
  if(user.subscriptionPlan?.type){
    userPlan = user.subscriptionPlan?.type[0].toUpperCase() + user.subscriptionPlan?.type.toLowerCase().slice(1)
  }

  return (
    <SideBarLayoutNew>
      <Space direction='vertical' size='middle' className='account-page-container'>
        <Card className='account-card' bordered={false}>
          <div className='custom-row'>
            <div className='custom-column'>
              <Typography className='column-title'>Profile</Typography>
              <div className='small-component'>
                <Typography className='small-title'>First & Last Name</Typography>
                <Input className='name-input'
                  placeholder={fullName}
                  value={inputValue}
                  onChange={handleInputChange}
                  onPressEnter={handleFullNameChanged}
                  suffix={
                    <SendOutlined onClick={handleFullNameChanged} style={{ cursor: 'pointer' }} />
                  }
                />
              </div>
              <br/>
              <div className='small-component'>
                <Typography className='small-title'>Password</Typography>
                <div className='reset-password'>
                  <Button className='account-action-button' onClick={resetPassword}>
                    Change Password
                  </Button>
                  <Typography>{resetPasswordStatus}</Typography>
                </div>
              </div>
              <>
                <Divider className="short-divider" />
                <Typography className='column-title'
                  style={!disableArcGIS ?
                    {color: '#CCCCCC'} : {}}>Integrations</Typography>
                { !isArcGISAuthorised
                  ?  <div className="">
                    <Typography className="small-title arcgis-sign-in"
                      style={!disableArcGIS ?
                        {color: '#CCCCCC'} : {}}>
                        ArcGIS Login</Typography>
                    <Button disabled={!disableArcGIS}
                      className={!disableArcGIS ?
                        'account-action-button-arcGIS-available': 
                        'account-action-button'} onClick={handleArcGISButtonClick}>
                        Sign in to ArcGIS
                    </Button>
                  </div>
                  :  <div>
                    <Typography className="small-title">ArcGIS</Typography>
                    <br/>
                    <Typography className='arcgis-sign-in'> <img
                      src={successLogo}
                      style={{ width: 20, height: 17 }}
                    /> ArcGIS account connected</Typography>
                    <div>
                      <Typography >
                        <span
                          onClick={handleArcGISButtonClick}
                          style={{
                            textDecoration: 'underline',
                            fontWeight: 600}}
                        >Sign out
                        </span>
                          &nbsp; from ArcGIS
                      </Typography>
                    </div>
                  </div>}
              </>
              { !disableArcGIS ? <div>
                <br/>
                <br/>
                <Typography className='small-title' style={{fontWeight: 500}}>
                  <InfoCircleOutlined /> Integrations are only available for Pro users
                </Typography>
                <br/>
                <Button
                  onClick={goToStripeCustomerPanel}
                  className="account-action-button-upgrade">
                  Upgrade to Pro
                </Button>
              </div> : null}
            </div>
            <div className='vertical-divider'></div>
            <div className='custom-column' style={{ paddingLeft: '8%' }}>
              <Typography className='column-title'>Subscription</Typography>
              <div className='small-component'>
                <div className='subscription-plan-title'>
                  <img src={mapMarker} className='map-marker-icon' alt='map-marker' />
                  <Typography className='small-title'>inCitu {userPlan} plan</Typography>
                </div>
                <Typography>
                  You are currently on the inCitu {userPlan} plan.
                </Typography>
                <Button className='account-action-button' onClick={goToStripeCustomerPanel}>
                  Change plan
                </Button>
              </div>
              {/*{user.subscriptionPlan?.type === 'PRO' && (*/}
              {/*  <>*/}
              {/*    <Typography className='column-title storage-space'>Storage space</Typography>*/}
              {/*    <div className='small-component'>*/}
              {/*      <Typography>*/}
              {/*        You have used*/}
              {/*        {' '}*/}
              {/*        { usedStoragePercentage ? usedStoragePercentage.toFixed(2) : 0 }%*/}
              {/*        {' '}*/}
              {/*        of your storage space*/}
              {/*      </Typography>*/}
              {/*      <div className='storage-bar'>*/}
              {/*        <div className='storage-used' style={{ width: `${usedStoragePercentage ? usedStoragePercentage.toFixed(2) : 0}%` }} />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>
          </div>
        </Card>
      </Space>
      {/*<OAuthCallback />*/}
    </SideBarLayoutNew>
  )
}

export default AccountDetails
