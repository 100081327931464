import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import closeIcon from '../../assets/close-icon.svg';

import './style.less';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';
import { MAX_MODEL_UPLOADS } from '../../constants/subscription';
import { authProvider } from '../../authPrvider/authProvider';
import { getRemainingNumberOfUploads } from '../../utils/subscription';

const UpgradePopup = ({
  isPopupVisible,
  onClose,
}: {
  isPopupVisible: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const uploadNumbersLeft = getRemainingNumberOfUploads(authProvider.getInCituUser())

  const goToStripeCustomerPanel = () => {
    mixPanelUtils.track(MixpanelEvent.CHANGE_PLAN_ACTION);
    window.open(
      'https://billing.stripe.com/p/login/8wM9CS3xY2nl9by4gg',
      '_blank',
      'noreferrer',
    );
  };

  return isPopupVisible ? (
    <div className="upgrade-pro-popup">
      <button
        className="close-button"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <img src={closeIcon} alt="Close" />
      </button>
      <div className="cta-content">
        <p>You have reached your project limit.</p>
        <Button
          className="upgrade-button"
          type="link"
          onClick={goToStripeCustomerPanel}
        >
          Upgrade subscription!
        </Button>
        {uploadNumbersLeft > 0 &&
          <div style={{fontSize: '12px', fontWeight: '400', marginTop: '8px'}}>
            <p>You can replace the model in your</p>
            <p>project {uploadNumbersLeft} more {uploadNumbersLeft == 1 ? 'time' : 'times'}.</p>
          </div>
        }
      </div>
    </div>
  ) :
    null;
};

export default UpgradePopup;
