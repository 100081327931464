import { Modal } from '@pankod/refine-antd';

export const showPublishModal = async (resultedProjectId: string, publishProject: Function) => {

  Modal.confirm({
    title: 'Publish Project',
    content: 'Please publish this project',
    okText: 'Publish',
    cancelText: 'Cancel',
    onOk: async () => {
      console.log('resultedProjectId:', resultedProjectId);
      if (resultedProjectId) {
        await publishProject(resultedProjectId);
      }
    },
    onCancel: () => {
    },
  });
};
