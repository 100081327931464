import { Col, Row } from '@pankod/refine-antd';
import React from 'react';
import LogoSVG from '../../assets/Logo_Black.png';
import HeaderAccount from '../HeaderAccount';

import './styles.less';
import NavigationTabBar from '../TabBar';

const DashboardHeader: React.FC = () => (
  <Row align='middle' justify='center'>
    {/*<Col xxl={21} xl={21} className='map-header-container' lg={21} md={21} sm={21} xs={21}>*/}
    {/*  <a href={`${window.location.origin}/projects`}  target='_blank'><img src={LogoSVG} alt='*' /></a>*/}
    {/*</Col>*/}
    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={10} className='map-header-container'>
      <a href='https://www.incitu.us' target='_blank' rel='noopener noreferrer'><img src={LogoSVG} alt='*' /></a>
    </Col>
    <Col xxl={17} xl={17} lg={17} md={17} sm={17} xs={10}>
      <NavigationTabBar dashboard={true} />
    </Col>
    <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={4}>
      <HeaderAccount />
    </Col>
  </Row>
);

export default DashboardHeader;
