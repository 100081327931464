import React from 'react';
import incituProLogo from '../../assets/incitu-pro-logo.png'

import './style.less';
import {Link} from 'react-router-dom';
import performLogout from '../../hooks/auth0/useLogout';
import { useAuth0 } from '@auth0/auth0-react';

const MobileComingSoon = (): JSX.Element => {
  const {
    logout,
  } = useAuth0();

  return (
    <div className="main-div-landing-page">
      <div className="sub-main-div-landing-page">

        <img src={incituProLogo} alt='incitu pro logo' className="img-incitu"/>
        <div className="title-all-set">Mobile coming soon!</div>
        <div className="div-text-coming-soon" >
          <div className="coming-soon-text">
                        The mobile version of the project dashboard is coming soon! For now, use the desktop version to upload and see your projects.
          </div>
          <p/>
          <div className="coming-soon-text">
                        Go to: <Link style={{color: 'black',cursor: 'pointer'}} to="https://app.incitu.us/projects">app.incitu.us/projects</Link>
          </div>
        </div>
        <button className="logout-button" onClick={async () => {
          await performLogout(logout);
        }}>Sign out</button>
      </div>
    </div>
  )
}

export default MobileComingSoon
