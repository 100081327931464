import { useCreate, useList, useMany, useNavigation, useOne, useQuery, useUpdate } from '@pankod/refine-core';
import jwt_decode from 'jwt-decode';
import dataProvider from '../../dataProvider/dataProvider';
import { Role, UserStatus } from '../../dataProvider/gql/types';
import { useAuth0 } from '@auth0/auth0-react';
import { authProvider } from '../../authPrvider/authProvider';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';
import performLogout from './useLogout';
import { fetchUsersByExternalId } from '../../api/user';
import {
  callSignInNativeAndroidApp,
  callSignOutNativeAndroidApp,
  checkIfAndroidInterfaceAvailable,
} from '../../utils/checkMobile';

export default function useLoginOrRegister() {
  const {
    mutate: createMutate,
  } = useCreate();

  const {
    user,
    logout,
  } = useAuth0();

  const handleLogout = async () => {
    await performLogout(logout)
  }

  const registerUser = async (authUser: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      createMutate({
        resource: 'users',
        values: {
          externalId: authUser.sub,
          email: authUser?.email || '',
          firstName: authUser.name,
          lastName: '',
          status: UserStatus.Active,
          role: Role.User,
        },
        metaData: {
          fields: [
            'id',
            'externalId',
            'email',
            'firstName',
            'lastName',
            'role',
            'organizationUsers { id organizationId }',
          ],
        },
      },
      {
        onSuccess(data: any) {
          console.log('Auth0 user created:', data);
          resolve(data);
        },
        onError(error: any) {
          handleLogout()
          console.error('Auth0 user creation failed:', error);
          reject(error);
        },
      },
      );
    });
  }

  const performLoginOrRegister = async () => {
    const authUser = user
    console.log('authUser:', authUser)
    if(!authUser?.sub || !authUser?.email) {
      return
    }
    const externalId =  authUser.sub

    const fetchedUser = await fetchUsersByExternalId(externalId)

    let incituUser
    let action = undefined
    console.log('Searching user:', fetchedUser)
    if(fetchedUser) {
      console.log('User found:', fetchedUser)
      incituUser = fetchedUser.data
      if(!authProvider.getInCituUser()) {
        action = MixpanelEvent.USER_LOGGED_IN
        console.log('User MixpanelEvent.USER_LOGGED_IN:', action)
        // if (Date.now() - incituUser.createdAt <= 60 * 1000) {
        //   action = MixpanelEvent.USER_SIGNED_UP
        //   console.log('User MixpanelEvent.USER_SIGNED_UP:', action)
        // }
      }
    } else {
      console.log('User not found:', fetchedUser)

      if (window.location.href.includes('localhost')) {
        action = MixpanelEvent.USER_SIGNED_UP
        try {
          incituUser = await registerUser(authUser)
          incituUser = incituUser.data
          authProvider.saveInCituUser(incituUser)

          mixPanelUtils.track(MixpanelEvent.USER_SIGNED_UP)
        } catch (e) {
          console.error('register error:', e)
          window.localStorage.setItem('showInfoAboutErrorLogin', authUser?.email)
          return incituUser
        }
      }
    }

    authProvider.saveInCituUser(incituUser)

    if(action) {
      mixPanelUtils.track(action)
    }
    return incituUser
  }

  return [performLoginOrRegister]
}

