import { useCreate, useDelete } from '@pankod/refine-core';
import { coverUploader } from '../api/cover';
import { AWSDocumentNames } from '../enums';

async function uploadCoverFile(projectId: string, cover: any) {
  let uploadedFileURL: any;
  const file = cover.file;

  if (projectId && file) {
    try {
      const response = await coverUploader(file, projectId);
      const { result } = await response.json();

      uploadedFileURL = result;
    } catch (e) {
      console.error('ERROR uploading file:', e)
    }
  }
  return {
    uploadedFileURL: uploadedFileURL,
    file: file,
  }
}

export default function useUploadCover() {
  const { mutate: createCover } = useCreate();
  const { mutate: deleteCover } = useDelete();

  const saveCover = async (projectInfo: any, newCoverFile: any, isCoverExist?: boolean, isUpdate?: boolean) => {

    const projectId = projectInfo?.id;
    const data = newCoverFile?.create?.data;

    if (projectId) {
      if (isUpdate) {
        const projectCover = projectInfo?.documents?.find((e: any) => e.name === AWSDocumentNames.COVER_IMAGE);
        const shouldReplaceCover = projectCover && newCoverFile;
        const shouldDeleteCover = !isCoverExist && projectCover;

        if (shouldReplaceCover || shouldDeleteCover) {
          deleteCover({
            resource: 'documents',
            id: projectCover.id,
            metaData: {
              fields: ['id'],
            },
          });
        }
      }

      if (newCoverFile) {
        const uploadedData = await uploadCoverFile(projectId, data)
        const uploadedFileURL: any = uploadedData.uploadedFileURL
        const coverValue: any = {
          name: AWSDocumentNames.COVER_IMAGE,
          url: uploadedFileURL,
          project: { connect: { id: projectId } },
        }
        createCover({
          resource: 'documents',
          values: coverValue,
          metaData: {
            fields: ['id'],
          },
        });
      }
    }
  }

  return [saveCover];
}
