declare global {
  interface Window {
    Android:any;
  }
}

export default function checkMobile() {
  const ua = navigator.userAgent;
  let isMobile = false;
  let isTablet = false;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    isTablet = true;
  }
  // eslint-disable-next-line max-len
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    isMobile = true;
  }

  return { isMobile, isTablet }
}

export function isAndroid() {
  const android = /Android/.test(navigator.userAgent);
  if (android) {
    return true;
  }

  return false;
}

// export function checkIfAndroidInterfaceAvailable(): boolean {
//   return typeof window !== 'undefined' && !!window.Android; // Outputs true if window.Android exists, otherwise false.
// }

export function checkIfAndroidInterfaceAvailable(): boolean {
  // @ts-ignore: Unresolved variable Android
  if (typeof Android === 'undefined') {
    return false;
  } else {
    return true;
  }
}

export const openNativeAndroidSharePopup = (body: string) => {
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.shareLink(body)
  }
}

export const openNativeAndroidApp = (projectId: string) => {
  // @ts-ignore: Unresolved variable Android
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.showARView(projectId)
  }
}

export const notifySignInDidSucceed = (flag: boolean) => {
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.signInDidSucceed(flag)
  }
}
export const notifySignOutDidSucceed = (flag: boolean) => {
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.signOutDidSucceed(flag)
  }
}

export const callSignInNativeAndroidApp = () => {
  // @ts-ignore: Unresolved variable Android
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.initiateSignIn()
  }
}

export const callSignOutNativeAndroidApp = () => {
  // @ts-ignore: Unresolved variable Android
  if (checkIfAndroidInterfaceAvailable()) {
    // @ts-ignore: Unresolved variable Android
    Android?.initiateSignOut()
  }
}
// export function checkIsAndroidWrapper() {
//   const ua = navigator.userAgent;
//   console.log('User Agent:', ua); // Log the user agent string
//
//   // Check if the user agent indicates an Android WebView
//   const isAndroidWrapper = /wv|\.0\.0\.0|android.*webkit/i.test(ua);
//   const isAndroidInterfaceAvailable = checkIfAndroidInterfaceAvailable();
//   console.log('isAndroidWrapper:', isAndroidWrapper);
//   console.log('isAndroidInterfaceAvailable:', isAndroidInterfaceAvailable);
//   return isAndroidWrapper && isAndroidInterfaceAvailable;
// }

