import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import EsriMap from '../../../../pages/public-view/components/esri-map';
import './MapLocationSelectModal.less';

interface MapLocationSelectModalTypes {
  visible: boolean;
  handleSelector?: any;
  handleClose: any;
  longitude: any;
  latitude: any;
  altitude?:any;
}

export default function MapLocationSelectModal({
  visible,
  handleSelector,
  handleClose,
  longitude,
  latitude,
  altitude,
}: MapLocationSelectModalTypes) {
  const [mapData, setMapData] = useState<any>(null);

  const handleSelected = (data: any) => {
    setMapData(data);
  };

  return (
    <Modal
      className="map-selector-modal"
      footer={[
        <Button disabled={mapData==null} type="primary" onClick={() => handleSelector(mapData)}>
          Save
        </Button>,
      ]}
      title="Get address from Map"
      onCancel={handleClose}
      open={visible}
    >
      <div className="esri-map-locate-div">
        <EsriMap
          id="viewDiv"
          longitude={longitude || 28.634276}
          latitude={latitude || 77.236350}
          altitude={altitude || 218.00}
          bbl={null}
          bin={null}
          isColumn={false}
          isInteractive={true}
          handleSelected={handleSelected}
        />
      </div>
    </Modal>
  );
}
