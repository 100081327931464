/* eslint-disable max-len */

import { Spin, Typography} from 'antd';
import React from 'react';

// import './style.less';

import attentionWarning from '../../../../../../assets/attentionWarning.png';

const WarningBeingPublished = () => {

  return (
    <>
      <div style={{
        paddingTop: 20,
        display: 'flex',
        alignItems: 'center',
        width: '313px',
        justifyContent: 'space-between',
      }}>
        <span style={{paddingRight: 10}}>
          <img src={attentionWarning}/>
        </span>
        <span>
          <Typography.Paragraph style={{fontSize: 14, fontWeight: 'bold', marginBottom: 0}}>
             Something went wrong
          </Typography.Paragraph>
          <Typography.Paragraph style={{fontSize: 12}}>
            We were not able to save and publish your project. Please try again or <span
              style={{textDecoration: 'underline'}}>contact us. </span>
          </Typography.Paragraph>
        </span>
      </div>
    </>
  );
};

export default WarningBeingPublished;
