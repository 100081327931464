import {AntdLayout} from '@pankod/refine-antd'
import {useRegistrationProgress} from '../../hooks/useRegistrationProgressBar'
import AppBar from './AppBar'
import {Progress} from 'antd'

const ProgressHeader = () => {
  const { progress } = useRegistrationProgress()

  return (
    <AntdLayout.Header style={{
      boxShadow: '0 2px 8px #f0f1f2',
      position: 'fixed',
      zIndex: 3,
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      height: '58px',
      backgroundColor: '#FFFFFF',
    }}>
      <div>
        <AppBar />
        <Progress
          percent={progress}
          showInfo={false}
          strokeLinecap={'square'}
          strokeColor={'#F98168'}
          style={{width: '100vw'}}/>
      </div>
    </AntdLayout.Header>
  )
}

export default ProgressHeader
