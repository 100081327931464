
import React, { useEffect } from 'react';
import performLogout from '../hooks/auth0/useLogout';
import { useAuth0 } from '@auth0/auth0-react';

function AutoLogoutPage() {

  const {
    user,
    logout,
  } = useAuth0();

  const handleLogout = async () => {
    await performLogout(logout)
  }

  useEffect(() => {
    handleLogout()
  })

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Logout.</h1>
    </div>
  );
}

export default AutoLogoutPage;
