import { useAuth0 } from '@auth0/auth0-react';
import { authProvider } from '../../authPrvider/authProvider';
import React, { useEffect, useState } from 'react';
import useLoginOrRegister from './useLogin';
import { MixpanelEvent, mixPanelUtils } from '../../pages/public-view/mixpanel/mixpanel';
import { delay } from '../../utils/util';
import { showErrorModal } from '../../components/Modal/ShowErrorModal';

export default function useAuth0Authorization() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [performLoginOrRegister] = useLoginOrRegister();

  useEffect(() => {
    async function setDelay() {
      await delay(5000)
      if (isAuthenticated && user) {
        performLoginOrRegister().then(() => {
          console.log('USER:', user);
        })
      }
    }

    setDelay().then(() => {})

  }, [isAuthenticated, user]);

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenWithPopup({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
      });
      return token
    } catch (e: any) {
      console.error(e);
      return ''
    }
  };
  const loginUserAndGetToken = async () => {
    // if no token in DB  only
    await loginWithPopup();
    console.log('DEBUG loginUserAndGetToken:');
    let token = ''
    try {
      token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
      });
      console.log('TRY DEBUG loginUserAndGetToken:');
    } catch (e) {
      console.log('CATCH DEBUG loginUserAndGetToken:');
      token = await getAccessToken();
    }
    console.log('DEBUG saveToken:', token);
    await authProvider.saveToken(token);

    return token
  };
  const performAuthorization = async () => {
    await loginUserAndGetToken()
    // console.log('________loginUserAndGetToken____:');
    // mixPanelUtils.track(MixpanelEvent.USER_LOGIN)
  };

  return { performAuthorization };
}
