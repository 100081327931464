import '@google/model-viewer';
import { Button } from '@pankod/refine-antd';

import './style.less';
import { useEffect, useMemo, useState } from 'react';
import { authProvider } from 'authPrvider/authProvider';
import { isSubscriptionModelUploadLimitsAchieved } from 'utils/subscription';
import HintPopover from 'pages/registration/HintPopover/HintPopover';
export default function ModelPreview({ modelFile, onReplaceModel }: any) {

  const handleReplaceModel = () => {
    if (onReplaceModel) {
      onReplaceModel();
    }
  };

  const [disableReplaceButton, setDisableReplaceButton] = useState(false)

  useEffect(() => {
    const incituUser = authProvider.getInCituUser();
    const { isLimitAchieved, modelUploads, isBasicPlan } = isSubscriptionModelUploadLimitsAchieved(incituUser)
    if(isLimitAchieved) {
      setDisableReplaceButton(true);
    }
  }, []);


  return (
    <>
      <div className="model-viewer-style">
        <model-viewer
          style={{ width: '100%', height: '28vw'}}
          src={`${modelFile}`}
          shadow-intensity="1"
          camera-controls={true}
          auto-rotate={true}
          ar={true}
          exposure="0.02"
          ar-placement="floor"
          ar-scale="auto"
          loading="eager"
        ></model-viewer>
      </div>
      <>
        <Button
          style={disableReplaceButton ? {opacity: 0.5} : {}}
          id='replace-model-button'
          className='replace-modal-button'
          onClick={handleReplaceModel}
        >
              Replace model
        </Button>
      </>
     
    </>

  );
}
