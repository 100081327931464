import {
  ProjectDetailComplexVisibleKeys,
  ProjectDetailVisibleKeys,
  ProjectDetailMobileVisibleKeys,
  UpperCasedKeys,
  UpperCasedValues,
  UpperCasedMobileKeys,
  ComplexMobileKeys,
  valueSeparator,
} from '../pages/public-view/constants';
import { errorCommon } from '../constants/messages/error/common';
import { errorToken } from '../constants/messages/error/token';
import { errorJWTExpired } from '../constants/messages/error/jwt';
import { errorDBConnection } from '../constants/messages/error/db';
import satusesJSON from './statuses.json';
import { ModelStrings } from '../constants/index'
import { authProvider } from '../authPrvider/authProvider';
import { getAWSUrl, getTemporaryModelsAWSUrl } from '../components/ProjectDetailsFormNew/utils/aws';
import { MixpanelEvent, mixPanelUtils } from '../pages/public-view/mixpanel/mixpanel';
import { EXAMPLE_PROJECT } from '../constants/general';

export const delay = (duration: number) => {
  return new Promise(resolve => setTimeout(resolve, duration));
};
export function getFileNameWithExtension(item?: any) {
  if (item === undefined) {
    return ''
  }
  const url = item?.url || ''
  const parts = url.split('.')
  let extension = parts?.length > 0 ? `${parts[parts?.length - 1]}` : ''
  extension = extension?.length > 0 ? '.' + extension : extension
  const name = item?.name || 'Attachment'

  return `${name}${extension}`
}

export function getFileName(str: string) {
  const arr = str.split('.')
  if(arr.length == 0) {
    return ''
  }
  const filename = arr[arr.length - 2]
  return filename
}

export const getTemporaryUploadedModelFileLocationUrl = (fileName: any) => {
  const userId = authProvider.getInCituUser().id
  const awsUrl = getTemporaryModelsAWSUrl()
  console.log('awsUrl:', awsUrl);
  return `${awsUrl}/${userId}/${fileName}`;
}

export function buildModelFileData(modelFile: string) {
  const arr = modelFile.split('/')
  let filename
  if(arr.length > 0) {
    filename = arr[arr.length - 1].trim()
  }

  const model = {
    file: {
      name: filename,
    },
  }
  return model
}
export function getFileExtension(name?: any) {
  if (name === undefined) {
    return ''
  }
  const parts = name.split('.')
  const extension = parts?.length > 0 ? `${parts[parts?.length - 1]}` : ''

  return extension;
}

export async function getFileURL(projectId: string, item?: any): Promise<any> {
  if(!item?.url) {
    return Promise.resolve({})
  }

  const filename = item?.url ? item?.url : 'Camera_Kit.pdf'
  const url = `${process.env.REACT_APP_API_URL}/aws/download/${projectId}/${filename}`

  try {
    // TODO: Move to api/
    const res = await fetch(url, {
      method: 'GET',
    });
    return res.json();
  } catch {
    //console.log('Error on document downloaing:', e)
    return Promise.resolve({})
  }
}

export const dataUtils = {

  findArrayValueByKey: function findArrayValueByKey(d: any, key: string) {
    if(d?.length > 0) {
      const res = d.find((e: any) => {
        return e?.key?.toLowerCase() == key
      })
      return res?.value ?? []
    } else {
      return []
    }
  },

  findLocationValueByKey: function findLocationValueByKey(d: any, key: string) {
    if(d?.length > 0) {
      const res = d.find((e: any) => {
        return e?.key?.toLowerCase() == key
      })
      return res?.value ?? '0'
    } else {
      return '0'
    }
  },

  findValueByKey: function findValueByKey(d: any, key: string) {
    if(d?.length > 0) {
      const res = d.find((e: any) => {
        return e?.key?.toLowerCase() == key
      })
      return res?.value ?? ''
    } else {
      return ''
    }
  },

  capitalizeFirstLetter: function capitalizeFirstLetter(str: string) {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
  },

  jsonToData: function jsonToData(jsonData: any) {
    const fromJsonData: any = Object.keys(jsonData).map((key) => {
      if (ProjectDetailVisibleKeys.includes(key)) {
        let keyData = key.split('_').map((e: string) => dataUtils.capitalizeFirstLetter(e)).join(' ')

        // UpperCased
        if(UpperCasedKeys.includes(key)) {
          keyData = key.split('_').map((e: string) => e.toUpperCase()).join(' ')
        }
        const valueData = jsonData[key] ?? ''

        return {key: keyData, value: valueData }
      } else if (ProjectDetailComplexVisibleKeys.includes(key)) {
        let keyData = ''
        let valueData = ''

        // Complex
        Object.keys(jsonData[key]).map((subKey: string) => {
          keyData = subKey.split('_').map((e: string) => dataUtils.capitalizeFirstLetter(e)).join(' ')
          if(UpperCasedValues.includes(subKey)) {
            if(subKey.includes('ulurp')) {
              keyData = subKey.split('_')
                .map((e: string) => e == 'ulurp'
                  ? e.toUpperCase()
                  : dataUtils.capitalizeFirstLetter(e)).join(' ')
            } else {
              keyData = subKey.split('_').map((e: string) => e.toUpperCase()).join(' ')
            }
          }
          valueData = jsonData[key][subKey] ?? ''
        })

        return {key: keyData, value: valueData }
      } else {
        return undefined;
      }
    })
    return fromJsonData.filter((e: any) => e != undefined)
  },

  jsonToDataMobile: function jsonToDataMobile(jsonData: any) {
    const fromJsonData: any = Object.keys(jsonData).map((key) => {
      if (ProjectDetailMobileVisibleKeys.includes(key)) {
        let keyData = key.split('_').map((e: string) => dataUtils.capitalizeFirstLetter(e)).join(' ')

        // UpperCased
        if(UpperCasedMobileKeys.includes(key)) {
          keyData = key.split('_').map((e: string) => e.toUpperCase()).join(' ')
        }
        const valueData = jsonData[key] ?? ''

        return {key: keyData, value: valueData }
      } else if (ProjectDetailComplexVisibleKeys.includes(key)) {
        let keyData = ''
        let valueData = ''
        const statuses: Array<any> = []

        // Complex
        Object.keys(jsonData[key]).map((subKey: string) => {
          keyData = subKey.split('_').map((e: string) => dataUtils.capitalizeFirstLetter(e)).join(' ')
          if(UpperCasedValues.includes(subKey)) {
            if(subKey.includes('ulurp')) {
              keyData = subKey.split('_')
                .map((e: string) => e == 'ulurp'
                  ? e.toUpperCase()
                  : dataUtils.capitalizeFirstLetter(e)).join(' ')
            } else {
              keyData = subKey.split('_').map((e: string) => e.toUpperCase()).join(' ')
            }
          }
          valueData = jsonData[key][subKey] ?? ''

          if(ComplexMobileKeys.includes(key)) {
            statuses.push({key: keyData, value: valueData })
          }
        })

        if(ComplexMobileKeys.includes(key)) {
          return {key: 'status', value: statuses }
        }
        return {key: keyData, value: valueData }
      } else {
        return undefined;
      }
    })
    return fromJsonData.filter((e: any) => e != undefined)
  },

}

export const jsonUtils = {
  buildJSONModel: async function buildJSONModel(projectInfo: any) {
    const modelDesktopUrl = ''
    const modelMobileUrl = ''
    const modelType = ModelStrings.ModelFormat.GLTF
    let isModelAvailable = false

    if (
      projectInfo.buildings?.length > 0
      && projectInfo.buildings?.[0].model3ds
      && projectInfo.buildings?.[0].model3ds?.length > 0
    ) {
      if(projectInfo.buildings[0].buildingHeight > 0) {
        isModelAvailable = true
      }
    }

    /* eslint-disable */
    const projectModelJSON = {
      model_url_desktop: modelDesktopUrl,
      model_url_mobile: modelMobileUrl,
      model_type: modelType,
      is_model_available: isModelAvailable,
    }
    /* eslint-disable */
    return projectModelJSON
  },
  buildJSON: async function buildJSON(projectInfo: any) {
    let attributes = projectInfo.attributes ?? {}
    let bin = ''
    let building_id = ''
    let building_height = ''

    // Building
    if (projectInfo.buildings?.length > 0) {
      bin = projectInfo.buildings?.[0]?.footprintId ?? ''
      building_id = projectInfo.buildings?.[0]?.id ?? ''
      building_height = projectInfo.buildings?.[0]?.buildingHeight ?? ''
    }

    // Documents
    let documents = attributes.documents || []
    if (projectInfo.documents?.length > 0) {

      documents = []
      for (const doc of projectInfo.documents) {
        if (doc.type != 'PDF' || doc.name == 'qr') {
          break;
        }

        const docData = {
          'name': doc.name,
          'url': doc.url,
        }

        documents.push(docData)
      }
    }

    const parsedBBL: any = projectInfo.bbl?.split(valueSeparator.bbl) || []
    const applicantTeam = projectInfo.organization?.name || ''

    let std = projectInfo.statusDetail
    if (std?.set) {
      std = std.set
    }
    const status = statusRulesBased.buildUpcomingVariables(std)

    let statusDetails = {
      ...(status.ulurp_status.length > 0 && {
        'ulurp_status': status.ulurp_status
      }),
      ...(status.construction_status.length > 0 && {
        'status': status.construction_status
      }),
    }


    /* eslint-disable */
    // temporary fix for the status, we need to use the statusDetail, and this line going to be removed
    // statusDetails.status = projectInfo.status


    // Investigate why "set" happens on DB side during the importing process
    if (attributes.set) {
      attributes = attributes.set
    }

    const projectJSON = {
      id: projectInfo.id,
      title: projectInfo.name ?? '',
      status: statusDetails ?? {},
      applicant_team: applicantTeam,
      description: projectInfo.description || '',
      borough: projectInfo.borough || '',
      address: projectInfo.address || '',
      council_district: attributes?.council_district || '',
      council_member: attributes?.council_member || '',
      community_board: attributes?.community_board || '',
      ceqr: projectInfo.ceqr || '',
      bbl: parsedBBL ?? [],
      latest_update: status.latest_update || '', // based on statusDetail
      upcoming: status.upcoming ?? '', // based on statusDetail
      map_image: attributes.map_image,
      doc_source: attributes.doc_source || {},
      documents: documents, // attributes.documents || [],
      latitude: projectInfo.latitude ?? '0',
      longitude: projectInfo.longitude ?? '0',
      altitude: projectInfo.altitude ?? '0',
      bin: bin,
      building_id: building_id,
      building_height,
      is_additional_info_visible: projectInfo?.projectViewPreference?.isAdditionalInfoVisible ?? false,
      isSnapToGround: projectInfo?.ProjectViewPreference?.isSnapToGround ?? false,
    }
    /* eslint-disable */

    return projectJSON
  },
}
export const deviceUtils = {
  isPhone: function isPhone() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent,
      )
    ) {
      return true
    }
    return  false
  },
}

export const appUtils = {
  updatePreviewLinks: function updatePreviewLinks(projectInfo: any) {
    if(projectInfo?.name) {
      const title = `See the future ${projectInfo.name} in Augmented Reality! inCitu AR`
      document.title = title;
    }
    // document.querySelector('meta[name="description"]').setAttribute('content', title);
  },
}

export const copyToClipboard=(textToCopy: string)=>{
  const temp = document.createElement('input')
  temp.type = 'text'
  temp.value = textToCopy

  document.body.appendChild(temp)
  temp.select()
  document.execCommand('Copy')
  document.body.removeChild(temp)
}

export const statusRulesBased = {
  buildUpcomingVariables: function buildUpcomingVariables(statusDetails: any) {
    // Setup initial state
    let _statusDetails = statusDetails

    if (_statusDetails == null) {
      _statusDetails = {
        'ulurp_status': '',
        'construction_status': '',
        'latest_update': 'Filed',
        'upcoming': 'Notice',
      }
    }

    const inPublicReview = [
        "Community Board Review",
        "Borough President Review",
        "City Planning Commission Review",
        "City Council Review",
        "Mayoral Review",
    ]

    const inPreConstruction = [
      "job application filed",
      "job application approved",
      "under Construction",
      "permits issued",
      "completed",
    ]

    let {
      latest_update = '',
      upcoming = '',
      ulurp_status = '',
      construction_status = '',
      construction_date = ''
    } = _statusDetails

    if(ulurp_status.length > 0 || construction_status.length > 0) {
      if(ulurp_status.toLowerCase().trim() == "approved" && construction_status.length == 0) {
        latest_update = "Mayoral Review"
        if (construction_date.length > 0) {
          latest_update += construction_date
        }
        upcoming = "Job application filing"
      }
      else {
        const status: any = satusesJSON.statuses.find((e: any) => {
          return e.construction_status.toLowerCase() == construction_status.toLowerCase().trim()
        })
        latest_update = status?.latest_update ?? ''
        upcoming = status?.upcoming ?? ''
      }

      const updated_status_details = {
        ..._statusDetails,
        "ulurp_status": ulurp_status,
        "construction_status": construction_status,
        "upcoming": upcoming,
        "latest_update": latest_update
      }
      return updated_status_details
    }

    // Check initial states
    if(latest_update.toLowerCase().trim() == 'filed') {
      ulurp_status = "Filed"
      construction_status = ""
    } else if (latest_update.toLowerCase().trim() == 'noticed') {
      ulurp_status = "Noticed"
      construction_status = ""
    } else if (inPublicReview.includes(latest_update.trim())) {
      ulurp_status = "In public review"
      construction_status = ""
    }

    // Check pre-construction states
    else if(inPreConstruction.includes(latest_update.toLowerCase().trim())) {
      const status: any = satusesJSON.statuses.find((e: any) => {
        return e.construction_status.toLowerCase() == latest_update.toLowerCase().trim()
      })
      latest_update = status?.latest_update ?? ''
      upcoming = status?.upcoming ?? ''

      ulurp_status = status?.ulurp_status ?? ''
      construction_status = status?.construction_status ?? ''
    }

    const updated_status_details = {
      ..._statusDetails,
      "ulurp_status": ulurp_status,
      "construction_status": construction_status,
      "upcoming": upcoming,
      "latest_update": latest_update
    }
    return updated_status_details
  },
}
export enum ProjectQueryType{
  AllPublic,
  CityPublic,
  Org,
  Single,
  None,
  AllPublicAndPrivate
}
export const mapUtils = {

  getEnvTag: function getEnvTag(): string {
    if (window.location.href.includes('localhost')) {
      return 'production'
    } else if (window.location.href.includes('staging')) {
      return 'staging'
    } else {
      return 'production'
    }
  },

}

export const projectUtils = {

  getErrorMessage: function getErrorMessage(projectSaveError?: string): string {
    let errMessage =  errorCommon
    if(projectSaveError?.toString().includes('token')) {
      errMessage = errorToken
    }
    if(projectSaveError?.toString().includes('jwt')) {
      errMessage = errorJWTExpired
    }
    if(projectSaveError?.toString().includes('database')) {
      errMessage = errorDBConnection
    }
    return errMessage
  },
  isProjectId: function isProjectId(id: string): boolean {
    const pattern = /^[a-z0-9]{25}$/;
    return pattern.test(id);
  },

}

export const getStepName = (step: number) => {
  // upload, place, detail, share
  switch (step) {
    case 0:
      return 'upload';
    case 1:
      return 'place';
    case 2:
      return 'detail';
    case 3:
      return 'share';
  }
}


export const isLinkUsPage = () => {
  return location.pathname.includes('/map/linkus');
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export const isExampleProject = (project: any) => {
  return project.origin === EXAMPLE_PROJECT
}
export const isExampleProjectByOrigin = (origin: any) => {
  return origin === EXAMPLE_PROJECT
}
export const goToStripeCustomerPanel = () => {
  mixPanelUtils.track(MixpanelEvent.CHANGE_PLAN_ACTION);
  window.open('https://billing.stripe.com/p/login/8wM9CS3xY2nl9by4gg', '_blank', 'noreferrer')
}


export  const getQRCodeUrl = (projectId: string) => {
  return `https://${process.env.REACT_APP_NAME_MAPS_PUBLIC}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${projectId}/QR_${projectId}.jpg`
}
