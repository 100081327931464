import { authProvider } from '../authPrvider/authProvider';
import { gql, GraphQLClient } from 'graphql-request';

export async function getElevation(
  longitude: number,
  latitude: number,
  onAltitudeChange?: (longitude: number, latitude: number, altitude: number) => void,
){
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/graphql`;
    const token = authProvider.getToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const query = gql`
      query altitude($latitude: Float!, $longitude: Float!) {
        altitude(latitude: $latitude, longitude: $longitude) {
          elevation
        }
      }
    `;

    const variables = {
      latitude,
      longitude,
    };

    try {
      const result: any = await graphQLClient.request(query, variables);
      if (onAltitudeChange) onAltitudeChange(longitude, latitude, +result.altitude.elevation);

      return result.altitude.elevation;
    } catch (error) {
      console.error('Error fetching elevation data:', error);
      throw error;
    }
  } catch (error) {
    console.log('Error:', error);
    return undefined
  }
}

