
export const ProjectDetailVisibleKeys =
    [
      'title',
      // 'status',
      'description',
      'applicant_team',
      // 'contact', // hidden for now
      'borough',
      'address',
      // 'community_distinct',
      'community_board',
      'council_district',
      'council_member',
      'ceqr',
      'bbl',
      'documents',
      'latest_update',
      'upcoming',
      // 'doc_source',
      'longitude','latitude','altitude', 'bin',
    ]

export const ProjectDetailMobileVisibleKeys =
    [
      'title',
      // 'status',
      'description',
      'applicant_team',
      // 'contact', // hidden for now
      'borough',
      'address',
      // 'community_distinct',
      'community_board',
      'council_district',
      'council_member',
      'ceqr',
      'bbl',
      'documents',
      'latest_update',
      'upcoming',
      // 'doc_source',
      'longitude','latitude','altitude', 'bin',
    ]

export const ProjectDetailHideFromListKeys =
    [
      'dob','title','zap','documents','boston plans',
      'longitude','latitude','altitude', 'bin',
    ]

export const ProjectDetailHideFromMobileListKeys =
    [
      'status', 'ulurp status',
      'dob','title','zap','documents','address','status','borough','ceqr','bbl','contact',
      'boston plans',
      'longitude','latitude','altitude','bin',
    ]

export const ProjectDetailDocumentsListKeys =
    [
      'dob','zap','documents', 'boston plans',
    ]

export const ProjectDetailComplexVisibleKeys =
    [
      'status',
      'doc_source',
    ]

export const UpperCasedKeys =
    [
      'ceqr',
      'bbl',
      'applicant_team',
    ]

export const UpperCasedMobileKeys =
    [
      'ceqr',
      'bbl',
    ]

export const UpperCasedValues =
    [
      'ulurp_status',
      'zap',
      'dob',
    ]

export const ComplexMobileKeys =
    [
      'status',
    ]

export const valueSeparator = {
  'bbl': ';',
}
