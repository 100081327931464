import React, { useEffect, useRef, useState } from 'react';
import {
  Row, Col, Typography, message, Button, Modal, Spin,
} from '@pankod/refine-antd';
import './style.less';
import { useParams } from 'react-router-dom';
import useProjectSave from '../../hooks/useProjectSave';
import ProjectDetailsForm from '../../components/ProjectDetailsFormNew';
import { authProvider } from '../../authPrvider/authProvider';
import useProjectDetailsFetchById from '../../hooks/useProjectDetailsFetchById';
import ProjectHeader from '../../pages/public-view/map/Header';
import ProjectsIcon from '../../assets/projects.svg';
import { useNavigation } from '@pankod/refine-core';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import performLogout from '../../hooks/auth0/useLogout';
import { useAuth0 } from '@auth0/auth0-react';

function ProjectDetailsNew() {
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState('add');

  const {
    logout,
    user,
  } = useAuth0();

  message.config({
    top: 55,
  });

  useEffect(() => {
    setFormType(id ? 'edit' : 'add');
  }, [id]);

  // todo
  const [projectInfo, pageLoadStatus] = useProjectDetailsFetchById({id});
  const [saveData, projectSaveStatus, projectSaveError] = useProjectSave(id, projectInfo);

  console.log('____projevctInfo: ', projectInfo)

  const { push } = useNavigation();

  const hasEffectRun = useRef(false);
  useEffect(() => {

    if(!hasEffectRun.current)
    {
      mixPanelUtils.track(id ? MixpanelEvent.PROJECT_EDIT_VISIT : MixpanelEvent.PROJECT_CREATE_VISIT, { projectId: id })
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {
    if(!authProvider.getToken()) {
      // window.location.href = '/login';
      // window.location.href = '/landing';
      performLogout(logout)
    }
  }, []);

  return (
    <Row justify="center" style={{ backgroundColor: '#FAFAFA' }}>
      <Col className='header-column' xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
        <ProjectHeader />
      </Col>
      <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
        <div className='project-details-container'>
          <div className='user-actions-sidebar'>
            <div className='actions-container'>
              <Button
                className='action-button'
                icon={<img src={ProjectsIcon} />}
                type='text'
                onClick={() => window.location.href = '/projects'}
              >
                My Projects
              </Button>
            </div>
          </div>
          <ProjectDetailsForm
            projectDetailsInformation={projectInfo}
            projectLoading={loading}
            type={formType}
            projectSaveStatus={projectSaveStatus}
            projectSaveError={projectSaveError}
            saveInfo={saveData}
          />
        </div>
      </Col>
    </Row>
  );
}

export default ProjectDetailsNew;
