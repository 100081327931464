import { WarningFilled } from '@ant-design/icons';
import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { useState } from 'react';
import { DOCUMENTATION_PATH, IOS_PATH } from '../../../constants/links';

export default function CloseFinishModalPopup({isOpen,onLeave,onContinue}:any){

  const [showAllText, setShowAllText] = useState(false)

  return (
    <Modal
      className="close-popup-modal-wrapper"
      closeIcon={false}
      closable={false}
      open={isOpen}
      width={'auto'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: 'auto',
        transform: 'translateY(-50%)',
      }}
      footer={[]}
    >
      <div >
        <div style={{paddingBottom: 15}}>
          <WarningFilled style={{ color: '#fae31a', fontSize: 35 }} />
        </div>
        <div
          style={{ float: 'left', textAlign: 'left', width: 315, fontWeight: 500, fontSize: 16 }}
        >
          <Typography.Title level={5} style={{textAlign: 'center'}} >Recommended:</Typography.Title>
          <Typography.Paragraph >
            Before sharing, test and adjust on-site with mobile AR-edit tool.
          </Typography.Paragraph>
          {!showAllText ? (
            <Typography.Paragraph
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={() => setShowAllText(!showAllText)}
            >
              Learn more
            </Typography.Paragraph>
          ) : null}
          {showAllText ? (
            <>
              <Typography.Paragraph>
                GPS+VPS accuracy can vary based on
                your address.
              </Typography.Paragraph>
              <Typography.Paragraph>
                AR-edit mode allows you to optimize 
                the positioning of your model on-site,
              
                on your mobile device. We recommend testing 
                once before sharing your project 
                with others.
              </Typography.Paragraph>
              <Typography.Paragraph>
                To enable AR-edit download 
                the inCituAR app for{' '}
                <a
                  style={{
                    textDecoration: 'underline',
                    color: 'black',
                  }}
                  target="_blank"
                  href={IOS_PATH}
                >
                  iOS
                </a>
                . <br />
                Read our &nbsp;
                <a
                  style={{
                    textDecoration: 'underline',
                    color: 'black',
                  }}
                  target="_blank"
                  href={DOCUMENTATION_PATH}
                >
                  documentation
                </a>
                &nbsp; to learn more.
              </Typography.Paragraph>
            </>
          ) : null}
          <Button
            type="link"
            onClick={onLeave}
            className="next-step-create"
            style={{
              fontSize:14,
              fontWeight: 600,
              marginBottom: 20,
              marginLeft: '35%',
              width: 94,
            }}
          >
                Got it!
          </Button>
        </div>
      </div>
    </Modal>
  );
}
