import React, {createContext} from 'react';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './styles/antd.less';
import '@pankod/refine-antd/dist/styles.min.css';
import { Auth0Provider } from '@auth0/auth0-react';
import ProjectCreationStore from './store/projectCreationStore';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const projectCreationStore = new ProjectCreationStore()

export const Context = createContext({
  projectCreationStore,
})

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={window.location.origin}
      audient={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
    >
      <Context.Provider value={{
        projectCreationStore,
      }}>
        <App />
      </Context.Provider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
