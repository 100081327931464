/* eslint-disable no-debugger */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  Button,
  Space,
  Row,
  Modal,
  message,
  Col,
  Typography,
} from '@pankod/refine-antd';
import { observer } from 'mobx-react-lite';
import { useNavigation } from '@pankod/refine-core';
import { toJS } from 'mobx';

import { ProjectVisibility } from '../../../enums';
import {
  buildModelFileData,
  getStepName,
  getTemporaryUploadedModelFileLocationUrl,
  projectUtils,
} from '../../../utils/util';
import { useProgressModal } from '../../../components/Modal/ProgressModal';
import { showSuccessModal } from '../../../components/Modal/ShowSuccessModal';
import { showErrorModal } from '../../../components/Modal/ShowErrorModal';
import { useProjectPublish } from '../../../hooks/useProjectPublish';
import useUploadPreview from '../../../hooks/useUpdatePreview';
import usePublishStatusPooling from '../../../hooks/usePublishStatusPooling';
import ProjectSteps from './steps/ProjectSteps';
import ModelUploaderStepView from './steps/uploader/ModelUploaderStepView';
import ModelShareStepView from './steps/share/ModelShareStepView';
import ModelProjectDetailStepView from './steps/project-details/ModelProjectDetailsStepVeiw';
import { Context } from '../../../index';
import ModelPlacementStepView from './steps/place/ModelPlacementStepView';
import { loadProjectDetails } from '../utils/project';
import { authProvider } from '../../../authPrvider/authProvider';
import { executeConvertModel } from '../../../api/model';

import { PublishingStatus } from '../../../store/projectCreationStore';

import './style.less';
import { MixpanelEvent, mixPanelUtils } from '../../public-view/mixpanel/mixpanel';
import { getTemporaryModelsAWSUrl } from '../../../components/ProjectDetailsFormNew/utils/aws';
import { useAuth0 } from '@auth0/auth0-react';
import WarningLimitModalUploadPopup from './WarningLimitModalUploadPopup';
import { checkWarningLimitModalUploadPopupVisibility } from '../../../utils/subscription';
import { CheckOutlined } from '@ant-design/icons';

const ProjectCreationStepModal = observer(({
  projectDetailsInformation,
  projectLoading,
  projectSaveStatus,
  projectSaveError,
  saveInfo,
  visible,
  setShowCloseWarning,
  closeModalPopup,
  goToStripeCustomerPanel,
  setShowPublishedRecommendations,
}: any) => {
  const { projectCreationStore } = useContext(Context);

  const {
    handleSetModelsList,
    isUploadModel,
    isPublishing: isProjectPublishing,
    isProjectSaving,
    publishingStatus: projectPublishingStatus,
    isEdit,
    setSaveInfo,
    setSavedProjectData,
    getProjectDetailInformation,
    projectSteps,
    createStep,
    setCreateStep,
    buildingsList,
    clearProjectData,
    modelUrl, setModelUrl,
    projectName,
    address,
    setShowErrorMessage,
    setIsCreateModalVisible,
    projectCreateOrUpdate,
    setReplaceModel,
    isNotPublishedAndNoChanges,
    isProjectChangesExists,
    setIsProjectChangesExists,
    setUploadModel,
    isShowWarningLimitModelUploadPopup,
    setIsShowWarningLimitModelUploadPopup,
  } = projectCreationStore;

  const {
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [pressEnterEvent, setPressEnterEvent] = useState(false);
  const [projectDetailsMapped, setProjectDetailsMapped] = useState<any>(null);
  const [isWarningLimitModalUploadPopupVisible, setWarningLimitModalUploadPopupVisible] = useState(false);

  message.config({
    top: 55,
  });
  
  const [projectVisibility, setProjectVisibility] = useState<any>(ProjectVisibility.RESTRICTED);
  const [isLoading, setIsLoading] = useState(projectLoading || false);

  const projectId = useMemo(() => projectDetailsInformation?.id, [projectDetailsInformation?.id]);

  const { showProgressModal, hideProgressModal, isProgressModalVisible } = useProgressModal();
  const { publishProject, data: publishedProjectData, isLoading: isPublishing, error } = useProjectPublish();
  const [savePreviewFromMap] = useUploadPreview();

  const [modelFileForPrew, setModelFileForPrew] = useState<any>(null);

  const { push } = useNavigation();
  // const [documentsRefreshKey, setDocumentsRefreshKey] = useState(Date.now);
  // const [owners, setOwners] = useState([{
  //   type: 'developer',
  //   website: '',
  // }]);

  useEffect(() => {
    console.log('set projectDetailsInformation:', projectDetailsInformation)
    setSavedProjectData(projectDetailsInformation)
    setIsProjectChangesExists(false)
  }, [projectDetailsInformation]);

  useEffect(() => {
    // clearProjectData()
    console.log('get projectDetailsInformation:', projectDetailsInformation)
    getProjectDetailInformation(projectDetailsInformation)
  }, [projectDetailsInformation]);

  const displayErrorMessage = (): void => {
    const errMessage = projectUtils.getErrorMessage(projectSaveError)
    showErrorModal('Saving Project Error', errMessage);
  }

  useEffect(() => {
    console.log('PROJECT SAVE: projectSaveStatus:', projectSaveStatus)
    if (projectSaveStatus === 'error') {
      hideProgressModal();
      displayErrorMessage();
    }
  }, [projectSaveStatus]);

  useEffect(() => {
    setSaveInfo(saveInfo)
  }, [saveInfo]);

  const { appClipSync } = projectDetailsInformation || {};

  if (projectDetailsInformation && !projectDetailsMapped) {
    setProjectDetailsMapped(loadProjectDetails(projectDetailsInformation));
  }

    interface NextStepButtonProps {
        createStep: number;
        isEdit: boolean;
        setShowCloseWarning: any;
        projectName: string;
        address: string;
        isUploadModel: boolean;
        setShowErrorMessage: any;
        setIsCreateModalVisible: any;
    }

    const NextStepButton: React.FC<NextStepButtonProps> = ({ createStep,
      isEdit,
      setShowCloseWarning ,
      projectName,
      address,
      isUploadModel,
      setShowErrorMessage}) => {

      useEffect(() => {

        if (createStep === 0 && !isUploadModel){
          setDisableNextButton(true)
        } else if (createStep === 1 && address === ''){
          setDisableNextButton(true)
        } else if (createStep === 2 && projectName === ''){
          setDisableNextButton(true)
        }else if (createStep === 3 && isProjectChangesExists) {
          setDisableNextButton(true)
        }else {
          setDisableNextButton(false)
        }
      }, [createStep, isProjectChangesExists]);

      const [disableNextButton, setDisableNextButton]=useState(true)

        type Step = 1 | 2 | 3;

        const buttonLabels: { [key in Step]: string } = {
          1: 'Next',
          2: 'Next',
          3: 'Close',
        };
        const buttonText = buttonLabels[createStep as Step] || 'Next';
        
        const handleClick = async () => {
          if (createStep === 3) {
            // setIsCreateModalVisible(false)
            if(projectPublishingStatus === PublishingStatus.Success) {
              setShowPublishedRecommendations(true)
            } else {
              closeModalPopup()
              clearProjectData()
            }
          }else if (createStep === 0 && !isUploadModel){
            setShowErrorMessage(true)
            return false
          }else {
            mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_NEXT_ACTION,
              { projectId: projectId, step: getStepName(createStep) });
            setCreateStep(createStep + 1, isEdit, publishProject);
          }
        };

        return (<>
          <Button
            style={{fontSize:14, fontWeight: 600}}
            disabled={disableNextButton}
            // icon={buttonText === 'Published' ? <CheckOutlined /> : null}
            className={disableNextButton ?
              'next-step-create-disable' : 'next-step-create'}
            onClick={handleClick}>
            {buttonText}
          </Button>
        </>
        );
    };

    const handleReplaceModel = () => {
      setReplaceModel(true);
      setCreateStep(0, isEdit, publishProject);

      const replaceModelButton = document.getElementById('replace-model-button');
      replaceModelButton?.click();
    }

    const setCloseWarningPopup = () =>{
      if(!isProjectChangesExists){
        if(createStep === 3) { // && projectPublishingStatus === PublishingStatus.Success) {
          setShowPublishedRecommendations(true)
        } else {
          closeModalPopup()
        }
        // closeModalPopup()
      } else {
        setShowCloseWarning(true)
      }
    }

    const isDisabled = () => {
      return isProjectSaving || projectPublishingStatus === PublishingStatus.InProgress
        || projectPublishingStatus === PublishingStatus.Timeout || projectPublishingStatus === PublishingStatus.Failed
    }

    const handleModelUploaded = async (modelFile: string) => {
      let modelFileData = buildModelFileData(modelFile);

      const isConversionRequired = !modelFileData?.file?.name?.includes('.glb');
      if (isConversionRequired && !window.location.href.includes('localhost')) {
        const modelLocation = modelFileData?.file?.name
        const userId = authProvider.getInCituUser().id

        if (userId && modelLocation) {
          const result: any = await executeConvertModel(userId, modelLocation, getAccessTokenSilently, logout);
          console.log('HERE_DEBUG_CONVERTED__convertModel__result:', result);
          const outputModelUrl = result?.convertModel?.outputModel;
          console.log('DEBUG_CONVERTED___outputModel:', outputModelUrl);
          modelFileData = buildModelFileData(outputModelUrl);
          console.log('DEBUG_CONVERTED___modelFileData:', modelFileData);
          handleSetModelsList(modelFileData, 0, 0);
          setModelUrl(`${getTemporaryModelsAWSUrl()}/${outputModelUrl}`);
          // setUploadModel(true);
        }
      } else {
        handleSetModelsList(modelFileData, 0, 0);
        setModelUrl(modelFile);
        // setUploadModel(true);
      }

    };

    const handleWarningLimitPopupClose = () => {
      console.log('=== handleWarningLimitPopupClose=== ')
      authProvider.saveLastDisplayModalReplaceWarningTime()
    };

    console.log(toJS(buildingsList[0]));
    return (
      <Modal width="80vw"
        className="project-create-modal"
        open={visible}
        closable={true}
        // closable={!(createStep === 1)}
        onCancel={()=>setCloseWarningPopup()}
        footer={[]}>
        <Row justify="center" style={{ backgroundColor: '#FAFAFA', borderRadius: 5 }}>
          <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="project-creation-step-composer-container">
              <div className="user-actions-sidebar-new">
                <div className="actions-container">
                  <ProjectSteps activeStep={createStep} items={projectSteps} />
                </div>
              </div>
              <Space
                direction="vertical"
                size="large"
                className={`${ createStep != 1 ? 'space-style project-creation-step-composer-content' : 'project-creation-step-composer-content-map'}`}
              >
                { /* Step scree */}
                <div style={{
                  visibility: createStep === 0 ? 'visible' : 'hidden',
                  height: createStep === 0 ? 'auto' : '0px',
                }}>
                  <div style={{fontSize: 20, fontWeight: 500, marginTop: createStep !== 1 ? 17 : 0 }}>Upload your 3D model</div>
                  <ModelUploaderStepView
                    onModelUploaded={handleModelUploaded}
                    setModelFileForPrew={setModelFileForPrew} />
                  {/*<ModelUploaderStepView onModelUploaded={async (modelFile: string) => {*/}
                  {/*  let modelFileData = buildModelFileData(modelFile)*/}

                  {/*  const isConversionRequired = !modelFileData?.file?.name?.includes('.glb')*/}
                  {/*  if(isConversionRequired){ // && !window.location.href.includes('localhost')*/}
                  {/*    console.log('DEBUG CONVERSION REQUIRED')*/}
                  {/*    const modelLocation = modelFileData?.file?.name*/}
                  {/*    const userId = authProvider.getInCituUser().id*/}
                  {/*    if (userId && modelLocation) {*/}
                  {/*      const result: any = await executeConvertModel(userId, modelLocation)*/}
                  {/*      console.log('HERE_DEBUG_CONVERTED__convertModel__result:', result)*/}
                  {/*      const outputModelUrl = result?.convertModel?.outputModel*/}
                  {/*      console.log('DEBUG_CONVERTED___outputModel:', outputModelUrl)*/}
                  {/*      modelFileData = buildModelFileData(outputModelUrl)*/}
                  {/*      console.log('DEBUG_CONVERTED___modelFileData:', modelFileData)*/}
                  {/*      handleSetModelsList(modelFileData, 0, 0)*/}
                  {/*      setModelUrl(`${getTemporaryModelsAWSUrl()}/${outputModelUrl}`);*/}
                  {/*    }*/}
                  {/*  } else {*/}
                  {/*    handleSetModelsList(modelFileData, 0, 0)*/}
                  {/*    setModelUrl(modelFile)*/}
                  {/*  }*/}
                  {/*}} />*/}
                </div>
                <div style={{
                  visibility: createStep === 1 ? 'visible' : 'hidden',
                  height: createStep === 1 ? 'auto' : '0px',
                }}>
                  {/*<div style={{fontSize: 20, fontWeight: 500}}>Place your model in the world</div>*/}
                  {/*<span style={{ fontSize: '12px' }}>Add location <sup>*</sup></span>*/}
                  <ModelPlacementStepView
                    isEdit={isEdit}
                    models={toJS(buildingsList[0]?.modelsList?.[0])}
                    newModelUrl={modelUrl}
                    projectDetailsInformation={projectDetailsInformation}
                    projectId={projectId}
                    // setCloseWarningPopup={setCloseWarningPopup}
                    onModelReplace={handleReplaceModel}
                  />
                </div>
                <div style={{
                  visibility: createStep === 2 ? 'visible' : 'hidden',
                  height: createStep === 2 ? 'auto' : '0px',
                }}>
                  <div style={{fontSize: 20, fontWeight: 500}}>Project details</div>
                  <ModelProjectDetailStepView modelFileForPrew={modelFileForPrew}/>
                </div>
                <div style={{
                  visibility: createStep === 3 ? 'visible' : 'hidden',
                  height: createStep === 3 ? 'auto' : '0px',
                }}>
                  <div style={{fontSize: 20, fontWeight: 500}}>Access settings</div>
                  <ModelShareStepView />
                </div>
                <Row align="middle" justify="space-between" gutter={[24, 24]}
                  className="project-creation-step-composer-footer"
                  style={{  display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: 'auto',
                    width: '100%' }}>
                  <Col
                    style={{paddingLeft: 0}}>
                    {createStep > 0 &&
                        <>
                          <Button
                            className="previos-step-create"
                            style={isDisabled() ? 
                              {
                                opacity: 0.5,
                                fontSize:14,
                                fontWeight: 600,
                              } : 
                              {
                                fontSize:14,
                                fontWeight: 600,
                              }}
                            disabled={isDisabled()}
                            onClick={
                              () => {
                                mixPanelUtils.track(MixpanelEvent.PROJECT_WIZARD_BACK_ACTION,
                                  { projectId: projectId, step: getStepName(createStep) });
                                setCreateStep(createStep - 1, isEdit, publishProject) }
                            }>
                              Back
                          </Button>
                          {/* {createStep === 3 && isProjectChangesExists
                            ? <Button
                              loading={isProjectSaving || isProjectPublishing}
                              hidden={!isProjectChangesExists} //isPublishedOrNoChanges()}
                              className="next-step-create"
                              style={{marginRight: '-70%', float: 'right', fontWeight: 600, fontSize: 14}}
                              onClick={
                                () => projectCreateOrUpdate(isEdit, publishProject)
                              }>
                                Save & Publish
                            </Button>
                            : null
                          } */}
                        </>
                    }

                  </Col>
                  <Col style={{paddingRight: 0}}>
                    
                    {createStep === 3 && isProjectChangesExists 
                      ? <Button
                        disabled={isProjectSaving || isProjectPublishing}
                        loading={isProjectSaving || isProjectPublishing}
                        hidden={!isProjectChangesExists} //isPublishedOrNoChanges()}
                        className="next-step-create"
                        style={isProjectSaving || isProjectPublishing ?
                          {float: 'right', fontWeight: 600, fontSize: 14 , opacity: 0.5}: 
                          {float: 'right', fontWeight: 600, fontSize: 14}}
                        icon={isProjectSaving || isProjectPublishing ? <CheckOutlined /> : null}
                        onClick={
                          () => projectCreateOrUpdate(isEdit, publishProject)
                        }>
                        Publish
                      </Button>
                      : <NextStepButton
                        createStep={createStep}
                        isEdit={isEdit}
                        setShowCloseWarning={setShowCloseWarning}
                        projectName={projectName}
                        address={address}
                        isUploadModel={isUploadModel}
                        setShowErrorMessage={setShowErrorMessage}
                        setIsCreateModalVisible={setIsCreateModalVisible}
                      />
                    }
                  </Col>
                </Row>

              </Space>
            </div>
          </Col>
        </Row>
        { isShowWarningLimitModelUploadPopup && checkWarningLimitModalUploadPopupVisibility() && (
          <WarningLimitModalUploadPopup
            showCloseWarning={() => {}}
            onContinue={() => {
              setIsShowWarningLimitModelUploadPopup(false);
              handleWarningLimitPopupClose();
              goToStripeCustomerPanel();
            }}
            onModelReplace={() => {
              setIsShowWarningLimitModelUploadPopup(false);
              handleWarningLimitPopupClose();
            }}
            isShowWarningLimitModelUploadPopup={isShowWarningLimitModelUploadPopup}
            setIsShowWarningLimitModelUploadPopup={setIsShowWarningLimitModelUploadPopup}
          />
        )}
      </Modal>
    );
})

export default ProjectCreationStepModal;
