/* eslint-disable max-len */

import { Spin, Typography} from 'antd';
import React from 'react';

// import './style.less';

import {LoadingOutlined} from '@ant-design/icons';

const BeingPublished = () => {

  return (
    <>
      <div style={{paddingTop: 20, display: 'flex', alignItems: 'center', margin: 'auto'}}>
        <span style={{paddingRight: 15}}>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: 'gray'}} spin={true}/>}/>
        </span>
        <span>
          <Typography.Paragraph style={{fontSize: 14, fontWeight: 'bold', marginBottom: 0}}>
             Your project is being published
          </Typography.Paragraph>
          <Typography.Paragraph style={{fontSize: 14}}>
            This may take a couple minutes
          </Typography.Paragraph>
        </span>
      </div>
    </>
  );
};

export default BeingPublished;
