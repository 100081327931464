/* eslint-disable max-len */

import { Button, Checkbox, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useContext } from 'react';

import './style.less';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import { ProjectVisibility } from '../../../../../enums';

interface PropsRadioButton {
    onChange: (e: RadioChangeEvent)=>void,
    value: number,
    download: boolean,
    premium: boolean
    disabled: boolean
}

const RadioButtonGroup: React.FC<PropsRadioButton> = observer((props: PropsRadioButton) => {

  const { projectCreationStore } = useContext(Context);
  const { visibilityPublishStatus, setVisibilityPublishStatus} = projectCreationStore

  console.log('visibilityPublishStatus: ', visibilityPublishStatus )
  return (
    <div style={{marginTop: 67, marginLeft: 30}}>
      <div style={{display: 'flex', paddingBottom: 16}}>
        <div style={{fontSize: 14, fontWeight: 600}}>
            Choose access settings
        </div>
      </div>
      <Radio.Group
        onChange={props.onChange} value={props.value} >
        <Space direction="vertical">
          <Radio
            value={2}
            disabled={props.disabled}
          >
            <div  style={{fontSize: 14, fontWeight: 600}}>
              Anyone with link can view
            </div>
            <br/>
          </Radio>
          <div style={{fontSize: 14, marginLeft: 20, color: '#000000'}}>
            <Checkbox
              checked={visibilityPublishStatus === ProjectVisibility.PUBLIC}
              style={{fontSize: 14, fontWeight: 400}}
              onChange={ (value) => {
                console.log('TODO: implement this', value.target.checked )
                setVisibilityPublishStatus(value.target.checked ? ProjectVisibility.PUBLIC : ProjectVisibility.RESTRICTED)
              }}
            >
              Public
            </Checkbox>
            <p style={{fontSize: 14, marginLeft: 25, marginBottom: 0}}>
              Public projects will show on the inCitu’s apps
            </p>
          </div>
          <Radio value={3} disabled={!props.premium}>
            <div
              style={!props.premium
                ? {opacity: 0.5, fontSize: 14, fontWeight: 600, paddingBottom: 5}
                : {fontSize: 14, fontWeight: 600, paddingBottom: 8}}
            >
              Private
            </div>
            <div style={{fontSize: 14, marginTop: 0, marginLeft: 25}}>
              Visible only to members of my organization
            </div>
          </Radio>
        </Space>
      </Radio.Group>
      <div>
        <label htmlFor="premium" className='premium-button-pd' style={{marginTop: 6, marginLeft: 45}}> Premium feature coming soon!</label>
        {/*<Button disabled={false}*/}
        {/*        className='premium-button-sh'*/}
        {/*        onClick={() => console.log('Upgrade to premium')}>*/}
        {/*    Upgrade to Pro*/}
        {/*</Button>*/}
      </div>

    </div>

  );
});

export default RadioButtonGroup;
