import React, {ReactNode} from 'react'
import {AntdLayout} from '@pankod/refine-antd'
import {RegistrationProgressProvider} from '../../hooks/useRegistrationProgressBar'
import ProgressHeader from './AppHeader'

export interface CustomLayoutProps {
    children?: ReactNode
}

const CustomLayout: React.FC<CustomLayoutProps> = ({ 
  children,
}) => {
  return  (
    <RegistrationProgressProvider>
      <AntdLayout>
        <ProgressHeader/>
        <AntdLayout.Content>
          <div style={{padding: '24 0', minHeight: 360}}>{children}</div>
        </AntdLayout.Content>
      </AntdLayout>
    </RegistrationProgressProvider>
  )
}

export default CustomLayout
