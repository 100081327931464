import React, { useState, ReactNode, useEffect } from 'react';
import './HintPopover.less';

interface HintPopoverProps {
  content: ReactNode;
  children: ReactNode;
  style?: React.CSSProperties;
  popoverClassName?: string;
  open?: boolean;
  arrowBottom?: boolean;
  popoverInner?: boolean;
}

const HintPopover: React.FC<HintPopoverProps> = ({ content, children, style, open, arrowBottom = true, popoverInner= true, popoverClassName }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if(open) {
      setIsVisible(open);
    }
  }, [open]);

  const showPopover = () => {
    setIsVisible(true);
  };

  const hidePopover = () => {
    setIsVisible(false);
  };

  return (
    <div className="popover-container" style={style}>
      <div className="popover-trigger" onMouseEnter={showPopover} onMouseLeave={hidePopover}>
        {children}
      </div>
      {isVisible && (
        <div className={ popoverClassName ? popoverClassName : 'popover-content'}>
          { arrowBottom &&
            <div className="arrow-bottom"></div>
          }
          { popoverInner &&
            <div className="popover-inner">
              {content}
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default HintPopover;

//
// const TooltipPopover: React.FC<HintPopoverProps> = ({ content, children, style, open }) => {
//   const [isVisible, setIsVisible] = useState<boolean>(false);
//
//   useEffect(() => {
//     if(open) {
//       setIsVisible(open);
//     }
//   }, [open]);
//
//   const showPopover = () => {
//     setIsVisible(true);
//   };
//
//   const hidePopover = () => {
//     setIsVisible(false);
//   };
//
//   return (
//     <div className="popover-container" style={style}>
//       <div className="popover-trigger" onMouseEnter={showPopover} onMouseLeave={hidePopover}>
//         {children}
//       </div>
//       {isVisible && (
//         <div className="popover-content">
//           {/*<div className="arrow-bottom"></div>*/}
//           <div className="popover-inner">
//             {content}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
//
// export  TooltipPopover;
