export enum AssetCategory {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export enum AWSDocumentNames {
  QR = 'qr',
  QR_APP_CLIP = 'qrAppClip',

  COVER_IMAGE = 'cover',
  PREVIEW_MODEL_IMAGE = 'preview',
}
