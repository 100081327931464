import { useUpdate } from '@pankod/refine-core';

export default function useSaveAnchoring() {
  const { mutate } = useUpdate();

  const saveAnchoring = async ({ buildingId, rotation, coord, anchoringId }: any) => {
    if (buildingId) {
      // if (!anchoringId) {
      //
      //   const objectRotation = rotation || 0;
      //   const data = {
      //     anchoring: {
      //       create: {
      //         rotation: objectRotation,
      //         anchorCoordinate: {
      //           create: {
      //             latitude: coord.latitude,
      //             longitude: coord.longitude,
      //             ...(coord.altitude && {
      //               'altitude': coord.altitude,
      //             }),
      //           },
      //         },
      //       },
      //     },
      //   }
      //   if (!data) { return }
      //
      //   try {
      //     await mutate({
      //       resource: 'buildings',
      //       id: buildingId,
      //       values: data,
      //       mutationMode: 'optimistic',
      //       metaData: {
      //         fields: ['id'],
      //       },
      //     });
      //   } catch (error: any) {
      //     console.error('Error updating buildings model3D: ', error.message);
      //   }
      // } else {
      const objectRotation = rotation || 0;
      const data = {
        rotation: {
          set: objectRotation,
        },
        anchorCoordinate: {
          update: {
            latitude: { set: coord.latitude },
            longitude: { set: coord.longitude },
            ...(coord.altitude && {
              'altitude': {
                set: coord.altitude,
              },
            }),
          },
        },
      }
      if (!data) { return }

      try {
        await mutate({
          resource: 'anchoring',
          id: anchoringId,
          values: data,
          mutationMode: 'optimistic',
          metaData: {
            fields: ['id'],
          },
        });
      } catch (error: any) {
        console.error('Error updating defaultAnchoring: ', error.message);
      }
      // }
    }
  };

  return [saveAnchoring];
}
