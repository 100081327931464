import React from 'react';
import './styles.less';

const ProjectDescriptionOfficialsItems:React.FC<any>=(data: any)=>{
  const councilDistrict = data?.attributes?.council_district;
  const councilMember = data?.attributes?.council_member;
  const communityBoard = data?.attributes?.community_board;
  return(
    <>
      {data?.attributes &&
        <>
          <h2 className='description-title details'>Local officials</h2>
          {councilDistrict &&
            <>
              <span className='description-title-item'>Council District: </span>
              <span className='description-item'>{councilDistrict}</span>
              <br />
            </>
          }
          {councilMember &&
            <>
              <span className='description-title-item'>Council Member: </span>
              <span className='description-item'>{councilMember}</span>
              <br />
            </>
          }
          {communityBoard &&
            <>
              <span className='description-title-item'>Community Board: </span>
              <span className='description-item'>{communityBoard}</span>
              <br />
            </>
          }
        </>
      }
    </>
  )
}

export default ProjectDescriptionOfficialsItems;
