import { Typography } from '@pankod/refine-antd';
import LoadingWrapperIcon from '../../../../../../assets/Ellipse.png';
import LinearIcon from '../../../../../../assets/liner.png';

const LoadingUploadView = () => (
  <>
    <p className="ant-upload-drag-icon">
      <div className="upload-wrapper">
        <img src={LoadingWrapperIcon} alt="upload" />
        <Typography.Text>Loading</Typography.Text>
        <img src={LinearIcon} className="upload-spinning" alt="upload" />
      </div>
    </p>
    <p className="ant-upload-text">Loading your model</p>
    <p className="ant-upload-hint">This may take up to a minute</p>
  </>
);

export default LoadingUploadView;