import WebMap from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapMakerImage from '../../assets/Map_Marker.png'
import SelectedMarker from '../../assets/SelectedMarker.svg'
import {highlightSelected} from './highlightSelected';
import LinkUSMapMarker from '../../assets/Map_Marker_LinkUS.png'

export async function createMapView2D(map: WebMap, mapView: any, projects: any,projectId: string,tag: string) {

  const mapMarkerImage = tag == '/map/linkus' ? LinkUSMapMarker: MapMakerImage

  const incituRenderer = {
    'type': 'simple',
    'symbol': {
      'type': 'picture-marker',
      'url': mapMarkerImage,
      'width': '20px',
      'height': '26px',
    },
  };

  try {
    const graphicCollection: Array<any> = [];
    let projectsArray: Array<any> = []

    if (projects)
    {
      if (Array.isArray(projects)) {
        projectsArray = projects;
      } else if (projects) {
        projectsArray.push(projects);
      }
      for (const result of projectsArray) {
       
        if(projectId == result.id){
          highlightSelected(mapView,result.latitude,result.longitude,tag)
        }
        // const symbol = {
        //   // @ts-ignore
        //   'type': 'picture-marker',
        //   'url': selectedMapMarkerImage,
        //   'width': '20px',
        //   'height': '26px',
        // }
        const graphic = new Graphic({
          attributes: { 'id': result.id },
          geometry: new Point({ x: result.longitude, y: result.latitude, z: 0 }),
          // @ts-ignore
          ///symbol: symbol ,
        });
        graphicCollection.push(graphic);
      }

      const featureLayer = new FeatureLayer({
        source: graphicCollection,
        // selectionSymbol: selectionSymbol,
        fields: [{
          name: 'OBJECTID',
          type: 'oid',
        }, {
          name: 'id',
          type: 'string',
        }],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderer: incituRenderer,
        outFields: ['*'],
        featureReduction: {
          type: 'cluster',
          labelingInfo: [{
            labelExpressionInfo: {
              expression: '$feature.cluster_count',
            },
            deconflictionStrategy: 'none',
            labelPlacement: 'center-center',
            symbol: {
              type: 'text',
              color: 'black',
              font: {
                size: '11px',
              },
              haloSize: 1,
              haloColor: 'white',
            },
          }],
        },
      });
      map.add(featureLayer);
      return featureLayer as FeatureLayer
    }
  } catch (error) {
    console.error('Error Creating Feature Layer', error);
  }
}
