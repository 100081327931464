import { Project } from '../dataProvider/gql/types';
import dataProvider from '../dataProvider/dataProvider';

export default function useProjectCommentsFetch(id: string) {
  const fetchComments = async (pageSize: number) => {
    if (id) {
      const dProvider = dataProvider(`${process.env.REACT_APP_API_URL}/graphql`)
      const {
        data,
        // eslint-disable-next-line react-hooks/rules-of-hooks
      }: any = await dProvider.getList<any>({
        resource: 'comments',
        filters: [{ field: 'projectId', operator: 'eq', value: id }],
        pagination: { current: 1, pageSize },
        sort: [{ field: 'createdAt', order: 'desc' }],
        metaData: {
          fields: [
            'id',
            'content',
            'rating',
            'user{firstName lastName locations{address}}',
          ],
        },
      });

      const {
        data: projectInfo,
        // eslint-disable-next-line react-hooks/rules-of-hooks
      }: any = await dProvider.getOne<Project>({
        resource: 'project',
        id,
        metaData: {
          fields: [
            'commentsCount',
          ],
        },
      });

      return {
        list: data,
        count: projectInfo?.commentsCount,
      };
    } else {
      return {
        list: [],
        count: 0,
      };
    }
  }

  return [fetchComments];
}
