import { Typography} from 'antd';
import React from 'react';

import './style.less';

import {CheckCircleFilled} from '@ant-design/icons';
import { PublishingStatus } from '../../../../../store/projectCreationStore';

interface AfterDownloadInfoProps {
  publishingStatus: PublishingStatus;
}
const AfterDownloadInfo: React.FC<AfterDownloadInfoProps> = ({ publishingStatus}) => {

  const getPublishingMessage = (publishingStatus: PublishingStatus) => {
    switch (publishingStatus) {
      // case PublishingStatus.InProgress:
      //   return ' Your project is being published. This may take up to a minute.';
      case PublishingStatus.Success:
        return 'Your project is published and ready to share!';
      case PublishingStatus.Failed:
        return 'Publishing failed. Please try again.';
      case PublishingStatus.Timeout:
        return 'Publishing timed out. Please try again.';
      case PublishingStatus.None:
        return '';
      default:
        return 'Some error occurred. Please try again.';
    }
  };

  return (
    <>
      {/*<div className="div-progress-line" >*/}
      <div className="div-progress-line">
        <div style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 16 }}>
          <CheckCircleFilled className="check-circle" />
          {/*<div className="progress-line-black" >*/}
          <Typography.Text strong={true}>
            <div>Your project is published</div>
            <div>and ready to share!</div>
          </Typography.Text>
        </div>
      </div>
      <div className="publish-text-info">
        <Typography.Paragraph>
          { publishingStatus !== PublishingStatus.Success &&
            <Typography.Text strong={true} style={{fontSize: 14}}>{
              getPublishingMessage(publishingStatus)
            }</Typography.Text>
          }
          {/*<Typography.Text strong={true}>*/}
          {/*              Your project is published*/}
          {/*              and ready to share!*/}
          {/*</Typography.Text>*/}
        </Typography.Paragraph>
      </div>
    </>
  );
};

export default AfterDownloadInfo;
