import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {authProvider} from '../../authPrvider/authProvider'
import { useAuth0 } from '@auth0/auth0-react';
import performLogout from '../../hooks/auth0/useLogout';
import { Layout , Spin } from 'antd';
import verifyingLogo from '../../../src/assets/verifying-logo.svg'
import { useNavigation } from '@pankod/refine-core';
import { LoadingOutlined } from '@ant-design/icons';
import performLoginSilently from '../../hooks/auth0/useLoginSilently';
import { MixpanelEvent, mixPanelUtils } from '../public-view/mixpanel/mixpanel';
import useUserInfoFetch from '../../hooks/fetchUserInfo';
import { useRegistrationProgress } from '../../hooks/useRegistrationProgressBar';
import envolope from '../../../src/assets/Envelope.svg'
export const EmailVerification = () => {
  const { push } = useNavigation()
  const user = useMemo(() => {
    return authProvider.getInCituUser()
  }, [])

  const {
    user: auth0User,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const [fetchUserInfo] = useUserInfoFetch();
  const {setProgress} = useRegistrationProgress()
  useLayoutEffect(() => {
    setProgress(33)
  })

  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current) {
      mixPanelUtils.track(MixpanelEvent.EMAIL_VERIFICATION);
      hasEffectRun.current = true;
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {

      if (!auth0User) {
        await getAccessTokenSilently({ignoreCache: true})
      } else if (auth0User && auth0User?.email_verified && auth0User.sub) {
        // Thought to have the same bahavior as confirmation has, to redirect to /email-verification-confirmation
        // But for now decided to keep existing flow
        const fetchedUser: any = await fetchUserInfo(auth0User.sub);
        authProvider.saveInCituUser(fetchedUser.data);

        push('/email-verification-confirmation?verified=true')
      } else {
        console.log('Re-fetch user');
        await getAccessTokenSilently({ ignoreCache: true })
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [auth0User, getIdTokenClaims, push]);

  const EmailConfirmationForm = ({ mainTitle, subTitle }: any) => {
    return (
      <Layout className="verification-layout-style">
        <div className="verification-layout-block">
          <div className="verification-layout-block-container">
            <div className="verification-logo-container">
              <div
                className="acc-loading-logo-container"
                style={{ width: '100%', height: '40px', position: 'relative', marginTop: -20 }}
              >
                <img src={envolope} style={{width: 50}} alt='envolope.svg'/>
              </div>
            </div>
            <Layout className="verification-layout-body-style">
              <div className="div-main-title" style={{marginBottom: 20}}>
                <div className="verification-main-title">{mainTitle}</div>
              </div>
              <div className="verification-sub-title">{subTitle}</div>
            </Layout>
          </div>
        </div>
      </Layout>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F0F0F0',
        flexShrink: 0,
      }}
    >
      <div>
        <EmailConfirmationForm
          mainTitle="Please verify your email account"
          subTitle="Follow the instructions sent to your email
to verify your account."
        />
      </div>
      <button
        className="logout-button"
        onClick={async () => {
          await performLogout(logout);
        }}
      >
        Sign out
      </button>
    </div>
  );

}
