import { memo } from 'react';
import { Button, Upload } from 'antd';

import UpArrowIcon from '../../../../assets/upArrowCircle.svg';
import './styles.less';

interface ModelUploaderProps {
  uploadProps: any;
  modelType: string;
  modelName: string | undefined;
}
const ModelUploaderComponent = ({ uploadProps, modelType, modelName }: ModelUploaderProps) => {
  return (
    <div className="model-uploader-wrapper">
      <Upload {...uploadProps}>
        <Button
          id='model-upload-btn-new'
          className='model-upload-btn-new'
          type="default"
          shape="round"
          icon={<img src={UpArrowIcon} />}
          size='middle'
        >
          upload GLB Model
        </Button>
      </Upload>
      {/* <div className="model-preview">
        {modelName}
      </div> */}
    </div>
  )
}

export const ModelUploader = memo(ModelUploaderComponent);
