import { buildCoverQuery } from '../query/cover';

export async function buildCover(cover: any): Promise<any> {
  const item: any = {
    name: 'cover',
    url: '',
    file: cover,
  }

  if (cover.id) {
    const arrFileParts = cover.url?.split('/') ?? [];
    let fileUrl = '';
    if (arrFileParts.length > 0) {
      fileUrl = arrFileParts[arrFileParts.length - 1];
      const arr = fileUrl.split('?');
      fileUrl = arr.length > 0 ? arr[0] : cover.url;
    }

    item.id = cover.id;
    item.url = fileUrl;
  }

  const coverQuery = buildCoverQuery(item);

  return coverQuery;
}