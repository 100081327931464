import { Modal } from '@pankod/refine-antd';
import { delay } from '../../utils/util';
import { MODAL_DELAY } from '../../constants/delay';

export const showErrorModal = async (title: string, reason: string) => {

  Modal.error({
    title: title,
    content: reason,
    okText: 'OK',
    onOk: () => {
    },
  });
};
