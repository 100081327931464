import React, { useState } from 'react';
import { Button, Col, Typography } from '@pankod/refine-antd';
import { FullscreenOutlined, LeftOutlined } from '@ant-design/icons';
import Model from '../../../project-mobile/components/model-preview/model';
import ARButton from '../../../../../assets/ar_button.png';
import QRCodeModalComponent from '../../../../../components/ProjectDetailsForm/components/QRCode/QRCodeComponent';

import './styles.less';

export default function ProjectModelPreview({
  path,
  modelPosition,
  isModelAvailable,
  projectId,
  usdzModel,
  glbModel,
  isMap,
  expandModel,
  collapseModel,
  isModelExpanded,
  isMobile,
  autoGenerateModels,
  ...rest }: any)
{
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  return (
    <Col span={24} className={`
    model-preview-project-description 
    ${isModelExpanded ? 'expanded' : ''} 
    ${isMobile ? 'mobile' : ''}
    `}>
      <div>
        {/*[DEBUG MODE]*/}
        <Model
          isDebugButtonVisible="false"
          isModelAvailable={isModelAvailable}
          projectId={projectId}
          usdzModel={usdzModel}
          glbModel={glbModel}
        />
        {!isMap && <button className='arButtn arButtonCustom' onClick={()=>{setIsQRModalOpen(true)}} slot='ar-button'>
          <img src={ARButton} alt='*'/>
        </button>}
        <QRCodeModalComponent projectId={projectId} isQRModalOpen={isQRModalOpen} setIsQRModalOpen={setIsQRModalOpen} />
        {!isModelExpanded && !isMobile && (
          <Button
            shape='circle'
            className='expand-btn'
            icon={<FullscreenOutlined />}
            onClick={expandModel} />
        )}
        {isModelExpanded && (
          <Button
            shape='circle'
            className='collapse-btn'
            icon={<LeftOutlined />}
            onClick={collapseModel} />
        )}
        {/* {isMobile && (
        <Button
          shape='circle'
          className='collapse-mobile-btn'
          icon={<DownOutlined />}
          onClick={collapseModel} />
      )} */}
      </div>
      { autoGenerateModels &&
        <div className='padding-lr'>
          <Typography.Text
            className='secondary-sub-text disclaimer'
            type='secondary'
          >{'Model generated by inCitu based on publicly available information' }
          </Typography.Text>
        </div>
      }
    </Col>
  );
}
