import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Col, Input, Row, Typography } from 'antd'
import { useAuth0 } from '@auth0/auth0-react';
import { authProvider } from '../../authPrvider/authProvider'
import { useUpdateUser } from '../../api/user'
import performLogout from '../../hooks/auth0/useLogout';
import DashboardHeader from '../../components/DashboardHeader'

import './style.less'

const AccountDetailsMobile = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    updateUser,
    data,
  } = useUpdateUser()

  const {
    logout,
  } = useAuth0();

  useEffect(() => {
    // TODO: discuss do we need to logout user if no subscriptionPlan == null
    if (!authProvider.getToken() || !user || (user?.subscriptionPlan == null && user?.role !== 'ADMIN')) {
      performLogout(logout)
    }
  }, [])

  const [user, setUser] = useState(authProvider.getInCituUser());
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (user?.firstName !== firstName || user?.lastName !== lastName) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }, [firstName, lastName, user]);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }

  useEffect(() => {
    if (data) {
      console.log('Updated user details:', data)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      authProvider.saveInCituUser(data.updateUser);
      setUser(authProvider.getInCituUser());
    }
  }, [data])

  const handleUpdateUser = async () => {
    const payload = {
      firstName: { set: firstName },
      lastName: { set: lastName },
    }

    if (user !== undefined) {
      await updateUser(user.externalId, payload);
    }
  }

  return (
    <Row justify="center">
      <Col className="header-column" xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
        <DashboardHeader />
      </Col>
      <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="account-mobile-container">
          <div className="header-container">
            <Typography className='column-title'>Profile</Typography>
            <Button
              className='save-button'
              type='link'
              onClick={handleUpdateUser}
              disabled={!hasChanges}
            >
              Save
            </Button>
          </div>
          <div className='small-component'>
            <Typography className='small-title'>First name</Typography>
            <Input 
              className='input-item'
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </div>
          <div className='small-component'>
            <Typography className='small-title'>Last name</Typography>
            <Input 
              className='input-item'
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
          <div className='small-component'>
            <Typography className='small-title'>Email</Typography>
            <Input 
              className='input-item'
              value={email}
              disabled={true}
            />
          </div>
          <div className="action-buttons-container">
            <Button type='link' onClick={() => performLogout(logout)}>Sign out</Button>
            <Button type='link' onClick={() => {
              navigate('/projects')
            }}>Cancel</Button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default AccountDetailsMobile
