import { AutoComplete, Input } from 'antd';

const SearchComponent = ({
  searchLocation,
  goTo,
  searchItems,
  locate,
} : {
  searchLocation: any,
  goTo: any,
  searchItems: any,
  locate: any,
}) => {
  const handleSearch = (value: string) => {
    searchLocation(value);
  };

  const handleSearchLocation = (value: string) => {
    goTo(value);
  };

  const handleLocate = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    locate();
  }

  return (<AutoComplete
    className='location-search'
    dropdownMatchSelectWidth={252}
    options={searchItems?.map((item: any) => ({
      value: item.text,
      label: (
        <div
          onClick={() => goTo(item)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {item.text}
        </div>
      ),
    }))}
    onSearch={handleSearch}
  >
    <Input.Search
      className='location-search-input'
      size="large"
      placeholder="Find address or place"
      enterButton={true}
      onSearch={handleSearchLocation}
      suffix={
        <button
          className='esri-widget--button esri-widget esri-locate-button esri-custom-locate-button'
          onClick={handleLocate}
        >
          <span className='esri-icon esri-icon-locate'></span>
        </button>
      }
    />
  </AutoComplete>)
}

export default SearchComponent;
