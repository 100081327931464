import { useUpdate } from '@pankod/refine-core';

export default function useSaveDefaultAnchoring() {
  const { mutate } = useUpdate();

  const saveDefaultAnchoring = async ({ buildingId, rotation, coord, defaultAnchoringId }: any) => {
    if (buildingId) {
      if (!defaultAnchoringId) {

        const objectRotation = rotation || 0;
        const data = {
          defaultAnchoring: {
            create: {
              rotation: objectRotation,
              anchorCoordinate: {
                create: {
                  latitude: coord.latitude,
                  longitude: coord.longitude,
                  ...(coord.altitude && {
                    'altitude': coord.altitude,
                  }),
                },
              },
            },
          },
        }
        if (!data) { return }

        try {
          await mutate({
            resource: 'buildings',
            id: buildingId,
            values: data,
            mutationMode: 'optimistic',
            metaData: {
              fields: ['id'],
            },
          });
        } catch (error: any) {
          console.error('Error updating buildings model3D: ', error.message);
        }
      } else {
        const objectRotation = rotation || 0;
        const data = {
          rotation: {
            set: objectRotation,
          },
          anchorCoordinate: {
            update: {
              latitude: { set: coord.latitude },
              longitude: { set: coord.longitude },
              ...(coord.altitude && {
                'altitude': {
                  set: coord.altitude,
                },
              }),
            },
          },
        }
        if (!data) { return }

        try {
          await mutate({
            resource: 'anchoring',
            id: defaultAnchoringId,
            values: data,
            mutationMode: 'optimistic',
            metaData: {
              fields: ['id'],
            },
          });
        } catch (error: any) {
          console.error('Error updating defaultAnchoring: ', error.message);
        }
      }
    }
  };

  return [saveDefaultAnchoring];
}
